import React, { Component } from "react";
//import cn from 'classnames';
import { connect } from "react-redux";

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {};
};

class MediaBlocked extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInfo: false,
    };
  }

  componentDidMount = () => {};

  componentWillUnmount = () => {};

  render() {
    let { showInfo } = this.state;

    return (
      <div className="media-prompt prompt">
        <p>
          <strong>We cannot access your camera and microphone.</strong>
        </p>
        <p>To give access, you need to go to your browser settings</p>

        {!showInfo && (
          <button onClick={() => this.setState({ showInfo: true })}>
            Instructions
          </button>
        )}

        {showInfo && (
          <div className="info-container">
            <p>iOS Users</p>
            <ul>
              <li>
                Go to <strong>Settings</strong>.
              </li>
              <li>
                Go to <strong>Camera</strong>.
              </li>
              <li>
                Select <strong>Ask</strong> or <strong>On</strong>
              </li>
            </ul>

            <p>Android Users</p>
            <ul>
              <li>
                Go to <strong>Settings</strong>.
              </li>
              <li>
                Go to <strong>Camera</strong>.
              </li>
              <li>
                Select <strong>Ask</strong> or <strong>On</strong>
              </li>
              <li>
                Go to <strong>Microphone</strong>.
              </li>
              <li>
                Select <strong>Ask</strong> or <strong>On</strong>
              </li>
              <li>Close and restart your browser</li>
              <li>Tap on link in text message</li>
            </ul>
          </div>
        )}

        <button className="secondary" onClick={() => window.location.reload()}>
          Try Again
        </button>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MediaBlocked);
