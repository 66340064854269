import PropTypes from "prop-types";
import React from "react";
import { DateTime } from "luxon";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { fetchDocuments } from "patient_app/api/profileActions";

import LoadingSpinner from "patient_app/components/utils/LoadingSpinner";
import SubTabBack from "patient_app/views/profiles/partials/SubTabBack";

import { mobileCheck } from "patient_app/helpers/supported";
import { getIconUrl } from "patient_app/helpers/linkHelpers";

const mapDispatchToProps = (dispatch) => {
  return {
    fetchDocuments: (profileId, params, date) =>
      dispatch(fetchDocuments(profileId, params, date)),
  };
};

const mapStateToProps = (state) => {
  return {
    fromChat: state.profile.fromChat,
    hasMoreFromChat: state.profile.hasMoreFromChat,
    loading: state.profile.loading,
  };
};

class UploadedFromChat extends React.Component {
  profileId = this.props.match.params.id;

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (mobileCheck()) {
      this.props.fetchDocuments(this.profileId, ["from_chat"]);
    }
  }

  render() {
    let { fromChat, hasMoreFromChat, loading } = this.props;

    return (
      <div>
        <SubTabBack />
        <div className="profile-section last">
          <h1>Uploaded From Chat</h1>

          {!fromChat && <LoadingSpinner />}

          {fromChat && (
            <div className="profile-list documents">
              {this.renderDocuments()}

              {hasMoreFromChat && (
                <button
                  className="custom-button load-more"
                  onClick={this.handleLoadMore}
                >
                  {loading ? <LoadingSpinner /> : "Load more..."}
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }

  renderDocuments() {
    if (this.props.fromChat.length === 0) {
      return (
        <div className="list-item none">
          <div className="row">
            <p>No uploads from chat.</p>
          </div>
        </div>
      );
    }

    return this.props.fromChat.map((doc, i) => {
      return (
        <div className="list-item" key={i} data-id={doc.id}>
          <div className="row">
            <p>
              <span>{doc.title}</span>
            </p>
            <div className="col align-right">
              <p className="green">
                <span>
                  Uploaded (
                  {DateTime.fromISO(doc.created_at).toFormat("LL/dd/yy")})
                </span>
              </p>

              {doc.urls && doc.urls.length > 0 && (
                <a href={doc.urls[0]} target="_blank" className="item-action">
                  <img
                    src={getIconUrl("chevron-right", "dark-blue")}
                    alt="View"
                  />
                </a>
              )}
            </div>
          </div>
        </div>
      );
    });
  }

  handleLoadMore = () => {
    const { fromChat } = this.props;
    const date = fromChat[fromChat.length - 1].created_at;
    this.props.fetchDocuments(this.profileId, ["from_chat", "get_more"], date);
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UploadedFromChat)
);
