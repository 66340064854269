import Api from "patient_app/api";

import {
  RDT_REQ_START,
  RDT_REQ_SUCCESS,
  RDT_REQ_FAILURE,
  NEW_ERRORS,
  RDT_LOAD,
} from "emr/constants/actionTypes";

export const fetchRandomDrugTest = (userId) => {
  const data = {
    url: `/random_drug_tests/fetch_for_user`,
    data: {
      method: "GET",
    },
  };

  return async (dispatch) => {
    try {
      const res = await Api.makeRequest(data);
      if (res.success) {
        dispatch({ type: RDT_LOAD, test: res.test });
        return;
      }

      dispatch({ type: NEW_ERRORS, errors: res.errors });
    } catch (e) {
      console.log(e);
    }
  };
};
