import { DateTime } from "luxon";

let timeHelpers = {
  formatLastSentAt(iso) {
    let current = DateTime.local();
    const startOfWeek = current.startOf("week");
    const startOfDay = current.startOf("day");
    let time = DateTime.fromISO(iso);

    let str;
    //d1 < d2 // is d1 before d2?
    if (time < startOfWeek) {
      str = time.toFormat("LLL d");
      return str;
    }

    if (time < startOfDay) {
      str = time.toFormat("ccc");
      return str;
    }

    str = time.toLocaleString(DateTime.TIME_SIMPLE);
    return str;
  },
};

export default timeHelpers;
