import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

import {
  fetchTreatmentPlan,
  approveTreatmentPlan,
} from "patient_app/api/treatmentPlanActions";
import {
  OPEN_CHAT,
  NEW_ERRORS,
  NEW_FIELD_SPECIFIC_ERROR,
  CLEAR_FIELD_SPECIFIC_ERRORS,
} from "patient_app/constants/actionTypes";

import Nav from "patient_app/components/navbar/Nav";
import PatientChat from "patient_app/components/chat/PatientChat";
import ProfileField from "patient_app/views/profiles/partials/ProfileField";

import permissions from "patient_app/helpers/permissions";
import css from "styled-jsx/css";
import { variables } from "patient_app/stylesheets/variables";
import onboardingLayout from "patient_app/stylesheets/onboarding/layout";

const txPlanStyles = css.global`
  .Treatment-Plan .main-plan {
    position: relative;
    top: 4.5rem;
    margin: ${variables.base} auto;
    padding: ${variables.base};
    background: white;
    border: 1px solid ${variables.grayLight};
    height: fit-content;
    width: 28rem;
    min-height: calc(100vh - 4.5rem - (2 * ${variables.base}));
    box-sizing: border-box;
  }

  .Treatment-Plan .main-plan h1 {
    font-size: 1.2rem;
    margin-bottom: ${variables.small};
  }

  .Treatment-Plan .main-plan p:not(.field-error) {
    font-family: ${variables.objReg};
    font-size: 0.8rem;
    margin-bottom: ${variables.small};
  }

  .Treatment-Plan .main-plan p span {
    font-family: ${variables.objMed};
  }

  .Treatment-Plan .main-plan button {
    width: 100%;
    display: block;
    font-family: ${variables.objMed};
    font-size: 0.8rem;
    padding: 0.5rem 1rem;
    min-height: calc(16px * 2.5);
    box-sizing: border-box;
    border: 1px solid ${variables.green};
    border-radius: 4px;
  }

  .Treatment-Plan .main-plan button.submit {
    color: white;
    background-color: ${variables.green};
    margin-bottom: ${variables.small};
  }

  .Treatment-Plan .main-plan button.select {
    color: ${variables.green};
    background-color: white;
  }

  @media (max-width: 768px) {
    .Treatment-Plan .main-plan {
      margin: 0;
      height: calc(100vh - 4.5rem);
      border: none;
    }
  }
`;

const ApproveTreatmentPlan = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();

  const [treatmentPlans, user, userTreatmentPlans] = useSelector((state) => {
    return [
      state.treatmentPlan.treatmentPlans,
      state.common.user,
      state.treatmentPlan.userTreatmentPlans,
    ];
  });

  const plan = treatmentPlans[params.id];
  const [name, setName] = useState("");
  const [agreement, setAgreement] = useState(false);

  useEffect(() => {
    dispatch(fetchTreatmentPlan(params.id));
  }, []);

  useEffect(() => {
    if (plan?.approved) {
      history.push("/appointments");
    }
  }, [plan]);

  const handleSubmit = () => {
    dispatch({ type: NEW_ERRORS, errors: [] });
    dispatch({ type: CLEAR_FIELD_SPECIFIC_ERRORS });
    let hasErrors = false;

    if (!name || !name.includes(" ")) {
      dispatch({
        type: NEW_FIELD_SPECIFIC_ERROR,
        field: "name",
        error: "Please enter your first and last name.",
      });
      hasErrors = true;
    }

    if (!agreement) {
      dispatch({
        type: NEW_FIELD_SPECIFIC_ERROR,
        field: "agreement",
        error: "Please check this box to confirm.",
      });
      hasErrors = true;
    }

    if (hasErrors) {
      dispatch({
        type: NEW_ERRORS,
        errors: [{ text: "Please fix the errors below." }],
      });
      window.scrollTo(0, 0);
      return;
    }

    dispatch(approveTreatmentPlan(params.id, name));
  };

  const handleMessage = () => {
    if (permissions.isClinic(user)) {
      dispatch({ type: OPEN_CHAT, data: { view: "index", topic: "COACH" } });
    } else {
      dispatch({ type: OPEN_CHAT, data: { view: "index" } });
    }
  };

  return (
    <div className="Treatment-Plan">
      <Nav />
      <PatientChat />

      <div className="main-plan">
        <h1>Your Treatment Plan</h1>

        <p>
          Your Counselor has updated your treatment plan. Please read the items
          below, then sign to confirm that these goals align with your intended
          recovery goals.
        </p>

        {!plan && <p>Loading...</p>}

        {plan && (
          <div>
            <p>
              <span>Treatment Goal</span>
              <br />
              {plan.clinical_goal}
            </p>

            <p>
              <span>Treatment Objective</span>
              <br />
              {plan.objective}
            </p>

            <p>
              <span>Available Support Services</span>
              <br />
              {plan.intervention}
            </p>

            <ProfileField
              field="name"
              fieldType="text"
              onUpdateField={(value) => setName(value)}
              title="First and Last Name"
              value={name}
            />

            <ProfileField
              field="agreement"
              fieldType="checkbox"
              onUpdateField={(value) => setAgreement(value)}
              title="I agree to electronically sign this"
              value={agreement}
            />

            <button className="custom-button submit" onClick={handleSubmit}>
              Confirm Goals
            </button>

            <button className="custom-button select" onClick={handleMessage}>
              Message Counselor
            </button>
          </div>
        )}
      </div>

      <style jsx>{onboardingLayout}</style>
      <style jsx>{txPlanStyles}</style>
    </div>
  );
};

export default ApproveTreatmentPlan;
