import css from "styled-jsx/css";
import { variables } from "patient_app/stylesheets/variables.js";

export default css.global`
  .dashboard-block {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .dashboard-block p {
    font-family: ${variables.objReg};
    font-size: 0.85rem;
    color: ${variables.darkBlue};
    margin: 0;
    line-height: 1.5rem;
  }

  .dashboard-block p span {
    font-family: ${variables.objBold};
  }

  .dashboard-block .block-item {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 1.75rem;
    border-bottom: 1px solid ${variables.boxBorder};
    box-sizing: border-box;
  }

  .dashboard-block .block-item.sma {
    background: ${variables.green};
  }

  .dashboard-block .block-item.sma p {
    color: white;
  }

  .dashboard-block .block-item.action-block {
    // background: #e5edfc;
    // background: ${variables.purpleDark};
  }

  .dashboard-block .block-item.action-block img {
    // margin-right: 10px;
  }

  .dashboard-block .block-item .group-thumbnail {
    height: 4rem;
    width: 5rem;
    object-position: center;
    object-fit: cover;
    border-radius: 8px;
    overflow: hidden;
    margin-right: ${variables.small};
    background-color: ${variables.greenSplashBackground};
  }

  .dashboard-block .block-item img.group-icon {
    width: 2rem;
    height: 2rem;
  }

  .dashboard-block .block-item.disabled {
    pointer-events: none;
  }

  .dashboard-block .block-item.no-border {
    border: none;
  }

  .appointments .dashboard-block .block-item:last-child,
  .care-team .dashboard-block .block-item:last-child {
    border: none;
  }

  .dashboard-block .block-item.appt:not(.disabled) p {
    color: white;
  }

  .dashboard-block .block-item.action-block p {
    color: white;
  }

  .dashboard-block .block-item .icon-wrapper {
    margin-right: ${variables.small};
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 99px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 42px;
    height: 42px;
  }

  .dashboard-block .block-item .details {
    flex: 5;
    text-align: left;
    margin-right: 0.5rem;
  }

  .dashboard-block .block-item.disabled p {
    color: ${variables.gray};
  }

  .dashboard-block .block-item .details.overdue p {
    color: ${variables.red};
  }

  .dashboard-block .block-item p.title {
    font-family: ${variables.objBold};
    margin-bottom: 0.25rem;
  }

  .dashboard-block .block-item p.title.no-margin {
    margin-bottom: 0;
  }

  .dashboard-block .block-item p.title.curriculum {
    margin: 0;
    font-size: 1rem;
  }

  .dashboard-block .block-item p.badge {
    font-family: ${variables.objMed};
    font-size: 0.7rem;
    text-align: center;
    color: white;
    border-radius: 4px;
    padding: 0.3rem 0.5rem 0.4rem;
    margin: 0 0.5rem;
    min-width: 2.25rem;
    width: fit-content;
  }

  .dashboard-block .block-item.disabled p.badge {
    background-color: ${variables.lightText};
  }

  .dashboard-block .block-item img.alert-icon {
    height: 1.2rem;
    margin-right: 0.5rem;
  }

  .dashboard-block .block-item a {
    color: ${variables.green};
    text-decoration: underline;
  }

  .dashboard-block .block-item .add-icon {
    margin-right: 1rem;
    width: 2.5rem;
    height: 2.5rem;
    object-fit: cover;
    border-radius: 50%;
  }

  .dashboard-block .section-bar {
    background-color: ${variables.boxBorder};
    padding: 0.5rem 1.5rem;
    border-bottom: 1px solid ${variables.boxBorder};
  }

  .dashboard-block .section-bar p {
    font-size: 0.7rem;
    font-family: ${variables.objMed};
  }

  .dashboard-block .view-more {
    width: 100%;
    font-family: ${variables.objReg};
    font-size: 0.85rem;
    text-align: left;
    color: ${variables.green};
    text-decoration: underline;
    padding: 1rem 1.5rem;
    border-bottom: 1px solid ${variables.boxBorder};
  }

  .dashboard-block .next-course {
    flex: 1;
    padding: 1.75rem;
    border-bottom: 1px solid ${variables.boxBorder};
  }

  .dashboard-block .next-course p.title {
    font-family: ${variables.objBold};
  }

  .dashboard-block .care-team-icon {
    display: flex;
    justify-content: center;
    padding: 1.75rem;
  }
  .dashboard-block .care-team-icon img {
    height: 6rem;
  }

  .dashboard-block .notification {
    padding: 1.75rem;
    background-color: ${variables.lightGray};
  }

  .dashboard-block .progress-circle {
    position: relative;
  }

  .dashboard-block .progress-circle-fill {
    stroke: ${variables.green};
    fill: transparent;
    transition: stroke-dashoffset 0.35s;
    transform: rotate(-90deg);
    transform-origin: 50%;
  }

  .dashboard-block .progress-circle-outline {
    stroke: ${variables.boxBorder};
    fill: transparent;
  }

  .dashboard-block .note-text {
    color: ${variables.lightText};
    font-family: ${variables.objMed};
    margin-right: 0.5rem;
    text-align: right;
    width: 50%;
  }

  .dashboard-block .action-item-selector {
    display: flex;
  }

  .dashboard-block .action-item-selector button {
    width: 50%;
    height: 3rem;
    font-family: ${variables.objReg};
    font-size: 0.85rem;
    color: ${variables.gray};
    border-bottom: 1px solid ${variables.boxBorder};
  }

  .dashboard-block .action-item-selector button.selected {
    color: ${variables.darkBlue};
    font-family: ${variables.objMed};
    border-bottom: 1px solid ${variables.darkBlue};
  }

  @media only screen and (max-width: 600px) {
  }
`;
