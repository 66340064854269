import PropTypes from "prop-types";
import React from "react";
import cn from "classnames";

import { Router, Route, Switch, Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  SESSION_EMAIL_UPDATE,
  NEW_FORM_ERRORS,
  NEW_FIELD_SPECIFIC_ERROR,
} from "patient_app/constants/actionTypes";

import FormErrors from "patient_app/components/utils/FormErrors";

import { sendResetPasswordInstructions } from "patient_app/api/sessionActions";

import onboardingAssets from "patient_app/assets/onboarding";

import MainLayout from "patient_app/components/layouts/MainLayout";
import ContentMain from "patient_app/components/forms/ContentMain";
import ContentBox from "patient_app/components/forms/ContentBox";
import Errors from "patient_app/components/utils/Errors";
import OnboardingField from "patient_app/views/onboarding/partials/OnboardingField";
import SubmitButton from "patient_app/components/buttons/SubmitButton";

const mapDispatchToProps = (dispatch) => {
  return {
    sendResetPasswordInstructions: (email, history) =>
      dispatch(sendResetPasswordInstructions(email, history)),
    onErrors: (errors) => dispatch({ type: NEW_FORM_ERRORS, errors: errors }),
    onError: (field, error) =>
      dispatch({ type: NEW_FIELD_SPECIFIC_ERROR, field: field, error: error }),
    updateEmail: (email) =>
      dispatch({ type: SESSION_EMAIL_UPDATE, email: email }),
  };
};

const mapStateToProps = (state) => {
  return {
    loading: state.common.loading,
    errors: state.common.errors,
    email: state.session.email,
    showSignUpAction: state.session.showSignUpAction,
  };
};

class New extends React.Component {
  componentDidMount() {
    document.title = "Reset Password | Workit Health";
  }

  render() {
    let { loading, errors, email, showSignUpAction } = this.props;

    return (
      <MainLayout className="Page-Password-Reset">
        <ContentMain>
          <ContentBox className="onboarding password_reset">
            <div className="inputs">
              <h1>Enter Your Email</h1>

              <FormErrors />

              <OnboardingField
                field="session_email"
                fieldType="text"
                required={true}
                title="Email"
                value={email}
                onKeyPress={this.onKeyPress}
                onUpdateField={this.handleUpdateField}
              />

              <SubmitButton
                text={"Continue"}
                loading={loading}
                onSubmit={this.handleSubmit}
              />
            </div>
          </ContentBox>
        </ContentMain>
      </MainLayout>
    );
  }

  onKeyPress = (e) => {
    if (e.keyCode == 13) {
      this.handleSubmit();
    }
  };

  handleUpdateField = (value, field) => {
    this.props.updateEmail(value);
  };

  handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    let { email } = this.props;
    this.props.onErrors([]);

    let errors = [];
    if (!email || !email.includes("@") || !email.includes(".")) {
      errors.push({ text: "Please enter a valid email" });
      this.props.onError("session_email", "Please enter a valid email.");
    }

    if (errors.length > 0) {
      this.props.onErrors(errors);
      window.scrollTo(0, 0);
      return;
    }

    this.props.sendResetPasswordInstructions(email, this.props.history);
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(New));
