import PropTypes from "prop-types";
import React from "react";
import cn from "classnames";
import { retry } from "patient_app/helpers/supported";

import {
  Router,
  Route,
  NavLink,
  Switch,
  withRouter,
  Redirect,
} from "react-router-dom";
import { connect } from "react-redux";

import DTOSidebar from "patient_app/views/drug_test_orders/DTOSidebar";
import Nav from "patient_app/components/navbar/Nav";

const NewOrder = React.lazy(() =>
  retry(() => import("patient_app/views/drug_test_orders/NewOrder"))
);
const Quantity = React.lazy(() =>
  retry(() => import("patient_app/views/drug_test_orders/Quantity"))
);
const Address = React.lazy(() =>
  retry(() => import("patient_app/views/drug_test_orders/Address"))
);
const VerifyAddress = React.lazy(() =>
  retry(() => import("patient_app/views/drug_test_orders/VerifyAddress"))
);
const Payment = React.lazy(() =>
  retry(() => import("patient_app/views/drug_test_orders/Payment"))
);
const Delivery = React.lazy(() =>
  retry(() => import("patient_app/views/drug_test_orders/Delivery"))
);
const Review = React.lazy(() =>
  retry(() => import("patient_app/views/drug_test_orders/Review"))
);
const Confirmation = React.lazy(() =>
  retry(() => import("patient_app/views/drug_test_orders/Confirmation"))
);

import { mobileCheck } from "patient_app/helpers/supported";
import profileLayout from "patient_app/stylesheets/profiles/layout";
import dtoLayout from "patient_app/stylesheets/drug_test_orders/layout";

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {};
};

class DTORouter extends React.Component {
  componentDidMount = () => {
    document.title = "Order Drug Tests | Workit Health";
  };

  render() {
    let { mainTab } = this.props;

    const profId = this.props.match.params.id;

    return (
      <div className="Patient-App-Container">
        <Nav />

        <div id="App-Body-Content" className="App-Profile Drug-Test-Order">
          <div className="profile-container">
            {!mobileCheck() && <DTOSidebar />}

            <div id="profile-content-main">
              <div className="profile-section last">
                <Route path="/drug_test_orders/new" component={NewOrder} />
                <Route
                  path="/drug_test_orders/:id/quantity"
                  component={Quantity}
                />
                <Route
                  path="/drug_test_orders/:id/address"
                  component={Address}
                />
                <Route
                  path="/drug_test_orders/:id/verify_address"
                  component={VerifyAddress}
                />
                <Route
                  path="/drug_test_orders/:id/payment"
                  component={Payment}
                />
                <Route
                  path="/drug_test_orders/:id/delivery"
                  component={Delivery}
                />
                <Route path="/drug_test_orders/:id/review" component={Review} />
                <Route
                  path="/drug_test_orders/:id/confirmation"
                  component={Confirmation}
                />
              </div>
            </div>
          </div>
        </div>

        <style jsx>{profileLayout}</style>
        <style jsx>{dtoLayout}</style>
      </div>
    );
  }

  handleErrors = (errors) => {
    this.setState({ errors: errors });
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DTORouter)
);
