import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import {
  SESSION_SET_LOCAL_STATE,
  NEW_ERRORS,
} from "patient_app/constants/actionTypes";
import { fetchUser, fetchProfile } from "patient_app/api/commonActions";
import { fetchHealthInfo } from "patient_app/api/onboardingActions";
import { getChecklistItems } from "patient_app/api/sessionActions";
import { OPEN_CHAT } from "patient_app/constants/actionTypes";

import MainLayout from "patient_app/components/layouts/MainLayout";
import ContentMain from "patient_app/components/forms/ContentMain";
import ContentBox from "patient_app/components/forms/ContentBox";
import Nav from "patient_app/components/navbar/Nav";

import PatientChat from "patient_app/components/chat/PatientChat";

import assets from "patient_app/assets";
import { getUrlVars } from "components/utils/helpers/linkHelpers";
import permissions from "patient_app/helpers/permissions";
import checklistStyles from "patient_app/stylesheets/users/checklistStyles";

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUser: () => dispatch(fetchUser()),
    fetchProfile: (user) => dispatch(fetchProfile(user)),
    fetchHealthInfo: () => dispatch(fetchHealthInfo()),
    getChecklistItems: () => dispatch(getChecklistItems()),
    setSessionLocalState: (obj, value) =>
      dispatch({ type: SESSION_SET_LOCAL_STATE, obj: obj, value: value }),
    onErrors: (errors) => dispatch({ type: NEW_ERRORS, errors: errors }),
    openChat: (data) => dispatch({ type: OPEN_CHAT, data: data }),
  };
};

const mapStateToProps = (state) => {
  return {
    user: state.common.user,
    checklistItemsMissing: state.session.checklistItemsMissing,
    errors: state.common.errors,
    healthInfo: state.onboarding.healthInfo,
    isFetching: state.common.isFetching,
    profile: state.common.profile,
    success: state.common.success,
  };
};

class Checklist extends React.Component {
  componentDidMount = () => {
    document.title = "Checklist | Workit Health";
    this.props.setSessionLocalState("checklistItems", null);
    this.props.getChecklistItems();

    if (!this.props.user) {
      this.props.fetchUser();
    } else if (this.props.user && this.props.user.locked_out) {
      this.props.history.push("/onboarding/program_description");
    }

    if (!this.props.profile) {
      this.props.fetchProfile();
    }

    if (!this.props.healthInfo) {
      this.props.fetchHealthInfo();
    }
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.user && this.props.user && this.props.user.locked_out) {
      this.props.history.push("/onboarding/program_description");
    }

    const { checklistItemsMissing } = this.props;
    if (
      (checklistItemsMissing && checklistItemsMissing.length === 0) ||
      checklistItemsMissing === false
    ) {
      this.props.history.push("/appointments");
    }
  }

  render() {
    let { user, checklistItemsMissing, errors, profile, success } = this.props;
    let completedConsent = false;
    let completedDL = false;

    if (
      checklistItemsMissing &&
      checklistItemsMissing.filter((item) => item.model === "ClinicFile")
        .length === 0
    ) {
      completedConsent = true;
    }

    if (
      checklistItemsMissing &&
      checklistItemsMissing.filter((item) => item.model === "MedDoc").length ===
        0
    ) {
      completedDL = true;
    }

    return (
      <div className="Patient-App-Container Checklist">
        {user && profile && <Nav />}

        {user && profile && (
          <PatientChat
            coachName={"Counselor"}
            userIsClinic={permissions.isClinic(user)}
            cta={permissions.isCore(user)}
            chatSessionId={getUrlVars(window.location.href)["chat_session_id"]}
            color="#1A7A65"
          />
        )}

        <MainLayout>
          <ContentMain>
            {user && profile && checklistItemsMissing && (
              <ContentBox className="checklist with-nav">
                <h1>
                  Welcome {!!user.pref_name ? user.pref_name : user.fir_name}!
                </h1>

                {permissions.isClinic(user) ? (
                  <div className="banner">
                    <p className="no-margin">
                      <span>Action required!</span> Your appointment will be
                      canceled if you have not completed the following steps by
                      48 hours prior to your scheduled appointment time.
                    </p>
                  </div>
                ) : (
                  <div className="banner">
                    <p className="no-margin">
                      Please complete the following items to continue.
                    </p>
                  </div>
                )}

                <Link to={`/consent_to_treatment/new`} className="block-item">
                  <p className="item-title">Consent and Legal</p>

                  {completedConsent ? (
                    <p className="note-text">Completed</p>
                  ) : (
                    <div className="right">
                      <p className="note-text">2-5 min</p>
                      <img src={assets.chevronRightNavy} />
                    </div>
                  )}
                </Link>

                {permissions.isClinic(user) && (
                  <Link
                    to={`/profiles/${profile.id}/drivers_license`}
                    className="block-item"
                  >
                    <p className="item-title">Driver's License Upload</p>
                    {completedDL ? (
                      <p className="note-text">Completed</p>
                    ) : (
                      <div className="right">
                        <p className="note-text">2 min</p>
                        <img src={assets.chevronRightNavy} />
                      </div>
                    )}
                  </Link>
                )}

                <div className="message-support">
                  <p>
                    Questions?{" "}
                    <a href="#" onClick={this.openChat}>
                      Message Support
                    </a>
                  </p>
                </div>
              </ContentBox>
            )}
          </ContentMain>
        </MainLayout>

        <style jsx>{checklistStyles}</style>
      </div>
    );
  }

  complete = () => {
    window.location.href = "/appointments";
  };

  openChat = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.props.openChat({
      view: "index",
      prepopulatedMessage:
        "Hi, I have a question about my pre-appointment check list.",
      topic: "ADMIN",
    });
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Checklist);
