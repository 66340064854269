let assets = {
  defaultProfilePic:
    "https://storage.googleapis.com/workit-client-assets/chat/default-profile-pic.png",
  camera: "https://storage.googleapis.com/workit-client-assets/chat/camera.svg",
  sendButton:
    "https://storage.googleapis.com/workit-client-assets/chat/send-button.svg",
  sendButtonDarkGreen:
    "https://storage.googleapis.com/workit-client-assets/chat/chat_send-button-dark-green.svg",
  ellipsis:
    "https://storage.googleapis.com/workit-client-assets/chat/ellipsis%20(1).gif",
  openQuotes:
    "https://storage.googleapis.com/workit-client-assets/chat/quotes_open.svg",
  closedQuotes:
    "https://storage.googleapis.com/workit-client-assets/chat/quotes_closed.svg",
  closeWhite:
    "https://storage.googleapis.com/workitassets/images/dashboard/icons/close-white.svg",
  closeGray:
    "https://storage.googleapis.com/workitassets/images/dashboard/icons/close-gray.svg",
  phone:
    "https://storage.googleapis.com/workitassets/images/dashboard/icons/phone.svg",
  goBack:
    "https://storage.googleapis.com/workitassets/images/dashboard/icons/go-back-graytrim.svg",
  rightArrow:
    "https://storage.googleapis.com/workitassets/images/dashboard/icons/arrow-forward-gray.svg",
  search:
    "https://storage.googleapis.com/workitassets/images/dashboard/icons/search.svg",
  downArrow:
    "https://storage.googleapis.com/workit-client-assets/chat/down-arrow.svg",
  emergency:
    "https://storage.googleapis.com/workit-client-assets/chat/Asterisk.svg",
  emergencyFilled:
    "https://storage.googleapis.com/workit-client-assets/chat/EmergencyButtonFilled.svg",
  speechBubble:
    "https://storage.googleapis.com/workit-client-assets/chat/SpeechBubble.svg",
  supportChat:
    "https://storage.googleapis.com/workit-client-assets/chat/support-chat.png",
};

export default assets;
