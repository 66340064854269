import PropTypes from "prop-types";
import React from "react";
import cn from "classnames";

import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { fetchUser, fetchProfile } from "patient_app/api/commonActions";
import { fetchCurriculumSection } from "patient_app/api/curriculumActions";
import {
  checkIfQuestionnaireDue,
  checkIfPendingItem,
} from "patient_app/api/sessionActions";
import { NEW_ERRORS } from "patient_app/constants/actionTypes";

import Nav from "patient_app/components/navbar/Nav";
import LoadingSpinner from "patient_app/components/utils/LoadingSpinner";
import PatientChat from "patient_app/components/chat/PatientChat";
import Cluster from "patient_app/views/curriculums/partials/Cluster";
import DashboardBanner from "patient_app/components/utils/DashboardBanner";

import { getUrlVars } from "components/utils/helpers/linkHelpers";
import permissions from "patient_app/helpers/permissions";
import assets from "patient_app/assets";
import curriculumHelper from "patient_app/helpers/curriculumHelper";

import courseStyles from "patient_app/stylesheets/curriculums/courseStyles";

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCurriculumSection: (curriculumId, sectionId, userId) =>
      dispatch(fetchCurriculumSection(curriculumId, sectionId, userId)),
    checkIfQuestionnaireDue: () => dispatch(checkIfQuestionnaireDue()),
    checkIfPendingItem: () => dispatch(checkIfPendingItem()),
    fetchUser: () => dispatch(fetchUser()),
    fetchProfile: (user) => dispatch(fetchProfile(user)),
    onErrors: (errors) => dispatch({ type: NEW_ERRORS, errors: errors }),
  };
};

const mapStateToProps = (state) => {
  return {
    user: state.common.user,
    profile: state.common.profile,
    errors: state.common.errors,
    success: state.common.success,
    isFetching: state.common.isFetching,
    curriculum: state.curriculum.curriculum,
    sections: state.curriculum.sections,
    skipLoading: state.curriculum.skipLoading,
    selectedItem: state.curriculum.selectedItem,
    gutCheckDue: state.session.gutCheckDue,
    alcoholCheckDue: state.session.alcoholCheckDue,
    hasPendingItem: state.session.hasPendingItem,
    path: state.session.path,
  };
};

class CourseSection extends React.Component {
  sectionId = getUrlVars(window.location.href)["section_id"];

  componentDidMount = () => {
    document.title = "Courses | Workit Health";

    let { user, profile, curriculum, gutCheckDue, hasPendingItem } = this.props;
    if ([null, undefined].includes(gutCheckDue)) {
      this.props.checkIfQuestionnaireDue();
    }
    if ([null, undefined].includes(hasPendingItem)) {
      this.props.checkIfPendingItem();
    }

    if (!user) {
      this.props.fetchUser();
    } else if (user && user.locked_out) {
      this.props.history.push("/onboarding/program_description");
    }

    !profile && this.props.fetchProfile();

    const curriculumId = this.props.match.params.id;
    if (!curriculumId || !this.sectionId) {
      this.props.onErrors([
        { status: "error", text: "Could not fetch courses." },
      ]);
      this.props.history.push("/courses");
    } else if (curriculumId && this.sectionId) {
      const userId = getUrlVars(window.location.href)["user_id"];
      this.props.fetchCurriculumSection(curriculumId, this.sectionId, userId);
    }
  };

  componentDidUpdate = async (prevProps) => {
    if (!prevProps.user && this.props.user && this.props.user.locked_out) {
      this.props.history.push("/onboarding/program_description");
    }

    if (!prevProps.gutCheckDue && this.props.gutCheckDue) {
      window.location.href = "/gut_checks/new";
    }

    if (!prevProps.alcoholCheckDue && this.props.alcoholCheckDue) {
      window.location.href = "/alcohol_checks/new";
    }

    if (
      !prevProps.hasPendingItem &&
      this.props.hasPendingItem &&
      this.props.hasPendingItem === true
    ) {
      window.location.href = this.props.path;
    }

    if (
      this.props.profile &&
      this.props.paywall &&
      this.props.paywall === true
    ) {
      this.props.history.push(
        `/profiles/${this.props.profile.id}/payment?status=failed&tab=payment_details`
      );
      this.props.onErrors([
        { text: "Please update your payment information to continue." },
      ]);
      return;
    }
  };

  render() {
    let { sections, user } = this.props;

    const section =
      sections[this.sectionId] &&
      curriculumHelper.prepareForRender([sections[this.sectionId]])[0];
    let clusters, locked;
    if (section && section.clusters) {
      clusters = section.clusters;
      locked = section.locked;
    }

    const userIdFromUrl = getUrlVars(window.location.href)["user_id"];

    return (
      <div className="Patient-App-Container">
        {user && (
          <div>
            <Nav />

            <div
              id="App-Body-Content"
              className={cn("App-Courses-Show", !section && "loading")}
              role="main"
            >
              <PatientChat
                coachName={"Counselor"}
                userIsClinic={permissions.isClinic(user)}
                cta={permissions.isCore(user)}
                chatSessionId={
                  getUrlVars(window.location.href)["chat_session_id"]
                }
                color="#1A7A65"
              />

              {!section ? (
                <LoadingSpinner />
              ) : (
                <div className="Courses-Container">
                  <div className="Tabs-Courses">
                    <div
                      className={cn("tab section", locked ? "locked" : "open")}
                    >
                      <div className="header">
                        <button
                          className="custom-button back"
                          onClick={this.returnToCourseList}
                        >
                          <img src={assets.chevronLeftNavy} />
                        </button>
                        <h1>{section.title}</h1>
                      </div>

                      {/*section.description && (
                        <p className="description">
                          {section.description}
                        </p>
                      )*/}

                      {clusters && this.renderClusters(clusters, locked)}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        <style jsx>{courseStyles}</style>

        {/* disable buttons if admin is viewing */}
        <style global jsx>{`
          #App-Body-Content button,
          #App-Body-Content a {
            pointer-events: ${!!userIdFromUrl ? "none" : ""};
          }
        `}</style>
      </div>
    );
  }

  setCurriculum = (curriculum) => {
    let sections = curriculumHelper.prepareForRender(curriculum.sections);

    sections = curriculumHelper.assignCurrent(sections);

    if (curriculum.completed === true) {
      sections = curriculumHelper.insertCompleted(sections);
    }

    this.props.loadSections(sections);
  };

  renderClusters = (clusters, locked) => {
    let { ...props } = this.props;

    return clusters.map((cluster, i) => {
      return (
        <Cluster
          {...props}
          cluster={cluster}
          sectionLocked={locked}
          key={i}
          clusterPosition={i + 1}
        />
      );
    });
  };

  returnToCourseList = () => {
    let curriculumId = this.props.match.params.id;
    let url = "/courses";
    if (curriculumId !== "default") {
      url = `/curriculums/${curriculumId}`;
    }

    const userId = getUrlVars(window.location.href)["user_id"];
    if (userId) {
      url += `?user_id=${userId}`;
    }

    this.props.history.push(url);
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseSection);
