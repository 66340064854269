import PropTypes from "prop-types";
import React from "react";
import assets from "patient_app/components/chat/assets";
import ContentEditable from "react-contenteditable";
import { mobileCheck } from "patient_app/helpers/supported";
import { debounce } from "throttle-debounce";

import cn from "classnames";

class Controls extends React.Component {
  constructor(props) {
    super(props);
    this.contentEditable = React.createRef();
  }

  render() {
    let {
      focused,
      loading,
      chatDisabled,
      uploadingImage,
      messageContent,
      message,
      fileInputRef,
      canInsertHyperlink,
      showHyperlink,
      tabIndex,
    } = this.props;

    return (
      <div
        className={cn(
          "controls",
          focused ? "focused" : "",
          loading ? "loading" : ""
        )}
        id="controls"
      >
        <div
          className={cn(
            "photo-upload",
            chatDisabled || uploadingImage ? "disabled" : ""
          )}
        >
          <input
            type="file"
            id="file-upload"
            onChange={this.props.handleFileUpload}
            ref={fileInputRef}
            tabIndex={tabIndex}
          />
          <button
            aria-label="file-upload"
            onClick={() => fileInputRef.current.click()}
          >
            {!uploadingImage && <img src={assets.camera} alt="camera" />}
            {uploadingImage && (
              <img
                src="https://storage.googleapis.com/workit-client-assets/chat/loading.gif"
                alt="loading-spinner"
                className="img-upload-loading"
              />
            )}
          </button>
        </div>
        {canInsertHyperlink && (
          <i
            className="material-icons link-icon"
            onClick={this.props.toggleHyperlink}
          >
            insert_link
          </i>
        )}

        <ContentEditable
          innerRef={this.contentEditable}
          html={messageContent} // innerHTML of the editable div
          onChange={this.props.handleInput} // handle innerHTML change
          className={cn("text-input", chatDisabled ? "disabled" : "")}
          onKeyDown={(e) => this.handleKeyDown(e)}
          onKeyUp={(e) => this.handleKeyUp(e)}
          onBlur={this.props.handleBlur}
          onFocus={this.props.handleFocus}
          tabIndex={tabIndex}
        />

        <div className="send-cont">
          {this.canSendMessage(message) && (
            <button
              className="custom-button"
              onClick={(e) => this.handleSend(e)}
              tabIndex={tabIndex}
              name="send-message-button"
            >
              <img src={assets.sendButtonDarkGreen} alt="send" />
            </button>
          )}
        </div>

        {this.props.children}
      </div>
    );
  }

  handleSend = (e) => {
    if (e) {
      e.preventDefault();
    }

    let { message } = this.props;
    let userAgent = "comp";
    let cleanMessage = this.parseHtml(message);
    let canSend = this.canSendMessage(cleanMessage);

    if (canSend) {
      this.props.handleSend(cleanMessage);
    }

    if (mobileCheck()) {
      console.log("resign keyboard");
    }
  };

  parseHtml = (message) => {
    const pStart = new RegExp("<p[^>]*?>", "g");
    const pEnd = new RegExp("</p>", "g");

    let divHtml = message;
    divHtml = divHtml.replace(pStart, "");
    divHtml = divHtml.replace(pEnd, "<br>");

    return divHtml;
  };

  canSendMessage(message) {
    let canSend = false;
    let divHtml;
    if (!message || !this.contentEditable.current) {
      return canSend;
    }

    divHtml = this.contentEditable.current.innerHTML;
    const br = new RegExp("<br>", "g");
    const empty = new RegExp(" ", "g");
    const whiteSpace = new RegExp("&nbsp;", "g");
    divHtml = divHtml.replace(br, "");
    divHtml = divHtml.replace(empty, "");
    divHtml = divHtml.replace(whiteSpace, "");

    if (divHtml.length > 0) {
      canSend = true;
    }

    return canSend;
  }

  handleKeyDown = (e) => {
    let keyCode = e.keyCode;
    let { shiftPressed } = this.props;

    if (keyCode === 16) {
      this.props.onShiftKeyChange(true);
    }

    if (keyCode === 13 && shiftPressed) {
      return;
    }

    if (keyCode === 13) {
      e.preventDefault();
      this.handleSend();
    }

    debounce(500, this.props.startTypingAlert());
  };

  handleKeyUp = (e) => {
    let keyCode = e.keyCode;

    if (keyCode === 16) {
      this.props.onShiftKeyChange(false);
    }
  };
}

export default Controls;
