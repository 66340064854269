import React, { Component } from "react";
import cn from "classnames";
import { connect } from "react-redux";

import { PAGE_LOAD } from "patient_app/constants/actionTypes";
import icons from "patient_app/assets/videoCallIcons";
import { getMediaPermissions } from "patient_app/api/videoCallActions";

import ActiveVideo from "patient_app/components/video/ActiveVideo";
import Errors from "patient_app/components/video/Errors";
import LocalVideoThumb from "patient_app/components/video/LocalVideoThumb";
import MediaPermissions from "patient_app/components/video/MediaPermissions";
import RemoteVideo from "patient_app/components/video/RemoteVideo";
import VideoControls from "patient_app/components/video/VideoControls";
import BusyScreen from "patient_app/views/video_rooms/partials/BusyScreen";

import videoCallStyles from "patient_app/stylesheets/video_calls/styles";

const mapDispatchToProps = (dispatch) => {
  return {
    onPageLoad: () => dispatch({ type: PAGE_LOAD }),
    getMediaPermissions: () => dispatch(getMediaPermissions()),
  };
};

const mapStateToProps = (state) => {
  return {
    callEnded: state.videoCall.callEnded,
    loading: state.videoCall.loading,
    initialLoading: state.videoCall.initialLoading,
    initialLoadingError: state.videoCall.initialLoadingError,
    roomStatus: state.videoCall.roomStatus,
    token: state.videoCall.token,
    zoomEventId: state.videoCall.zoomEventId,
    mediaPermissionsAllowed: state.videoCall.mediaPermissionsAllowed,
  };
};

class Video extends Component {
  componentDidMount = () => {
    this.props.getMediaPermissions();
  };

  render() {
    let {
      callEnded,
      initialLoading,
      initialLoadingError,
      loading,
      mediaPermissionsAllowed,
      roomStatus,
      token,
      zoomEventId,
    } = this.props;

    return (
      <div className="outer-video-call-container">
        <div className={cn("App-Video-Call", loading ? "loading" : "")}>
          {!mediaPermissionsAllowed && <MediaPermissions />}

          <Errors />
          {!initialLoading && initialLoadingError && (
            <div className="error-container prompt">
              <p>{initialLoadingError}</p>
            </div>
          )}

          {mediaPermissionsAllowed && (
            <div className="main-video-container">
              <BusyScreen />
              <LocalVideoThumb />

              {!initialLoading &&
                !initialLoadingError &&
                !["WAITING", "DISCONNECTED"].includes(roomStatus) && (
                  <div>
                    <RemoteVideo />
                    <VideoControls />
                  </div>
                )}
            </div>
          )}
        </div>

        <style jsx>{videoCallStyles}</style>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Video);
