const icons = {
  spinner:
    "https://storage.googleapis.com/workit-client-assets/chat/loading.gif",
  face:
    "https://storage.googleapis.com/workit-client-assets/icons/face-24px.svg",
  callEnd:
    "https://storage.googleapis.com/workit-client-assets/video/white_call_end-24px.svg",
  videoOn:
    "https://storage.googleapis.com/workit-client-assets/video/white_videocam-24px.svg",
  videoOff:
    "https://storage.googleapis.com/workit-client-assets/video/white_videocam_off-24px.svg",
  audioOn:
    "https://storage.googleapis.com/workit-client-assets/video/white_volume_up-24px.svg",
  audioOff:
    "https://storage.googleapis.com/workit-client-assets/video/white_volume_off-24px.svg",
};

export default icons;
