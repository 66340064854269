import {
  CURRICULUM_LOAD,
  CURRICULUM_LOAD_SECTION,
  CURRICULUM_TOGGLE_SKIP_LOADING,
  CURRICULUM_UPDATE_SELECTED_ITEM,
  CURRICULUM_LOAD_SPECIAL_DELIVERY,
  CURRICULUM_SKIPPED,
} from "patient_app/constants/actionTypes";
import _ from "lodash";

const initialState = {
  skipLoading: false,
  sections: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CURRICULUM_LOAD:
      return {
        ...state,
        curriculum: action.curriculum,
      };
    case CURRICULUM_LOAD_SECTION:
      return {
        ...state,
        sections: {
          ...state.section,
          [action.section.id]: action.section,
        },
      };
    case CURRICULUM_TOGGLE_SKIP_LOADING:
      return {
        ...state,
        skipLoading: action.skipLoading,
      };
    case CURRICULUM_UPDATE_SELECTED_ITEM:
      return {
        ...state,
        selectedItem: action.selectedItem,
      };
    case CURRICULUM_LOAD_SPECIAL_DELIVERY:
      return {
        ...state,
        specialDelivery: action.specialDelivery,
      };
    case CURRICULUM_SKIPPED:
      let section = state.sections[action.skippedItem.curriculum_section_id];

      if (!section) {
        return state;
      }

      let updatedSection = Object.assign({}, section);
      let skippedIdx = updatedSection.items.findIndex(
        (item) => item.id === action.skippedItem.id
      );
      let nextIdx = updatedSection.items.findIndex(
        (item) => item.id === action.nextItem.id
      );

      if (skippedIdx) {
        updatedSection.items[skippedIdx] = action.skippedItem;
      }
      if (nextIdx) {
        updatedSection.items[nextIdx] = action.nextItem;
      }

      return {
        ...state,
        sections: {
          ...state.sections,
          [action.skippedItem.curriculum_section_id]: updatedSection,
        },
      };
    default:
      return state;
  }
};
