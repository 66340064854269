import PropTypes from "prop-types";
import React from "react";
import cn from "classnames";

import commonAssets from "patient_app/assets";
import timezones from "patient_app/components/input_fields/utils/constants";

class TimeZoneField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      showSearch: false,
      timeZoneOptions: timezones,
      optionValue: 0,
    };

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.escapeTimeZone = this.escapeTimeZone.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    document.addEventListener("keydown", this.escapeTimeZone, false);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    document.removeEventListener("keydown", this.escapeTimeZone);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ showSearch: false, query: "" });
    }
  };

  escapeTimeZone = (event) => {
    if (event.keyCode === 27) {
      this.setState({ showSearch: false, query: "" });
    }
  };

  render() {
    let { title, timeZone, openDirection } = this.props;

    let { query, showSearch, timeZoneOptions } = this.state;

    let tzFormatted =
      timeZone && timeZone.includes("/")
        ? this.formatTimeZone(timezones, timeZone)
        : timeZone;

    // set direction for search to be absolutely positioned
    let direction = openDirection ? openDirection : "down";

    return (
      <div className="timezone-field" ref={this.setWrapperRef}>
        <label id={title + "-label"} htmlFor="selected-timezone">
          {title}
        </label>
        <button
          id="selected-timezone"
          className="custom-button text"
          onClick={(e) => this.toggleSearch(e.target.value)}
          onKeyDown={(e) => {
            if (e.keyCode === 13) this.toggleSearch(e);
          }}
          tabIndex={0}
          aria-label="Select your time zone"
          aria-haspopup="listbox"
        >
          {tzFormatted}
          <i className="ico ico-arrow-down ico--small"></i>
        </button>

        <div
          className={cn("search", showSearch ? "" : "hidden", [direction])}
          onKeyDown={this.handleKeyPress}
        >
          <input
            id="timezone"
            type="text"
            value={query}
            onChange={(e) => this.handleChange(e.target.value)}
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            placeholder="Search..."
            aria-label="Search for time zones"
          />

          <div
            className="options"
            role="listbox"
            aria-labelledby={title + "-label"}
            aria-activedescendant={this.htmlify(tzFormatted)}
          >
            {this.renderOptions(timeZoneOptions)}
          </div>
        </div>
      </div>
    );
  }

  htmlify(str) {
    return (str && str.length) > 0
      ? str.replace(/\W+/g, "-").toLowerCase()
      : "";
  }

  formatTimeZone(timezones, tz) {
    for (let t of timezones) {
      if (t[0] === tz) return t[1];
    }

    return tz; // if not found...
  }

  renderOptions = (options) => {
    return options.map((option, i) => {
      return (
        <button
          key={option[0]}
          id={this.htmlify(option[1])}
          className={cn(
            "custom-button timezone-option",
            this.state.optionValue === i ? "hover" : ""
          )}
          onClick={() => this.onSelect(option[0])}
          role="option"
        >
          {option[1]}
        </button>
      );
    });
  };

  filter(options, value) {
    let filtered = [];

    if (!value || value.length === 0) {
      return options;
    }

    for (let option of options) {
      const x1 = option[0];
      const x2 = option[1];
      let y = value;
      y = y.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"); // regex breaks if special characters are typed
      const rgxp = new RegExp(y, "gi");
      const result1 = x1.match(rgxp);
      const result2 = x2.match(rgxp);
      if ((result1 && result1.length) || (result2 && result2.length)) {
        filtered.push(option);
      }
    }

    return filtered;
  }

  toggleSearch = (e) => {
    if (e) e.preventDefault();

    if (this.state.showSearch) {
      this.handleChange(""); // clear query, reset all options
    } else {
      let element = document.getElementById("timezone");
      if (element) element.focus();
    }

    this.setState({ showSearch: !this.state.showSearch });
  };

  handleChange = (value) => {
    let filtered = this.filter(timezones, value);
    this.setState({ query: value, timeZoneOptions: filtered });
  };

  handleKeyPress = (e) => {
    let { timeZoneOptions, optionValue, showSearch } = this.state;
    let newValue = -1;

    if (showSearch) {
      switch (e.keyCode) {
        case 13: // enter
          if (timeZoneOptions[optionValue]) {
            this.onSelect(timeZoneOptions[optionValue][0]);
            this.setState({ optionValue: 0 });
          }
          break;
        case 40: // down arrow
          newValue =
            optionValue + 1 < timeZoneOptions.length
              ? optionValue + 1
              : timeZoneOptions.length - 1;
          this.setState({ optionValue: newValue });
          break;
        case 38: // up arrow
          newValue = optionValue - 1 >= 0 ? optionValue - 1 : 0;
          this.setState({ optionValue: newValue });
          break;
        default:
          break;
      }
    }

    // scroll element into view if necessary
    // let element = document.getElementById("timezone-option-"+newValue)
    let element =
      newValue >= 0 &&
      document.getElementById(this.htmlify(timeZoneOptions[newValue][1]));
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  };

  onSelect = (option) => {
    this.setState({ query: "", showSearch: false, timeZoneOptions: timezones });
    this.props.onSelect(option);
  };
}

export default TimeZoneField;
