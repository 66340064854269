import PropTypes from "prop-types";
import React from "react";
import { DateTime } from "luxon";

import { Router, withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";

import { checkUserSignedIn } from "patient_app/api/commonActions";
import { getUrlVars } from "components/utils/helpers/linkHelpers";

import onboardingAssets from "patient_app/assets/onboarding";

import MainLayout from "patient_app/components/layouts/MainLayout";
import ContentMain from "patient_app/components/forms/ContentMain";
import ContentBox from "patient_app/components/forms/ContentBox";
import SubmitButton from "patient_app/components/buttons/SubmitButton";

import SessionStore from "patient_app/helpers/SessionStore";

import layout from "patient_app/stylesheets/onboarding/layout";

const mapDispatchToProps = (dispatch) => {
  return {
    checkUserSignedIn: () => dispatch(checkUserSignedIn()),
  };
};

const mapStateToProps = (state) => {
  return {
    user: state.onboarding.newUser,
    userSignedIn: state.common.userSignedIn,
  };
};

class Welcome extends React.Component {
  componentDidMount = () => {
    document.title = "Welcome | Workit Health";
    this.checkForIncomingParams();

    const user = SessionStore.get("user");
    if (user) {
      SessionStore.remove("user");
    }
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.userSignedIn) {
      console.log("user signed in");
      this.props.history.push("/onboarding/program_description");
    }

    if (prevProps.user.state !== this.props.user.state) {
      SessionStore.set("user", this.props.user);
    }
  };

  render() {
    let { errors, formErrors, loading, user } = this.props;

    return (
      <MainLayout>
        <ContentMain>
          <ContentBox className="onboarding">
            {user && (
              <div className="inputs">
                <h1>Sign up for Workit Health</h1>

                <p className="description">
                  Get started by answering a few questions to help us determine
                  which program is right for you.
                </p>

                <SubmitButton
                  text={"Continue"}
                  loading={loading}
                  onSubmit={() =>
                    this.props.history.push("/onboarding/safety_check")
                  }
                />

                <p className="sign-in-text mobile-only">
                  Already have an account? <Link to="/signin">Sign in</Link>
                </p>
                <p className="sign-in-text mobile-only">
                  Already scheduled an appointment?{" "}
                  <Link to="/activate">Activate</Link>
                </p>

                <div className="under-content-box">
                  <div className="text">
                    <p className="sign-in-text web-only">
                      Already have an account? <Link to="/signin">Sign in</Link>
                    </p>
                    <p className="sign-in-text web-only">
                      Already scheduled an appointment?{" "}
                      <Link to="/activate">Activate</Link>
                    </p>
                  </div>
                </div>
              </div>
            )}
          </ContentBox>
        </ContentMain>
        <style jsx>{layout}</style>
      </MainLayout>
    );
  }

  checkForIncomingParams = () => {
    // cookie expiration dates require a javascript date
    // format: new Date(new Date().getTime() + minutes * 60000);
    const cookieExpires = new Date(new Date().getTime() + 60 * 60000);
    try {
      const params = getUrlVars(window.location.href);
      // member referral code
      if (params["referral_code"]) {
        window.AF("banners", "hideBanner");
        SessionStore.set("member_referral_code", params["referral_code"]);
      }

      // company referral code
      params["ref_code"] &&
        SessionStore.set("company_ref_code", params["ref_code"], cookieExpires);
      params["tier"] &&
        SessionStore.set("referral_tier_param", params["tier"], cookieExpires);
      params["treatment_type"] &&
        SessionStore.set(
          "referral_treatment_type_param",
          params["treatment_type"]
        );
      params["platform"] &&
        SessionStore.set("user_platform", params["platform"], cookieExpires);
    } catch (e) {
      console.log(e, "error saving incoming params");
    }
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Welcome)
);
