import {
  INSURANCE_REQ_START,
  INSURANCE_REQ_SUCCESS,
  INSURANCE_REQ_FAILURE,
  INSURANCE_LOADING,
  INSURANCE_UPDATE_STATE,
  INSURANCE_UPDATE_COVERAGE,
  INSURANCE_PAGE_LOAD,
  UPDATE_INSURANCE_DETAILS_LOCAL,
  TOGGLE_COVERAGE_CHECK_IN_PROGRESS,
  INSURANCE_UPDATE_ELIGIBLE_COVERAGE,
  UPDATE_PATIENT_PAYMENT,
  INSURANCE_INFO_LOAD,
  INSURANCE_CLEAR_COVERAGE_INFO,
} from "patient_app/constants/actionTypes";

const initialState = {
  coverageCheckInProgress: false,
  coverageInfo: {
    planName: "",
    insuranceProviderId: "",
    groupId: "",
    memberId: "",
    firName: "",
    lasName: "",
    dob: "",
    state: "",
    zipCode: "",
    program: "",
    relationshipToPolicyholder: "",
    policyholderLasName: "",
    policyholderFirName: "",
    policyholderDob: "",
  },
  coverageStatus: null,
  isFetching: false,
  loading: false,
  success: false,
  onboardingUser: null,
  insuranceProviders: [],
  formType: "image",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case INSURANCE_PAGE_LOAD:
      return {
        ...state,
        isFetching: false,
        success: false,
        coverageStatus: null,
        eligibleCoverageStatus: null,
        paymentAmount: null,
      };
    case INSURANCE_REQ_START:
      return {
        ...state,
        isFetching: true,
        loading: true,
        success: false,
      };
    case INSURANCE_REQ_SUCCESS:
      return {
        ...state,
        isFetching: false,
        loading: false,
        success: true,
      };
    case INSURANCE_REQ_FAILURE:
      return {
        ...state,
        isFetching: false,
        loading: false,
        success: false,
      };
    case INSURANCE_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case INSURANCE_UPDATE_STATE:
      return {
        ...state,
        [action.obj]: action.value,
      };
    case INSURANCE_UPDATE_COVERAGE:
      return {
        ...state,
        coverageStatus: action.coverage,
      };
    case INSURANCE_UPDATE_ELIGIBLE_COVERAGE:
      return {
        ...state,
        eligibleCoverageStatus: action.eligibleCoverageStatus,
      };
    case UPDATE_INSURANCE_DETAILS_LOCAL:
      return {
        ...state,
        insuranceDetails: action.insuranceDetails,
      };
    case TOGGLE_COVERAGE_CHECK_IN_PROGRESS:
      return {
        ...state,
        coverageCheckInProgress: action.bool,
      };
    case UPDATE_PATIENT_PAYMENT:
      return {
        ...state,
        paymentAmount: action.amount,
      };
    case INSURANCE_INFO_LOAD:
      return {
        ...state,
        insuranceProviders: action.insuranceProviders,
      };
    case INSURANCE_CLEAR_COVERAGE_INFO:
      let updatedInfo = { ...state.coverageInfo };
      Object.keys(updatedInfo).forEach((key) => {
        updatedInfo[key] = "";
      });
      return {
        ...state,
        coverageInfo: updatedInfo,
      };
    default:
      return state;
  }
};
