import PropTypes from "prop-types";
import React from "react";
import Api from "patient_app/api";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { fetchProfile } from "patient_app/api/commonActions";
import {
  fetchPayment,
  chargePrimaryCard,
} from "patient_app/api/profileActions";
import {
  PROFILE_REQ_START,
  PROFILE_REQ_SUCCESS,
  PROFILE_REQ_FAILURE,
  NEW_FIELD_SPECIFIC_ERROR,
  NEW_ERRORS,
  CLEAR_FIELD_SPECIFIC_ERRORS,
  OPEN_CHAT,
  TOGGLE_PAYWALL,
  PROFILE_UPDATE_STATE_LOCAL,
  USER_LOAD,
} from "patient_app/constants/actionTypes";

import LoadingSpinner from "patient_app/components/utils/LoadingSpinner";
import SubTabBack from "patient_app/views/profiles/partials/SubTabBack";
import ProfileField from "patient_app/views/profiles/partials/ProfileField";

import { mobileCheck } from "patient_app/helpers/supported";
import permissions from "patient_app/helpers/permissions";
import stripeHelpers from "patient_app/helpers/stripeHelpers";

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPayment: (profileId, params) =>
      dispatch(fetchPayment(profileId, params)),
    openChat: (data) => dispatch({ type: OPEN_CHAT, data: data }),
    startSubmitCard: () => dispatch({ type: PROFILE_REQ_START }),
    successSubmitCard: () => {
      dispatch({ type: PROFILE_REQ_SUCCESS }),
        dispatch({
          type: NEW_ERRORS,
          errors: [{ status: "info", text: "Successfully updated card." }],
        });
    },
    failureSubmitCard: (errors) => {
      dispatch({ type: PROFILE_REQ_FAILURE }),
        dispatch({ type: NEW_ERRORS, errors: errors });
    },
    togglePaywall: (paywall) =>
      dispatch({ type: TOGGLE_PAYWALL, paywall: paywall }),
    loadUser: (user) => dispatch({ type: USER_LOAD, user: user }),
    chargePrimaryCard: (profileId, user) =>
      dispatch(chargePrimaryCard(profileId, user)),
    newFieldSpecificError: (field, error) =>
      dispatch({ type: NEW_FIELD_SPECIFIC_ERROR, field: field, error: error }),
    newGeneralErrors: (errors) =>
      dispatch({ type: NEW_ERRORS, errors: errors }),
    clearFieldSpecificErrors: () =>
      dispatch({ type: CLEAR_FIELD_SPECIFIC_ERRORS }),
    updateProfileState: (obj, value) =>
      dispatch({ type: PROFILE_UPDATE_STATE_LOCAL, obj: obj, value: value }),
  };
};

const mapStateToProps = (state) => {
  return {
    loading: state.profile.loading,
    paymentInfo: state.profile.paymentInfo,
    paywall: state.common.paywall,
    stripeKey: state.profile.stripeKey,
    stripeAccountKey: state.profile.stripeAccountKey,
    user: state.common.user,
  };
};

class PaymentDetails extends React.Component {
  profileId = this.props.match.params.id;

  constructor(props) {
    super(props);
    this.state = {
      cardName: "",
      editing: false,
    };
  }

  componentDidMount() {
    if (mobileCheck()) {
      this.props.fetchPayment(this.profileId, ["payment_details"]);
    }

    if (this.props.stripeKey) {
      this.mountStripe();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.stripeKey && this.props.stripeKey) {
      this.mountStripe();
    }

    if (!prevState.editing && this.state.editing) {
      this.state.stripeCardElement.mount("#card-element");
    }
  }

  mountStripe = () => {
    let { stripeKey, stripeAccountKey } = this.props;

    let stripe = stripeHelpers.mountStripe(stripeKey, stripeAccountKey);
    let elements = stripe.elements();
    let cardElement = elements.create("card", {
      iconStyle: "solid",
      hidePostalCode: false,
      style: {
        base: {
          fontSize: "1rem",
        },
      },
    });

    this.setState({
      stripe: stripe,
      stripeElements: elements,
      stripeCardElement: cardElement,
    });
  };

  render() {
    let { loading, paymentInfo, paywall, user } = this.props;

    let { cardName, editing } = this.state;

    let amountDue = 0;
    if (paymentInfo && paymentInfo.outstanding_balance) {
      amountDue = paymentInfo.outstanding_balance.due
        ? paymentInfo.outstanding_balance.due
        : 0;
    }

    const subscription = paymentInfo && paymentInfo.subscription;

    return (
      <div>
        <SubTabBack />
        <div className="profile-section">
          <h1>Payment Details</h1>

          {!paymentInfo && <LoadingSpinner />}

          {paymentInfo && paywall && (
            <div className="warning">
              <i className="material-icons warning-icon">error</i>
              <p>
                To unlock your account, please update your payment information
                below or{" "}
                <a href="#" onClick={this.openChat}>
                  message our support staff
                </a>
                .
              </p>
            </div>
          )}

          {paymentInfo && (
            <div className="profile-list payment">
              <div className="list-item">
                <div className="row">
                  <p>
                    <span>Payment Method</span>
                  </p>
                  <div className="col align-right">
                    {paymentInfo.card_brand ? (
                      <p>
                        {paymentInfo.card_brand} ({paymentInfo.card_digits})
                      </p>
                    ) : (
                      <p>No card on file.</p>
                    )}

                    <button
                      className="custom-button item-action"
                      onClick={this.toggleEdit}
                    >
                      {editing
                        ? "Cancel"
                        : paymentInfo.card_brand
                        ? "Edit"
                        : "Add Card"}
                    </button>
                  </div>
                </div>
              </div>

              {editing && (
                <div className="list-item form">
                  <div className="row">
                    <ProfileField
                      field="cardName"
                      fieldType="text"
                      onUpdateField={(value) =>
                        this.setState({ cardName: value })
                      }
                      title="Cardholder Name"
                      value={cardName}
                    />

                    <div id="card-element" className="card-form" />

                    {amountDue > 0 && (
                      <p className="red">
                        <span>
                          Your new card will be charged ${amountDue.toFixed(2)}.
                        </span>
                      </p>
                    )}

                    <button
                      className={`custom-button submit half ${
                        loading ? "loading" : ""
                      }`}
                      onClick={this.handleSubmit}
                    >
                      {loading ? <LoadingSpinner /> : "Save Changes"}
                    </button>
                  </div>
                </div>
              )}

              {subscription && (
                <div>
                  <div className="list-item">
                    <div className="row">
                      <p>
                        <span>Treatment Cost</span>
                      </p>
                      <div className="col align-right">
                        <p>
                          ${(subscription.plan.amount_decimal / 100).toFixed(2)}
                          /{subscription.plan.interval}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="list-item">
                    <div className="row">
                      <p>
                        <span>Next Bill Date</span>
                      </p>
                      <div className="col align-right">
                        <p>{paymentInfo.next_payment}</p>
                      </div>
                    </div>
                  </div>

                  {amountDue > 0 && paymentInfo.card_brand && (
                    <button
                      className="custom-button action-link mobile-only"
                      onClick={() =>
                        this.props.chargePrimaryCard(this.profileId, user)
                      }
                    >
                      {loading ? (
                        <LoadingSpinner />
                      ) : (
                        `Charge Amount to ${paymentInfo.card_brand} (${paymentInfo.card_digits})`
                      )}
                    </button>
                  )}
                </div>
              )}

              {!subscription && (
                <div className="list-item">
                  <div className="row">
                    <p>
                      <span>Program Cost</span>
                    </p>
                    <div className="col align-right">
                      <p>$0</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }

  openChat = (e) => {
    if (e) e.preventDefault();
    this.props.openChat({ view: "index", topic: "ADMIN" });
  };

  toggleEdit = () => {
    if (this.state.editing) {
      this.setState({ cardName: "", editing: false });
    } else {
      this.setState({ editing: true });
    }
  };

  handleSubmit = async () => {
    this.props.clearFieldSpecificErrors();
    this.props.newGeneralErrors([]);

    if (!this.state.cardName) {
      this.props.newGeneralErrors([
        { text: "Cardholder name cannot be blank." },
      ]);
      this.props.newFieldSpecificError(
        "cardName",
        "Cardholder name cannot be blank."
      );
      window.scrollTo(0, 0);
      return;
    }

    this.props.startSubmitCard();
    this.state.stripe
      .createToken(this.state.stripeCardElement, { name: this.state.cardName })
      .then((result) => {
        if (result.error) {
          // Inform the user if there was an error
          this.props.failureSubmitCard([{ text: result.error.message }]);
          window.scrollTo(0, 0);
        } else {
          // Send the token to your server
          this.handleStripeResponse(result.token);
        }
      });
  };

  handleStripeResponse = async (token) => {
    let { user } = this.props;
    const data = {
      url: `/profiles/update_card`,
      path: `/api/v2`,
      data: {
        method: "POST",
        body: {
          card_id: token.id,
          user_id: user.id,
        },
      },
    };

    try {
      const res = await Api.makeLongRequest(data);
      if (res.success) {
        const wasPaywalled = this.props.paywall;
        if (!!res.payment_info) {
          this.props.updateProfileState("paymentInfo", res.payment_info);
        }
        this.props.successSubmitCard();
        this.toggleEdit();

        if (wasPaywalled) {
          // update paywall/locked out
          this.props.togglePaywall(false);
          let updatedUser = Object.assign({}, user);
          updatedUser.locked_out = false;
          this.props.loadUser(updatedUser);

          this.props.newGeneralErrors([
            { status: "info", text: "Successfully updated and charged card." },
          ]);
        } else {
          this.props.newGeneralErrors([
            { status: "info", text: "Successfully updated card." },
          ]);
        }
      } else {
        this.props.failureSubmitCard([{ text: res.error }]);
        if (!cardData.card_id) {
          this.props.newGeneralErrors([{ text: res.error }]);
        }
        let errorShown = document.getElementsByClassName(
          "Errors-Container Forms"
        );
        if (errorShown.length > 0) {
          errorShown[0].scrollIntoView();
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PaymentDetails)
);
