import React, { Component } from "react";
//import cn from 'classnames';
import { connect } from "react-redux";
import { initializeLocalTracks } from "patient_app/api/videoCallActions";

import VideoTrack from "patient_app/components/video/VideoTrack";

const mapDispatchToProps = (dispatch) => {
  return {
    initializeLocalTracks: () => {
      dispatch(initializeLocalTracks());
    },
  };
};

const mapStateToProps = (state) => {
  return {
    localVideoTrack: state.videoCall.localVideoTrack,
    //localAudioTrack: state.video.localAudioTrack,
  };
};

class LocalVideoThumb extends Component {
  componentDidMount = () => {
    this.props.initializeLocalTracks();
  };

  render() {
    let {
      //localAudioTrack,
      localVideoTrack,
    } = this.props;

    return (
      <div className="local-video-thumb-container">
        <div className="local-video-thumb">
          <VideoTrack track={localVideoTrack} className="thumbnail small" />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocalVideoThumb);
