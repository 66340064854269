import { views } from "patient_app/components/chat/constants/views";
import Index from "patient_app/components/chat/Index";
import Chat from "patient_app/components/chat/Chat";
import Search from "patient_app/components/chat/Search";
import Loading from "patient_app/components/chat/Loading";
import Upgrade from "patient_app/components/chat/Upgrade";

const VIEWS = {
  [views.INDEX]: Index,
  [views.CHAT]: Chat,
  [views.SEARCH]: Search,
  [views.LOADING]: Loading,
  [views.UPGRADE]: Upgrade,
};

export default VIEWS;
