import PropTypes from "prop-types";
import React from "react";

export default class LoadingSpinner extends React.Component {
  render() {
    return (
      <div className={`loading__comp ${this.props.className}`}>
        <img
          src="https://storage.googleapis.com/workit-client-assets/chat/loading.gif"
          alt="loading spinner"
        />
      </div>
    );
  }
}
