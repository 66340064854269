import {
  TX_PLAN_LOAD,
  TX_PLAN_USER_PLANS,
} from "patient_app/constants/actionTypes";

const initialState = {
  treatmentPlans: {}, // { TreatmentPlan id: TreatmentPlan }
  userTreatmentPlans: null, // [TreatmentPlan ids]
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TX_PLAN_LOAD:
      return {
        ...state,
        treatmentPlans: {
          ...state.treatmentPlans,
          [action.treatmentPlan.id]: action.treatmentPlan,
        },
      };
    case TX_PLAN_USER_PLANS:
      return {
        ...state,
        userTreatmentPlans: action.userTreatmentPlans,
      };
    default:
      return state;
  }
};
