import { ALCOHOL_CHECK_QUESTIONS_LOAD } from "patient_app/constants/actionTypes";

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case ALCOHOL_CHECK_QUESTIONS_LOAD:
      return {
        ...state,
        questions: action.questions,
      };
    default:
      return state;
  }
};
