import PropTypes from "prop-types";
import React from "react";
import cn from "classnames";

import MainLayout from "patient_app/components/layouts/MainLayout";
import ContentMain from "patient_app/components/forms/ContentMain";
import ContentBox from "patient_app/components/forms/ContentBox";
import TextField from "patient_app/components/input_fields/TextField";
import SelectButton from "patient_app/components/buttons/SelectButton";
import SubmitButton from "patient_app/components/buttons/SubmitButton";
import FormErrors from "patient_app/components/utils/FormErrors";
import PatientChat from "patient_app/components/chat/PatientChat";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  PENDING_ITEM_TOGGLE_SIGNED,
  PENDING_ITEM_UPDATE_SIGNATURE,
  NEW_FORM_ERRORS,
  NEW_FIELD_SPECIFIC_ERROR,
  CLEAR_FIELD_SPECIFIC_ERRORS,
  OPEN_CHAT,
} from "patient_app/constants/actionTypes";
import { fetchUser, fetchProfile } from "patient_app/api/commonActions";
import {
  fetchPendingItem,
  signPendingItem,
  declinePendingItem,
  fetchStartTalking,
} from "patient_app/api/pendingItemActions";
import { fetchMissionMap } from "patient_app/api/profileActions";

import { getUrlVars } from "components/utils/helpers/linkHelpers";
import { mobileCheck } from "patient_app/helpers/supported";
import permissions from "patient_app/helpers/permissions";

import layout from "patient_app/stylesheets/onboarding/layout";

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUser: () => dispatch(fetchUser()),
    fetchProfile: (user) => dispatch(fetchProfile(user)),
    fetchMissionMap: (userId) => dispatch(fetchMissionMap(userId)),
    fetchStartTalking: () => dispatch(fetchStartTalking()),
    fetchPendingItem: (userId, title) =>
      dispatch(fetchPendingItem(userId, title)),
    toggleUserSigned: () => dispatch({ type: PENDING_ITEM_TOGGLE_SIGNED }),
    updateSignature: (signature) =>
      dispatch({ type: PENDING_ITEM_UPDATE_SIGNATURE, signature: signature }),
    onError: (field, error) =>
      dispatch({ type: NEW_FIELD_SPECIFIC_ERROR, field: field, error: error }),
    onGeneralErrors: (errors) =>
      dispatch({ type: NEW_FORM_ERRORS, errors: errors }),
    clearErrors: () => dispatch({ type: CLEAR_FIELD_SPECIFIC_ERRORS }),
    signPendingItem: (itemId, signature, fileUrl) =>
      dispatch(signPendingItem(itemId, signature, fileUrl)),
    declinePendingItem: (itemId) => dispatch(declinePendingItem(itemId)),
    openChat: (data) => dispatch({ type: OPEN_CHAT, data: data }),
  };
};

const mapStateToProps = (state) => {
  return {
    fieldSpecificErrors: state.common.fieldSpecificErrors,
    item: state.pendingItem.item,
    clinicFile: state.pendingItem.clinicFile,
    loading: state.pendingItem.loading,
    missionMap: state.profile.missionMap,
    profile: state.common.profile,
    signature: state.pendingItem.signature,
    startTalking: state.pendingItem.startTalking,
    success: state.pendingItem.success,
    user: state.common.user,
    userSigned: state.pendingItem.userSigned,
  };
};

class ItemApproval extends React.Component {
  userId = this.props.match.params.id;
  itemTitle = getUrlVars(window.location.href)["title"];

  componentDidMount = () => {
    document.title = "Document Approval | Workit Health";
    if (!this.props.user) {
      this.props.fetchUser();
    }

    if (!this.props.item || this.itemTitle) {
      this.props.fetchPendingItem(this.userId, this.itemTitle);
    }

    if (!this.props.profile) {
      this.props.fetchProfile({ id: this.userId });
    }

    if (this.props.userSigned && this.props.userSigned === true) {
      //reset form upon signing consecutive documents
      this.props.toggleUserSigned();
    }
    this.props.updateSignature("");
  };

  componentDidUpdate = (prevProps) => {
    if (!prevProps.item && this.props.item) {
      if (this.props.item.category === "mission_map") {
        this.props.fetchMissionMap(this.userId);
      } else if (this.props.item.category === "start_talking") {
        console.log("fetch start talking");
        this.props.fetchStartTalking();
      }
    }

    if (!prevProps.success && this.props.success) {
      let user = this.props.user;
      if (user) {
        if (user.tier === 3) {
          if (this.props.item.category === "pre_appointment_checklist") {
            this.props.history.push("/onboarding/signup_name");
          } else {
            this.props.history.push("/appointments");
          }
        } else {
          this.props.history.push("/courses");
        }
      } else {
        this.props.history.push("/courses");
      }
    }
  };

  render() {
    let {
      fieldSpecificErrors,
      item,
      clinicFile,
      loading,
      missionMap,
      profile,
      signature,
      startTalking,
      userSigned,
      user,
    } = this.props;
    console.log("startTalking", startTalking);

    let header = "Document Approval";
    let subtitle = "";
    let description = "Please sign to continue to your dashboard.";
    if (item) {
      if (
        item.category === "document" ||
        item.category === "pre_appointment_checklist"
      ) {
        header = "Sign Document";
        description =
          "Your care team at Workit has sent you a document to sign:";
      } else if (item.category === "consent") {
        header = "Consent to Recipient Rights and Bill of Rights.";
        description = "Please sign to continue to your dashboard.";
      } else if (item.category === "program") {
        header = "Consent to Program Description";
        description = "Please sign to continue to your dashboard.";
      } else if (item.category === "mission_map") {
        header = "Updated Program Goals";
        description =
          "Please verify these changes that your counselor made while you were away:";
      } else if (item.category === "start_talking") {
        header = "Opioid Start Talking";
        description = "";
      }
    }

    if (clinicFile) {
      header = clinicFile.title;
      subtitle = clinicFile.subtitle;
      description = clinicFile.patient_description || "";
    }

    const scrollCategories = [
      "mission_map",
      "document",
      "pre_appointment_checklist",
      "start_talking",
    ];
    const centerVertically =
      item && !scrollCategories.includes(item.category) && !mobileCheck();

    return (
      <div className="Patient-App-Container Item-Approval">
        <MainLayout>
          <ContentMain halfBleed={true} centerVertically={false}>
            {item && (
              <ContentBox className="onboarding taller-than-container">
                <div className="inputs">
                  <h1
                    className={cn(item.category === "consent" ? "consent" : "")}
                  >
                    {header}
                  </h1>

                  <div>
                    <FormErrors />
                    {!!subtitle && (
                      <p className="description">
                        <span>{subtitle}</span>
                      </p>
                    )}

                    {description.split("\n").map((str, i) => {
                      if (!!str) {
                        return (
                          <p className="description" key={i}>
                            {str}
                          </p>
                        );
                      }
                    })}

                    {(item.category === "document" ||
                      item.category === "pre_appointment_checklist") &&
                      item.title &&
                      !clinicFile && (
                        <p className="document-title">{item.title}</p>
                      )}

                    {(item.category === "document" ||
                      item.category === "pre_appointment_checklist") &&
                      item.description &&
                      !clinicFile && (
                        <p className="item-description">{item.description}</p>
                      )}

                    {(item.category === "document" ||
                      item.category === "pre_appointment_checklist") &&
                      item.file && (
                        <div className="document-links">
                          <a
                            href={item.file}
                            target="_blank"
                            className="view-doc"
                          >
                            <i className="material-icons">info</i>View Document
                          </a>
                        </div>
                      )}

                    {clinicFile?.other_url && (
                      <p className="description">
                        <a href={clinicFile.other_url} target="_blank">
                          {clinicFile.other_url_text || "Learn more"}
                        </a>
                      </p>
                    )}

                    {item.category === "consent" && (
                      <div className="document-links">
                        <a
                          href="https://storage.googleapis.com/workithealth-files/pre_appointment_checklist/recipient_rights.pdf"
                          target="_blank"
                          className="view-doc"
                        >
                          <i className="material-icons">info</i>Recipient Rights
                        </a>
                        <a
                          href="https://storage.googleapis.com/workitassets/bill_of_rights.pdf"
                          target="_blank"
                          className="view-doc"
                        >
                          <i className="material-icons">info</i>Bill Of Rights
                        </a>
                      </div>
                    )}

                    {item.category === "mission_map" &&
                      missionMap &&
                      profile &&
                      this.renderMissionMap(profile, missionMap)}

                    {item.category === "start_talking" &&
                      startTalking &&
                      this.renderStartTalking(startTalking)}

                    <TextField
                      field={"name"}
                      title={"Your Legal Name"}
                      value={signature ? signature : ""}
                      id={"name"}
                      onUpdateField={(value, field) =>
                        this.props.updateSignature(value)
                      }
                      uid={"name"}
                    />

                    <div className="signature-section">
                      <div className="signature-checkbox">
                        <input
                          type="checkbox"
                          id="agree-checkbox"
                          checked={userSigned}
                          onChange={this.props.toggleUserSigned}
                        />
                        <label htmlFor="agree-checkbox">
                          I agree to electronically sign this document
                        </label>
                      </div>
                      {fieldSpecificErrors["checkbox"] && (
                        <p className="field-specific-error">
                          {fieldSpecificErrors["checkbox"]}
                        </p>
                      )}
                    </div>
                  </div>

                  <SubmitButton
                    text="Sign"
                    loading={loading}
                    onSubmit={(e) => this.handleSubmit(e)}
                  />

                  {clinicFile?.link_to_chat && (
                    <button
                      className="custom-button select-button"
                      onClick={this.handleOpenChat}
                    >
                      Talk to a member of our team
                    </button>
                  )}

                  {!item.mandatory && (
                    <a href="#" onClick={this.handleOther} className="skip">
                      Decline to sign this document
                    </a>
                  )}
                </div>

                <PatientChat
                  userIsClinic={permissions.isClinic(user)}
                  cta={permissions.isCore(user)}
                />
              </ContentBox>
            )}
          </ContentMain>
        </MainLayout>

        <style jsx>{layout}</style>
        <style jsx>{`
          .Item-Approval .custom-button.select-button {
            width: 100% !important;
            background: white;
          }
        `}</style>
      </div>
    );
  }

  renderMissionMap(profile, missionMap) {
    return (
      <div className="mission-map-section">
        <p className="bold">What is your mission?</p>
        <p>{profile.mission}</p>

        {missionMap.current_tools && (
          <div>
            <p className="bold">
              What tools can you use to achieve your mission?
            </p>
            <p>{missionMap.current_tools}</p>
          </div>
        )}

        {missionMap.challenges && (
          <div>
            <p className="bold">What challenges do you anticipate?</p>
            <p>{missionMap.challenges}</p>
          </div>
        )}

        {missionMap.success && (
          <div>
            <p className="bold">What does success look like to you?</p>
            <p>{missionMap.success}</p>
          </div>
        )}

        {missionMap.support && (
          <div>
            <p className="bold">How can Workit support you and your mission?</p>
            <p>{missionMap.support}</p>
          </div>
        )}

        {missionMap.reason_for_discharge && (
          <div>
            <p className="bold">Details for Discharge</p>
            <p>{missionMap.reason_for_discharge}</p>
          </div>
        )}

        {missionMap.victories && (
          <div>
            <p className="bold">
              Small victories you can achieve along the way:
            </p>
            <p>{missionMap.victories}</p>
          </div>
        )}
      </div>
    );
  }

  renderStartTalking(form) {
    return (
      <div className="start-talking-section">
        <p className="bold">
          A controlled substance is a drug or other substance that the United
          States Drug Enforcement Administration has identified as having a
          potential for abuse. My provider shared the following:
        </p>
        <ol>
          <li>
            The risks of substance use disorder and overdose associated with the
            controlled substance containing an opioid.
          </li>
          <li>
            Individuals with mental illness and substance use disorders may have
            an increased risk of addiction to a controlled substance. (Required
            only for minors.)
          </li>
          <li>
            Mixing opioids with benzodiazepines, alcohol, muscle relaxers, or
            any other drug that may depress the central nervous system can cause
            serious health risks, including death or disability. (Required only
            for minors.)
          </li>
          <li>
            For a female who is pregnant or is of reproductive age, the
            heightened risk of short and long-term effects of opioids, including
            but not limited to neonatal abstinence syndrome.
          </li>
          <li>
            Any other information necessary for members to use the drug safely
            and effectively as found in the member counseling information
            section of the labeling for the controlled substance.
          </li>
          <li>
            Safe disposal of opioids has shown to reduce injury and death in
            family members. Proper disposal of expired, unused or unwanted
            controlled substances may be done through community take-back
            programs, local pharmacies, or local law enforcement agencies.
            Information on where to return your prescription drugs can be found
            at{" "}
            <a href="http://www.michigan.gov/deqdrugdisposal">
              http://www.michigan.gov/deqdrugdisposal
            </a>
            .
          </li>
          <li>
            It is a felony to illegally deliver, distribute or share a
            controlled substance without a prescription properly issued by a
            licensed health care prescriber.
          </li>
        </ol>

        <p className="bold">
          I acknowledge the potential benefits and risks of an opioid medication
          as described by my provider along with the responsibility of properly
          managing my medication as stated above.
        </p>

        <p>Member Name: {form.patient_name}</p>
        <p>Date of Birth: {form.patient_dob}</p>
        <p>Dosage: {form.dosage}</p>
        <p>Dosage Quantity Prescribed: {form.dosage_quantity}</p>
        <p>Number of Refills: {form.num_refills}</p>
      </div>
    );
  }

  handleOpenChat = () => {
    this.props.openChat({ view: "index", topic: "ADMIN" });
  };

  handleSubmit = (e) => {
    let {
      signature,
      userSigned,
      fieldSpecificErrors,
      item,
      clinicFile,
    } = this.props;
    if (e) {
      e.preventDefault();
    }
    let fileUrl;
    if (clinicFile) {
      fileUrl = clinicFile.file.url;
    } else if (item) {
      fileUrl = item.file;
    }

    this.props.clearErrors();
    let hasErrors = false;
    if (!signature || signature.length === 0) {
      this.props.onError("name", "Please type your name to sign");
      hasErrors = true;
    }
    if (!userSigned) {
      this.props.onError("checkbox", "Please check this box to confirm");
      hasErrors = true;
    }
    if (hasErrors) {
      this.props.onGeneralErrors([
        { text: "Please correct the issues listed below." },
      ]);
      return;
    }

    this.props.signPendingItem(item.id, signature, fileUrl);
  };

  handleOther = (e) => {
    if (e) e.preventDefault();

    this.props.declinePendingItem(this.props.item.id);
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ItemApproval)
);
