import PropTypes from "prop-types";
import React from "react";
import cn from "classnames";
import Cleave from "cleave.js/react";
import { connect } from "react-redux";

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {
    fieldSpecificErrors: state.common.fieldSpecificErrors,
  };
};

class ZipCodeField extends React.Component {
  render() {
    let {
      field,
      title,
      value,
      id,
      required,
      fieldSpecificErrors,
      uid,
    } = this.props;

    let error = fieldSpecificErrors[uid];

    return (
      <div className={cn("field", field)}>
        <label htmlFor={id} id={"label-" + id}>
          {title} {required && <span className="light">(Required)</span>}
        </label>
        <Cleave
          id={id}
          maxLength="10"
          type="text"
          aria-invalid={error ? true : false}
          aria-describedby={error && `aria-describedby-${uid}`}
          value={value}
          onChange={(e) => this.props.onUpdateField(e.target.value, field)}
          onKeyDown={this.props.onKeyPress}
          aria-invalid={error ? true : false}
          className={cn(error && "has-error")}
        ></Cleave>

        {error && (
          <p
            role="alert"
            id={`aria-describedby-${uid}`}
            className={cn("field-specific-error", field)}
          >
            Error: {error}
          </p>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ZipCodeField);
