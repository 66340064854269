import PropTypes from "prop-types";
import React from "react";
import cn from "classnames";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { submitFeedback } from "patient_app/api/profileActions";
import {
  NEW_ERRORS,
  NEW_FIELD_SPECIFIC_ERROR,
  CLEAR_FIELD_SPECIFIC_ERRORS,
} from "patient_app/constants/actionTypes";

import ProfileField from "patient_app/views/profiles/partials/ProfileField";
import LoadingSpinner from "patient_app/components/utils/LoadingSpinner";
import DragAndDrop from "patient_app/components/utils/DragAndDrop";
import MainTabBack from "patient_app/views/profiles/partials/MainTabBack";

import assets from "patient_app/components/chat/assets";
import { mobileCheck } from "patient_app/helpers/supported";
import { getUrlVars } from "patient_app/helpers/linkHelpers";
import permissions from "patient_app/helpers/permissions";

const mapDispatchToProps = (dispatch) => {
  return {
    submitFeedback: (profileId, feedback) =>
      dispatch(submitFeedback(profileId, feedback)),
    newFieldSpecificError: (field, error) =>
      dispatch({ type: NEW_FIELD_SPECIFIC_ERROR, field: field, error: error }),
    clearFieldSpecificErrors: () =>
      dispatch({ type: CLEAR_FIELD_SPECIFIC_ERRORS }),
    newGeneralErrors: (errors) =>
      dispatch({ type: NEW_ERRORS, errors: errors }),
  };
};

const mapStateToProps = (state) => {
  return {
    fieldSpecificErrors: state.common.fieldSpecificErrors,
    loading: state.profile.loading,
    success: state.profile.success,
  };
};

class Feedback extends React.Component {
  profileId = this.props.match.params.id;

  constructor(props) {
    super(props);
    this.state = {
      feedback: "",
      files: null,
    };
  }

  componentDidMount() {
    document.title = "Feedback | Workit Health";
    this.props.clearFieldSpecificErrors();
    this.props.newGeneralErrors([]);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.success && this.props.success) {
      this.setState({ feedback: "", files: null });
    }
  }

  render() {
    let { fieldSpecificErrors, loading } = this.props;
    let { feedback, files } = this.state;

    return (
      <div>
        <MainTabBack />

        <div className="profile-section last">
          <h1>Feedback</h1>

          <p>
            <span>Please share your feedback below</span>
          </p>
          <p>
            Please provide as many details as possible to help us understand
            your feedback.
          </p>
          <p>
            If you're running into something unexpected, please describe what
            you did, what you were expecting, and what happened.
          </p>

          <div style={{ marginBottom: "2rem" }} />

          <ProfileField
            field="feedback"
            fieldType="textarea"
            onUpdateField={(value) => this.setState({ feedback: value })}
            title="Enter your feedback"
            value={feedback}
          />

          <DragAndDrop
            error={fieldSpecificErrors["feedback_file"]}
            files={files}
            onFileInput={this.handleFileInput}
            onRemoveFile={this.handleRemoveFile}
            allowMultiple={false}
          />

          <button
            className={`custom-button submit ${loading ? "loading" : ""}`}
            onClick={this.handleSubmit}
          >
            Submit
          </button>
        </div>
      </div>
    );
  }

  handleFileInput = (file) => {
    this.setState({ files: [file] });
  };

  handleRemoveFile = (fileIndex) => {
    let files = [...this.state.files];
    files.splice(fileIndex, 1);
    this.setState({ files: files });
  };

  handleSubmit = () => {
    let { feedback, files } = this.state;

    this.props.clearFieldSpecificErrors();
    this.props.newGeneralErrors([]);
    let hasErrors = false;

    if (!feedback || feedback.length === 0) {
      this.props.newFieldSpecificError("feedback", "Feedback cannot be blank.");
      hasErrors = true;
    }
    if (files?.length > 0) {
      const types = ["image/png", "image/jpeg", "image/gif"];
      if (types.every((type) => files[0].type !== type)) {
        this.props.newFieldSpecificError("feedback_file", "Invalid file type.");
        hasErrors = true;
      } else if (files[0].size > 6000000) {
        this.props.newFieldSpecificError(
          "feedback_file",
          "Please choose a smaller file."
        );
        hasErrors = true;
      }
    }

    if (hasErrors) {
      this.props.newGeneralErrors([
        { text: "Please correct the issues listed below." },
      ]);
      window.scrollTo(0, 0);
      return;
    }

    let formData = new FormData();
    formData.append("feedback", feedback);
    if (files?.length > 0) {
      formData.append("image", files[0]);
    }

    this.props.submitFeedback(this.profileId, formData);
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Feedback)
);
