import PropTypes from "prop-types";
import React from "react";
import cn from "classnames";

import SessionStore from "patient_app/helpers/SessionStore";
import assets from "patient_app/assets";

export default class CookieBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
    };
  }

  componentDidMount() {
    this.checkForSession();
  }

  render() {
    let { show } = this.state;

    const privacyLink = "https://www.workithealth.com/privacy-policy";

    return (
      <div className={cn("cookie-banner", show ? "" : "hide")}>
        <p>
          This site uses cookies to improve your experience. By using this site,
          you consent to our{" "}
          <a href={privacyLink} target="_blank">
            use of cookies
          </a>
          .
        </p>

        <button className="custom-button dismiss" onClick={this.handleDismiss}>
          <img src={assets.grayCross} />
        </button>
      </div>
    );
  }

  checkForSession = () => {
    // check cookies to see if user has logged in before
    // if user has not signed in on this browser session, cookie = "current_model=user"
    const cookie = decodeURIComponent(document.cookie);
    if (cookie.search("cookieConsent=") > -1) {
      this.setState({ show: false });
    }
  };

  handleDismiss = () => {
    SessionStore.set("cookieConsent", true);
    this.setState({ show: false });
  };
}
