import PropTypes from "prop-types";
import React from "react";
import { DateTime } from "luxon";

import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { fetchPayment } from "patient_app/api/profileActions";

import LoadingSpinner from "patient_app/components/utils/LoadingSpinner";
import SubTabBack from "patient_app/views/profiles/partials/SubTabBack";

import { mobileCheck } from "patient_app/helpers/supported";
import textHelpers from "patient_app/helpers/textHelpers";
import assets from "patient_app/assets";

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPayment: (profileId, params) =>
      dispatch(fetchPayment(profileId, params)),
  };
};

const mapStateToProps = (state) => {
  return {
    hasMorePayments: state.profile.hasMorePayments,
    loading: state.profile.loading,
    payments: state.profile.payments,
    user: state.common.user,
  };
};

class PaymentHistory extends React.Component {
  profileId = this.props.match.params.id;

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (mobileCheck()) {
      this.props.fetchPayment(this.profileId, ["payment_history"]);
    }
  }

  render() {
    let { hasMorePayments, loading, payments, user } = this.props;

    return (
      <div>
        <SubTabBack />
        <div className="profile-section last">
          <div className="header">
            <h1>Payment History</h1>

            {payments && (
              <button
                className="custom-button section-action"
                onClick={() =>
                  window.open("/payments/payment_history", "_blank")
                }
              >
                Print
              </button>
            )}
          </div>

          {!payments && <LoadingSpinner />}

          {user && payments && (
            <div className="profile-list payment-history">
              {payments.length > 0 && (
                <div className="list-item header">
                  <div className="row">
                    <p>Payment</p>
                    <p>Amount</p>
                    <p>Method</p>
                    <p>Status</p>
                    <div className="col" />
                  </div>
                </div>
              )}

              {this.renderPayments()}

              {hasMorePayments && (
                <button
                  className="custom-button load-more"
                  onClick={this.handleLoadMore}
                >
                  {loading ? <LoadingSpinner /> : "Load all..."}
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }

  renderPayments() {
    if (this.props.payments.length === 0) {
      return (
        <div className="list-item none">
          <div className="row">
            <p>No payments.</p>
          </div>
        </div>
      );
    }

    return this.props.payments.map((payment, i) => {
      const createdAt = DateTime.fromSeconds(payment.date).toFormat("LL/dd/yy");

      let url = `/payments/receipt?user_id=${this.props.user.id}`;
      if (payment.paid_with === "Cash") {
        url += `&cash_payment_id=${payment.id}`;
      } else {
        url += `&payment_id=${payment.id}`;
      }

      return (
        <div className="list-item" key={i} data-id={payment.id}>
          <div className="row">
            <p>
              {textHelpers.truncate(`${createdAt} ${payment.description}`, 25)}
            </p>
            <p>${(payment.amount / 100).toFixed(2)}</p>
            <p>{payment.paid_with}</p>
            <p>
              {payment.status === "Failed" ? (
                <span>Failed</span>
              ) : (
                payment.status
              )}
            </p>
            <div className="col align-right">
              <Link className="item-action" to={url} target="_blank">
                Receipt
              </Link>
            </div>
          </div>
        </div>
      );
    });
  }

  handleLoadMore = () => {
    this.props.fetchPayment(this.profileId, ["payment_history", "get_more"]);
  };

  // printBillingHistory = (e) => {
  //   let { user } = this.props
  //   if (e) { e.preventDefault() }
  //   var doc = document.getElementById('billing-history').innerHTML
  //   var printStyling = '<style>*{font-family:helvetica;}button,h1{display:none}table{width:100%;display:flex;flex-direction:column;}td,th{width:33%;}tr{display:flex;padding-top:0.25rem;padding-bottom:0.25rem;padding-left:0.5rem;text-align:left;}</style>'
  //
  //   var printWindow = window.open('', 'PRINT', 'height=400,width=600');
  //   printWindow.document.write('<html><head><title>' + "Workit Billing History"  + '</title>');
  //   printWindow.document.write(printStyling);
  //   printWindow.document.write('</head><body>');
  //   printWindow.document.write("<h3>Workit Billing History for " + user.email + "</h3>");
  //   printWindow.document.write(doc);
  //   printWindow.document.write('</body></html>');
  //
  //   printWindow.document.close(); // necessary for IE >= 10
  //   printWindow.focus(); // necessary for IE >= 10*/
  //
  //   printWindow.print();
  // }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PaymentHistory)
);
