import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ONBOARDING_UPDATE_STATE } from "patient_app/constants/actionTypes";

import permissions from "patient_app/helpers/permissions";
import textHelpers from "patient_app/helpers/textHelpers";
import { getIconUrl } from "patient_app/helpers/linkHelpers";

import layout from "patient_app/stylesheets/onboarding/layout";
import privatePayStyles from "patient_app/stylesheets/onboarding/privatePayStyles";

const CostRow = (props) => {
  const {
    costString, // string: cost breakdown (e.g. "$75/week")
    description, // string: brief description of what cost is for
    onShowDescription, // function: toggle showing description
    rowId, // string: unique row name for determining
    showDescription, // boolean: if description should be shown
    title, // string: cost title (e.g. "Initial Deposit", "Weekly Fee")
  } = props;

  const isAddOn = rowId.includes("add_on");

  return (
    <div key={rowId} className={`cost-row ${isAddOn ? "add-on" : ""}`}>
      <div className="cost-breakdown">
        <p>{title}</p>
        <div className="flex">
          <p>{costString}</p>
          <button
            className={`custom-button toggle-description ${
              showDescription ? "expanded" : ""
            }`}
            onClick={() => onShowDescription(rowId)}
            style={!!description ? {} : { opacity: "0", pointerEvents: "none" }}
          >
            <img
              src={getIconUrl("chevron-right", isAddOn ? "grey" : "dark-blue")}
              alt={showDescription ? "Collapse" : "Expand"}
            />
          </button>
        </div>
      </div>

      {showDescription && <p>{description}</p>}
      <style jsx>{privatePayStyles}</style>
    </div>
  );
};

const CostBreakdown = (props) => {
  const dispatch = useDispatch();
  const { coverageResults, profile, programCosts, user } = useSelector(
    (state) => {
      return {
        coverageResults: state.insurance.coverageResults,
        profile: state.common.profile,
        programCosts: state.onboarding.programCosts,
        user: state.common.user,
      };
    }
  );

  const [expandedSection, setExpandedSection] = useState(null);
  const isClinic = permissions.isClinic(user);
  const isInsuranceBillingType = [
    "commercial_insurance",
    // "medicaid", // do not include medicaid, assume a ProgramCost will be returned if there is a tech fee
    "medicare",
  ].includes(profile?.billing_type);

  const toggleSection = (sectionName) => {
    if (expandedSection === sectionName) {
      // hide if same section was already expanded
      setExpandedSection(null);
    } else {
      // show if different section was clicked / no sections were expanded
      setExpandedSection(sectionName);
    }
  };

  const renderAddOns = () => {
    // if program any program add-ons, display the options
    if (!programCosts.add_ons?.length) {
      return;
    }

    return programCosts.add_ons.map((costObj, i) => {
      let amount = `$${(costObj.cost / 100).toFixed(2)}`;
      if (amount.includes(".00")) {
        amount = amount.replace(".00", "");
      }

      const sectionName = `add_on_${costObj.add_on
        .toLowerCase()
        .replaceAll(" ", "_")}`;
      return (
        <CostRow
          key={i}
          costString={`${amount}/${costObj.interval}`}
          description={costObj.description}
          onShowDescription={toggleSection}
          rowId={sectionName}
          showDescription={expandedSection === sectionName}
          title={costObj.add_on}
        />
      );
    });
  };

  const renderRecurring = () => {
    // display all recurring program costs
    // if a program has 'phases', display cost of each phase in order
    // otherwise just display the base cost
    const costArr =
      programCosts.phased_costs?.length > 0
        ? programCosts.phased_costs
        : [programCosts.base_cost];

    let phaseMonthStart = 1;
    if (costArr.length > 0) {
      return costArr.map((costObj, i) => {
        if (!costObj) {
          return (
            <CostRow
              key={i}
              costString="$0/week*"
              description="Cost may vary based on coverage and program. You may have a coinsurance or a copay which will be based on your insurance coverage."
              onShowDescription={toggleSection}
              rowId="recurring"
              showDescription={true}
              title="Weekly"
            />
          );
        }

        let amount = `$${(costObj.cost / 100).toFixed(2)}`;
        if (amount.includes(".00")) {
          amount = amount.replace(".00", "");
        }

        let title = textHelpers.titleize(costObj.interval + "ly");
        if (costObj.phase_position) {
          if (costObj.phase_weeks) {
            // assume 4 weeks = 1 month
            let phaseMonthEnd =
              phaseMonthStart + Math.ceil(costObj.phase_weeks / 4) - 1;
            if (phaseMonthStart === phaseMonthEnd) {
              title = `Month ${phaseMonthStart}`;
            } else {
              title = `Month ${phaseMonthStart} – ${phaseMonthEnd}`;
            }
            phaseMonthStart = phaseMonthEnd + 1;
          } else {
            title = `Month ${phaseMonthStart}+`;
          }
        }

        const description = costObj.description;
        const sectionName = costObj.phase_position
          ? `recurring_${costObj.phase_position}`
          : "recurring";

        let costString = `${amount}/${costObj.interval}`;
        if (costObj.description?.startsWith("*")) {
          costString += "*";
        }

        return (
          <CostRow
            key={i}
            costString={costString}
            description={description}
            onShowDescription={toggleSection}
            rowId={sectionName}
            showDescription={expandedSection === sectionName}
            title={title}
          />
        );
      });
    }
  };

  useEffect(() => {
    // for members paying with insurance (minus medicaid), if coverageResults are present,
    // update base_cost with values returned in coverageResults and include a disclaimer
    // about how returned cost is an estimate
    if (
      !!programCosts?.base_cost &&
      !!coverageResults &&
      isInsuranceBillingType &&
      isClinic
    ) {
      const updatedBaseCost = { ...programCosts.base_cost };
      if (!!coverageResults?.amount_details) {
        // if member is paying with insurance and coverage results return amount_details
        // amount_details is returned in format $XX.XX
        updatedBaseCost.cost =
          parseFloat(coverageResults.amount_details.replace("$", "")) * 100;
      } else if (!!coverageResults?.amount) {
        // if member is paying with insurance and coverage results return amount
        // amount is returned in format $XX.XX
        recurringCost =
          parseFloat(coverageResults.amount_details.replace("$", "")) * 100;
      }

      if (!!coverageResults?.interval) {
        // if member is paying with insurance and coverage results return interval
        if (coverageResults.interval.toLowerCase() === "week") {
          updatedBaseCost.interval = "week";
        } else if (coverageResults.interval.toLowerCase() === "month") {
          updatedBaseCost.interval = "month";
        }
      }

      if (
        updatedBaseCost.cost !== programCosts.base_cost.cost ||
        updatedBaseCost.interval !== programCosts.base_cost.interval
      ) {
        // if cost or interval was updated, update programCosts in reducer
        dispatch({
          type: ONBOARDING_UPDATE_STATE,
          key: "programCosts",
          value: { ...programCosts, base_cost: updatedBaseCost },
        });
      }
    }
  }, [programCosts, coverageResults]);

  if (!profile) {
    return <div />;
  }

  let firstApptCost =
    programCosts?.base_cost?.induction_fee > 0
      ? (programCosts.base_cost.induction_fee / 100).toFixed(2)
      : "";
  if (firstApptCost.includes(".00")) {
    firstApptCost = firstApptCost.replace(".00", "");
  }

  return (
    <div>
      <div className="program-cost-details">
        <p>Click below to learn more about the payment details.</p>
        {isClinic && programCosts?.base_cost?.induction_fee > 0 && (
          <CostRow
            costString={`$${firstApptCost}/one time`}
            description={`In order to hold your first appointment, you will be charged a one-time, non-refundable $${firstApptCost} fee.`}
            onShowDescription={toggleSection}
            rowId="deposit"
            showDescription={expandedSection === "deposit"}
            title="Initial Deposit"
          />
        )}

        {programCosts && renderRecurring()}
        {programCosts && renderAddOns()}
      </div>

      {programCosts?.base_cost?.cost > 0 &&
        profile?.billing_type === "medicaid" && (
          <p className="description">
            This {programCosts.base_cost.interval.toLowerCase() + "ly"} fee
            covers your use of the Workit Health technology, which is a service
            not covered by your insurance. Your insurance does cover the cost of
            all medical services (minus any applicable copay, cost share, or
            deductibles).
          </p>
        )}

      <style jsx>{layout}</style>
      <style jsx>{privatePayStyles}</style>
    </div>
  );
};

export default CostBreakdown;
