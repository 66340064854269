import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import modalStyles from "patient_app/stylesheets/appointments/modalStyles";
import dashboardBlockStyles from "patient_app/stylesheets/appointments/dashboardBlockStyles";
import { variables } from "patient_app/stylesheets/variables";
import assets from "patient_app/assets";
import onboardingHelpers, {
  getLocalPhone,
} from "patient_app/helpers/onboardingHelpers";

const ResendEmailFor2FAModa = (props) => {
  const dispatch = useDispatch();
  const modalRef = useRef(null);
  const closeRef = useRef(null);
  const localPhone = getLocalPhone(user);

  const [showDetails, setShowDetails] = useState(false);

  const { user } = useSelector((state) => {
    return {
      user: state.common.user,
    };
  });

  const search = useLocation().search;
  const params = new URLSearchParams(search);
  const userId = params.get("user");

  const handleClickSendEmail = () => {
    props.onSubmit();
    setShowDetails(!showDetails);
  };

  const handleCloseModal = (e) => {
    props.onClose();
  };

  useEffect(() => {
    document.body.removeEventListener("click", onClickOutside);
    return () => document.removeEventListener("click", onClickOutside);
  }, []);

  const onClickOutside = (e) => {
    const element = e.target;
    if (modalRef.current && !modalRef.current.contains(element)) {
      e.preventDefault();
      e.stopPropagation();
      props.onClose();
    }
  };

  return (
    <div className="dashboard-modal email-confirmation">
      <div className="modal" ref={modalRef}>
        <div>
          <div className="header">
            <p>
              <b>Please verify your email</b>
            </p>
            <button
              ref={closeRef}
              className="close"
              style={{
                backgroundColor: variables.grayDark,
                borderColor: variables.greenDark,
                width: "fit-content",
              }}
              onClick={handleCloseModal}
            >
              <img
                style={{ height: "16px", width: "16px" }}
                src={assets.closeWhite}
              />
            </button>
          </div>
        </div>
        <div className="content">
          <div>
            <p>
              Please check your inbox and{" "}
              <b>
                click the link provided in your email to verify your account
              </b>
              .
            </p>
          </div>

          <div className="details-div">
            <p>
              Need help?{" "}
              <a href={`tel:${localPhone}`}>Call us 9am - 7pm EST.</a>
            </p>
            <p>
              Haven't received anything at <em>{`${user.email}`}</em>?
              <span
                className="show-details"
                onClick={() => setShowDetails(!showDetails)}
              >
                {showDetails ? (
                  <span className="hide">&nbsp;&nbsp;Hide</span>
                ) : (
                  <span className="click-here">&nbsp;&nbsp;Click here</span>
                )}
              </span>
            </p>

            {showDetails && (
              <div style={{ marginTop: "10px" }}>
                <p>
                  You should receive an email to the address you provided (see
                  above) within 30 seconds. If you do not,
                </p>
                <a
                  role="button"
                  onClick={() => {
                    handleClickSendEmail();
                  }}
                >
                  click here to generate a new email.
                </a>
              </div>
            )}
          </div>
        </div>

        <style jsx>{dashboardBlockStyles}</style>
        <style jsx>{modalStyles}</style>
        <style global jsx>{``}</style>
      </div>
    </div>
  );
};

export default ResendEmailFor2FAModa;
