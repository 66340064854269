import React from "react";
import appointmentTabStyles from "patient_app/stylesheets/components/appointmentTabStyles";

class Tab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let { title, subtitle, className } = this.props;

    return (
      <div role="region" className={`App-Tab ${className ? className : ""}`}>
        <div className="header">
          <h2>
            {title}
            {!!subtitle && <span className="subtitle">{subtitle}</span>}
          </h2>
        </div>

        {this.props.children}
        <style jsx>{appointmentTabStyles}</style>
      </div>
    );
  }
}

export default Tab;
