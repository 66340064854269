import css from "styled-jsx/css";
import { variables } from "patient_app/stylesheets/variables";

export default css.global`
  .emergency-banner {
    z-index: 2000;
  }

  .emergency-banner .banner-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(40, 62, 78, 0.5);
  }

  .emergency-banner .modal-box {
    position: absolute;
    top: 0;
    flex-direction: column;
    justify-content: flex-end;
    height: fit-content;
    max-height: fit-content;
    margin: ${variables.base};
    background-color: white;
  }

  .emergency-banner .modal-box ul {
    list-style-type: disc;
    padding-inline-start: ${variables.base};
    margin: 0;
  }

  .emergency-banner .modal-box p,
  .emergency-banner .modal-box li {
    color: ${variables.blueDark};
    font-family: ${variables.objReg};
    font-size: 0.8rem;
    margin: 0;
  }

  .emergency-banner .modal-box p span,
  .emergency-banner .modal-box li span {
    font-family: ${variables.objMed};
  }

  .emergency-banner .modal-box .modal-header {
    display: flex;
    justify-content: space-between;
    padding: ${variables.small} ${variables.base};
    border-bottom: 1px solid ${variables.grayLight};
  }

  .emergency-banner .modal-box .close-eb {
    width: ${variables.small};
    height: ${variables.small};
  }

  .emergency-banner .modal-box .close-eb:hover,
  .emergency-banner .modal-box .close-eb:focus {
    outline-color: ${variables.green};
  }

  .emergency-banner .modal-box .modal-content {
    padding: ${variables.small} ${variables.base};
    overflow-y: scroll;
    height: 100%;
  }

  .emergency-banner .modal-box .modal-content p,
  .emergency-banner .modal-box .modal-content li {
    margin-bottom: ${variables.small};
  }

  .emergency-banner .modal-box .modal-content a {
    font-family: ${variables.objMed};
    color: ${variables.green};
    text-decoration: underline;
  }

  .emergency-banner .modal-box button.submit {
    color: white;
    background-color: ${variables.green};
    border: 1px solid ${variables.green};
    font-family: ${variables.objMed};
    font-size: 0.85rem;
    padding: ${variables.tiny} ${variables.small};
    border-radius: 4px;
    width: 100%;
    height: calc(16px * 2.5);
  }
`;
