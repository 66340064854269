import css from "styled-jsx/css";
import { variables } from "patient_app/stylesheets/variables.js";

export default css.global`
  /* Mobile redirect to app store or desktop */
  .Video-Mobile-Redirect {
    position: relative;
    top: 4.5rem;
    padding: 2rem;
  }

  .Video-Mobile-Redirect h1 {
    line-height: 30px;
    text-align: center;
    margin-bottom: 1rem;
    margin-top: 1.5rem;
    font-size: 1.25rem;
  }

  .Video-Mobile-Redirect p {
    font-size: 1rem;
  }

  .mobile-options {
    display: flex;
    flex-direction: column;
    word-wrap: break-word;
    text-align: center;
  }

  .mobile-options p.option,
  .mobile-options a {
    font-family: ${variables.objMed};
    font-size: 1rem;
    text-align: center;
    display: block;
    margin-bottom: 1.25rem;
  }

  .mobile-options button {
    margin-top: 2rem;
    border: 1px solid ${variables.green};
    color: ${variables.green};
    background-color: #ffffff;
    width: initial;
    min-width: 10rem;
    margin-bottom: 1rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1),
      0 3px 6px 0 rgba(0, 0, 0, 0.07);
    border-radius: 4px;
    font-family: ${variables.objReg};
    padding: 10px;
    font-size: 0.85rem;
    cursor: pointer;
  }

  .mobile-options a {
    color: ${variables.green};
    text-decoration: underline;
  }

  .mobile-options p.option a {
    font-size: 0.85rem;
  }
`;
