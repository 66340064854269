import PropTypes from "prop-types";
import React from "react";
import cn from "classnames";
import { connect } from "react-redux";
import assets from "patient_app/assets";

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {
    fieldSpecificErrors: state.common.fieldSpecificErrors,
  };
};

class PasswordField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  render() {
    let {
      field,
      title,
      value,
      id,
      showPasswordStrength,
      passwordDetails,
      required,
      fieldSpecificErrors,
      uid,
      name,
    } = this.props;
    let { show } = this.state;

    let error = fieldSpecificErrors[uid];

    return (
      <div className={cn("field", field)}>
        <label htmlFor={id}>
          {title} {required && <span className="light">(Required)</span>}
          {showPasswordStrength && (
            <span className={cn("password-strength", passwordDetails.color)}>
              {passwordDetails.status}
            </span>
          )}
        </label>

        <div className={`input-wrapper ${error ? "has-error" : ""}`}>
          <input
            id={id}
            type={show ? "text" : "password"}
            value={value}
            onChange={(e) => this.props.onUpdateField(e.target.value, field)}
            onKeyDown={this.props.onKeyPress}
            autoCorrect="off"
            autoCapitalize="off"
            aria-invalid={error ? true : false}
            spellCheck="false"
            name={name}
            ref={(input) => (this.input = input)}
            aria-describedby={error && `aria-describedby-${uid}`}
            className={cn(error && "has-error")}
            onBlur={this.props.onBlur}
          ></input>

          <button
            className="custom-button show-password"
            onClick={this.toggleShowPassword}
          >
            {show ? (
              <img src={assets.passwordHidden} />
            ) : (
              <img src={assets.passwordShown} />
            )}
          </button>
        </div>

        {error && !Array.isArray(error) && (
          <p
            role="alert"
            id={`aria-describedby-${uid}`}
            className={cn("field-specific-error field-error", field)}
          >
            Error: {error}
          </p>
        )}

        {error && Array.isArray(error) && this.renderMultipleErrors(error)}
      </div>
    );
  }

  toggleShowPassword = (e) => {
    if (e) e.preventDefault();
    this.setState({ show: !this.state.show });
  };

  renderMultipleErrors = (errors) => {
    try {
      return errors.map((error, i) => {
        return (
          <p
            role="alert"
            className="field-specific-error field-error multiple"
            key={i}
          >
            {error}
          </p>
        );
      });
    } catch (e) {
      return (
        <p role="alert" className="field-specific-error field-error">
          {errors}
        </p>
      );
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordField);
