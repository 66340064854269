import css from "styled-jsx/css";
import { variables } from "patient_app/stylesheets/variables.js";

export default css.global`
  .App-VideoRoom-Room {
    position: relative;
    min-height: calc(100vh - (16px * 4.5));
    height: calc(100vh - (16px * 4.5));
    min-width: 100vw;
    box-sizing: border-box;
  }

  .App-VideoRoom-Room.loading {
    display: flex;
  }

  .VideoRoom-Container {
    box-sizing: border-box;
    background: #272727;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    display: flex;
    height: calc(100vh - (16px * 4.5));
  }

  /* acts as wrapper for chat, middle, and local video components */
  .Video-Main-Content {
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;
