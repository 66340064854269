import PropTypes from "prop-types";
import React from "react";

import {
  Router,
  Route,
  NavLink,
  Switch,
  withRouter,
  Redirect,
  Link,
} from "react-router-dom";
import { connect } from "react-redux";
import { OPEN_CHAT } from "patient_app/constants/actionTypes";

import { getIconUrl } from "patient_app/helpers/linkHelpers";
import dashboardBlockStyles from "patient_app/stylesheets/appointments/dashboardBlockStyles";

const mapDispatchToProps = (dispatch) => {
  return {
    openChat: (data) => dispatch({ type: OPEN_CHAT, data: data }),
  };
};

const mapStateToProps = (state) => {
  return {};
};

class MessageShortcut extends React.Component {
  render() {
    let { other, chat } = this.props;

    if (!chat) {
      // don't render a button if there is no chat
      return (
        <div className="block-item">
          <img
            src={other.pic}
            alt={other.role + " image"}
            className="add-icon"
          />

          <div className="details">
            <p className="title">{other.full_name}</p>
            <p>{other.title}</p>
          </div>
        </div>
      );
    }

    return (
      <button
        className="custom-button block-item"
        onClick={(e) => this.props.openChat({ view: "index", chatId: chat.id })}
      >
        <img
          src={other.pic}
          alt={other.role + " profile picture"}
          className="add-icon"
        />

        <div className="details">
          <p className="title">{other.full_name}</p>
          {other.title && <p>{other.title}</p>}
        </div>

        {chat && <img src={getIconUrl("chevron-right", "dark-blue")} />}

        <style jsx>{dashboardBlockStyles}</style>
      </button>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageShortcut);
