export let occupationQuestions = {
  steelcase: "Are you a Steelcase employee, or a family member?",
  honigman: "What is your role at your company?",
};

export let occupationOptions = {
  steelcase: [
    ["Employee", "employee"],
    ["Family Member", "family_member"],
  ],
  honigman: [
    ["Attorney", "attorney"],
    ["Other Staff", "general_staff"],
  ],
};
