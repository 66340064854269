import css from "styled-jsx/css";
import { variables } from "patient_app/stylesheets/variables.js";

export default css.global`
  .Checklist p.no-margin {
    margin: 0;
  }

  .Checklist .has-errors {
    margin-top: 1rem;
  }

  .Checklist ul.document-list {
    padding: 0;
    margin: 0;
    margin-bottom: ${variables.base};
  }

  .Checklist ul.document-list li,
  .Checklist .block-item {
    width: 100%;
    display: flex;
    align-items: center;
    padding: ${variables.base} 0;
    border-top: 1px solid ${variables.grayLight};
    box-sizing: border-box;
  }

  .Checklist ul.document-list li:last-of-type {
    border-bottom: 1px solid ${variables.grayLight};
  }

  .Checklist .block-item.disabled {
    pointer-events: none;
  }

  body .Checklist #Main-Container :global(a.block-item) {
    text-decoration: none;
  }

  .Checklist .block-item input {
    margin-right: 0.5rem;
  }

  .Checklist .block-item label,
  .Checklist .block-item .item-title {
    flex: 5;
    font-family: ${variables.objMed};
    font-size: 0.8rem;
    margin: 0;
  }

  .Checklist label span.asterisk {
    color: #757575;
  }

  .Checklist .block-item .right {
    display: flex;
    align-items: center;
  }

  .Checklist .block-item .note-text {
    font-size: 0.7rem;
    color: #757575;
    margin: 0;
  }

  .Checklist .block-item .right img.hide {
    display: none;
  }

  .Checklist .block-item .note-text.margin {
    margin-right: 1rem;
  }

  :global(body .Checklist #Main-Container .field label) {
    color: ${variables.darkBlue};
  }

  :global(body .Checklist #Main-Container .submit-button.full-width) {
    width: 100%;
    margin-top: 2rem;
  }

  .Checklist .field.checkbox {
    flex-direction: column;
    align-items: flex-start;
  }

  .Checklist .field.checkbox .input-row {
    display: flex;
    align-items: center;
  }

  .Checklist .message-support {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem;
  }

  .Checklist .message-support p,
  .Checklist .message-support a {
    font-size: 0.8rem;
    font-family: ${variables.objMed};
    margin: 0;
  }

  .Checklist .submit-container {
    padding: 1.5rem 1rem 0;
  }

  .Checklist .submit-container .submit {
    background-color: ${variables.green};
    color: white;
    height: 2.5rem;
    width: 100%;
    font-family: ${variables.objMed};
    font-size: 0.85rem;
    box-sizing: border-box;
    border-radius: 4px;
  }

  @media only screen and (max-width: 950px) {
    .Checklist .content-main .content-box.checklist {
      width: 70%;
    }
  }

  @media only screen and (max-width: 600px) {
    .Checklist .content-main .content-box.checklist {
      width: 100%;
      margin: 0;
      padding-top: 4.5rem;
    }

    .Checklist .consent-checkbox .field input[type="checkbox"] {
      flex: initial;
    }

    .Checklist h1 {
      text-align: left;
    }

    .inputs.align-top {
      padding-left: 0;
      padding-right: 0;
    }

    .consent-fields .line {
      display: block;
      margin-top: 1rem;
    }

    :global(.field input[type="checkbox"]) {
      flex: 0;
    }

    .Checklist .message-support {
      justify-content: flex-start;
    }
  }
`;
