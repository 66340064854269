import PropTypes from "prop-types";
import React from "react";

import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { fetchProgram } from "patient_app/api/profileActions";

import LoadingSpinner from "patient_app/components/utils/LoadingSpinner";
import ProgressCircle from "patient_app/views/curriculums/partials/ProgressCircle";
import SubTabBack from "patient_app/views/profiles/partials/SubTabBack";

import { mobileCheck } from "patient_app/helpers/supported";

const mapDispatchToProps = (dispatch) => {
  return {
    fetchProgram: (profileId, params) =>
      dispatch(fetchProgram(profileId, params)),
  };
};

const mapStateToProps = (state) => {
  return {
    curriculums: state.profile.curriculums,
  };
};

class Curriculums extends React.Component {
  profileId = this.props.match.params.id;

  componentDidMount() {
    if (mobileCheck()) {
      this.props.fetchProgram(this.profileId, ["curriculums"]);
    }
  }

  render() {
    let { curriculums } = this.props;

    return (
      <div>
        <SubTabBack />
        <div className="profile-section last">
          <h1>My Curriculums</h1>

          {!curriculums && <LoadingSpinner />}

          {curriculums && (
            <div className="profile-list curriculums">
              {this.renderCurriculums()}
            </div>
          )}
        </div>
      </div>
    );
  }

  renderCurriculums() {
    if (this.props.curriculums.length === 0) {
      return (
        <div className="list-item none">
          <div className="row">
            <p>No assigned curriculums.</p>
          </div>
        </div>
      );
    }

    return this.props.curriculums.map((curric, i) => {
      const curricName = curric.active ? "Current Curriculum" : curric.name;
      const curricLink = curric.active
        ? "/courses"
        : `/curriculums/${curric.id}`;
      return (
        <div className="list-item" key={i}>
          <div className="row">
            <p>
              <span>{curricName}</span>
            </p>
            <div className="col align-right">
              <Link
                to={curricLink}
                aria-label={`Go to ${curricName}`}
                target="_blank"
              >
                <ProgressCircle
                  size={36}
                  strokeWidth={3}
                  percent={curric.progress}
                />
              </Link>
            </div>
          </div>
        </div>
      );
    });
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Curriculums)
);
