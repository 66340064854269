import React, { useState, useEffect } from "react";
import { getParticipantSignature } from "patient_app/api/zoomEventActions";
import { ZoomMtg } from "@zoom/meetingsdk";

const ZoomWebView = (props) => {
  //const client = ZoomMtgEmbedded.createClient();

  useEffect(() => {
    async function fetchData() {
      let params = new URLSearchParams(document.location.search);
      let id = params.get("zoom_participant_id");
      let registrantId = params.get("zoom_registrant_id");
      let registrantToken = params.get("zoom_registrant_token");
      const resp = await getParticipantSignature(
        id,
        registrantId,
        registrantToken
      );
      if (!resp.success) {
        window.alert(resp.error);
      } else {
        startMeeting(resp);
      }
    }
    fetchData();
  }, []);

  const startMeeting = (resp) => {
    let meetingSDKElement = document.getElementById("meetingSDKElement");

    let url = new URL(resp.zoom_participant.meeting_url);
    let params = new URLSearchParams(url.search);
    let registrantToken = params.get("tk");
    const leaveUrl = `${window.location.protocol}//${window.location.host}/api/v2/video_calls/leave_drug_test?zoom_participant_id=${resp.zoom_participant.id}`;

    console.log(leaveUrl, "leave pls");
    ZoomMtg.init({
      leaveUrl: leaveUrl,
      patchJsMedia: true,
      leaveOnPageUnload: true,
      success: (success) => {
        ZoomMtg.join({
          signature: resp.signature,
          sdkKey: resp.sdk_key,
          meetingNumber: resp.meeting_number,
          userName: `${resp.zoom_participant_json.first_name} ${resp.zoom_participant_json.last_name}`,
          userEmail: resp.zoom_participant_json.email,
          tk: registrantToken,
          success: (success) => {
            console.log(success);
          },
          error: (error) => {
            console.log(error);
          },
        });
      },
      error: (error) => {
        console.log(error);
      },
    });
  };

  return (
    <div>
      <main>
        <div id="meetingSDKElement">
          {/* Zoom Meeting SDK Component View Rendered Here */}
        </div>
      </main>

      <style global jsx>{`
        html * {
          font-family: -apple-system, BlinkMacSystemFont, "Segoe UI Adjusted",
            "Segoe UI", "Liberation Sans", sans-serif;
        }
      `}</style>
    </div>
  );
};

export default ZoomWebView;
