import PropTypes from "prop-types";
import React from "react";
import assets from "patient_app/components/chat/assets";
import timeHelpers from "patient_app/components/chat/utils/timeHelpers";

class SearchRow extends React.Component {
  static propTypes = {
    result: PropTypes.object.isRequired,
  };

  render() {
    let { result, resultType, q, viewingAsAdmin, tabIndex } = this.props;

    let profilePic = assets.defaultProfilePic;
    let fullName = "Workit Health";
    let lastMessageCreatedAt;
    let lastMessageHtml = "<p></p>";

    if (result.other) {
      fullName = result.other.full_name;

      if (
        result.other.pic &&
        !result.other.pic.endsWith(
          "dashboard-003e096ea7b2a80e981bff26aaba8dfdd88ef8e1e3284b65deb6b28763e5147e.png"
        )
      ) {
        profilePic = result.other.pic;
      }
    }

    if (result.category && result.category === "support" && !viewingAsAdmin) {
      profilePic = assets.supportChat;
      fullName = "Workit Support";
    }

    if (result.last_message) {
      lastMessageCreatedAt = timeHelpers.formatLastSentAt(
        result.last_message.created_at
      );
      lastMessageHtml = result.last_message.message;

      if (result.last_message.media_type !== "text") {
        lastMessageHtml = "<p><i>Attachment</i></p>";
      }
    }

    return (
      <button
        onClick={(e) =>
          this.props.onSelectResult(this.props.result, resultType)
        }
        className="custom-button"
        tabIndex={tabIndex}
      >
        <div className="item">
          <div className="profile-pic">
            <img src={profilePic} alt="" />
          </div>

          <div className="content">
            <div className="inner">
              {resultType === "name" && (
                <div>
                  {/*<h5>{this.formatMessage(fullName)}</h5>*/}
                  <h2 dangerouslySetInnerHTML={this.formatText(fullName)} />
                  <div
                    className="last-message-cont"
                    dangerouslySetInnerHTML={{ __html: lastMessageHtml }}
                  />
                </div>
              )}
              {resultType === "message" && (
                <div>
                  <h2>{fullName}</h2>
                  <div
                    className="last-message-cont"
                    dangerouslySetInnerHTML={this.formatText(lastMessageHtml)}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="info">
            <div className="inner">
              <p>{lastMessageCreatedAt}</p>
            </div>
          </div>
        </div>
      </button>
    );
  }

  formatText = (text) => {
    let { q } = this.props;
    let output = "";
    let index = -1;

    if (!text || !q) {
      return { __html: output };
    }

    // here we match against a lowercase version
    // but replace with the original case formatting
    let query = q.toLowerCase();
    let counter = 0;

    let workingText = text;
    while (workingText.length > 0) {
      index = workingText.toLowerCase().indexOf(query);
      let match = "";
      if (index < 0) {
        match = workingText.slice(0, workingText.length);
        output = output + match;
      } else if (index === 0) {
        match = workingText.slice(0, query.length);
        output = output + `<span>` + match + `</span>`;
      } else {
        match = workingText.slice(0, index);
        output = output + match;
      }
      workingText = workingText.replace(match, "");
      counter++;
    }

    // clean up output
    const pStart = new RegExp("<p[^>]*?>", "g");
    const pEnd = new RegExp("</p>", "g");
    const br = new RegExp("<br>", "g");
    output = output.replace(pStart, "");
    output = output.replace(pEnd, "");
    output = output.replace(br, " ");

    if (this.props.resultType === "name") {
      output = `<div>` + output + `</div>`;
    } else {
      output = "<p>" + output + "</p>";
    }

    return { __html: output };
  };
}

export default SearchRow;
