import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DateTime } from "luxon";

import { CHAT_UPDATE_STATE } from "patient_app/constants/actionTypes";
import { fetchUser } from "patient_app/api/commonActions";
import { fetchClinic } from "patient_app/api/clinicActions";

import assets from "patient_app/assets";
import SessionStore from "patient_app/helpers/SessionStore";
import businessHoursStyles from "patient_app/stylesheets/chat/businessHoursStyles";

const BusinessHours = (props) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const { clinic, counselor, user } = useSelector((state) => {
    return {
      clinic: state.common.clinic,
      counselor: state.common.counselor,
      user: state.common.user,
    };
  });

  const checkIfBannerShouldShow = () => {
    // do not show if hours are between 9-5 in clinic time zone
    if (!checkTime()) {
      return;
    }

    // do not show pop-up if last cleared less than 2 hours ago
    const lastViewed = SessionStore.get("chatInfo");
    if (
      !lastViewed ||
      DateTime.fromISO(lastViewed) <= DateTime.local().minus({ hours: 2 })
    ) {
      dispatch({
        type: CHAT_UPDATE_STATE,
        key: "emergencyBannerType",
        value: "after_hours",
      });
      dispatch({
        type: CHAT_UPDATE_STATE,
        key: "showEmergencyBanner",
        value: true,
      });
    }

    setShow(true);
  };

  const checkTime = () => {
    const tz = clinic?.time_zone || counselor?.time_zone || "America/Detroit";
    const currentTime = DateTime.utc().setZone(tz);
    const beforeNine = currentTime.set({ hour: 9 }).startOf("hour");
    const afterFive = currentTime
      .set({ hour: 17, minute: 30 })
      .startOf("minute");

    if ([6, 7].includes(currentTime.weekday)) {
      // if saturday or sunday
      return true;
    } else if (currentTime < beforeNine || currentTime > afterFive) {
      // if time is before 9am or after 5pm in the clinic's time zone
      return true;
    }

    return false;
  };

  const handleToggleEmergencyBanner = () => {
    dispatch({
      type: CHAT_UPDATE_STATE,
      key: "emergencyBannerType",
      value: "emergency",
    });
    dispatch({
      type: CHAT_UPDATE_STATE,
      key: "showEmergencyBanner",
      value: true,
    });
  };

  useEffect(() => {
    if (!user) {
      dispatch(fetchUser());
    }

    if (!clinic) {
      dispatch(fetchClinic());
    }

    checkIfBannerShouldShow();
  }, []);

  useEffect(() => {
    if (clinic || counselor) {
      checkIfBannerShouldShow();
    }
  }, [clinic, counselor]);

  let zone = "EST"; // if not a clinic member, default to Eastern Standard Time
  if (clinic?.time_zone) {
    zone = DateTime.utc().setZone(clinic.time_zone).toFormat("ZZZZ");
  } else if (counselor?.primary_time_zone) {
    zone = DateTime.utc().setZone(counselor.primary_time_zone).toFormat("ZZZZ");
  }

  if (!show) {
    return <div />;
  }

  return (
    <button
      className="custom-button business-hours"
      onClick={handleToggleEmergencyBanner}
    >
      <p>
        <span>
          Your Care Team is currently unavailable. Click here if you need help
          now.
        </span>
      </p>

      <style jsx>{businessHoursStyles}</style>
    </button>
  );
};

export default BusinessHours;
