import React from "react";
import { getIconUrl } from "patient_app/helpers/linkHelpers";
import { variables } from "patient_app/stylesheets/variables";

const Modal = (props) => {
  return (
    <div className={`modal ${props.className ? props.className : ""}`}>
      <div className="modal-overlay" />
      <div className="modal-box">
        <div className="header">
          <p>
            <span>{props.title}</span>
          </p>

          <button className="custom-button" onClick={props.onCancel}>
            <img src={getIconUrl("exit", "dark-blue")} alt="Cancel" />
          </button>
        </div>

        <div className="content">{props.children}</div>
      </div>

      <style jsx>{`
        .modal {
          position: fixed;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 3;
        }

        .modal-overlay {
          position: fixed;
          width: 100%;
          height: 100%;
          background: ${variables.gray};
          opacity: 0.5;
        }

        .modal-box {
          position: absolute;
          display: flex;
          flex-direction: column;
          width: calc(${variables.small} * 25);
          min-height: calc(${variables.small} * 25);
          height: fit-content;
          background: white;
          top: calc(${variables.small} * 5);
          left: 0;
          right: 0;
          margin: auto;
          opacity: 1;
          z-index: 1;
        }

        .modal-box p {
          font-family: ${variables.objReg};
          font-size: 0.8rem;
          margin: 0;
        }

        .modal-box p span {
          font-family: ${variables.objMed};
        }

        .modal-box .header {
          display: flex;
          justify-content: space-between;
          padding: ${variables.base};
          border-bottom: 1px solid ${variables.grayLight};
        }

        .modal-box .header button {
          display: flex;
        }

        .modal-box .header button img {
          height: ${variables.small};
        }

        .modal-box .content {
          box-sizing: border-box;
          padding: ${variables.base};
        }

        @media (max-width: 768px) {
          .modal-box {
            width: 100vw;
            height: 100vh;
            min-height: initial;
            margin: 0;
            top: 0;
            box-sizing: border-box;
          }

          .modal-box .content {
            flex: 1;
          }
        }
      `}</style>
    </div>
  );
};

export default Modal;
