import css from "styled-jsx/css";
import { variables } from "patient_app/stylesheets/variables.js";

export default css.global`
  .Video-Busy-Screen {
    flex: auto;
    position: relative;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    height: calc(100vh - 4rem);
    overflow: scroll;
  }

  .Video-Busy-Screen.CONNECTED {
    display: none;
  }

  .Video-Busy-Screen.DISCONNECTED.show-feedback {
    align-items: flex-start;
  }

  .Video-Busy-Screen h1 {
    font-size: 1.1rem;
    line-height: 1.7;
  }

  .Video-Busy-Screen p {
    font-family: ${variables.objMed};
    font-size: 0.75rem;
  }

  .Video-Busy-Screen a.submit {
    display: flex;
    color: white;
    background-color: ${variables.green};
    font-family: ${variables.objMed};
    font-size: 0.8rem;
    min-width: calc(16px * 13);
    height: fit-content;
    min-height: calc(16px * 2.5);
    justify-content: center;
    align-items: center;
    border-radius: 0;
    text-align: center;
    box-sizing: border-box;
  }

  .Video-Busy-Screen a.link {
    display: block;
    color: ${variables.green};
    font-family: ${variables.objReg};
    font-size: 0.8rem;
    text-decoration: underline;
    min-width: calc(16px * 13);
    height: fit-content;
    min-height: calc(16px * 2.5);
    padding: ${variables.tiny} ${variables.small};
    border-radius: 0;
    text-align: center;
    box-sizing: border-box;
  }

  .Video-Busy-Screen a.submit.link {
    text-decoration: underline;
  }

  .Video-Busy-Screen a.submit:visited {
    color: white;
  }

  .App-Video-Call .Video-Busy-Screen p {
    line-height: 26px;
    font-size: 0.8rem;
    text-align: center;
    font-family: ${variables.objReg};
    color: ${variables.darkBlue};
    margin-bottom: 1rem;
  }

  .Video-Busy-Screen .inner {
    background: white;
    width: 27rem;
    max-width: 27rem;
    padding: 3rem;
    margin: 2rem 0;
    box-sizing: border-box;
  }

  .Video-Busy-Screen .circle {
    position: relative;
    display: block;
    height: 5rem;
    margin: auto;
    width: 5rem;
    border-radius: 50%;
    background: transparent;
    border: 3px solid ${variables.medGray};
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Video-Busy-Screen .circle .logo {
    height: 2.25rem;
    width: 2.25rem;
  }

  .Video-Busy-Screen .circle .logo img {
    height: 100%;
    width: 100%;
  }

  .Video-Busy-Screen .circle .status-radius {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    height: 0;
    transform: rotate(45deg);
    transition: all 300ms;
    transform-origin: 0 0;
  }

  .Video-Busy-Screen .circle .status-radius.CONNECTED,
  .Video-Busy-Screen .circle .status-radius.CONNECTING {
    transform: rotate(225deg);
  }

  .Video-Busy-Screen .circle .status-radius.CONNECTED .status,
  .Video-Busy-Screen .circle .status-radius.CONNECTING .status {
    background-color: ${variables.green};
  }

  .Video-Busy-Screen .circle .status-radius .status {
    border: 2px solid ${variables.medGray};
    background-color: ${variables.red};
    height: 0.85rem;
    width: 0.85rem;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
  }

  .Video-Busy-Screen p.status-text {
    margin-top: 1.25rem;
    text-align: center;
    font-family: ${variables.objReg};
    font-size: 1rem;
    line-height: initial;
  }

  .Video-Busy-Screen p.status-text.WAITING,
  .Video-Busy-Screen p.status-text.DISCONNECTED {
    color: ${variables.red};
  }

  .Video-Busy-Screen p.status-text.CONNECTED,
  .Video-Busy-Screen p.status-text.CONNECTING {
    color: ${variables.green};
  }

  .Video-Busy-Screen .info-box {
    background: white;
    width: 27rem;
    max-width: 27rem;
    min-height: 50%;
    margin: 2rem 0;
    box-sizing: border-box;
  }

  .Video-Busy-Screen .info-box .header {
    padding: 2rem;
    border-bottom: 1px solid ${variables.boxBorder};
  }

  .Video-Busy-Screen .info-box .header p {
    color: ${variables.lightText};
  }

  .Video-Busy-Screen .info-box .header p,
  .Video-Busy-Screen .rating-section p.question {
    font-family: ${variables.objBold};
    font-size: 1rem;
    text-align: left;
    margin: 0;
    padding: 0;
  }

  .Video-Busy-Screen .rating-section {
    padding: 2rem;
  }

  .Video-Busy-Screen .rating-section button.submit {
    width: 100%;
    height: 2.75rem;
    color: ${variables.green};
    background-color: transparent;
    border: 1px solid ${variables.green};
    border-radius: 4px;
  }

  .Video-Busy-Screen .rating-section button.submit.green {
    color: white;
    background-color: ${variables.green};
  }

  .Video-Busy-Screen .rating-star {
    display: flex;
    justify-content: space-between;
    margin: 2rem 0;
  }

  .Video-Busy-Screen .rating-star button.star {
    background-color: transparent;
    width: fit-content;
    height: fit-content;
    padding: 0;
    margin: 0;
  }

  .Video-Busy-Screen .rating-star button.star img {
    width: 3.5rem;
  }

  .Video-Busy-Screen .rating-feedback {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 1rem;
  }

  .Video-Busy-Screen :global(.gtc-multi-select .searchBox) {
    width: 0;
  }
  .Video-Busy-Screen :global(label) {
    text-align: left;
  }

  @media only screen and (max-width: 600px) {
    .Video-Busy-Screen .inner {
      box-sizing: border-box;
      height: 100%;
      width: 100%;
      max-height: 100%;
      max-width: 100%;
    }
  }
`;
