import { DateTime } from "luxon";

let time = {
  formatForTileStart(time) {
    let str = time.toFormat("h:mm");

    if (time.toFormat("mm") == "00") {
      str = time.toFormat("h");
    }

    return str;
  },
  formatForTileEnd(time) {
    let str = `${time.toFormat("h:mm")}${time.toFormat("a").toLowerCase()}`;

    if (time.toFormat("mm") == "00") {
      str = `${time.toFormat("h")}${time.toFormat("a").toLowerCase()}`;
    }

    return str;
  },

  getTimeZone(profile) {
    return (
      profile?.time_zone || DateTime.local()?.zoneName || "America/Detroit"
    );
  },
};

export default time;
