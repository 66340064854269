import PropTypes from "prop-types";
import React from "react";
import moment from "moment";
import cn from "classnames";
import _ from "lodash";

import { Router, Route, NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import ItemActions from "patient_app/views/curriculums/partials/ItemActions";
import OuterElement from "patient_app/views/curriculums/partials/OuterElement";
import { curriculum } from "patient_app/assets/curriculum";

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {
    selectedItem: state.curriculum.selectedItem,
    specialDelivery: state.curriculum.specialDelivery,
    user: state.common.user,
  };
};

class Item extends React.Component {
  static propTypes = {
    item: PropTypes.object,
  };

  render() {
    let { item, sectionLocked, selectedItem, skipLoading, user } = this.props;

    const iconLink = this.getIconLink();
    const isSelected = selectedItem ? selectedItem.id === item.id : false;
    let locked = item.locked;

    let unlocksString;
    if (item.unlocks_at && item.locked === true) {
      unlocksString = this.getUnlocksString(item.unlocks_at);
    } else if (item.unlocks_at) {
      unlocksString = "placeholder";
    }

    if (locked === true && this.isFirstItemInSection()) {
      locked = false;
    }

    let grayBorder = (locked || item.status != "COMPLETED") && "gray-border";

    return (
      <li
        className={cn(
          "item",
          locked ? "locked" : "",
          isSelected ? "selected" : "",
          `item-${item.id}`,
          unlocksString ? "has-unlock" : "",
          grayBorder
        )}
      >
        <OuterElement
          renderButton={!locked}
          item={item}
          toggleSelect={this.toggleSelect}
        >
          <div className="content">
            {unlocksString && (
              <p
                className={cn(
                  "unlocks",
                  unlocksString === "placeholder" ? "placeholder" : ""
                )}
              >
                {unlocksString}
              </p>
            )}

            <div className="image">
              <img
                data-src={item.image}
                className="lazyload main"
                aria-hidden="true"
                alt=""
              />
              <div
                className={cn(
                  "icon",
                  iconLink === curriculum.navyCheck && "min-padding"
                )}
              >
                <img
                  src={iconLink}
                  alt={item.locked ? "Locked icon." : "Play icon"}
                />
              </div>
            </div>

            <p className="title">{item.exercise ? item.exercise.name : "-"}</p>
          </div>
        </OuterElement>

        {isSelected && (
          <ItemActions
            item={item}
            toggleSelect={this.toggleSelect}
            onSkip={this.props.onSkip}
            skipLoading={skipLoading}
            isSelected={isSelected}
            user={user}
          />
        )}
      </li>
    );
  }

  toggleSelect = (item) => {
    this.props.onToggleItem(item);
  };

  getIconLink = () => {
    let { item } = this.props;
    let link = curriculum.playGray;
    if (item.locked && !item.progressed) {
      link = curriculum.locked;
    } else if (!item.locked && item.status == "SKIPPED") {
      link = curriculum.skipped;
    }

    if (item.status === "COMPLETED") {
      link = curriculum.navyCheck;
    }

    return link;
  };

  isFirstItemInSection = () => {
    let { position, clusterPosition, sectionLocked } = this.props;
    return position === 1 && clusterPosition === 1 && !sectionLocked;
  };

  getUnlocksString(unlocks_at) {
    let unlockString = "";
    let unlockAt = moment(unlocks_at);
    let dayDiff = unlockAt.diff(moment(), "days");
    if (dayDiff == 0) {
      let hourDiff = unlockAt.diff(moment(), "hours");
      if (hourDiff > 1) {
        unlockString = `${hourDiff} hour${hourDiff == 1 ? "" : "s"}`;
      } else {
        let minDiff = unlockAt.diff(moment(), "minutes");
        if (minDiff > 1) {
          unlockString = `${minDiff} minute${minDiff == 1 ? "" : "s"}`;
        } else {
          unlockString = "less than a minute!";
        }
      }
    } else if (dayDiff >= 30) {
      let weekDiff = unlockAt.diff(moment(), "weeks");
      unlockString = `${weekDiff} week${weekDiff == 1 ? "" : "s"}`;
    } else {
      unlockString = `${dayDiff} day${dayDiff == 1 ? "" : "s"}`;
    }
    return `Unlocks in ${unlockString}`;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Item);
