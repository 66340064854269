import React from "react";
import _ from "lodash";

import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  createNetPromoterScore,
  updateNetPromoterScore,
} from "patient_app/api/netPromoterScoreActions";
import { SESSION_SET_LOCAL_STATE } from "patient_app/constants/actionTypes";

import ProfileField from "patient_app/views/profiles/partials/ProfileField";

import assets from "patient_app/assets";
import npsStyles from "patient_app/stylesheets/components/npsStyles";

const mapDispatchToProps = (dispatch) => {
  return {
    createNPS: (params) => dispatch(createNetPromoterScore(params)),
    updateNPS: (params) => dispatch(updateNetPromoterScore(params)),
    closeModal: () =>
      dispatch({ type: SESSION_SET_LOCAL_STATE, obj: "npsDue", value: false }),
  };
};

const mapStateToProps = (state) => {
  return {
    nps: state.session.nps,
  };
};

class NetPromoterScore extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rating: null,
      details: "",
      canContact: false,
    };
  }

  componentDidMount() {
    if (this.props.nps) {
      this.setState({
        rating: this.props.nps.rating,
        details: this.props.nps.details,
        canContact: this.props.nps.can_contact,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const nps = this.props.nps;
    if (!prevProps.nps && nps) {
      this.setState({
        rating: !!nps.rating ? nps.rating : "",
        details: !!nps.details ? nps.details : "",
        canContact: nps.can_contact ? nps.can_contact : false,
      });
    }
  }

  render() {
    let { nps } = this.props;
    let { rating, details, canContact } = this.state;

    let prompt = "rating"; // "rating", "details", "completed"
    if (nps) {
      if (!!nps.rating && !nps.completed) {
        // rating has been submitted, but still requires details
        prompt = "details";
      } else if (nps.completed) {
        prompt = "completed";
      }
    }

    let question = "";
    let negative = rating <= 6;
    if (prompt === "rating") {
      question = "How likely are you to recommend Workit Health to a friend?";
    } else if (prompt === "details") {
      if (negative) {
        question = "What do you like the least?";
      } else {
        question = "What do you like the best?";
      }
    }

    const canSubmit =
      (prompt === "rating" && !!rating) || (prompt === "details" && !!details);
    return (
      <div className={`nps ${prompt}`}>
        {["completed"].includes(prompt) && (
          <div className="nps-modal">
            <button
              className="custom-button close-nps"
              onClick={this.props.closeModal}
            >
              <img src={assets.grayCross} />
            </button>

            <div className="section">
              <p className="thank-you">Thank you for your feedback!</p>
              <p>
                Our goal is to create the best possible product, and your
                thoughts, ideas, and suggestions play a major role in helping us
                identify opportunities to improve.
              </p>
            </div>
          </div>
        )}

        {["rating", "details"].includes(prompt) && (
          <div className="nps-modal">
            <div className="section left">
              <p>{question}</p>
            </div>

            {prompt === "rating" && (
              <div className="section center">
                <fieldset
                  className="rating-boxes"
                  aria-label={`${question} 1 - not likely, 10 - very likely`}
                >
                  {this.renderRatingBoxes()}
                </fieldset>
                <div className="rating-scale">
                  <p>Not likely</p>
                  <p>Very likely</p>
                </div>
              </div>
            )}

            {prompt === "details" && (
              <div className="section center">
                <input
                  id="details"
                  onChange={(e) => this.setState({ details: e.target.value })}
                  type="text"
                  value={details || ""}
                  aria-label={question}
                />

                <div className="chat-more">
                  <input
                    id="can-contact"
                    checked={canContact || false}
                    onChange={(e) =>
                      this.setState({ canContact: e.target.checked })
                    }
                    type="checkbox"
                  />
                  <label htmlFor="can-contact">
                    I'd be interested in talking to Workit about ways to make
                    the app and program better.
                  </label>
                </div>
              </div>
            )}

            <div className="section right">
              <button
                className={`custom-button submit ${
                  canSubmit ? "" : "disabled"
                }`}
                onClick={this.handleSubmitNPS}
              >
                Submit Answer
              </button>
            </div>
          </div>
        )}

        <style jsx>{npsStyles}</style>
      </div>
    );
  }

  renderRatingBoxes() {
    let { rating } = this.state;
    const arr = new Array(10);
    return _.range(1, 11).map((num) => {
      const isSelected = num === rating;
      let color = "red";
      if ([9, 10].includes(num)) {
        color = "green";
      } else if ([7, 8].includes(num)) {
        color = "yellow";
      }

      if (isSelected) {
        color = "navy";
      }

      let image = null;
      let altText = ` face - selected rating of ${rating}`;
      if ([9, 10].includes(rating)) {
        image = assets.ratingSmile;
        altText = "Smiling" + altText;
      } else if ([7, 8].includes(rating)) {
        image = assets.ratingNeutral;
        altText = "Neutral" + altText;
      } else {
        image = assets.ratingFrown;
        altText = "Frowning" + altText;
      }

      return (
        <button
          key={num}
          className={`custom-button rating-box ${color}`}
          onClick={() => this.setState({ rating: num })}
          aria-label={`Rate the Workit Health program ${num}`}
          aria-selected={isSelected}
        >
          {isSelected ? <img src={image} alt={altText} /> : num}
        </button>
      );
    });
  }

  handleSubmitNPS = () => {
    // no error checking - button is disabled until rating is selected or
    // details are inputted

    if (!this.props.nps) {
      // if no NetPromoterScore object was fetched, then it needs to be created
      const nps = { rating: this.state.rating };
      this.props.createNPS(nps);
    } else {
      let nps = this.props.nps;
      if (this.state.rating) {
        nps.rating = this.state.rating;
      }

      if (this.state.details) {
        nps.details = this.state.details;
        nps.can_contact = this.state.canContact;
      }
      this.props.updateNPS(nps);
    }
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NetPromoterScore)
);
