import PropTypes from "prop-types";
import React from "react";
import cn from "classnames";
import _ from "lodash";
import Api from "patient_app/api";
//import actionCable from 'components/utils/actionCable';
import ActionCable from "actioncable";
import { DateTime } from "luxon";

import { connect } from "react-redux";
import permissions from "patient_app/helpers/permissions";
import { getUrlVars } from "patient_app/helpers/linkHelpers";

import {
  UPDATE_UNREAD_COUNT,
  UPDATE_SHOW_CHAT,
  CHATS_LOAD,
  OPEN_CHAT,
  CHAT_UPDATE_STATE,
} from "patient_app/constants/actionTypes";

//rework
import ChatToggle from "patient_app/components/chat/ChatToggle";
import Container from "patient_app/components/chat/Container";
import EmergencyBanner from "patient_app/components/chat/EmergencyBanner";
import VIEWS from "patient_app/components/chat/Views";
import {
  standardViews,
  views,
} from "patient_app/components/chat/constants/views";
import { mobileCheck } from "patient_app/helpers/supported";

const mapDispatchToProps = (dispatch) => {
  return {
    updateUnreadMessages: (unreadCount) =>
      dispatch({ type: UPDATE_UNREAD_COUNT, unreadCount: unreadCount }),
    updateShowChat: (showChat) =>
      dispatch({ type: UPDATE_SHOW_CHAT, showChat: showChat }),
    loadChats: (chats) => dispatch({ type: CHATS_LOAD, chats: chats }),
    openChat: (data) => dispatch({ type: OPEN_CHAT, data: data }),
    updateChatState: (key, value) =>
      dispatch({ type: CHAT_UPDATE_STATE, key: key, value: value }),
  };
};

const mapStateToProps = (state) => {
  return {
    chatActions: state.chat.chatActions,
    chats: state.chat.chats,
    coach: state.common.counselor,
    showEmergencyBanner: state.chat.showEmergencyBanner,
    unreadCount: state.chat.unreadCount,
    user: state.common.user,
  };
};

class PatientChat extends React.Component {
  static propTypes = {};

  static defaultProps = {
    open: false,
    unreadCount: 0,
    view: "index",
    previousView: "index",
    views: standardViews,
    chats: [],
    showEmergencyButton: true,
    chatSub: null,
    cable: null,
    totalUnreads: 0,
    showSearchButton: true,
    showSearchBar: false,
    q: "",
    searchIsFocused: false,
    searchResultNames: [],
    searchResultMessages: [],
    selectedMessageId: 0,
    prepopulatedMessage: "",
    bioLink: "",
    viewingAsAdmin: false,
  };

  constructor(props) {
    super(props);
    //this.contentEditable = React.createRef();
    this.state = {
      open: props.open,
      unreadCount: props.unreadCount,
      view: props.view,
      previousView: props.previousView,
      chats: props.chats,
      activeChat: props.activeChat,
      showEmergencyButton: props.showEmergencyButton,
      notifSub: props.notifSub,
      cable: props.cable,
      totalUnreads: props.totalUnreads,
      showSearchButton: props.showSearchButton,
      showSearchBar: props.showSearchBar,
      q: props.q,
      searchIsFocused: props.searchIsFocused,
      searchResultNames: props.searchResultNames,
      searchResultMessages: props.searchResultMessages,
      selectedMessageId: props.selectedMessageId,
      prepopulatedMessage: props.prepopulatedMessage,
      bioLink: props.bioLink,
      viewingAsAdmin: props.viewingAsAdmin,
      coachChatId: props.coachChatId,
    };
    this.escapeEmergencyBanner = this.escapeEmergencyBanner.bind(this);
  }

  componentWillUnmount = () => {
    let { cable } = this.state;

    if (cable && cable.subscriptions && cable.subscriptions.subscriptions) {
      cable.subscriptions.subscriptions.map((sub, i) => {
        cable.subscriptions.remove(sub);
      });
    }

    this.setState({ cable: null, notifSub: null, activeChat: null });
    document.removeEventListener("keydown", this.escapeEmergencyBanner, false);
  };

  componentDidMount = () => {
    let { cta } = this.props;
    if (cta) {
      this.setState({
        showEmergencyButton: false,
        showSearchButton: false,
        view: "upgrade",
        canGoBack: false,
      });
      return;
    }

    this.initialize();

    //check to see if we need to load search results every 800 ms
    const intervalId = setInterval(this.fetchSearchFallback, 800);

    //listen for escape key
    document.addEventListener("keydown", this.escapeEmergencyBanner, false);
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.chatActions !== this.props.chatActions && this.state.chats) {
      let prepopulated = "";
      let activeChat;
      let view = "index";
      const lastAction =
        this.props.chatActions[this.props.chatActions.length - 1];

      if (lastAction?.prepopulatedMessage) {
        prepopulated = lastAction.prepopulatedMessage;
      }

      if (lastAction?.adminId) {
        const chatToOpen = _.find(this.state.chats, {
          admin_id: lastAction.adminId,
          comm_type: "ONE_ON_ONE",
        });
        if (chatToOpen) {
          activeChat = chatToOpen;
          view = "chat";
        }
      }

      if (lastAction?.category) {
        const chatToOpen = _.find(this.state.chats, {
          category: lastAction.category,
        });
        if (chatToOpen) {
          activeChat = chatToOpen;
          view = "chat";
        }
      }

      if (lastAction?.topic) {
        const chatToOpen = _.find(this.state.chats, {
          topic: lastAction.topic,
        });
        if (chatToOpen) {
          activeChat = chatToOpen;
          view = "chat";
        }
      }

      if (lastAction?.chatId) {
        const chatToOpen = _.find(this.state.chats, { id: lastAction.chatId });
        if (chatToOpen) {
          activeChat = chatToOpen;
          view = "chat";
        }
      }

      if (
        !this.state.open ||
        this.state.activeChat !== activeChat ||
        this.state.view !== view
      ) {
        this.setState({
          open: true,
          view: view,
          q: "",
          showSearchBar: false,
          activeChat: activeChat,
          prepopulatedMessage: prepopulated,
        });
      }

      const chatToggle = document.getElementById("chat-toggle");
      if (chatToggle) {
        chatToggle.focus();
      }
    }
  };

  initialize = async () => {
    let url = "/chats";
    if (getUrlVars(window.location.href)["user_id"]) {
      url += `?user_id=${getUrlVars(window.location.href)["user_id"]}`;
    }
    const data = {
      url: url,
      path: "/api/v1",
      data: { method: "GET" },
    };
    const res = await Api.makeRequest(data);

    if (res && res.chats) {
      this.setState({
        chats: res.chats,
        coachChatId: res.coachChatId,
        totalUnreads: res.unreadCount,
      });
      this.props.loadChats(res.chats);
      this.props.updateUnreadMessages(res.unreadCount);
      this.subscribe();
    }
  };

  fetchSearchFallback = () => {
    const now = DateTime.local();
    let { q, lastTypingCheckIn, view } = this.state;
    let diff;

    if (q && q.length >= 2 && lastTypingCheckIn) {
      diff = now.diff(lastTypingCheckIn, "seconds");
      if (diff.seconds >= 0.8 && view === "loading") {
        this.fetchSearchResults();
      }
    } else if (q && !lastTypingCheckIn) {
      this.setState({ lastTypingCheckIn: now });
    }
  };

  render() {
    let { showEmergencyBanner, views } = this.props;

    let {
      open,
      unreadCount,
      view,
      chats,
      totalUnreads,
      showSearchButton,
      showSearchBar,
      q,
      searchIsFocused,
      showEmergencyButton,
      bioLink,
    } = this.state;

    if (!chats) {
      return <div></div>;
    }

    const View = VIEWS[view];
    const props = this.getPropsForView(view);
    const title = this.getTitleForView(view);
    this.applyMobileOverflow();

    return (
      <div id="App-Chat" className={cn(open ? "open" : "")}>
        <ChatToggle
          badgeCount={totalUnreads}
          open={open}
          onToggleChats={this.handleToggleChats}
          view={view + (showEmergencyBanner ? " emergency-banner" : "")}
          //color={this.props.color}
        />

        <Container
          title={title}
          onToggleChats={this.handleToggleChats}
          onToggleEmergencyBanner={this.handleToggleEmergencyBanner}
          showEmergencyBanner={showEmergencyBanner}
          showEmergencyButton={showEmergencyButton}
          canGoBack={this.canGoBack(view)}
          onGoBack={this.handleGoBack}
          onToggleSearch={this.handleToggleSearch}
          q={q}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onCancel={this.handleCancel}
          onKeyDown={this.handleKeyDown}
          showSearchButton={showSearchButton}
          showSearchBar={showSearchBar}
          searchIsFocused={searchIsFocused}
          bioLink={bioLink}
          open={open}
          tabIndex={showEmergencyBanner ? -1 : 0}
        >
          {open && showEmergencyBanner && (
            <EmergencyBanner
              onToggleEmergencyBanner={this.handleToggleEmergencyBanner}
            />
          )}

          <div
            className={cn(
              "content",
              searchIsFocused ? "search-focus-overlay" : ""
            )}
          >
            {open && <View {...props} onOpenChat={this.handleOpenChat} />}
          </div>
        </Container>
      </div>
    );
  }

  getPropsForView = (view) => {
    let {
      chats,
      activeChat,
      searchResultNames,
      searchResultMessages,
      q,
      selectedMessageId,
      prepopulatedMessage,
    } = this.state;
    const { showEmergencyBanner } = this.props;

    let props;

    switch (view) {
      case "index":
        props = {
          chats: chats,
          onSelectChat: this.handleSelectChat,
          clearChatData: this.clearChatData,
          viewingAsAdmin: this.props.viewingAsAdmin,
          tabIndex: showEmergencyBanner ? -1 : 0,
        };
        break;
      case "chat":
        props = {
          chat: activeChat,
          selectedMessageId: selectedMessageId,
          showGetNextMessages: selectedMessageId ? true : false,
          onUpdateChat: this.handleUpdateChat,
          onUpdateTotalUnreads: this.handleUpdateTotalUnreads,
          message: prepopulatedMessage,
          viewingAsAdmin: this.props.viewingAsAdmin,
          tabIndex: showEmergencyBanner ? -1 : 0,
          currentUser: this.props.user,
        };
        break;
      case "search":
        props = {
          resultNames: searchResultNames,
          resultMessages: searchResultMessages,
          onSelectResult: this.handleSelectResult,
          q: q,
          tabIndex: showEmergencyBanner ? -1 : 0,
        };
        break;
      default:
        props = {};
        break;
    }

    return props;
  };

  clearChatData = () => {
    this.setState({
      activeChat: null,
      selectedMessageId: null,
    });
  };

  getTitleForView = (view) => {
    let title;
    let { activeChat } = this.state;

    switch (view) {
      case "index":
        title = "Chats";
        break;
      case "chat":
        title = "Chat";
        if (activeChat && activeChat.other) {
          title = activeChat.other.full_name;
          if (activeChat.category === "support") {
            title = "Workit Support";
          }
        }
        break;
      case "search":
        title = "Chats";
        break;
      case "loading":
        title = "Chats";
        break;
      case "upgrade":
        title = "Upgrade";
        break;
      default:
        title = "";
        break;
    }

    return title;
  };

  handleToggleChats = (e) => {
    if (e) {
      e.preventDefault();
    }
    //reset view to index if we are closing the chats
    let { view, open } = this.state;
    let newView = view;
    if (view !== "upgrade") {
      newView = "index";
    }

    this.setState({
      open: !open,
      view: "index",
      q: "",
      showSearchBar: false,
      view: newView,
      bioLink: "",
    });
  };

  handleToggleEmergencyBanner = () => {
    this.props.updateChatState(
      "showEmergencyBanner",
      !this.props.showEmergencyBanner
    );
  };

  handleSelectChat = (chat) => {
    this.getBioLink(chat).then((data) => this.setState({ bioLink: data }));

    this.setState({
      activeChat: chat,
      view: "chat",
      previousView: "index",
      showSearchButton: false,
      q: "",
      showSearchBar: false,
      open: true,
    });
  };

  handleToggleSearch = () => {
    this.setState({ showSearchBar: !this.state.showSearchBar });
  };

  handleChange = (val) => {
    const now = DateTime.local();
    this.setState({ q: val, lastTypingCheckIn: now });
  };

  handleFocus = (e) => {
    this.setState({ searchIsFocused: true });
  };

  handleBlur = (e) => {
    this.setState({ searchIsFocused: false });
  };

  handleCancel = (e) => {
    this.setState({
      searchIsFocused: false,
      view: "index",
      q: "",
      showSearchButton: true,
      showSearchBar: false,
    });
  };

  handleKeyDown = (e) => {
    if (e.keyCode !== 13 && e.keyCode !== 9) {
      this.setState({ view: "loading" });
    } else if (e.keyCode === 9) {
      this.handleBlur();
    } else if (e.keyCode === 13) {
      let { chats, view } = this.props;

      this.setState({ view: "loading", searchIsFocused: false });
      this.fetchSearchResults();
    }
  };

  handleOpenChat = (topic) => {
    this.props.openChat({ view: "chat", topic: topic.toUpperCase() });
  };

  fetchSearchResults = async () => {
    let { q } = this.state;
    let { viewingAsAdmin } = this.props;
    let userId = viewingAsAdmin && this.props.chats[0].user_id;

    const data = {
      url: `/chat_sessions/search?query=${q}&user_id=${userId}`,
      data: {
        method: "GET",
      },
    };
    const response = await Api.makeRequest(data);

    this.setState({
      searchResultNames: response.name_matches,
      searchResultMessages: response.message_matches,
      view: "search",
    });
  };

  subscribe = async () => {
    if (this.props.viewingAsAdmin) {
      return;
    }

    let endpoint = `wss://${window.location.host}/cable`;
    //use non ssl endpoint for local host, ssl otherwise
    if (window.location.host.startsWith("localhost")) {
      endpoint = `ws://${window.location.host}/cable`;
    }
    const params = {
      channel: "NotificationsChannel",
    };

    const eventHandlers = {
      received: (data) => {
        this.handleReceiveData(data);
      },
    };

    const cable = ActionCable.createConsumer(endpoint);
    let notifSub = cable.subscriptions.create(params, eventHandlers);
    this.props.updateShowChat(true);
    this.setState({ notifSub: notifSub, cable: cable });
  };

  handleReceiveData = (received) => {
    let data = received.data;
    switch (received.type) {
      case "user_unread_messages":
        this.handleReceiveMessage(data);
        break;
      case "new_chat_init":
        this.handleReceiveChat(data);
        break;
      default:
        break;
    }
  };

  handleReceiveMessage = (data) => {
    let { chats } = this.state;
    let newChat = data.chat;
    let currentChat = _.find(chats, { id: newChat.id });
    if (data.message) {
      newChat.last_message = data.message;
    }
    newChat.unread_count = newChat.user_unreads;
    if (currentChat) {
      chats[chats.indexOf(currentChat)] = newChat;
      this.setState({ chats: chats, totalUnreads: data.all_user_unreads });
      this.props.updateUnreadMessages(data.all_user_unreads);
    }
  };

  handleReceiveChat = (data) => {
    let { chats, view, activeChat } = this.state;
    chats.push(data.chat);

    //if this is the only chat for the user,
    //set that chat as active
    if (chats.length === 1) {
      view = "chat";
      activeChat = data.chat;
    }

    this.setState({
      activeChat: activeChat,
      view: view,
      previousView: "index",
      chats: chats,
    });
  };

  handleUpdateChat = (newChat) => {
    let { chats } = this.state;
    let oldChat = _.find(chats, { id: newChat.id });

    chats[chats.indexOf(oldChat)] = newChat;

    this.setState({ chats: chats });
  };

  handleUpdateTotalUnreads = (oldCount) => {
    let { totalUnreads } = this.state;
    let newUnreads = Math.max(0, totalUnreads - oldCount);

    this.setState({ totalUnreads: newUnreads });
    this.props.updateUnreadMessages(newUnreads);
  };

  //evaluates weather the
  //back button should be present
  //in the header
  canGoBack(view) {
    let canGoBack = false;
    let { chats } = this.state;
    if (view === "chat" && chats.length > 1) {
      canGoBack = true;
    }

    return canGoBack;
  }

  //back button from header
  handleGoBack = () => {
    this.setState({
      prepopulatedMessage: "",
      view: this.state.previousView,
      activeChat: null,
      showSearchButton: true,
      selectedMessageId: null,
      bioLink: "",
    });

    if (this.state.previousView === "search") {
      this.setState({
        showSearchBar: true,
      });
    }
  };

  handleSelectResult = (result, resultType) => {
    this.getBioLink(result).then((data) => this.setState({ bioLink: data }));

    this.setState({
      activeChat: result,
      view: "chat",
      previousView: "search",
      showSearchButton: false,
      showSearchBar: false,
    });

    if (resultType === "name") {
      this.setState({ selectedMessageId: null });
    }
    if (resultType === "message") {
      this.setState({ selectedMessageId: result.last_message.id });
    }
  };

  getBioLink = async (chat) => {
    let bioLink = "";

    let path;
    if (chat.other && chat.other.model === "admin") {
      path = `/admins/${chat.other.id}/has_bio`;
    }

    if (!path) {
      return bioLink;
    }

    const data = {
      url: path,
      data: {
        method: "GET",
      },
    };
    const res = await Api.makeRequest(data);
    if (!!res && res.success && res.has_bio) {
      if (chat.other && chat.other.model === "admin") {
        bioLink = bioLink + "/admins/" + `${chat.other.id}` + "/bio";
      }
    }

    return bioLink;
  };

  applyMobileOverflow = () => {
    let { open } = this.state;
    let mobile = mobileCheck();
    let elem = document.getElementsByClassName("App-Body-Content")[0];

    if (!elem) {
      return;
    }

    if (open && mobile) {
      elem.style.display = "none";
      window.scrollTo(0, 0);
    } else {
      elem.style.display = "block";
    }
  };

  escapeEmergencyBanner = (e) => {
    if (e.keyCode === 27) {
      this.handleToggleEmergencyBanner();
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientChat);
