import {
  CLINICS_LOAD,
  CLINIC_LOAD,
  CLINIC_LOAD_HOLIDAY,
} from "patient_app/constants/actionTypes";

const initialState = {
  clinics: {},
  userClinic: null,
  clinicHolidays: {}, // { ClinicHoliday id: ClinicHoliday }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CLINICS_LOAD:
      return {
        ...state,
        clinics: action.clinics,
      };
    case CLINIC_LOAD:
      return {
        ...state,
        userClinic: action.clinic,
      };
    case CLINIC_LOAD_HOLIDAY:
      return {
        ...state,
        clinicHolidays: {
          ...state.clinicHolidays,
          [action.clinicHoliday.id]: action.clinicHoliday,
        },
      };
    default:
      return state;
  }
};
