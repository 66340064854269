import css from "styled-jsx/css";
import { variables } from "patient_app/stylesheets/variables.js";

export default css.global`
  .Drug-Test-Order {
    position: relative;
    overflow: hidden;
    min-height: 100vh;
  }

  .sidebar .tab {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 3.5rem;
    padding: 0 1rem;
    border-bottom: 1px solid ${variables.grayTrim};
    font-size: 0.85rem;
    font-family: ${variables.objReg};
    margin: 0;
  }

  .sidebar .tab.current {
    font-family: ${variables.objBold};
  }
  .sidebar .tab.disabled {
    pointer-events: none;
    color: ${variables.medGray};
  }

  .Drug-Test-Order .note {
    background-color: ${variables.lightGray};
    padding: 1rem 2rem;
    margin: 1rem 0;
  }

  .Drug-Test-Order .note p {
    font-size: 0.7rem;
    line-height: 1.25rem;
    margin: 0;
  }

  .field label {
    font-size: 0.8rem;
    font-family: ${variables.objMed};
    margin-bottom: 0.25rem;
    color: ${variables.darkBlue};
  }

  .field label span.light {
    color: ${variables.lightText};
  }

  .field p.subtitle {
    font-size: 0.7rem;
    line-height: 1.25rem;
  }

  .field input {
    height: 2.5rem;
    background: none;
    border-radius: 4px;
    border: 1px solid ${variables.grayLight};
    margin-bottom: 1rem;
    padding: 0 0.75rem;
    font-size: 0.85rem;
    font-family: ${variables.objLight};
    box-sizing: border-box;
    width: 100%;
  }

  .radio-field,
  .section {
    position: relative;
    display: flex;
    border-bottom: 1px solid ${variables.grayTrim};
    padding: 1rem 0;
  }

  .radio-field:first-of-type,
  .section:first-of-type {
    border-top: 1px solid ${variables.grayTrim};
  }

  .radio-field input {
    width: fit-content;
    height: fit-content;
    margin: 0.25rem 1rem 0 0;
    padding: 0;
  }

  .radio-field label {
    margin: 0;
    font-family: ${variables.objReg};
    font-size: 0.85rem;
    line-height: 1.5rem;
  }

  .radio-field label span {
    font-family: ${variables.objBold};
    margin-bottom: 0.5rem;
  }

  button.card-option {
    width: 100%;
    border: 1px solid ${variables.green};
    color: ${variables.green};
    height: 3rem;
    font-size: 1rem;
    font-family: ${variables.objReg};
    margin-bottom: 1rem;
  }

  button.card-option.selected {
    background-color: ${variables.green};
    color: white;
  }

  .row {
    display: flex;
  }
  .row .section {
    flex: 1;
  }

  fieldset {
    margin-bottom: 1.5rem;
  }

  p.required {
    font-size: 0.75rem;
    font-family: ${variables.objReg};
  }

  button.submit-button {
    width: 100%;
    border-radius: 4px;
    height: 3rem;
  }

  button.submit-button.disabled {
    background-color: ${variables.offGray};
    color: black;
    border: 1px solid ${variables.medGray};
    pointer-events: none;
  }

  .city-state-zip {
    display: flex;
  }

  .city-state-zip .field:not(:last-of-type) {
    margin-right: 0.5rem;
  }

  /* .city-state-zip .field.city { flex: 4; }
  .city-state-zip .field.state { flex: 2; }
  .city-state-zip .field.zip_code { flex: 3; } */

  p.field-specific-error {
    font-family: ${variables.objReg};
    font-size: 0.75rem;
    color: ${variables.red};
    margin-top: 0;
    margin-bottom: 1rem;
  }

  .Drug-Test-Order .card-form {
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 2rem;
    padding: 0.5rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    border: 1px solid ${variables.grayLight};
    border-radius: 4px;
    box-sizing: border-box;
    max-height: 2.75rem;
  }

  .section p {
    margin: 0;
    font-size: 0.85rem;
  }

  .section p.label {
    font-family: ${variables.objBold};
    width: 40%;
  }

  .section .details {
    width: 55%;
  }

  .section .edit {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
    box-sizing: border-box;
  }

  .order-total {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f4f4f4;
    padding: 1rem;
    margin: 1rem 0 2rem;
  }

  .order-total p {
    font-family: ${variables.objBold};
    margin: 0;
  }

  .Drug-Test-Order button.link {
    color: ${variables.green};
    text-decoration: underline;
    font-size: 0.8rem;
    font-family: ${variables.objReg};
    margin-bottom: 1rem;
  }

  .help-modal {
    position: absolute;
    z-index: 10;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .help-modal .help-modal-container {
    width: 50%;
    height: 50%;
    background: white;
    margin: 10rem auto 0;
    padding: 2rem;
    overflow-y: scroll;
    box-sizing: border-box;
  }

  .help-modal p,
  .help-modal a,
  .help-modal li {
    font-size: 0.8rem;
    font-family: ${variables.objReg};
    line-height: 1.25rem;
  }

  .help-modal p.title {
    font-size: 1rem;
    font-family: ${variables.objMed};
  }

  .help-modal a {
    color: ${variables.green};
    text-decoration: underline;
  }

  .help-modal ul {
    list-style-type: disc;
    padding-left: 1.5rem;
  }

  .help-modal li {
    margin-bottom: 0.5rem;
  }

  .help-modal .top-row {
    display: flex;
    justify-content: space-between;
  }

  .help-modal button.close {
    height: fit-content;
  }

  .help-modal button.close img {
    background-color: ${variables.darkBlue};
    border-radius: 50%;
    height: 1rem;
    padding: 0.25rem;
  }

  @media only screen and (max-width: 768px) {
    .Drug-Test-Order {
      height: 100vh;
      overflow: scroll;
    }

    .sidebar {
      display: none;
    }
    .field input {
      height: 3.5rem;
    }

    .section {
      flex-direction: column;
    }

    .section p.label,
    .section .details {
      width: 100%;
      margin-bottom: 0.5rem;
    }

    .section .edit {
      position: absolute;
      right: 0;
      top: 1rem;
    }

    .help-modal .help-modal-container {
      width: 100%;
      height: 100%;
      margin: 4rem 0 0;
    }
  }
`;
