import css from "styled-jsx/css";
import { variables } from "patient_app/stylesheets/variables";

export default css.global`
  .App-Profile {
    position: relative;
    overflow: hidden;
    min-height: 100vh;
  }

  .web-only {
    display: initial;
  }
  .mobile-only {
    display: none;
  }

  .profile-container {
    width: 55rem;
    min-height: 32rem;
    height: fit-content;
    margin: 3rem auto;
    background-color: white;
    border: 1px solid ${variables.boxBorder};
    border-radius: 4px;
    display: flex;
  }

  .profile-container p {
    font-size: 0.8rem;
    font-family: ${variables.objReg};
    color: ${variables.darkBlue};
  }
  .profile-container p span {
    font-family: ${variables.objMed};
  }
  .profile-container p.green {
    color: ${variables.green};
  }
  .profile-container p.red {
    color: ${variables.red};
  }

  .profile-container a.link {
    font-size: 0.8rem;
    font-family: ${variables.objReg};
    color: ${variables.green};
    text-decoration: underline;
  }
  .profile-container a span {
    font-family: ${variables.objMed};
  }

  .profile-container button.link {
    font-size: 0.8rem;
    font-family: ${variables.objMed};
    color: ${variables.green};
    text-decoration: underline;
    width: fit-content;
  }

  .profile-sidebar {
    width: 35%;
    box-sizing: border-box;
    border-right: 1px solid ${variables.boxBorder};
  }

  .profile-sidebar .tab {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 4rem;
    padding: 0 1rem;
    border-bottom: 1px solid ${variables.boxBorder};
    font-size: 0.85rem;
    font-family: ${variables.objMed};
    margin: 0;
    box-sizing: border-box;
  }

  .profile-sidebar .tab.current {
    color: ${variables.green};
  }

  .profile-sidebar .tab.skip-link {
    position: absolute;
    z-index: -1;
    opacity: 0;
    top: 0;
  }

  .profile-sidebar .tab.skip-link:focus {
    position: relative;
    z-index: 1;
    opacity: 1;
  }

  #profile-content-main {
    width: 100%;
  }
  #profile-content-main.hidden {
    display: none;
  }

  .profile-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    padding: 2rem;
    border-bottom: 1px solid ${variables.boxBorder};
  }

  .profile-section.last {
    border: none;
  }

  .profile-section h1 {
    font-family: ${variables.objBold};
    font-size: 1.2rem;
    color: ${variables.darkBlue};
    margin-bottom: 2rem;
  }

  .profile-section p {
    font-family: ${variables.objReg};
    font-size: 0.8rem;
  }

  .profile-section .header {
    display: flex;
    justify-content: space-between;
  }

  .profile-section button.section-action {
    color: ${variables.green};
    text-decoration: underline;
    font-family: ${variables.objMed};
    font-size: 0.8rem;
    height: fit-content;
  }

  .profile-section :global(.loading__comp) {
    height: fit-content;
  }
  .profile-section .profile-list :global(.loading__comp) {
    margin: 0;
  }

  .profile-section :global(.loading__comp img) {
    width: 1rem;
    height: 1rem;
  }

  .profile-section .submit-container {
    display: flex;
    justify-content: space-between;
  }

  .profile-section button.submit {
    background-color: ${variables.green};
    color: white;
    font-size: 1rem;
    font-family: ${variables.objMed};
    padding: 0.8rem 1rem;
    border: 1px solid ${variables.green};
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
  }

  .profile-section button.submit.other {
    background-color: white;
    color: ${variables.green};
  }

  .profile-section button.submit.half {
    width: 48%;
  }

  .profile-section button.submit.loading {
    opacity: 0.3;
    pointer-events: none;
  }

  .profile-section button.submit :global(.loading__comp) {
    margin: auto;
  }

  .profile-section .paragraph {
    margin-bottom: 1rem;
  }
  .profile-section .paragraph p {
    margin-bottom: 0.25rem;
  }

  .profile-section .warning {
    display: flex;
  }

  .profile-section .warning .warning-icon {
    margin-right: 0.5rem;
  }

  .profile-section .warning p {
    color: ${variables.red};
    font-family: ${variables.objMed};
  }

  .profile-section .warning a {
    color: ${variables.red};
    text-decoration: underline;
  }

  .profile-section button.action-link {
    color: ${variables.green};
    font-family: ${variables.objMed};
    font-size: 0.8rem;
    text-decoration: underline;
    width: fit-content;
    margin-right: 1rem;
  }

  .profile-container button.profile-back {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem 2rem 0;
    width: 100%;
    font-size: 0.8rem;
    font-family: ${variables.objReg};
    border-bottom: 1px solid ${variables.boxBorder};
    padding-bottom: 1rem;
  }

  .profile-container button.profile-back .back-icon {
    position: absolute;
    left: 0.8rem;
    transform: rotate(180deg);
    height: 0.8rem;
    margin: auto 0;
  }

  .profile-field {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.2rem;
  }

  .profile-field.no-margin {
    margin-bottom: 0;
  }

  .profile-field fieldset {
    border: none;
    padding: 0;
    margin: 0;
  }

  .profile-field legend {
    font-family: ${variables.objMed};
    font-size: 0.8rem;
    line-height: 1.5rem;
    color: ${variables.darkBlue};
    padding: 0;
  }

  .profile-field .row {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 0.25rem;
  }

  .profile-field label,
  :global(.timezone-field label),
  :global(.field label) {
    font-family: ${variables.objMed};
    font-size: 0.8rem;
    color: ${variables.darkBlue};
    margin-bottom: 0.5rem;
  }

  .profile-field label span.required,
  :global(.timezone-field label span.required),
  :global(.field label span.required) {
    color: ${variables.lightText};
  }

  .profile-field.plain-text label {
    position: absolute;
    top: 0.8rem;
    left: 0;
  }

  .profile-field .checkbox {
    display: flex;
  }

  .profile-field .checkbox label {
    color: ${variables.darkBlue};
    position: relative;
    top: initial;
    left: initial;
    margin: 0;
  }

  .profile-field.multi-checkbox .checkbox label {
    font-family: ${variables.objReg};
  }

  .profile-field .checkbox :global(input) {
    margin-right: 0.5rem;
    margin-top: 0.25rem;
    width: initial;
    height: initial;
    padding: initial;
    /* border: initial; */
    -moz-appearance: checkbox; /* Firefox */
    -webkit-appearance: checkbox; /* Safari and Chrome */
    appearance: checkbox;
  }

  .profile-field :global(input),
  .profile-field select,
  :global(.timezone-field .text),
  :global(.field input),
  .profile-field textarea {
    font-family: ${variables.objReg};
    font-size: 0.8rem;
    color: ${variables.darkBlue};
    padding: 0 1rem;
    height: 2.75rem;
    margin: 0;
    border: 1px solid ${variables.darkBlue};
    border-radius: 4px;
    box-sizing: border-box;

    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
  }

  .profile-field select option {
    text-overflow: ellipsis;
  }

  .profile-field textarea {
    height: 5rem;
    resize: none;
    padding: 0.5rem 1rem;
  }

  :global(.timezone-field) {
    margin-bottom: 1.5rem;
  }
  :global(.timezone-field .text .ico-arrow-down) {
    display: none;
  }

  :global(.field) {
    margin-bottom: 1.5rem;
  }
  :global(.field .input-wrapper) {
    height: fit-content;
  }
  /* :global(.field .show-password) { top: 1.4rem; } */

  .profile-field.plain-text p,
  .profile-field.plain-text-password :global(input) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-height: 2.75rem;
    height: fit-content;
    padding: 0;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid ${variables.darkBlue};
    background: white;
  }

  .profile-field.plain-text p.no-label,
  .profile-field.plain-text-password :global(input.no-label) {
    text-align: left;
    justify-content: flex-start;
  }

  .profile-field :global(.field-error) {
    font-family: ${variables.objReg};
    font-size: 0.7rem;
    color: ${variables.red};
    margin: 0;
  }

  .profile-field img {
    width: 10rem;
  }

  .profile-list {
    margin-bottom: 1rem;
  }

  .profile-list .list-item {
    border-bottom: 1px solid ${variables.darkBlue};
  }

  .profile-list .list-item .row {
    display: grid;
    width: 100%;
    height: 4rem;
    box-sizing: border-box;
  }

  .profile-list .list-item.header .row {
    height: 3rem;
  }
  .profile-list .list-item.header .row p {
    font-family: ${variables.objMed};
  }

  .profile-list .list-item.none .row {
    display: flex;
  }

  .profile-list .list-item.form .row {
    display: flex;
    flex-direction: column;
    height: fit-content;
    padding: 1rem 0;
  }

  .profile-list .list-item p {
    position: relative;
    box-sizing: border-box;
    font-size: 0.8rem;
    font-family: ${variables.objReg};
    margin: auto 0;
    line-height: 1.2rem;
  }

  .profile-list .list-item p.cursive {
    font-family: ${variables.cursive};
  }

  .profile-list .list-item .col {
    position: relative;
  }

  .profile-list .list-item .col.align-left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .profile-list .list-item .col.align-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .profile-list .list-item .col.align-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .profile-list .list-item .col.align-right p {
    padding: 0 0 0 1rem;
  }

  .profile-list .list-item .item-action {
    color: ${variables.green};
    font-family: ${variables.objMed};
    font-size: 0.8rem;
    text-decoration: underline;
    margin-left: 1rem;
    width: fit-content;
  }

  .profile-list .list-item .item-action.red {
    color: ${variables.red};
  }

  .profile-list .list-item .item-action.loading {
    color: ${variables.lightText};
    pointer-events: none;
  }

  .profile-list .list-item .item-action.more {
    display: flex;
    transition: all 250ms ease 0s;
  }

  .profile-list .list-item .item-action.more.expanded {
    transform: rotate(90deg);
  }

  .profile-list .list-item .expanded-details {
    padding: 2rem;
    border-top: 1px solid ${variables.darkBlue};
    background: rgba(243, 243, 243, 0.5);
  }

  .profile-list .list-item .expanded-details.two-column {
    display: flex;
  }
  .profile-list .list-item .expanded-details.two-column .column {
    flex: 1;
  }

  .profile-list .list-item .expanded-details .line {
    display: flex;
    flex-direction: column;
  }

  .profile-list .list-item .expanded-details .line:not(:last-of-type) {
    margin-bottom: 1rem;
  }

  .profile-list .list-item .expanded-details .line > p:first-of-type {
    font-family: ${variables.objMed};
  }

  .profile-list .list-item .expanded-details .line .order-item {
    display: flex;
    justify-content: space-between;
  }

  .profile-list .list-item .expanded-details .line .order-item p {
    padding: 0;
  }

  .profile-list .list-item .expanded-details .line .order-item.line-break {
    border-top: 1px solid ${variables.darkBlue};
    margin: 0.5rem 0;
  }

  .profile-list .list-item .expanded-details .insurance-photo {
    max-width: 100%;
  }

  .profile-list .load-more {
    color: ${variables.green};
    font-family: ${variables.objMed};
    font-size: 0.8rem;
    text-decoration: underline;
    width: fit-content;
    margin-top: 1rem;
  }

  .profile-list.emergency-contacts .list-item .row {
    grid-template-columns: 30% 25% 25% 20%;
  }

  .profile-list.documents .list-item .row,
  .profile-list.treatment-plans .list-item .row {
    grid-template-columns: 60% 40%;
  }

  .profile-list.roi .list-item .row {
    grid-template-columns: 40% 60%;
  }

  .profile-list.dto .list-item .row {
    grid-template-columns: 60% 15% 25%;
  }

  .profile-list.curriculums .list-item .row,
  .profile-list.payment .list-item .row,
  .profile-list.insurance .list-item .row {
    display: flex;
    justify-content: space-between;
  }

  .profile-list.patient-invoices .list-item .row {
    grid-template-columns: 15% 20% 15% 50%;
  }

  .profile-list.patient-invoices .list-item .row .col.web-only {
    display: flex;
  }
  .profile-list.patient-invoices .list-item .row .col.mobile-only {
    display: none;
  }

  .profile-list.payment-history .list-item .row {
    grid-template-columns: 35% 15% 25% 15% 10%;
  }

  .profile-list .progress-circle {
    position: relative;
  }

  .profile-list .progress-circle-fill {
    stroke: ${variables.green};
    fill: transparent;
    transition: stroke-dashoffset 0.35s;
    transform: rotate(-90deg);
    transform-origin: 50%;
  }

  .profile-list .progress-circle-outline {
    stroke: ${variables.boxBorder};
    fill: transparent;
  }

  .profile-list .play-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  .profile-container .profile-picture-container {
    position: relative;
    width: fit-content;
    margin-bottom: 1rem;
  }

  .profile-container .profile-picture-container .change-picture {
    position: absolute;
    top: -0.25rem;
    right: -0.25rem;
    background-color: ${variables.lightGray};
    padding: 0.25rem;
    border-radius: 50%;
  }

  .profile-container .profile-picture-container .profile-picture {
    display: flex;
    width: 5rem;
    height: 5rem;
    object-fit: cover;
    border-radius: 50%;
    background-color: ${variables.green};
  }

  .profile-container .picture-input {
    display: none;
  }

  .profile-container a.drivers-license {
    width: fit-content;
    margin-right: ${variables.small};
  }

  .profile-container a.drivers-license img {
    height: 12rem;
    width: auto;
    max-width: 100%;
    margin: 1rem 0 2rem;
  }

  .profile-container .drag-and-drop {
    margin-bottom: 2rem;
  }

  .profile-container .drag-and-drop .drop-box {
    height: 12rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${variables.lightGray};
    border: 1px solid ${variables.boxBorder};
    border-radius: 4px;
    margin-bottom: 0.25rem;
    padding: 2rem;
    box-sizing: border-box;
  }

  .profile-container .drag-and-drop .actions-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .profile-container .drag-and-drop button.select-file {
    background-color: white;
    border: 1px solid ${variables.boxBorder};
    border-radius: 4px;
    color: ${variables.lightText};
    height: 3rem;
    width: 50%;
    margin-bottom: 1rem;
    font-size: 0.8rem;
    font-family: ${variables.objReg};
  }

  .profile-container .drag-and-drop a.remove-file {
    text-decoration: underline;
    color: ${variables.lightText};
  }

  .profile-container .drag-and-drop p {
    color: ${variables.lightText};
    margin: 0;
    text-align: center;
  }

  .profile-container .drag-and-drop .field-error {
    font-size: 0.7rem;
    color: ${variables.red};
  }

  .profile-container p.under-submit {
    margin-top: 2rem;
  }

  .profile-container p.under-submit a {
    color: ${variables.green};
    text-decoration: underline;
  }

  .profile-container p.mission-map {
    padding-bottom: 2rem;
    border-bottom: 1px solid ${variables.darkBlue};
    margin-bottom: 2rem;
  }

  .profile-container .medi-cal-section p {
    margin-bottom: 2rem;
  }

  .profile-list .list-item.form .card-form {
    font-family: ${variables.objReg};
    font-size: 0.8rem;
    color: ${variables.darkBlue};
    padding: 0.8rem 1rem;
    height: 2.75rem;
    margin-bottom: 1rem;
    border: 1px solid ${variables.darkBlue};
    border-radius: 4px;
    box-sizing: border-box;
  }

  .profile-list .list-item.form p {
    margin-bottom: 1rem;
  }

  @media only screen and (max-width: 900px) {
    .profile-container {
      width: 100%;
      height: calc(100vh - 4.5rem);
      margin: 0;
      overflow: hidden;
    }

    #profile-content-main {
      overflow: scroll;
    }

    .profile-section button.submit.half {
      width: 100%;
    }
  }

  @media only screen and (max-width: 600px) {
    .web-only {
      display: none;
    }
    .mobile-only {
      display: initial;
    }

    .profile-sidebar {
      width: 100%;
      border: none;
    }

    .profile-sidebar .tab {
      height: 4.5rem;
      padding: 0 2rem;
    }

    .profile-container {
      height: fit-content;
    }

    .profile-section {
      border-bottom: none;
    }

    .profile-list.dto .list-item .row {
      grid-template-columns: 50% 50%;
    }

    .profile-list .list-item .expanded-details.two-column {
      flex-direction: column;
    }
    .profile-list .list-item .expanded-details.two-column .column {
      margin-bottom: 1rem;
    }

    .profile-container img.drivers-license {
      width: 100%;
      height: auto;
    }

    .profile-field fieldset {
      border: none;
      padding: 0;
      margin: 0;
    }

    .profile-field :global(input),
    .profile-field select,
    .profile-field select:focus,
    :global(.timezone-field .text),
    :global(.timezone-field .search input),
    :global(.field input),
    .profile-field textarea,
    .profile-field.plain-text-password :global(input) {
      font-size: 16px;
    }

    .profile-list.patient-invoices .list-item .row {
      grid-template-columns: 10% 30% 40% 20%;
    }
    .profile-list.patient-invoices .list-item .row .col.web-only {
      display: none;
    }
    .profile-list.patient-invoices .list-item .row .col.mobile-only {
      display: flex;
    }
  }
`;
