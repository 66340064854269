import Api from "patient_app/api";

import {
  DTO_START,
  DTO_SUCCESS,
  DTO_FAILURE,
  NEW_ERRORS,
  DTO_LOAD_ALL,
  DTO_LOAD_MORE,
  DTO_LOAD_ONE,
  DTO_UPDATE_STATE,
} from "patient_app/constants/actionTypes";

export const fetchOrders = (orderId = null, getMore = false, date = null) => {
  let url = `/drug_test_orders/fetch`;
  if (orderId) {
    url += `?order_id=${orderId}`;
  } else if (getMore) {
    url += `?get_more=true&date=${date}`;
  }

  const data = {
    url: url,
    path: "/api/v1",
    data: {
      method: "GET",
    },
  };

  return async (dispatch) => {
    try {
      dispatch({ type: DTO_START });
      const res = await Api.makeRequest(data);
      if (res.success) {
        if (orderId) {
          dispatch({ type: DTO_LOAD_ONE, order: res.order });
        } else if (getMore) {
          dispatch({
            type: DTO_LOAD_MORE,
            orders: res.orders,
            hasMore: res.has_more,
          });
        } else {
          dispatch({
            type: DTO_LOAD_ALL,
            orders: res.orders,
            hasMore: res.has_more,
          });

          dispatch({
            type: DTO_UPDATE_STATE,
            key: "completedFirstTest",
            value: res.completed_first_test,
          });
        }

        dispatch({ type: DTO_SUCCESS, continue: false });
        return;
      }

      dispatch({ type: NEW_ERRORS, errors: [{ text: res.error }] });
      dispatch({ type: DTO_FAILURE });
    } catch (e) {
      console.log(e);
      dispatch({ type: DTO_FAILURE });
    }
  };
};

export const createOrder = (urineTests, salivaTests) => {
  const data = {
    url: `/drug_test_orders`,
    path: "/api/v1",
    data: {
      method: "POST",
      body: {
        ten_panel_remaining: urineTests,
        oral_fluid_swab_remaining: salivaTests,
      },
    },
  };

  return async (dispatch) => {
    try {
      dispatch({ type: DTO_START });
      const res = await Api.makeRequest(data);
      if (res.success) {
        dispatch({ type: DTO_LOAD_ONE, order: res.order });
        dispatch({ type: DTO_SUCCESS, continue: true });
        return;
      }

      dispatch({ type: NEW_ERRORS, errors: [{ text: res.error }] });
      dispatch({ type: DTO_FAILURE });
    } catch (e) {
      console.log(e);
      dispatch({ type: DTO_FAILURE });
    }
  };
};

export const updateOrder = (order) => {
  const data = {
    url: `/drug_test_orders/${order.id}`,
    path: "/api/v1",
    data: {
      method: "PATCH",
      body: {
        drug_test_order: order,
      },
    },
  };

  return async (dispatch) => {
    try {
      dispatch({ type: DTO_START });
      const res = await Api.makeRequest(data);
      if (res.success) {
        dispatch({ type: DTO_LOAD_ONE, order: res.order });
        dispatch({ type: DTO_SUCCESS, continue: true });
        return;
      }

      dispatch({ type: NEW_ERRORS, errors: [{ text: res.error }] });
      dispatch({ type: DTO_FAILURE });
    } catch (e) {
      console.log(e);
      dispatch({ type: DTO_FAILURE });
    }
  };
};

export const destroyOrder = (orderId) => {
  const data = {
    url: `/drug_test_orders/${orderId}`,
    path: "/api/v1",
    data: {
      method: "DELETE",
    },
  };

  return async (dispatch) => {
    try {
      dispatch({ type: DTO_START });
      const res = await Api.makeRequest(data);
      if (res.success) {
        dispatch({ type: DTO_LOAD_ALL, orders: res.orders });
        dispatch({ type: DTO_SUCCESS, continue: true });
        return;
      }

      dispatch({ type: NEW_ERRORS, errors: [{ text: res.error }] });
      dispatch({ type: DTO_FAILURE });
    } catch (e) {
      console.log(e);
      dispatch({ type: DTO_FAILURE });
    }
  };
};

export const validateAddress = (order) => {
  const data = {
    url: `/drug_test_orders/${order.id}/validate_address`,
    path: "/api/v1",
    data: {
      method: "GET",
    },
  };

  return async (dispatch) => {
    try {
      dispatch({ type: DTO_START });
      const res = await Api.makeRequest(data);
      if (res.success) {
        dispatch({
          type: DTO_UPDATE_STATE,
          key: "suggestedAddress",
          value: res.suggested_address,
        });
        dispatch({ type: DTO_SUCCESS, continue: false }); // continue: true is causing verify address screen to skip
        return;
      }

      dispatch({ type: NEW_ERRORS, errors: [{ text: res.error }] });
      dispatch({ type: DTO_FAILURE });
    } catch (e) {
      console.log(e);
      dispatch({ type: DTO_FAILURE });
    }
  };
};

export const requestOrder = (orderId) => {
  const data = {
    url: `/drug_test_orders/${orderId}`,
    path: "/api/v1",
    data: {
      method: "PATCH",
      body: {
        drug_test_order: {
          status: "AWAITING_APPROVAL",
        },
      },
    },
  };

  return async (dispatch) => {
    try {
      dispatch({ type: DTO_START });
      const res = await Api.makeRequest(data);
      if (res.success) {
        console.log("requestOrder res", res);
        dispatch({ type: DTO_LOAD_ONE, order: res.order });
        dispatch({ type: DTO_SUCCESS, continue: true });
        return;
      }

      dispatch({ type: NEW_ERRORS, errors: [{ text: res.error }] });
      dispatch({ type: DTO_FAILURE });
    } catch (e) {
      console.log(e);
      dispatch({ type: DTO_FAILURE });
    }
  };
};
