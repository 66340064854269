import Api from "patient_app/api";

import {
  NEW_ERRORS,
  TX_PLAN_LOAD,
  TX_PLAN_USER_PLANS,
} from "patient_app/constants/actionTypes";

export const fetchUserTreatmentPlans = () => {
  const data = {
    url: `/api/v2/treatment_plans`,
    data: { method: "GET" },
  };

  return async (dispatch) => {
    try {
      const res = await Api.makeRequest(data);
      if (res.success) {
        let userTreatmentPlans = [];
        res.treatment_plans.forEach((plan) => {
          userTreatmentPlans.push(plan.id);
          dispatch({ type: TX_PLAN_LOAD, treatmentPlan: plan });
        });
        dispatch({
          type: TX_PLAN_USER_PLANS,
          userTreatmentPlans: userTreatmentPlans,
        });
      } else {
        dispatch({ type: NEW_ERRORS, errors: [{ text: res.error }] });
      }
    } catch (e) {
      dispatch({
        type: NEW_ERRORS,
        errors: [{ text: "Something went wrong." }],
      });
      console.log(e);
    }
  };
};

export const fetchTreatmentPlan = (planId) => {
  const data = {
    url: `/api/v2/treatment_plans/${planId}`,
    data: { method: "GET" },
  };

  return async (dispatch) => {
    try {
      const res = await Api.makeRequest(data);
      if (res.success) {
        dispatch({ type: TX_PLAN_LOAD, treatmentPlan: res.treatment_plan });
      } else {
        dispatch({ type: NEW_ERRORS, errors: [{ text: res.error }] });
      }
    } catch (e) {
      dispatch({
        type: NEW_ERRORS,
        errors: [{ text: "Something went wrong." }],
      });
      console.log(e);
    }
  };
};

export const approveTreatmentPlan = (planId, signature) => {
  const data = {
    url: `/api/v2/treatment_plans/${planId}`,
    data: {
      method: "PATCH",
      body: {
        treatment_plan: { approved: true },
        signature: signature,
      },
    },
  };

  return async (dispatch) => {
    try {
      const res = await Api.makeRequest(data);
      if (res.success) {
        dispatch({ type: TX_PLAN_LOAD, treatmentPlan: res.treatment_plan });
      } else {
        dispatch({ type: NEW_ERRORS, errors: [{ text: res.error }] });
      }
    } catch (e) {
      dispatch({
        type: NEW_ERRORS,
        errors: [{ text: "Something went wrong." }],
      });
      console.log(e);
    }
  };
};
