import css from "styled-jsx/css";
import { variables } from "patient_app/stylesheets/variables.js";

export default css.global`
  .Page-Program-Description ul {
    list-style-type: initial;
    text-align: left;
    margin: 0;
    padding-inline-start: 1.5rem;
  }

  .Page-Program-Description p.squish {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  p.no-margin {
    margin: 0;
  }

  p.number {
    font-size: 2rem;
    font-family: ${variables.objMed};
    margin-bottom: 0.5rem;
    line-height: 3rem;
    color: ${variables.darkBlue};
  }

  p.call-us-times {
    font-family: ${variables.objMed};
    margin-bottom: 1.5rem;
  }

  .program-info {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    width: 100%;
    flex: 0 0 16rem;
    margin-bottom: 2rem;
  }

  .program-info.program-info__photo {
    margin-bottom: 2rem;
  }

  .program-info .arrow {
    position: absolute;
    top: 0;
    height: 16rem;
  }

  .program-info .arrow img {
    height: 3rem;
  }

  .program-info .program-info__text {
    position: absolute;
    color: white;
    left: 0;
    right: 0;
    top: 1.3rem;
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .program-info .program-info__text p {
    margin: 0;
    font-size: 0.95rem;
  }

  .program-info .program-info__text .description-header {
    font-family: ${variables.objBold};
    margin-bottom: 1rem;
  }

  .program-info .call-us > * {
    color: white;
  }

  .program-info .program-info__text .phone-number {
    display: block;
    color: white !important;
    font-size: 2rem;
    font-family: ${variables.objMed};
    margin: 2rem 0;
  }

  .program-info .arrow.left {
    left: 0;
  }
  .program-info .arrow.right {
    right: 0;
  }

  .program-info .program-image {
    width: 100%;
    height: auto;
    height: 16rem;
    overflow: hidden;
    object-fit: contain;
    background-color: #2f3447;
  }

  .program-info .program-info-item {
    display: flex column 2;
    width: 100%;
    padding: 1rem 0;
    border-top: 1px solid ${variables.boxBorder};
  }

  .program-info .program-info-item:last-of-type {
    border-bottom: 1px solid ${variables.boxBorder};
  }

  .program-info .program-info-item .icon,
  .program-info button.link .icon {
    height: fit-content;
    margin: 0.3rem 1rem 0 0.5rem;
  }

  .program-info button.link .icon {
    margin-top: 0.4rem;
  }

  .program-info .program-info-item span {
    font-family: ${variables.objLight};
    margin: 0;
    padding-left: 2.4rem;
  }
  .program-info .program-info-item div {
    display: flex;
    font-family: ${variables.objReg};
    height: 1.5rem;
  }

  .program-info button.link {
    display: flex;
    padding: 1rem 0;
  }

  .program-info button.link p {
    display: flex;
    font-family: ${variables.objMed};
    font-size: 0.8rem;
    color: ${variables.green};
    text-decoration: underline;
  }

  .program-info.clinic-finish {
    margin: 0 auto 1.5rem;
  }

  .program-info.clinic-finish p {
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }

  .program-info ul li {
    font-size: 0.8rem;
    font-family: ${variables.objReg};
    color: ${variables.blueDark};
  }

  .Conclusion .program-info {
    margin-bottom: 1.5rem;
  }

  .Conclusion .program-info p:last-of-type {
    margin-bottom: 0;
  }

  p.description.clinic-finish {
    font-family: ${variables.objMed};
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
  }

  p.clinic-name {
    font-family: ${variables.objBold};
    font-size: 1rem;
    color: ${variables.green};
  }

  .event-info {
    margin: 1.5rem 0;
  }

  .event-info p {
    margin: 0;
  }

  .faded {
    color: ${variables.medGray};
  }

  .progress-dots-container {
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: 0.75rem;
    width: 100%;
  }

  .progress-dots-container .progress-dot {
    border-radius: 50%;
    /* border: 1px solid ${variables.grayTrim}; */
    width: 0.5rem;
    height: 0.5rem;
    background-color: ${variables.medGray};
    margin: 0 0.25rem;
  }

  .progress-dots-container .progress-dot.filled {
    background-color: ${variables.offGray};
  }

  :global(body #Main-Container.Conclusion .submit-button) {
    padding: 0.5rem 0;
  }

  @media only screen and (max-width: 600px) {
    .program-info {
      flex: none;
    }

    .program-info .program-info-item {
      flex: none;
    }

    .program-info p.number {
      text-align: left;
    }

    .program-info ul li {
      font-size: 0.8rem;
    }
  }

  @media only screen and (max-width: 320px) {
    /* for iPhone 5 and other smaller width screens */
    .Conclusion button.submit-button {
      font-size: 0.9rem;
      line-height: 1rem;
    }
  }
`;
