import PropTypes from "prop-types";
import React from "react";
import cn from "classnames";

import FormErrors from "patient_app/components/utils/FormErrors";

import { Router, Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  fetchUser,
  fetchProfile,
  updateProfile,
} from "patient_app/api/commonActions";
import {
  NEW_FORM_ERRORS,
  PROFILE_UPDATE_LOCAL,
  NEW_FIELD_SPECIFIC_ERROR,
  ONBOARDING_UPDATE_PROGRESS,
} from "patient_app/constants/actionTypes";

import MainLayout from "patient_app/components/layouts/MainLayout";

import ContentMain from "patient_app/components/forms/ContentMain";
import ContentBox from "patient_app/components/forms/ContentBox";
import SelectButton from "patient_app/components/buttons/SelectButton";
import SubmitButton from "patient_app/components/buttons/SubmitButton";
import OnboardingProgressBar from "patient_app/components/utils/OnboardingProgressBar";

import {
  occupationQuestions,
  occupationOptions,
} from "patient_app/views/enterprise_onboarding/utils/constants";
import textHelpers from "patient_app/helpers/textHelpers";
import onboardingHelpers from "patient_app/helpers/onboardingHelpers";
import layout from "patient_app/stylesheets/onboarding/layout.js";
import commonAssets from "patient_app/assets";

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUser: () => dispatch(fetchUser()),
    fetchProfile: (id) => dispatch(fetchProfile()),
    updateProfile: (profile) => dispatch(updateProfile(profile, false)),
    onError: (field, error) =>
      dispatch({ type: NEW_FIELD_SPECIFIC_ERROR, field: field, error: error }),
    onGeneralErrors: (errors) =>
      dispatch({ type: NEW_FORM_ERRORS, errors: errors }),
    updateProfileLocal: (profile) =>
      dispatch({ type: PROFILE_UPDATE_LOCAL, profile: profile }),
    updateProgress: (progress) =>
      dispatch({ type: ONBOARDING_UPDATE_PROGRESS, progress: progress }),
  };
};

const mapStateToProps = (state) => {
  return {
    fieldSpecificErrors: state.common.fieldSpecificErrors,
    user: state.common.user,
    profile: state.common.profile,
    loading: state.common.isFetching,
    success: state.common.success,
  };
};

class Occupation extends React.Component {
  componentDidMount = () => {
    document.title = "Occupation | Workit Health";
    window.scrollTo(0, 0);

    let { user, profile } = this.props;
    !user && this.props.fetchUser();
    !profile && this.props.fetchProfile();

    const progress = onboardingHelpers.calculateProgress(
      this.props.location.pathname
    );
    this.props.updateProgress(progress);
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.success && this.props.success) {
      // this.props.history.push("/onboarding/primary_curriculum");
      if (["salesforce", "Red Bull"].includes(this.props.user.company)) {
        this.props.history.push("/enterprise_onboarding/questionnaire/start");
      } else {
        this.props.history.push("/onboarding/address");
      }
    }
  }

  render() {
    let { fieldSpecificErrors, user, profile, loading } = this.props;

    let occupationQuestion;
    let options;

    if (user) {
      occupationQuestion = occupationQuestions[user.company];
      options = occupationOptions[user.company];
    }

    const uid = "treatment_type";
    let error = fieldSpecificErrors[uid];

    return (
      <MainLayout>
        <ContentMain halfBleed={true}>
          {user && profile && options && (
            <ContentBox className="onboarding">
              <OnboardingProgressBar />

              <div className="inputs">
                <fieldset
                  aria-describedby={error && `aria-describedby-${uid}`}
                  aria-invalid={error ? true : false}
                  aria-label={`What is your connection to ${user.company}?`}
                >
                  <legend className="select-button-legend">
                    <h1>
                      What is your connection to{" "}
                      {textHelpers.uppercaseFirstLetter(user.company)}?
                    </h1>
                  </legend>

                  {this.renderOptions(options)}
                </fieldset>
              </div>

              <style jsx>{layout}</style>
            </ContentBox>
          )}
        </ContentMain>
      </MainLayout>
    );
  }

  renderOptions(options) {
    return options.map((option, i) => {
      return (
        <SelectButton
          key={i}
          id={option[1]}
          value={option[1]}
          selectedValue={this.props.profile.occupation}
          text={option[0]}
          onSelect={this.handleSelect}
          classNames={"full-width"}
          aria-label={option[0]}
        />
      );
    });
  }

  handleSelect = (value) => {
    const { profile } = this.props;
    let updatedProfile = Object.assign({}, profile);
    updatedProfile.occupation = value;

    this.props.onGeneralErrors([]); // clear errors
    this.props.updateProfile(updatedProfile);
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Occupation)
);
