import PropTypes from "prop-types";
import React from "react";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { mobileCheck } from "patient_app/helpers/supported";
import layout from "patient_app/stylesheets/onboarding/layout";

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {
    progress: state.onboarding.progress,
  };
};

class OnboardingProgressBar extends React.Component {
  componentDidMount() {}

  render() {
    let isComplete = this.props.progress === 100;
    let isMobile = mobileCheck();
    const styles = {
      width: `${this.props.progress}%`,
      //borderRadius: isMobile ? "0" : (isComplete ? "10px 10px 0 0" : "10px 0 0 0"),
    };
    return (
      <div className="onboarding-progress-bar">
        <div className="filler" style={styles} />
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OnboardingProgressBar)
);
