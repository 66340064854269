import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DateTime } from "luxon";

import { confirmAfterHours } from "patient_app/api/chatActions";
import { CHAT_UPDATE_STATE } from "patient_app/constants/actionTypes";
import SubmitButton from "patient_app/components/buttons/SubmitButton";
import { getIconUrl } from "patient_app/helpers/linkHelpers";
import emergencyBannerStyles from "patient_app/stylesheets/chat/emergencyBannerStyles";
import SessionStore from "patient_app/helpers/SessionStore";

const EmergencyBanner = (props) => {
  const dispatch = useDispatch();
  const { clinic, emergencyBannerType } = useSelector((state) => {
    return {
      clinic: state.common.clinic,
      emergencyBannerType: state.chat.emergencyBannerType,
    };
  });

  const [modalContentHeight, setModalContentHeight] = useState("400px");

  let zone = "EST"; // if not a clinic member, default to Eastern Standard Time
  if (clinic?.time_zone) {
    zone = DateTime.utc().setZone(clinic.time_zone).toFormat("ZZZZ");
  }

  const handleClose = () => {
    if (emergencyBannerType === "after_hours") {
      dispatch(confirmAfterHours());
      SessionStore.set("chatInfo", DateTime.local().toISO());
    }

    dispatch({
      type: CHAT_UPDATE_STATE,
      key: "emergencyBannerType",
      value: "emergency",
    });
    props.onToggleEmergencyBanner();
  };

  useEffect(() => {
    const closeButton = document.getElementById("close-eb");
    closeButton && closeButton.focus();

    const overlayContainer = document.getElementById("banner-overlay");
    const modalHeader = document.getElementById("modal-header");
    if (overlayContainer && modalHeader) {
      const newContentHeight =
        overlayContainer.scrollHeight - modalHeader.scrollHeight - 16 * 8;
      setModalContentHeight(`${newContentHeight}px`);
    }
  }, []);

  return (
    <div className="emergency-banner">
      <div id="banner-overlay" className="banner-overlay" />
      <div className="modal-box">
        <div id="modal-header" className="modal-header">
          <p>
            <span>
              {emergencyBannerType === "after_hours"
                ? "Your Care Team is currently unavailable"
                : "Experiencing an emergency?"}
            </span>
          </p>

          <button
            id="close-eb"
            onClick={handleClose}
            className="close-eb custom-button"
          >
            <img
              className="close-eb"
              src={getIconUrl("exit", "dark-blue")}
              alt="Close"
            />
          </button>
        </div>

        <div className="modal-content" style={{ height: modalContentHeight }}>
          {emergencyBannerType === "after_hours" ? (
            <p>
              You can leave a message and your Care Team will respond during
              your clinic's open hours (Monday–Friday, 9 AM–5:30 PM {zone}).{" "}
            </p>
          ) : (
            <p>
              Your Care Team will respond to your messages during your clinic’s
              open hours (Monday–Friday, 9 AM–5:30 PM {zone}).{" "}
            </p>
          )}
          <p>
            <span>If you need help now, here are some resources: </span>{" "}
          </p>

          <ul>
            <li>
              <span>Medical emergency:</span> CALL <a href="tel:911">911</a> OR
              GO TO YOUR NEAREST EMERGENCY ROOM
            </li>

            <li>
              <span>Mental health and substance use resourcess:</span> call the
              SAMHSA National Helpline at{" "}
              <a href="tel:800-662-4357">800-662-HELP (4357)</a>
            </li>

            <li>
              <span>Crisis text line:</span> text “HOME” to 741741
            </li>

            <li>
              <span>Suicide lifeline:</span> call the National Suicide
              Prevention Lifeline at <a href="tel:988">988</a>
            </li>

            <li>
              <span>Urgent medication assistance:</span> call our after-hours
              call service at <a href="tel:855-659-7734">855-659-7734</a>
            </li>
          </ul>

          {emergencyBannerType === "after_hours" && (
            <button
              type="button"
              onClick={handleClose}
              className="custom-button submit"
            >
              I understand
            </button>
          )}
        </div>
      </div>

      <style jsx>{emergencyBannerStyles}</style>
    </div>
  );
};

export default EmergencyBanner;
