import PropTypes from "prop-types";
import React from "react";
import cn from "classnames";
import { DateTime } from "luxon";
import assets from "patient_app/components/chat/assets";
import timeHelpers from "patient_app/components/chat/utils/timeHelpers";

class ChatRow extends React.Component {
  static propTypes = {
    chat: PropTypes.object.isRequired,
    viewingAsAdmin: PropTypes.bool,
    viewingSupportChat: PropTypes.bool,
  };

  static defaultProps = {
    viewingAsAdmin: false,
    viewingSupportChat: false,
  };

  render() {
    let { chat, viewingAsAdmin, viewingSupportChat, tabIndex } = this.props;

    let profilePic = assets.defaultProfilePic;
    let fullName = "Workit Health";
    let lastMessageCreatedAt;
    let lastMessageHtml = "<p></p>";

    if (!chat) return null;

    if (chat.other) {
      fullName = chat.other.full_name;

      if (
        chat.other.pic &&
        !chat.other.pic.endsWith(
          "dashboard-003e096ea7b2a80e981bff26aaba8dfdd88ef8e1e3284b65deb6b28763e5147e.png"
        )
      ) {
        profilePic = chat.other.pic;
      }
    }

    if (chat.topic) {
      lastMessageHtml = chat.other.title;
    } else {
      if (chat.last_message) {
        lastMessageHtml = chat.last_message.message;

        if (chat.last_message.media_type !== "text") {
          lastMessageHtml = "<p><i>Attachment</i></p>";
        }
      } else {
        lastMessageHtml = "<p>No messages</p>";
      }
    }

    if (chat.last_message) {
      lastMessageCreatedAt = DateTime.fromISO(
        chat.last_message.created_at
      ).toFormat("LL/dd");
    }

    if (fullName.length >= 20) {
      fullName = fullName.substring(0, 17) + "...";
    }

    if (viewingAsAdmin && !viewingSupportChat) {
      fullName = fullName + ` (${chat.category})`;
    }

    if (!chat.active && viewingAsAdmin) {
      fullName = fullName + " - (read only)";
    }

    if (!chat.active && viewingAsAdmin) {
      fullName = fullName + " - (archived)";
    }

    return (
      <button
        onClick={(e) => this.props.onSelectChat(this.props.chat)}
        className="custom-button"
        tabIndex={tabIndex}
        aria-label="open chat"
      >
        <div className="item">
          <div className="profile-pic">
            <img src={profilePic} alt="" />
          </div>

          <div className="content">
            <div className={cn("inner", chat.topic ? "group" : "")}>
              <h2>{fullName}</h2>
              {chat.topic ? (
                <div className="last-message-cont allow-overflow">
                  <p className="allow-overflow">{lastMessageHtml}</p>
                </div>
              ) : (
                <div
                  className="last-message-cont"
                  dangerouslySetInnerHTML={this.formatMessage(lastMessageHtml)}
                />
              )}
            </div>
          </div>

          <div className="info">
            <div className="inner">
              <p>{lastMessageCreatedAt}</p>
              {chat.unread_count > 0 && (
                <div className="badge">
                  <span>{chat.unread_count}</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </button>
    );
  }

  formatMessage = (text) => {
    const pStart = new RegExp("<p[^>]*?>", "g");
    const pEnd = new RegExp("</p>", "g");
    const br = new RegExp("<br>", "g");

    let divHtml = text;
    divHtml = divHtml.replace(pStart, "");
    divHtml = divHtml.replace(pEnd, "");
    divHtml = divHtml.replace(br, " ");
    divHtml = "<p>" + divHtml + "</p>";

    return { __html: divHtml };
  };
}

export default ChatRow;
