import PropTypes from "prop-types";
import React from "react";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { fetchProgram } from "patient_app/api/profileActions";
import {
  PROFILE_UPDATE_STATE_LOCAL,
  NEW_ERRORS,
  NEW_FIELD_SPECIFIC_ERROR,
  CLEAR_FIELD_SPECIFIC_ERRORS,
} from "patient_app/constants/actionTypes";

import MainTabBack from "patient_app/views/profiles/partials/MainTabBack";
import Clinic from "patient_app/views/profiles/partials/program/Clinic";
import ProgramGoals from "patient_app/views/profiles/partials/program/ProgramGoals";
import TreatmentPlans from "patient_app/views/profiles/partials/program/TreatmentPlans";
import Curriculums from "patient_app/views/profiles/partials/program/Curriculums";

import { mobileCheck } from "patient_app/helpers/supported";
import permissions from "patient_app/helpers/permissions";
import layout from "patient_app/stylesheets/profiles/layout";

const mapDispatchToProps = (dispatch) => {
  return {
    fetchProgram: (profileId, params) =>
      dispatch(fetchProgram(profileId, params)),
    setProfileState: (obj, value) =>
      dispatch({ type: PROFILE_UPDATE_STATE_LOCAL, obj: obj, value: value }),
    clearFieldSpecificErrors: () =>
      dispatch({ type: CLEAR_FIELD_SPECIFIC_ERRORS }),
    newGeneralErrors: (errors) =>
      dispatch({ type: NEW_ERRORS, errors: errors }),
  };
};

const mapStateToProps = (state) => {
  return {
    mainTab: state.profile.mainTab,
    subTab: state.profile.subTab,
    user: state.common.user,
  };
};

class Program extends React.Component {
  profileId = this.props.match.params.id;

  componentDidMount = async () => {
    document.title = "Program and Clinic | Workit Health";
    if (!mobileCheck()) {
      this.props.fetchProgram(this.profileId, [
        "clinic",
        "program_goals",
        "curriculums",
      ]);
      this.props.setProfileState("subTab", [
        "clinic",
        "program_goals",
        "curriculums",
      ]);
    }

    this.props.clearFieldSpecificErrors();
    this.props.newGeneralErrors([]);
  };

  render() {
    let { mainTab, subTab, user } = this.props;

    return (
      <div>
        {mainTab.length > 0 && subTab.length === 0 && (
          <nav
            className="profile-sidebar"
            role="navigation"
            aria-label="Profile navigation"
          >
            <MainTabBack />

            {user && permissions.isClinic(user) && (
              <button
                className="custom-button tab"
                onClick={() => this.props.setProfileState("subTab", ["clinic"])}
              >
                Clinic
              </button>
            )}

            <button
              className="custom-button tab"
              onClick={() =>
                this.props.setProfileState("subTab", ["program_goals"])
              }
            >
              Treatment Plans
            </button>

            <button
              className="custom-button tab"
              onClick={() =>
                this.props.setProfileState("subTab", ["curriculums"])
              }
            >
              Curriculums
            </button>
          </nav>
        )}

        {subTab.includes("clinic") && user && permissions.isClinic(user) && (
          <Clinic />
        )}
        {/*subTab.includes("program_goals") && <ProgramGoals />*/}
        {subTab.includes("program_goals") && <TreatmentPlans />}
        {subTab.includes("curriculums") && <Curriculums />}
        <style jsx>{layout}</style>
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Program)
);
