import PropTypes from "prop-types";
import React from "react";
import cn from "classnames";
import { DateTime } from "luxon";
import TimeAgo from "react-timeago";
import Ellipsis from "patient_app/components/chat/partials/Ellipsis";
import lazysizes from "lazysizes";
import assets from "patient_app/components/chat/assets";

export default class Messages extends React.Component {
  static defaultProps = {
    fetchingInitial: false,
    viewingSupportChat: false,
    admins: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      chatLinks: [],
      showDetails: [],
    };
  }

  componentDidMount = () => {
    this.props.setScrollRef(this.chatContainer);
  };

  componentDidUpdate(prevProps, prevState) {
    const links = document.querySelectorAll("[data-topic]");
    if (this.state.chatLinks.length !== links.length) {
      links.forEach((link) => {
        link.onclick = (e) => this.handleOpenChat(e, link.dataset.topic);
      });
      this.setState({ chatLinks: links });
    }
  }

  render() {
    let {
      messages,
      showGetPrevMessages,
      showGetNextMessages,
      fetchingInitial,
      otherTyping,
      otherModel,
      uploadingImage,
      tabIndex,
    } = this.props;

    return (
      <div
        className={cn(
          "App-Messages-Container",
          fetchingInitial ? "fetching-initial" : ""
        )}
        id="messages-container"
        ref={(el) => {
          this.chatContainer = el;
        }}
      >
        <div className="messages">
          <div className="list">
            {showGetPrevMessages && messages.length > 0 && (
              <button
                className="get-more-messages custom-button"
                onClick={(e) => this.props.onGetMoreMessages("prev")}
                tabIndex={tabIndex}
              >
                Get more
              </button>
            )}

            {this.renderMessages(messages)}

            {otherTyping && !showGetNextMessages && (
              <Ellipsis otherModel={otherModel} />
            )}

            {showGetNextMessages && messages.length > 0 && (
              <button
                className="get-more-messages custom-button"
                onClick={(e) => this.props.onGetMoreMessages("next")}
                tabIndex={tabIndex}
              >
                Get more
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }

  renderMessages = (messages) => {
    let {
      providerImgUrl,
      fetchingInitial,
      viewingSupportChat,
      admins,
      tabIndex,
    } = this.props;
    let { showDetails } = this.state;
    let lastCreatedAt;
    let lastMessageSender;
    let now = DateTime.local();

    if (messages.length === 0 && !fetchingInitial) {
      return (
        <div className="date-bar" name="loaded-message">
          <span className="line1"></span>

          <div className="date">
            <span>No Messages</span>
          </div>

          <span className="line2"></span>
        </div>
      );
    }

    return messages.map((message, i) => {
      if (message.agent === "event") {
        // return a blank div for scrolling purposes
        return (
          <div
            key={i}
            className="message-container hidden"
            data-id={message.id}
            id={`message-${message.id}`}
          />
        );
      }

      let shouldRenderDateBar = false;
      let isFirstMessageInGroup = false;
      let thisCreatedAt = DateTime.fromISO(message.created_at);
      let currentMessageSender = message.user_id ? "user" : "other";
      let sentAt = thisCreatedAt.toFormat("h:mma").toLowerCase();
      let formattedMessage = this.clean(message.message);

      let sentBy = "";
      let admin = null;
      if (message.other_id && admins[message.other_id]) {
        admin = admins[message.other_id];
        sentBy = admin.fir_name?.trim();
      }
      // else if (message.agent == "system") {
      //   sentBy = "Automated message"
      // }

      if (lastCreatedAt && lastCreatedAt.day < thisCreatedAt.day) {
        shouldRenderDateBar = true;
      }

      if (!lastMessageSender || lastMessageSender !== currentMessageSender) {
        isFirstMessageInGroup = true;
      }

      if (viewingSupportChat) {
        providerImgUrl = assets.supportChat;
      }

      lastMessageSender = currentMessageSender;
      lastCreatedAt = thisCreatedAt;

      return (
        <div key={i} name="loaded-message">
          {shouldRenderDateBar && (
            <div className="date-bar">
              <div className="date">
                <span>{this.formatCreatedAt(thisCreatedAt, now)}</span>
              </div>
            </div>
          )}

          <div
            className={cn(
              "message-container",
              message.media_type,
              currentMessageSender,
              isFirstMessageInGroup ? "first" : ""
            )}
            data-id={message.id}
          >
            <div
              className={cn("message", isFirstMessageInGroup ? "first" : "")}
            >
              {message.media_type === "video" && (
                <div className="text" id={`message-${message.id}`}>
                  <a href={message.message} target="_blank" tabIndex={tabIndex}>
                    {message.message}
                  </a>
                </div>
              )}

              {message.media_type === "image" && (
                <div className="text" id={`message-${message.id}`}>
                  <a
                    href={message.image ? message.image.url : ""}
                    target="_blank"
                    tabIndex={tabIndex}
                  >
                    <img
                      data-src={message.image ? message.image.url : ""}
                      alt="Message attachment."
                      className="message-img lazyload"
                    />
                  </a>
                </div>
              )}

              {message.media_type !== "video" &&
                message.media_type !== "image" && (
                  <div className="text" id={`message-${message.id}`}>
                    <p
                      dangerouslySetInnerHTML={{ __html: formattedMessage }}
                    ></p>
                  </div>
                )}
            </div>

            <div className="sent-at-container">
              <div className="row">
                {sentBy ? (
                  <div className="left">
                    {/*<button
                      className="custom-button"
                      onClick={() => this.handleToggleDetails(message.id)}
                    >
                      {showDetails.includes(message.id) ? "-" : "+"}
                    </button>*/}
                    <p>{sentBy}</p>
                  </div>
                ) : (
                  <div />
                )}

                <p>{sentAt}</p>
              </div>

              {admin && (
                <div className="row">
                  <p>{admin.title ? admin.title : ""}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    });
  };

  clean(string) {
    if (!string) {
      return "";
    }
    return string.replace("<p>", "").replace("</p>", "");
  }

  formatCreatedAt = (createdAt, now) => {
    let str = createdAt.toFormat("LLL d").toUpperCase();

    if (now.hasSame(createdAt, "day")) {
      str = "TODAY";
    }

    return str;
  };

  handleOpenChat = (e, topic) => {
    if (e) {
      e.preventDefault();
    }
    this.props.onOpenChat(topic);
  };

  handleToggleDetails = (messageId) => {
    let showDetails = this.state.showDetails;
    const idx = showDetails.indexOf(messageId);
    if (idx > -1) {
      showDetails.splice(idx, 1);
    } else {
      showDetails.push(messageId);
    }
    this.setState({ showDetails: showDetails });
  };
}
