import PropTypes from "prop-types";
import React from "react";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { fetchDocuments } from "patient_app/api/profileActions";

import LoadingSpinner from "patient_app/components/utils/LoadingSpinner";
import SubTabBack from "patient_app/views/profiles/partials/SubTabBack";

import { mobileCheck } from "patient_app/helpers/supported";
import { getUrlVars, getIconUrl } from "patient_app/helpers/linkHelpers";

const mapDispatchToProps = (dispatch) => {
  return {
    fetchDocuments: (profileId, params) =>
      dispatch(fetchDocuments(profileId, params)),
  };
};

const mapStateToProps = (state) => {
  return {
    generalDocuments: state.profile.generalDocuments,
  };
};

class GeneralDocuments extends React.Component {
  profileId = this.props.match.params.id;
  clinicFileId = getUrlVars(window.location.href)["clinic_file_id"];

  constructor(props) {
    super(props);
    this.state = {
      highlightedDocumentId: null,
    };
  }

  componentDidMount() {
    if (mobileCheck()) {
      this.props.fetchDocuments(this.profileId, ["general_documents"]);
    }

    if (!!this.clinicFileId && !!this.props.generalDocuments) {
      this.findDocumentFromUrl();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !!this.clinicFileId &&
      !prevProps.generalDocuments &&
      this.props.generalDocuments
    ) {
      this.findDocumentFromUrl();
    }

    if (!prevState.highlightedDocumentId && this.state.highlightedDocumentId) {
      // maybe scroll to document
      const element = document.querySelector(
        `[data-id='${this.state.highlightedDocumentId}']`
      );
      if (!!element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }

  render() {
    let { generalDocuments } = this.props;
    return (
      <div>
        <SubTabBack />
        <div className="profile-section">
          <h1>General Documents</h1>

          {!generalDocuments && <LoadingSpinner />}

          {generalDocuments && (
            <div className="profile-list documents">
              {this.renderDocuments()}
            </div>
          )}
        </div>
      </div>
    );
  }

  renderDocuments = () => {
    return this.props.generalDocuments.map((doc, i) => {
      return (
        <div className="list-item" key={i} data-id={doc[2]}>
          <div className="row">
            <p>
              <span>{doc[0]}</span>
            </p>
            <div className="col align-right">
              <a href={doc[1]} target="_blank" style={{ display: "flex" }}>
                <img
                  src={getIconUrl("chevron-right", "dark-blue")}
                  alt="View"
                />
              </a>
            </div>
          </div>
        </div>
      );
    });
  };

  findDocumentFromUrl = () => {
    const document = this.props.generalDocuments.find(
      (d) => d[2] === parseInt(this.clinicFileId)
    );
    if (document) {
      this.setState({ highlightedDocumentId: document[2] });
    }
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GeneralDocuments)
);
