import PropTypes from "prop-types";
import React from "react";

import ProfileField from "patient_app/views/profiles/partials/ProfileField";
import PasswordField from "patient_app/components/input_fields/PasswordField";
import SubTabBack from "patient_app/views/profiles/partials/SubTabBack";
import LoadingSpinner from "patient_app/components/utils/LoadingSpinner";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { fetchAccount, updatePassword } from "patient_app/api/profileActions";
import {
  NEW_FIELD_SPECIFIC_ERROR,
  NEW_ERRORS,
  CLEAR_FIELD_SPECIFIC_ERRORS,
} from "patient_app/constants/actionTypes";

import validation from "patient_app/helpers/validation";
import { mobileCheck } from "patient_app/helpers/supported";

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAccount: (profileId, params) =>
      dispatch(fetchAccount(profileId, params)),
    updatePassword: (profileId, current, newPass, newPassConf, otpCode) =>
      dispatch(
        updatePassword(profileId, current, newPass, newPassConf, otpCode)
      ),
    newFieldSpecificError: (field, error) =>
      dispatch({ type: NEW_FIELD_SPECIFIC_ERROR, field: field, error: error }),
    newGeneralErrors: (errors) =>
      dispatch({ type: NEW_ERRORS, errors: errors }),
    clearFieldSpecificErrors: () =>
      dispatch({ type: CLEAR_FIELD_SPECIFIC_ERRORS }),
  };
};

const mapStateToProps = (state) => {
  return {
    user: state.common.user,
    profile: state.common.profile,
    loading: state.profile.loading,
    success: state.profile.success,
    emergencyContact: state.profile.emergencyContact,
  };
};

class Account extends React.Component {
  profileId = this.props.match.params.id;

  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      currentPassword: "",
      newPassword: "",
      newPasswordConf: "",
      otpCode: "",
    };
  }

  componentDidMount() {
    if (mobileCheck()) {
      this.props.fetchAccount(this.profileId);
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.success && this.props.success) {
      this.resetEditPassword();
    }
  }

  render() {
    let { loading, profile, user } = this.props;
    let {
      editing,
      currentPassword,
      newPassword,
      newPasswordConf,
      otpCode,
    } = this.state;

    let showPasswordStrength = false;
    let passwordStrength;
    let passwordDetails;

    if (newPassword && newPassword.length > 0) {
      showPasswordStrength = true;
      passwordStrength = validation.passwordStrength(newPassword);
      passwordDetails = validation.passwordDetails(passwordStrength);
    }

    return (
      <div>
        <SubTabBack />
        <div className="profile-section">
          <div className="header">
            <h1>Password</h1>

            <button
              className="custom-button section-action"
              onClick={this.toggleEditPassword}
            >
              {editing ? "Cancel" : "Edit"}
            </button>
          </div>

          {!editing && !user && <LoadingSpinner />}

          {!editing && user && (
            <ProfileField
              field="password"
              fieldType="plain-text-password"
              showLabel={false}
              title="Password"
              value="password"
            />
          )}

          {editing && (
            <div>
              <div className="paragraph">
                <p>
                  <span>Password must be at least 8 characters.</span>
                </p>
                <p>
                  <span>
                    Password must contain at least one upper or lower case
                    letter.
                  </span>
                </p>
                <p>
                  <span>Password must contain at least one number.</span>
                </p>
              </div>

              <PasswordField
                field="current_password"
                title="Current Password"
                value={currentPassword}
                id="current_password"
                onUpdateField={(value, field) =>
                  this.setState({ currentPassword: value })
                }
                showPasswordStrength={false}
                uid="current_password"
              />

              <PasswordField
                field="new_password"
                title="New Password"
                value={newPassword}
                id="new_password"
                onUpdateField={(value, field) =>
                  this.setState({ newPassword: value })
                }
                showPasswordStrength={showPasswordStrength}
                passwordDetails={passwordDetails}
                uid={"new_password"}
              />

              <PasswordField
                field="new_password_conf"
                title="Confirm New Password"
                value={newPasswordConf}
                id="new_password_conf"
                onUpdateField={(value, field) =>
                  this.setState({ newPasswordConf: value })
                }
                showPasswordStrength={false}
                uid="new_password_conf"
              />

              {user && user.mfa_enabled && (
                <ProfileField
                  field="otp_code"
                  fieldType="text"
                  onUpdateField={(value, field) =>
                    this.setState({ otpCode: value })
                  }
                  title="2FA Token"
                  value={otpCode}
                />
              )}

              <button
                className={`custom-button submit half ${
                  loading ? "loading" : ""
                }`}
                onClick={this.handleSubmit}
              >
                Save Changes
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }

  toggleEditPassword = () => {
    if (this.state.editing) {
      this.resetEditPassword();
      this.props.clearFieldSpecificErrors();
      this.props.newGeneralErrors([]);
    } else {
      this.setState({ editing: !this.state.editing });
    }
  };

  resetEditPassword = () => {
    this.setState({
      editing: false,
      currentPassword: "",
      newPassword: "",
      newPasswordConf: "",
    });
  };

  handleSubmit = () => {
    let { currentPassword, newPassword, newPasswordConf, otpCode } = this.state;
    this.props.clearFieldSpecificErrors();
    this.props.newGeneralErrors([]);

    let errors = this.validate();
    console.log("errors", errors);
    if (errors.length > 0) {
      for (var error of errors) {
        this.props.newFieldSpecificError(error.field, error.text);
      }
      this.props.newGeneralErrors([{ text: "Please fix the issues below." }]);
      window.scrollTo(0, 0);
      return;
    }

    this.props.updatePassword(
      this.profileId,
      currentPassword,
      newPassword,
      newPasswordConf,
      otpCode
    );
  };

  validate = () => {
    let { user } = this.props;
    let { currentPassword, newPassword, newPasswordConf, otpCode } = this.state;

    let errors = [];
    let passwordErrors = [];

    if (!currentPassword) {
      errors.push({
        field: "current_password",
        text: ["Current password required."],
      });
    }

    if (newPassword && newPassword.length < 8) {
      passwordErrors.push("Password must be at least 8 characters.");
    }

    if (newPasswordConf.length === 0) {
      errors.push({
        field: "new_password_conf",
        text: "Please confirm password.",
      });
    } else if (newPassword !== newPasswordConf) {
      errors.push({
        field: "new_password_conf",
        text: "Password and password confirmation do not match.",
      });
    }

    if (!/[A-Z]/.exec(newPassword) && !/[a-z]/.exec(newPassword)) {
      passwordErrors.push(
        "Password must contain at least one upper or lower case letter."
      );
    }

    if (!/\d/.exec(newPassword)) {
      passwordErrors.push("Password must contain at least one number.");
    }

    if (passwordErrors.length > 0) {
      errors.push({ field: "new_password", text: passwordErrors });
    }

    if (user.mfa_enabled && (!otpCode || otpCode.length < 6)) {
      errors.push({
        field: "otp_code",
        text: "2FA code must be 6 characters in length.",
      });
    }

    return errors;
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Account)
);
