import css from "styled-jsx/css";
import { variables } from "patient_app/stylesheets/variables.js";

export default css.global`
  .Courses-Container {
    padding-bottom: 2rem;
  }

  .Tabs-Courses {
    grid-template-columns: 1fr;
    grid-row-gap: 0;
    max-width: 40vw;
    margin: 10rem auto;
    margin-top: 2rem;
    background-color: white;
    border: 1px solid ${variables.boxBorder};
  }

  .Tabs-Courses .header {
    position: relative;
    padding: 1.75rem 2.5rem;
    border-bottom: 1px solid ${variables.boxBorder};
    box-sizing: border-box;
    height: 6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .Tabs-Courses .header h1 {
    margin: 0;
    font-size: 1.2rem;
    font-family: ${variables.objBold};
    color: ${variables.darkBlue};
    letter-spacing: 0.25px;
    margin-top: 2px;
  }

  .Tabs-Courses .header .course-toggle {
    position: relative;
  }

  .Tabs-Courses .header .course-toggle button {
    position: relative;
    width: 10rem;
    color: ${variables.darkBlue};
    font-family: ${variables.objBold};
    font-size: 0.85rem;
    border: 1px solid ${variables.boxBorder};
    border-radius: 4px;
    padding: 0.5rem;
  }

  .Tabs-Courses .header .course-toggle button:first-of-type {
    position: absolute;
    right: 9rem;
    bottom: 0;
  }

  .Tabs-Courses .header .course-toggle button.selected {
    color: white;
    background-color: ${variables.darkBlue};
    border: 1px solid ${variables.darkBlue};
    z-index: 1;
  }

  .Tabs-Courses .dashboard-block .block-item {
    padding: 1.75rem 2.5rem;
  }

  .Tabs-Courses .dashboard-block .play-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  .Tabs-Courses .section :global(.loading__comp) {
    padding: 2rem;
    width: 1rem;
    height: 1rem;
  }

  .Tabs-Courses .no-courses {
    padding: 2rem;
  }
  .Tabs-Courses .no-courses p {
    font-family: ${variables.objMed};
  }

  .Tabs-Courses .next-item-title {
    display: none;
    color: ${variables.gray};
    font-family: ${variables.objMed};
  }

  @media only screen and (max-width: 1200px) {
    .Tabs-Courses .header {
      flex-direction: column;
      min-height: 6rem;
      height: fit-content;
    }

    .Tabs-Courses .header .course-toggle {
      display: flex;
      justify-content: flex-end;
      width: 19rem;
      margin-top: 1rem;
    }

    .Tabs-Courses .next-item-title {
      display: block;
    }
    .Tabs-Courses .note-text {
      display: none;
    }
  }

  @media only screen and (max-width: 900px) {
    .Tabs-Courses {
      max-width: 80%;
    }
  }

  @media only screen and (max-width: 768px) {
    .Tabs-Courses {
      max-width: 100%;
      margin: 0;
    }
  }
`;
