import PropTypes from "prop-types";
import React from "react";
import cn from "classnames";
import axios from "axios";
import Api from "patient_app/api";

import CostBreakdown from "patient_app/views/onboarding/partials/CostBreakdown";
import FormErrors from "patient_app/components/utils/FormErrors";
import Appsflyer from "patient_app/helpers/Appsflyer";
import { getUrlVars } from "components/utils/helpers/linkHelpers";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { skipPendingItem } from "patient_app/api/pendingItemActions";
import {
  NEW_FORM_ERRORS,
  NEW_FIELD_SPECIFIC_ERROR,
  CLEAR_FIELD_SPECIFIC_ERRORS,
  TOGGLE_LOADING,
  ONBOARDING_UPDATE_PROGRESS,
  TOGGLE_ONBOARDING_LOADING,
} from "patient_app/constants/actionTypes";

import { fetchUser, fetchProfile } from "patient_app/api/commonActions";
import {
  reactivateStripeAccount,
  fetchProgramCost,
  fetchClinicPrograms,
} from "patient_app/api/onboardingActions";
import { fetchInsuranceDetails } from "patient_app/api/insuranceActions";

import MainLayout from "patient_app/components/layouts/MainLayout";

import ContentMain from "patient_app/components/forms/ContentMain";
import ContentBox from "patient_app/components/forms/ContentBox";

import SubmitButton from "patient_app/components/buttons/SubmitButton";
import PaymentSummary from "patient_app/views/payments/partials/PaymentSummary";
import OnboardingProgressBar from "patient_app/components/utils/OnboardingProgressBar";
import OnboardingField from "patient_app/views/onboarding/partials/OnboardingField";
import ContactUs from "patient_app/views/onboarding/partials/ContactUs";

import permissions from "patient_app/helpers/permissions";
import onboardingHelpers from "patient_app/helpers/onboardingHelpers";
import textHelpers from "patient_app/helpers/textHelpers";
import onboardingAssets from "patient_app/assets/onboarding";

import layout from "patient_app/stylesheets/onboarding/layout";
import creditCardFormStyle from "patient_app/stylesheets/onboarding/creditCardFormStyle";
import trackerActions from "patient_app/helpers/trackerActions";

const mapDispatchToProps = (dispatch) => {
  return {
    fetchInsuranceDetails: () => dispatch(fetchInsuranceDetails()),
    fetchUser: () => dispatch(fetchUser()),
    fetchProfile: (id) => dispatch(fetchProfile()),
    fetchClinicPrograms: () => dispatch(fetchClinicPrograms()),
    skipPendingItem: (title, uid) => dispatch(skipPendingItem(title, uid)),
    fetchProgramCost: (userId) => dispatch(fetchProgramCost(userId)),
    onGeneralErrors: (errors) =>
      dispatch({ type: NEW_FORM_ERRORS, errors: errors }),
    onError: (field, error) =>
      dispatch({ type: NEW_FIELD_SPECIFIC_ERROR, field: field, error: error }),
    clearErrors: () => dispatch({ type: CLEAR_FIELD_SPECIFIC_ERRORS }),
    toggleLoading: (loading) => {
      dispatch({ type: TOGGLE_LOADING, loading: loading });
      dispatch({ type: TOGGLE_ONBOARDING_LOADING, loading: loading });
    },
    updateProgress: (progress) =>
      dispatch({ type: ONBOARDING_UPDATE_PROGRESS, progress: progress }),
    reactivateStripeAccount: () => dispatch(reactivateStripeAccount()),
  };
};

const mapStateToProps = (state) => {
  return {
    clinicPrograms: state.onboarding.clinicPrograms,
    errors: state.common.errors,
    insuranceDetails: state.insurance.insuranceDetails,
    loading: state.common.loading,
    medicaidFeeStates: state.onboarding.medicaidFeeStates,
    pendingItemSuccess: state.pendingItem.success,
    primaryDiagnosis: state.common.primaryDiagnosis,
    profile: state.common.profile,
    programCosts: state.onboarding.programCosts,
    user: state.common.user,
  };
};

class Purchase extends React.Component {
  redirect = getUrlVars(window.location.href)["redirect"];
  signal = axios.CancelToken.source();
  stripe;

  static defaultProps = {
    errors: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      cardName: "",
      errors: props.errors,
      showCardForm: false,
      content: false,
      unmounted: true,
    };
  }

  componentDidMount = async () => {
    document.title = "Payment | Workit Health";
    let { clinicPrograms, medicaidFeeStates, user, profile } = this.props;
    !user && this.props.fetchUser();
    this.props.fetchProfile(); // always fetch in case billing type changed
    this.props.fetchInsuranceDetails();
    !clinicPrograms && this.props.fetchClinicPrograms();
    user && this.props.fetchProgramCost(user.id);

    user && profile && medicaidFeeStates && this.maybeContinue();

    const progress = onboardingHelpers.calculateProgress(
      this.props.location.pathname
    );
    this.props.updateProgress(progress);

    if (getUrlVars(window.location.href)["error"] === "payment_failed") {
      this.props.onGeneralErrors([
        {
          text: "We were unable to process your payment. Please update your payment information and try again.",
        },
      ]);
    }
  };

  componentDidUpdate(prevProps) {
    let { medicaidFeeStates, user, profile } = this.props;

    if (
      (!prevProps.profile && profile && user && medicaidFeeStates) ||
      (!prevProps.user && user && profile && medicaidFeeStates) ||
      (!prevProps.medicaidFeeStates && medicaidFeeStates && user && profile)
    ) {
      this.maybeContinue();
    }

    if (
      (!prevProps.user && user) ||
      (user && prevProps.profile?.billing_type !== profile?.billing_type)
    ) {
      this.props.fetchProgramCost(user.id);
    }

    if (!prevProps.pendingItemSuccess && this.props.pendingItemSuccess) {
      // user skipped payment pending item from checklist or action items
      if (this.redirect === "checklist") {
        this.props.history.push("/onboarding/signup_name");
      } else if (this.redirect === "todo") {
        this.props.history.push("/appointments");
      }
    }
  }

  maybeContinue = () => {
    let { medicaidFeeStates, user, profile } = this.props;
    if (profile.billing_type === "commercial_insurance") {
      this.handleContinue();
      return;
    } else if (profile.billing_type === "medicaid") {
      const program = permissions.isAud(user) ? "aud" : "oud";

      if (!medicaidFeeStates.includes(profile.state)) {
        this.handleContinue();
        return;
      }
    } else if (
      ["medicare", "grant", "enterprise"].includes(profile.billing_type) &&
      !getUrlVars(window.location.href)["redirect"]
    ) {
      this.handleContinue();
      return;
    }

    this.fetch();
  };

  fetch = async () => {
    let path = `/payments/purchase?json=true&mat_onboarding=true`;
    let stripe;
    let elements;
    let cardElement;

    try {
      const res = await axios.get(path, {
        cancelToken: this.signal.token,
      });

      if (res.data.stripeKey) {
        //Stripe.setPublishableKey(res.data.stripeKey, { stripeAccount: res.data.stripeAccountKey});
        if (res.data.stripeAccountKey) {
          stripe = Stripe(res.data.stripeKey, {
            stripeAccount: res.data.stripeAccountKey,
          });
        } else {
          stripe = Stripe(res.data.stripeKey);
        }

        elements = stripe.elements();
        cardElement = elements.create("card", {
          iconStyle: "solid",
          hidePostalCode: false,
          disableLink: true,
          style: {
            base: {
              color: "#354052",
              fontSize: "16px",
              fontFamily: "obj3-reg, arial",
            },
          },
        });
      }

      this.setState(
        {
          content: true,
          cardDetails: res.data.cardDetails,
          showCardForm: res.data.showCardForm,
          pubKey: res.data.stripeKey,
          stripe: stripe,
          stripeElements: elements,
          stripeCardElement: cardElement,
        },
        () => {
          setTimeout(() => {
            cardElement.mount("#card-element");
            this.setState({ unmounted: false });
          }, 10);
        }
      );
    } catch (err) {
      console.log(err);
      if (axios.isCancel(err)) {
        console.log("Error: ", err.message); // => prints: Api is being canceled
      } else {
        this.props.toggleLoading(false);
      }
    }
  };

  render() {
    const {
      clinicPrograms,
      errors,
      insuranceDetails,
      loading,
      primaryDiagnosis,
      profile,
      programCosts,
      user,
    } = this.props;
    const { cardName, cardDetails, showCardForm, content, unmounted } =
      this.state;

    let headerText = "Treatment Cost";
    if (user?.company?.length > 0 && user.company !== "consumer") {
      headerText = `The cost after ${textHelpers.uppercaseFirstLetter(
        user.company
      )} deductions`;
    }

    let subtitle;
    if (permissions.isClinic(user)) {
      const treatmentType = primaryDiagnosis?.uid || user.treatment_type;
      const clinicProgram = clinicPrograms?.find(
        (cp) => cp.uid === treatmentType
      );
      if (clinicProgram) {
        subtitle = clinicProgram.name;
      }
    }

    // show card form if there is a program cost
    // or if member is paying with medicare
    const needsCard =
      !!programCosts?.base_cost?.cost || profile?.billing_type === "medicare";

    return (
      <MainLayout>
        <ContentMain halfBleed={true}>
          {content && (
            <ContentBox className="onboarding">
              <OnboardingProgressBar />
              <ContactUs />
              <div className="inputs">
                <h1 className={subtitle ? "with-subtitle" : ""}>
                  {headerText}
                </h1>
                {subtitle && <h2>{subtitle}</h2>}

                <FormErrors />
                <CostBreakdown />

                {cardDetails && needsCard && (
                  <div className="card-details">
                    <button
                      className={cn(
                        "custom-button",
                        "option",
                        showCardForm ? "" : "selected"
                      )}
                      onClick={(e) => this.toggleCardForm(true)}
                    >
                      <div className="text" key="1">
                        <p>
                          Use <b>{cardDetails.brand}</b> ending in{" "}
                          <b>{cardDetails.last4}</b>
                        </p>
                      </div>
                    </button>

                    <button
                      className={cn(
                        "custom-button",
                        "option",
                        showCardForm ? "selected" : ""
                      )}
                      onClick={(e) => this.toggleCardForm(false)}
                    >
                      <div className="text" key="1">
                        <p>Use a different card</p>
                      </div>
                    </button>
                  </div>
                )}

                {showCardForm && !unmounted && needsCard && (
                  <OnboardingField
                    field="cardName"
                    fieldType="text"
                    required={true}
                    title="Cardholder Name"
                    value={cardName}
                    onUpdateField={(value) =>
                      this.setState({ cardName: value })
                    }
                  />
                )}

                <div
                  id="card-element"
                  className={cn(
                    "card-form",
                    (!showCardForm || unmounted || !needsCard) && "hidden"
                  )}
                ></div>

                <div className="align-bottom">
                  <SubmitButton
                    id="private-pay-purchase"
                    text={"Continue"}
                    loading={loading}
                    onSubmit={
                      showCardForm ? this.handleSubmit : this.handleContinue
                    }
                  />

                  {profile?.billing_type === "medicare" && (
                    <button
                      loading={loading}
                      onClick={() =>
                        this.props.skipPendingItem("Payment Method", "")
                      }
                      className="custom-button other-option"
                    >
                      Skip this step
                    </button>
                  )}

                  <div className="links-container">
                    <a
                      target="_blank"
                      href="https://www.workithealth.com/refund-policy/"
                    >
                      Refund Policy
                    </a>
                  </div>
                </div>
              </div>

              {needsCard && (
                <div className="secure-payments-container">
                  <p>
                    <i>secure payment processed by</i>
                  </p>
                  <img src={onboardingAssets.stripeBadge} alt="Stripe" />
                </div>
              )}

              <style jsx>{layout}</style>
              <style jsx>{creditCardFormStyle}</style>

              {/* the card form for "Use a different card" is overflowing and causing the continue button to be unclickable */}
              <style jsx>{`
                :global(.__PrivateStripeElement) {
                  height: 100%;
                }
              `}</style>
            </ContentBox>
          )}
        </ContentMain>
      </MainLayout>
    );
  }

  onKeyPress = (e) => {
    if (e.keyCode == 13) {
      this.handleSubmit();
    }
  };

  handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.props.clearErrors();
    this.props.onGeneralErrors([]);

    if (!this.state.cardName) {
      this.props.onGeneralErrors([
        { text: "Cardholder name cannot be blank." },
      ]);
      this.props.onError("cardName", "Cardholder name cannot be blank.");
      window.scrollTo(0, 0);
      return;
    }

    this.props.toggleLoading(true);
    //Stripe.card.createToken(card, this.handleStripeResponse);
    this.state.stripe
      .createToken(this.state.stripeCardElement, { name: this.state.cardName })
      .then((result) => {
        if (result.error) {
          // Inform the user if there was an error
          this.props.onGeneralErrors([{ text: result.error.message }]);
          window.scrollTo(0, 0);
          this.props.toggleLoading(false);
        } else {
          // Send the token to your server
          this.submitCard(result.token);
        }
      });
  };

  handleStripeResponse = (token) => {
    const data = {
      card_id: token && token.id,
      pub_key: this.state.pubKey,
    };
    this.submitCard(data);
  };

  submitCard = async (token) => {
    let url = "/api/v2/registrations/add_card";
    let { user, profile, insuranceDetails } = this.props;

    const dataBody = {
      url: url,
      data: {
        method: "POST",
        body: { card_id: token?.id },
      },
    };

    try {
      const response = await Api.makeRequest(dataBody);
      if (response.success === true) {
        let appsflyerObj = Object.assign({}, this.props.user);
        appsflyerObj.billing_type = this.props.profile.billing_type;
        Appsflyer.event("Credit_Card_Added", appsflyerObj, 2);
        trackerActions.logMixpanelEvent("CreditCardAdded", {
          onboarding: "web",
        });
        this.props.toggleLoading(false);
        this.handleContinue();
        return;
      }

      let errors = [];
      response.errors.forEach((err) => {
        errors.push({ text: err });
      });
      this.props.onGeneralErrors(errors);
    } catch (e) {
      this.props.onGeneralErrors([
        { text: "Could not add card, please try again." },
      ]);
    }

    this.props.toggleLoading(false);
  };

  handleContinue = (e) => {
    let { user, profile } = this.props;
    if (e) {
      e.preventDefault();
    }

    // in case no stripe accounts are active
    this.props.reactivateStripeAccount();

    if (this.redirect === "checklist") {
      this.props.skipPendingItem("Payment Method", "");
      // this.props.history.push("/users/checklist")
    } else if (this.redirect === "todo") {
      // mark todo item complete
      this.props.skipPendingItem("", "payment_todo");
      // this.props.history.push("/appointments")
    } else {
      this.props.history.push("/onboarding/signup_name");
    } /*else if (
      ["honigman", "steelcase"].includes(user.company?.toLowerCase())
    ) {
      this.props.history.push("/onboarding/occupation");
    } else if (!permissions.isClinic(user)) {
      this.props.history.push("/onboarding/primary_curriculum");
    } else if (permissions.isClinic(user) && permissions.isAud(user)) {
      this.props.history.push("/onboarding/address");
    } else {
      this.props.history.push("/onboarding/address");
    }*/
  };

  toggleCardForm = (show) => {
    if (show == this.state.showCardForm) {
      this.setState({ showCardForm: !this.state.showCardForm, errors: [] });
    }
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Purchase)
);
