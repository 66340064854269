import Api from "patient_app/api";
import { getUrlVars } from "../helpers/linkHelpers";
import {
  ERRORS,
  MEDIA_DEVICE_ERRORS,
  TWILIO_ERROR_CODES,
} from "patient_app/constants/videoCallMessages";

import { mediaDevicePermissionsAllowed } from "patient_app/helpers/mediaHelpers";
import {
  flattenMap,
  flattenParticipant,
} from "patient_app/helpers/videoCallHelpers";

import {
  NEW_ERRORS,
  VC_INITIAL_REQUEST_FAILED,
  VC_TOKEN_LOAD,
  VC_SET_MEDIA_PERMISSIONS_ALLOWED,
  VC_SET_MEDIA_PERMISSIONS_BLOCKED,
  VC_SET_ERROR,
  VC_UPDATE_STATE,
  VC_SET_PARTICIPANT,
  VC_REMOVE_ROOM,
  VC_SET_REMOTE_TRACK,
  VRQ_USES_ZOOM,
  VC_ZOOM_EVENT_ID_LOAD,
  ZOOM_EVENTS_LOAD_PARTICIPANT,
} from "../constants/actionTypes";

import {
  connect,
  createLocalTracks,
  //createLocalVideoTrack,
} from "twilio-video";

export const getMediaPermissions = () => {
  return async (dispatch) => {
    const allowed = await mediaDevicePermissionsAllowed();
    dispatch({ type: VC_SET_MEDIA_PERMISSIONS_ALLOWED, allowed: allowed });
  };
};

export const askForMedia = () => {
  return async (dispatch) => {
    if (!navigator.mediaDevices) {
      dispatch({
        type: VC_INITIAL_REQUEST_FAILED,
        error: ERRORS.NOT_SUPPORTED,
      });
      return;
    }

    try {
      await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });

      dispatch({ type: VC_SET_MEDIA_PERMISSIONS_ALLOWED, allowed: true });
    } catch (error) {
      handleMediaError(dispatch, error);
    }
  };
};

export const initializeLocalTracks = () => {
  return async (dispatch) => {
    if (!navigator.mediaDevices) {
      dispatch({
        type: VC_INITIAL_REQUEST_FAILED,
        error: ERRORS.NOT_SUPPORTED,
      });
      return;
    }

    try {
      dispatch({
        type: VC_UPDATE_STATE,
        key: "creatingLocalTracks",
        value: true,
      });
      const tracks = await createLocalTracks({
        audio: true,
        video: {
          facingMode: "user",
        },
      });

      dispatch({ type: VC_SET_MEDIA_PERMISSIONS_ALLOWED, allowed: true });
      tracks.forEach(async (track) => {
        setLocalTrack(dispatch, track);
        setupTrackListeners(dispatch, track);
      });

      //await Promise.all(
      //  tracks.map((track) => dispatch('publishTrack', track)),
      //);
    } catch (error) {
      handleMediaError(dispatch, error);
    } finally {
      dispatch({
        type: VC_UPDATE_STATE,
        key: "creatingLocalTracks",
        value: false,
      });
    }
  };
};

export const setLocalTrack = (dispatch, track) => {
  let key = track.kind === "audio" ? "localAudioTrack" : "localVideoTrack";
  dispatch({ type: VC_UPDATE_STATE, key: key, value: track });
};

export const setupTrackListeners = (dispatch, track) => {
  const toggleEnabled = (track) => {
    let key =
      track.kind === "audio" ? "localAudioEnabled" : "localVideoEnabled";
    dispatch({ type: VC_UPDATE_STATE, key: key, value: track.isEnabled });
  };

  track.on("enabled", toggleEnabled);
  track.on("disabled", toggleEnabled);
  toggleEnabled(track);
};

export const combineTracks = (audioTrack, videoTrack) => {
  return [audioTrack, videoTrack].filter((track) => !!track);
};

export const joinCall = (props, callback) => {
  return async (dispatch) => {
    dispatch({ type: VC_UPDATE_STATE, key: "isConnecting", value: true });
    dispatch({ type: VC_SET_ERROR, error: null });
    dispatch({ type: VC_UPDATE_STATE, key: "callEnded", value: false });

    try {
      const localTracks = combineTracks(
        props.localAudioTrack,
        props.localVideoTrack
      );

      const room = await connect(props.token, {
        logLevel: "info",
        dominantSpeaker: true,
        tracks: localTracks,
        audio: false,
        video: false,
      });

      dispatch({ type: VC_UPDATE_STATE, key: "room", value: room });
      dispatch({ type: VC_UPDATE_STATE, key: "hasJoined", value: true });

      callback(room);
    } catch (error) {
      handleTwilioError(dispatch, error);
    }

    dispatch({ type: VC_UPDATE_STATE, key: "isConnecting", value: false });
  };
};

export const setParticipants = (participants) => {
  return async (dispatch) => {
    let sids = [];
    flattenMap(participants).forEach((participant) => {
      dispatch({
        type: VC_SET_PARTICIPANT,
        sid: participant.sid,
        participant: flattenParticipant(participant),
      });
      sids.push(participant.sid);
    });

    dispatch({ type: VC_UPDATE_STATE, key: "participantSids", value: sids });
  };
};

export const setParticipant = (participant, participantSids) => {
  return async (dispatch) => {
    let updatedSids = [...participantSids];
    dispatch({
      type: VC_SET_PARTICIPANT,
      sid: participant.sid,
      participant: flattenParticipant(participant),
    });
    !updatedSids.includes(participant.sid) && updatedSids.push(participant.sid);
    dispatch({
      type: VC_UPDATE_STATE,
      key: "participantSids",
      value: updatedSids,
    });
  };
};

export const removeParticipant = (
  participant,
  participantSids,
  participants,
  remoteTracks
) => {
  return async (dispatch) => {
    try {
      participants[participant.sid].trackSids
        .map((sid) => remoteTracks[sid])
        .forEach((track) => {
          if (track) {
            const mediaElements = track.detach();
            mediaElements.forEach((mediaElement) => mediaElement.remove());
          }
        });

      dispatch({
        type: VC_SET_PARTICIPANT,
        sid: participant.sid,
        participant: null,
      });
      const updatedSids = participantSids.filter(
        (sid) => sid !== participant.sid
      );
      dispatch({
        type: VC_UPDATE_STATE,
        key: "participantSids",
        value: updatedSids,
      });

      return participant;
    } catch (e) {
      console.log(e);
      return null;
    }
  };
};

export const removeRoom = (room, remoteTracks, remoteTrackSids) => {
  return async (dispatch) => {
    remoteTrackSids
      .map((sid) => remoteTracks[sid])
      .forEach((track) => {
        if (track) {
          const mediaElements = track.detach();
          mediaElements.forEach((mediaElement) => mediaElement.remove());
        }
      });

    dispatch({ type: VC_REMOVE_ROOM });
  };
};

export const leaveRoom = (room, localAudioTrack, localVideoTrack) => {
  return async (dispatch) => {
    localAudioTrack && localAudioTrack.stop();
    localVideoTrack && localVideoTrack.stop();

    room &&
      room.localParticipant &&
      localAudioTrack &&
      room.localParticipant.unpublishTrack(localAudioTrack);
    room &&
      room.localParticipant &&
      localVideoTrack &&
      room.localParticipant.unpublishTrack(localVideoTrack);
    localAudioTrack && detachMediaElements(localAudioTrack);
    localVideoTrack && detachMediaElements(localVideoTrack);

    room && room.disconnect();

    dispatch({ type: VC_UPDATE_STATE, key: "callEnded", value: true });
    dispatch({ type: VC_UPDATE_STATE, key: "localAudioTrack", value: null });
    dispatch({ type: VC_UPDATE_STATE, key: "localVideo", value: null });
    dispatch({
      type: VC_UPDATE_STATE,
      key: "roomStatus",
      value: "DISCONNECTED",
    });
  };
};

export const detachMediaElements = (track) => {
  const mediaElements = track.detach();
  mediaElements.forEach((mediaElement) => mediaElement.remove());
};

export const setRemoteTrack = (
  publication,
  participant,
  participants,
  remoteTrackSids
) => {
  return async (dispatch) => {
    const track = publication.track;

    if (track) {
      let updatedPart = Object.assign({}, participants[participant.sid]);
      if (!!updatedPart.trackSids) {
        updatedPart.trackSids.push(track.sid);
      } else {
        updatedPart.trackSids = [track.sid];
      }
      dispatch({
        type: VC_SET_PARTICIPANT,
        sid: participant.sid,
        participant: updatedPart,
      });
      const updatedTrackSids = [...remoteTrackSids];

      updatedTrackSids.push(track.sid);
      dispatch({ type: VC_SET_REMOTE_TRACK, sid: track.sid, track: track });
      dispatch({
        type: VC_UPDATE_STATE,
        key: "remoteTrackSids",
        value: updatedTrackSids,
      });
    }
  };
};

export const removeRemoteTrack = (
  publication,
  participant,
  participants,
  remoteTrackSids
) => {
  return async (dispatch) => {
    const track = publication.track;
    if (track) {
      const mediaElements = publication.track.detach();
      Array.from(mediaElements).forEach((mediaElement) =>
        mediaElement.remove()
      );
    }

    let updatedPart = Object.assign({}, participants[participant.sid]);
    updatedPart.trackSids = updatedPart.trackSids.filter(
      (sid) => sid !== publication.trackSid
    );

    dispatch({
      type: VC_SET_REMOTE_TRACK,
      sid: publication.trackSid,
      track: null,
    });

    const updatedSids = [...remoteTrackSids].filter(
      (sid) => sid !== publication.trackSid
    );
    dispatch({
      type: VC_UPDATE_STATE,
      key: "remoteTrackSids",
      value: updatedSids,
    });

    return track;
  };
};

export const handleMediaError = (dispatch, error) => {
  switch (error.name) {
    case MEDIA_DEVICE_ERRORS.NOT_ALLOWED_ERROR:
    case MEDIA_DEVICE_ERRORS.SECURITY_ERROR:
    case MEDIA_DEVICE_ERRORS.TYPE_ERROR:
      dispatch({ type: VC_SET_MEDIA_PERMISSIONS_BLOCKED, blocked: true });
      break;
    case MEDIA_DEVICE_ERRORS.ABORT_ERROR:
    case MEDIA_DEVICE_ERRORS.NOT_READABLE_ERROR:
      dispatch({
        type: VC_SET_ERROR,
        error: "We're having trouble accessing your camera and/or microphone.",
        errorSubtitle:
          "Your camera or microphone already are already in use. If you are on a call, please hang up before joining.",
        errorCta: "reload",
      });
      break;
    case MEDIA_DEVICE_ERRORS.NOT_FOUND_ERROR:
    case MEDIA_DEVICE_ERRORS.OVERCONSTRAINED_ERROR:
      dispatch({
        type: VC_SET_ERROR,
        error: "No Camera/Microphone found.",
        errorCta: "reload",
      });
      break;
    default:
      // dispatch('setError', { messages: [error.message], cta: 'Reload' });
      dispatch({
        type: VC_SET_ERROR,
        error: error.message,
        errorCta: "reload",
      });
  }
};

export const handleTwilioError = (dispatch, error) => {
  if (!dispatch) {
    return async (dispatch) => {
      return performTwilioError(dispatch, error);
    };
  } else {
    return performTwilioError(dispatch, error);
  }
};

export const performTwilioError = (dispatch, error) => {
  switch (error.code) {
    case TWILIO_ERROR_CODES.ACCESS_TOKEN_INVALID:
      dispatch({
        type: VC_SET_ERROR,
        error: "Meeting code invalid.",
        errorCta: "reload",
      });
      break;
    case TWILIO_ERROR_CODES.ACCES_TOKEN_EXPIRED:
      dispatch({
        type: VC_SET_ERROR,
        error: "This meeting has expired.",
        errorCta: "reload",
      });
      break;
    case TWILIO_ERROR_CODES.DUPLICATE_IDENTITY:
      dispatch({
        type: VC_SET_ERROR,
        error: "This link has already been used on another device.",
        errorCta: "reload",
      });
      break;
    case TWILIO_ERROR_CODES.ROOM_NOT_FOUND:
      dispatch({
        type: VC_SET_ERROR,
        error: "Meeting room not found.",
        errorCta: "reload",
      });
      break;
    default:
      dispatch({
        type: VC_SET_ERROR,
        error: error.message,
        errorCta: "reload",
      });
  }
};

export const submitRating = (videoCallId, videoCallParams) => {
  const data = {
    url: `/video_calls/${videoCallId}/rate`,
    path: "api/v2",
    data: {
      method: "POST",
      body: {
        video_call: videoCallParams,
      },
    },
  };

  return async (dispatch) => {
    try {
      const res = await Api.makeRequest(data);
      if (res.success) {
        // only show success text if issues/details are submitted
        dispatch({ type: VC_UPDATE_STATE, key: "videoCall", value: res.call });
      } else if (!res.success) {
        // always show errors regardless of what is being submitted
        dispatch({ type: NEW_ERRORS, errors: res.errors });
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const fetchVrqUsesZoom = () => {
  const data = {
    url: `/video_calls/uses_zoom`,
    path: "api/v2",
    data: { method: "POST" },
  };

  return async (dispatch) => {
    try {
      const res = await Api.makeRequest(data);
      // console.log("res.vrq_uses_zoom", res.vrq_uses_zoom);
      if (res.success) {
        dispatch({
          type: VRQ_USES_ZOOM,
          vrqUsesZoom: res.vrq_uses_zoom,
        });
      }
    } catch (e) {
      console.log(e);
      dispatch({
        type: NEW_ERRORS,
        errors: [{ text: "Something went wrong trying to fetchVrqUsesZoom." }],
      });
    }
  };
};

export const waitForZoomInvite = (userId, queueId) => {
  const data = {
    url: `/video_calls/get_zoom_event_id?video_queue_id=${queueId}&user_id=${userId}&client=web`,
    path: `/api/v2`,
    data: {
      method: "POST",
    },
  };

  return async (dispatch) => {
    try {
      const res = await Api.makeRequest(data);
      if (res?.success) {
        console.log("waitForZoomInvite res", res);
        dispatch({
          type: VC_ZOOM_EVENT_ID_LOAD,
          zoomEventId: res.zoom_event_id,
        });
        dispatch({
          type: ZOOM_EVENTS_LOAD_PARTICIPANT,
          zoomEventParticipant: res.participant,
        });
        // dispatch({
        //   type: VC_UPDATE_STATE,
        //   key: "adminType",
        //   value: res.admin_type,
        // });
        // dispatch({ type: VC_UPDATE_STATE, key: "videoCall", value: res.call });
        // callback(res.participant, res.host_zoom_email);
        return;
      }

      if (![null, undefined].includes(res?.position)) {
        dispatch({
          type: VC_UPDATE_STATE,
          key: "queuePosition",
          value: res.position,
        });
      }

      if (!!res && res.locked_out && res.locked_out === true) {
        dispatch({
          type: NEW_ERRORS,
          errors: [
            {
              text: "Cannot start call while failed payments are present. You can adjust your payment settings in your profile.",
            },
          ],
        });
        dispatch({
          type: VC_INITIAL_REQUEST_FAILED,
          error: ERRORS.INITIAL_ERROR,
        });
        return;
      }
    } catch (e) {
      console.log(e);
    }
  };
};
