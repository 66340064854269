import PropTypes from "prop-types";
import React from "react";
import SearchRow from "patient_app/components/chat/partials/SearchRow";

class Search extends React.Component {
  render() {
    let { resultNames, resultMessages, onSelectResult, q } = this.props;

    return (
      <div className="Search">
        <h1>Chats</h1>
        {(!resultNames || resultNames.length === 0) && (
          <div className="no-results">No results</div>
        )}
        {resultNames?.length > 0 && this.renderNames()}

        <h1>Messages</h1>
        {(!resultMessages || resultMessages.length === 0) && (
          <div className="no-results">No results</div>
        )}
        {resultMessages?.length > 0 && this.renderMessages()}
      </div>
    );
  }

  renderNames = () => {
    let { resultNames, q } = this.props;

    return resultNames.map((result, i) => {
      return (
        <SearchRow
          key={i}
          result={result}
          resultType={"name"}
          onSelectResult={this.props.onSelectResult}
          q={q}
        />
      );
    });
  };

  renderMessages = () => {
    let { resultMessages, q } = this.props;

    return resultMessages.map((result, i) => {
      return (
        <SearchRow
          key={i}
          result={result}
          resultType={"message"}
          onSelectResult={this.props.onSelectResult}
          q={q}
          tabIndex={this.props.tabIndex}
        />
      );
    });
  };
}

export default Search;
