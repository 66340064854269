import {
  RDT_REQ_START,
  RDT_REQ_SUCCESS,
  RDT_REQ_FAILURE,
  RDT_LOAD,
} from "emr/constants/actionTypes";

const initialState = {
  isFetching: false,
  reqFinished: false,
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RDT_REQ_START:
      return {
        ...state,
        isFetching: true,
        success: false,
      };
    case RDT_REQ_SUCCESS:
      return {
        ...state,
        isFetching: false,
        reqFinished: true,
        success: true,
      };
    case RDT_REQ_FAILURE:
      return {
        ...state,
        isFetching: false,
        reqFinished: true,
        success: false,
      };
    case RDT_LOAD:
      return {
        ...state,
        test: action.test,
      };
    default:
      return state;
  }
};
