let textHelpers = {
  lowercaseFirstLetter(string) {
    return string.charAt(0).toLowerCase() + string.slice(1);
  },
  uppercaseFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },
  htmlify(string) {
    return string.replace(/\W+/g, "-").toLowerCase();
  },
  titleize(string) {
    let words = string.toLowerCase().replaceAll("_", " ").split(" ");

    words.map((word, i) => {
      words[i] = word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });

    return words.join(" ");
  },
  formatPhone(phone) {
    if (!phone) return "";
    phone = phone.replace(/\W/g, ""); // remove non-alphanumeric characters
    if (phone.length === 10) {
      return `${phone.substring(0, 3)}-${phone.substring(
        3,
        6
      )}-${phone.substring(6, 10)}`;
    } else if (phone.length === 11) {
      return `${phone.substring(0, 1)}-${phone.substring(
        1,
        4
      )}-${phone.substring(4, 7)}-${phone.substring(7, 11)}`;
    }
    return phone;
  },
  truncate(str, n) {
    return str.length > n ? str.substr(0, n - 1) + "..." : str;
  },
  formatName(user) {
    let name = "";
    if (user) {
      if (!!user.fir_name || !!user.pref_name) {
        name = !!user.pref_name
          ? `${user.pref_name} ${user.las_name}`
          : `${user.fir_name} ${user.las_name}`;
      } else if (!!user.name) {
        name = user.name;
      }
    }

    return name;
  },
  stringifyNumber(n = 1) {
    let special = [
      "zeroth",
      "first",
      "second",
      "third",
      "fourth",
      "fifth",
      "sixth",
      "seventh",
      "eighth",
      "ninth",
      "tenth",
      "eleventh",
      "twelfth",
      "thirteenth",
      "fourteenth",
      "fifteenth",
      "sixteenth",
      "seventeenth",
      "eighteenth",
      "nineteenth",
    ];
    let deca = [
      "twent",
      "thirt",
      "fort",
      "fift",
      "sixt",
      "sevent",
      "eight",
      "ninet",
    ];
    if (n >= 100) {
      return `${n}`;
    }
    if (n < 20) {
      return special[n];
    }
    if (n % 10 === 0) {
      return deca[Math.floor(n / 10) - 2] + "ieth";
    }
    return deca[Math.floor(n / 10) - 2] + "y-" + special[n % 10];
  },
};

export default textHelpers;
