import * as Sentry from "@sentry/browser";

let sentryHelper = {
  init() {
    /*
    let entry = "https://7666ad6fa5474cb395fde6d2450d4660@sentry.io/150892";
    let env = "production";

    if (
      window.location.hostname &&
      window.location.hostname.startsWith("local")
    ) {
      return;
    }

    if (
      window.location.hostname &&
      window.location.hostname.startsWith("staging")
    ) {
      entry = "https://7666ad6fa5474cb395fde6d2450d4660@sentry.io/150892";
      env = "staging";
    }

    try {
      entry && env && Sentry.init({ 
        dsn: entry, 
        environment: env,
        ignoreErrors: [
          "Non-Error promise rejection captured"
        ],
      });
    } catch (e) {
      console.log("sentry init error:", e);
    }
    */
  },
};

export default sentryHelper;
