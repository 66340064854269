import Api from "patient_app/api";
import SessionStore from "patient_app/helpers/SessionStore";
import {
  CARE_PROVIDER_LOAD,
  CLINIC_LOAD,
  COACH_LOAD,
  COMMON_UPDATE_START,
  COMMON_UPDATE_SUCCESS,
  COMMON_UPDATE_FAILURE,
  COMMON_UPDATE_STATE,
  DECLARE_NO_COACH,
  NEW_ERRORS,
  NEW_FORM_ERRORS,
  ONBOARDING_VERIFY_ADDRESS,
  PROFILE_LOAD,
  PROFILE_UPDATE_LOCAL,
  TOGGLE_PAYWALL,
  UPDATE_USER_SIGNED_IN,
  USER_LOAD,
  USER_UPDATE_LOCAL,
} from "patient_app/constants/actionTypes";

export const fetchUser = () => {
  const data = {
    url: `/users/show`,
    path: "/api/v1",
    data: { method: "GET" },
  };

  return async (dispatch) => {
    try {
      const res = await Api.makeRequest(data);
      if (res.success) {
        dispatch({
          type: COMMON_UPDATE_STATE,
          key: "userDiagnoses",
          value: res.user_diagnoses,
        });
        dispatch({
          type: COMMON_UPDATE_STATE,
          key: "primaryDiagnosis",
          value: res.primary_diagnosis,
        });
        dispatch({
          type: COMMON_UPDATE_STATE,
          key: "primaryProgram",
          value: res.primary_program,
        });

        res.user.locked_out = res.locked_out;
        dispatch({ type: USER_LOAD, user: res.user });
      }
      if (res.paywall) {
        dispatch({ type: TOGGLE_PAYWALL, paywall: res.paywall });
        dispatch({
          type: NEW_ERRORS,
          errors: [
            { text: "Please update your payment information to continue." },
          ],
        });
        dispatch({ type: NEW_FORM_ERRORS, errors: null });
      }
    } catch (e) {
      console.log(e);
      console.log("rederecting to sign in from fetchUser in common actions");
      window.location.href = "/signin";
    }
  };
};

export const checkUserSignedIn = () => {
  const data = {
    url: `/users/check_user_signed_in`,
    data: {
      method: "GET",
    },
  };

  return async (dispatch) => {
    try {
      const res = await Api.makeRequest(data);
      if (res && res.user_signed_in) {
        dispatch({ type: USER_LOAD, user: res.user });
        dispatch({
          type: UPDATE_USER_SIGNED_IN,
          userSignedIn: res.user_signed_in,
        });
      }
    } catch (e) {
      console.log(e, "error in check user sign in");
    }
  };
};

export const updateUser = (user) => {
  const data = {
    url: `/users/update_user_params`,
    data: {
      body: {
        user: user,
        zip_code: user.zip_code,
      },
      method: "POST",
    },
  };

  return async (dispatch) => {
    dispatch({ type: COMMON_UPDATE_START });
    try {
      const res = await Api.makeRequest(data);
      if (res.success === true) {
        dispatch({ type: USER_LOAD, user: res.user });
        dispatch({
          type: COMMON_UPDATE_SUCCESS,
          successText: "Successfully updated.",
        });
        return;
      }

      dispatch({ type: COMMON_UPDATE_FAILURE, errors: res.errors });
    } catch (e) {
      console.log(e);
    }
  };
};

export const fetchProfile = (user = undefined, profileId = undefined) => {
  let id = profileId ? profileId : user && user.id;
  id = id || "current";
  const data = {
    url: `/profiles/${id}?json=true`,
    data: {
      method: "GET",
    },
  };

  return async (dispatch) => {
    try {
      const res = await Api.makeRequest(data);

      if (res && res.profile) {
        dispatch({
          type: PROFILE_LOAD,
          profile: res.profile,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
};

/*
  @params
  profile (object): Profile object
  showSuccessBanner (boolean): true if NEW_ERRORS should be rendered with success text
*/
export const updateProfile = (profile, showSuccessBanner = true) => {
  const data = {
    url: `/profiles/${profile.id}`,
    data: {
      body: {
        profile: {
          mission: profile.mission,
          pharmacy: profile.pharmacy,
          occupation: profile.occupation,
          insurance: profile.insurance,
          address: profile.address,
          address_two: profile.address_two,
          city: profile.city,
          state: profile.state,
          zip_code: profile.zip_code,
          time_zone: profile.time_zone,
          billing_type: profile.billing_type,
          terms_agreed_to_at: profile.terms_agreed_to_at
            ? profile.terms_agreed_to_at
            : null,
        },
      },
      method: "PATCH",
    },
  };

  return async (dispatch) => {
    dispatch({ type: COMMON_UPDATE_START });
    try {
      const res = await Api.makeRequest(data);
      // console.log("updateProfile res", res)
      if (res.success === true) {
        dispatch({ type: PROFILE_UPDATE_LOCAL, profile: res.profile });
        if (showSuccessBanner) {
          dispatch({
            type: NEW_ERRORS,
            errors: [{ status: "info", text: "Successfully updated profile." }],
          });
        }

        dispatch({ type: COMMON_UPDATE_SUCCESS });
        return;
      }

      dispatch({ type: COMMON_UPDATE_FAILURE });
      dispatch({ type: NEW_ERRORS, errors: res.errors });
    } catch (e) {
      console.log(e);
    }
  };
};

export const startOver = () => {
  const data = {
    url: `/users/user_destroy`,
    data: {
      method: "POST",
    },
  };

  return async (dispatch) => {
    try {
      dispatch({ type: COMMON_UPDATE_START });

      const res = await Api.makeRequest(data);
      if (res.success) {
        dispatch({ type: COMMON_UPDATE_SUCCESS });
        return;
      }

      dispatch({ type: COMMON_UPDATE_FAILURE, errors: res.errors });
    } catch (e) {
      console.log(e);
    }
  };
};

export const createEvent = (event) => {
  const data = {
    url: `/events`,
    data: {
      method: "POST",
      body: {
        event: event,
      },
    },
  };

  return async (dispatch) => {
    try {
      dispatch({ type: COMMON_UPDATE_START });

      const res = await Api.makeRequest(data);
      if (res.success) {
        dispatch({ type: COMMON_UPDATE_SUCCESS });
        return;
      }
      dispatch({ type: COMMON_UPDATE_FAILURE, errors: res.errors });
    } catch (e) {
      console.log(e);
    }
  };
};

export const fetchCareProvider = () => {
  const data = {
    url: `/admins/fetch_cp_for_user`,
    data: {
      method: "GET",
    },
  };

  return async (dispatch) => {
    try {
      const res = await Api.makeRequest(data);
      if (res.success) {
        dispatch({
          type: CARE_PROVIDER_LOAD,
          careProvider: res.care_provider,
        });
        return;
      }

      dispatch({ type: NEW_ERRORS, errors: res.errors });
    } catch (e) {
      console.log(e);
    }
  };
};

export const signOut = () => {
  const data = {
    url: "/sign_out",
    data: {
      method: "DELETE",
    },
  };

  return async (dispatch) => {
    try {
      const res = await Api.makeRequest(data);
      let redir = "/signin";
      SessionStore.set("isSetProfileCalled", false);
      window.location.href = redir;
    } catch (e) {
      console.log(e);
    }
  };
};

export const logConnectionInfo = (info) => {
  const data = {
    url: `/member_connection_infos`,
    path: "/api/v2",
    data: {
      body: {
        member_connection_info: info,
      },
      method: "POST",
    },
  };

  return async (dispatch) => {
    try {
      console.log("loggin connection info");
      const res = await Api.makeRequest(data);
      // console.log("connection info res", res);
    } catch (e) {
      console.error("member_connection_info failure", e);
    }
  };
};
