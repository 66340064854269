import mixpanel from "mixpanel-browser";
// or with require() syntax:
// const mixpanel = require('mixpanel-browser');

// Enabling the debug mode flag is useful during implementation,
// but it's recommended you remove it for production
const hostname = window.location.hostname;
const isLocal = hostname.startsWith("local");
const isStaging = hostname.startsWith("staging");
const isProd = !(hostname && (isLocal || isStaging));

const MIXPANEL_TOKEN = isProd
  ? "6b0164e2a6efe02520022773c84cdb14"
  : "dd672b30eaa078375e8c68abeeb08d20";

let trackerActions = {
  async configureMixpanel() {
    //if (!!mixpanel) {
    //  mixpanel.init(MIXPANEL_TOKEN, {
    //   debug: true,
    //  });
    //} else {
    //  console.error("missing mixpanel module");
    //}
  },
  async logMixpanelEvent(eventName = "event_name", eventValues = {}) {
    //returning early for mixpanel
    return;

    eventName = `v1:${eventName}`;
    eventValues.web = true;

    eventValues.release_channel = isProd
      ? "production"
      : isStaging
      ? "staging"
      : "localhost";
    eventValues.client_version = "0.0.0";
    eventValues.api = hostname;

    if (!isProd) {
      if (isLocal) {
        eventName = `localhost-${eventName}`;
        eventValues.localhost = true;
      } else {
        eventName = `staging-${eventName}`;
        eventValues.test_flight = true;
      }
    }

    // prevent race conditions
    let retries = 5;
    while (!mixpanel && retries > 0) {
      retries--;
      // console.warn(`await mixpanel configuration for ${retries} more attempts`);
      await new Promise((r) => setTimeout(r, 500));
    }

    // console.log(
    //   `mixpanel event: ${eventName}`,
    //   eventValues
    // );
    mixpanel?.track(eventName, eventValues);
  },

  async setCustomerUserId(userId) {
    //returning early for mixpanel
    return;

    userId = userId.toString();
    let distinctId = userId;
    trackerActions.mixpanel?.identify(distinctId);
    trackerActions.setProfile("$email", distinctId);
  },

  setProfile(key, val = null) {
    //returning early for mixpanel
    return;

    if (!val) return;
    trackerActions.mixpanel?.getPeople().set(key, val);
  },

  updateFullProfile(data) {
    //returning early for mixpanel
    return;

    let { user, profile, insurance, employer } = data;
    let tier = user?.tier;
    trackerActions.setProfile("Tier", tier);
    let treatmentType =
      user?.treatmentType ||
      (tier == 0
        ? "freemium"
        : tier == 1
        ? "coach"
        : tier == 2
        ? "counselor"
        : null);
    trackerActions.setProfile("TreatmentType", user?.treatmentType);
    trackerActions.setProfile("DOB", user?.dob);
    trackerActions.setProfile("BillingType", user?.billingType);
    trackerActions.setProfile("State", user?.state);
    trackerActions.setProfile("Insurance", insurance);
    trackerActions.setProfile("Gender", profile?.gender);
    trackerActions.setProfile("Employer", employer);
    // trackerActions.setProfile("Email", user?.email);
    // console.warn("updated mixpanel profile", data);
  },
};

export default trackerActions;
