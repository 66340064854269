import PropTypes from "prop-types";
import React from "react";
import cn from "classnames";

class SelectButton extends React.Component {
  render() {
    let { id, selectedValue, value, text, onSelect, classNames } = this.props;

    return (
      <button
        id={id}
        className={cn(
          "custom-button select-button",
          value === selectedValue ? "selected" : "",
          classNames
        )}
        onClick={() => onSelect(value)}
        role="checkbox"
        tabIndex="0"
        aria-checked={value === selectedValue ? "true" : "false"}
      >
        {text}
      </button>
    );
  }
}

export default SelectButton;
