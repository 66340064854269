import React, { Component } from "react";
import { connect } from "react-redux";

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {
    error: state.videoCall.error,
    errorSubtitle: state.videoCall.errorSubtitle,
    errorCta: state.videoCall.errorCta,
  };
};

class Errors extends Component {
  render() {
    let { error, errorSubtitle, errorCta } = this.props;

    if (!error) {
      return null;
    }

    return (
      <div className="errors-container">
        <p>
          <strong>Error: {error}</strong>
        </p>
        {errorSubtitle && <p className="subtitle">{errorSubtitle}</p>}

        {errorCta && (
          <button onClick={() => this.handleCta(errorCta)}>
            {this.getCtaText(errorCta)}
          </button>
        )}
      </div>
    );
  }

  getCtaText(action) {
    let text;
    switch (action) {
      case "reload":
        text = "Try Again";
        break;
      default:
        text = "Try Again";
        break;
    }

    return text;
  }

  handleCta(action) {
    switch (action) {
      case "reload":
        window.location.reload();
        break;
      default:
        window.location.reload();
        break;
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Errors);
