import Api from "patient_app/api";

import {
  TOGGLE_LOADING,
  USER_LOAD,
  NEW_FIELD_SPECIFIC_ERROR,
  NEW_ERRORS,
  NEW_FORM_ERRORS,
  SESSION_TOGGLE_SHOW_SIGNUP,
  UPDATE_SET_PASSWORD,
  SESSION_QUESTIONNAIRE_DUE,
  SESSION_HAS_PENDING_ITEM,
  MEMBER_REQUEST_SEND_2FA_EMAIL,
  SESSION_LOAD_OTP_CONFIG,
  SESSION_LOAD_CHECKLIST,
  SESSION_VERIFY_EMAIL,
  SESSION_EMAIL_UPDATE,
  SESSION_USERID_UPDATE,
  SESSION_UNIQUE_PHONE_UPDATE,
  SESSION_SET_LOCAL_STATE,
} from "patient_app/constants/actionTypes";

export const checkEmail = (email, history) => {
  // for sign in flow
  const data = {
    url: `/sessions/check_email`,
    data: {
      method: "POST",
      body: {
        email: email,
      },
    },
  };

  return async (dispatch) => {
    dispatch({ type: TOGGLE_LOADING, loading: true });
    try {
      const res = await Api.makeRequest(data);
      if (res.success === true) {
        history.push("/password");
        return;
      }

      if (res.needs_activation) {
        history.push(`/activate?user_id=${res.user_id}&auto_activate=true`);
        return;
      }

      dispatch({ type: TOGGLE_LOADING, loading: false });
      dispatch({
        type: NEW_FORM_ERRORS,
        errors: [{ text: "Please correct the issues listed below" }],
      });
      dispatch({
        type: NEW_FIELD_SPECIFIC_ERROR,
        error: res.errors && res.errors[0].text,
        field: "session_email",
      });
      dispatch({ type: SESSION_TOGGLE_SHOW_SIGNUP, showSignUpAction: true });
    } catch (e) {
      console.log(e);
    }
  };
};

export const checkUserId = (userId, history) => {
  // for sign in flow
  const data = {
    url: `/sessions/check_user_id`,
    data: {
      method: "POST",
      body: {
        user_id: userId,
      },
    },
  };

  return async (dispatch) => {
    dispatch({ type: TOGGLE_LOADING, loading: true });
    try {
      const res = await Api.makeRequest(data);
      if (res.success === true) {
        dispatch({ type: SESSION_EMAIL_UPDATE, email: res.email });
        dispatch({
          type: SESSION_UNIQUE_PHONE_UPDATE,
          uniquePhone: res.unique_phone,
        });
        //dispatch({type: SESSION_USERID_UPDATE, userId: res.email});
        history.push("/password");
        return;
      }

      if (res.needs_activation) {
        history.push(`/activate?user_id=${res.user_id}&auto_activate=true`);
        return;
      }

      dispatch({ type: TOGGLE_LOADING, loading: false });
      dispatch({
        type: NEW_FORM_ERRORS,
        errors: [{ text: "Please correct the issues listed below" }],
      });
      dispatch({
        type: NEW_FIELD_SPECIFIC_ERROR,
        error: res.errors && res.errors[0].text,
        field: "userId",
      });
      dispatch({ type: SESSION_TOGGLE_SHOW_SIGNUP, showSignUpAction: true });
    } catch (e) {
      console.log(e);
    }
  };
};

export const verifyUserId = (userId) => {
  // for activate flow
  const data = {
    url: `/authentication/verify_user_id`,
    data: {
      method: "POST",
      body: {
        user_id: userId,
      },
    },
  };

  return async (dispatch) => {
    dispatch({ type: TOGGLE_LOADING, loading: true });
    try {
      const res = await Api.makeRequest(data);
      if (res.status === "200") {
        dispatch({
          type: SESSION_VERIFY_EMAIL,
          sessionKey: res.session_key,
          confirmationType: res.confirmation_type,
          phone: res.phone,
          email: res.email,
        });
        dispatch({ type: TOGGLE_LOADING, loading: false });
        return;
      }

      dispatch({ type: NEW_FORM_ERRORS, errors: res.errors });
      dispatch({ type: TOGGLE_LOADING, loading: false });
    } catch (e) {
      console.log(e);
    }
  };
};

export const verifyCode = (code, sessionKey) => {
  // for activate flow
  const data = {
    url: `/authentication/verify_code`,
    data: {
      method: "POST",
      body: {
        code: code,
        session_key: sessionKey,
      },
    },
  };

  return async (dispatch) => {
    dispatch({ type: TOGGLE_LOADING, loading: true });
    try {
      const res = await Api.makeRequest(data);
      if (res.status === "200") {
        dispatch({ type: TOGGLE_LOADING, loading: false });
        return;
      }

      dispatch({ type: NEW_FORM_ERRORS, errors: res.errors });
      dispatch({ type: TOGGLE_LOADING, loading: false });
    } catch (e) {
      console.log(e);
    }
  };
};

export const setInitialPassword = (password) => {
  // for activate flow
  const data = {
    url: `/set_initial_password`,
    data: {
      method: "POST",
      body: {
        user: {
          password: password,
        },
        json: true,
      },
    },
  };

  return async (dispatch) => {
    dispatch({ type: TOGGLE_LOADING, loading: true });
    try {
      const res = await Api.makeRequest(data);
      if (res.success) {
        dispatch({ type: TOGGLE_LOADING, loading: false });
        return;
      }

      dispatch({ type: NEW_FORM_ERRORS, errors: [{ text: res.error }] });
      dispatch({ type: TOGGLE_LOADING, loading: false });
    } catch (e) {
      console.log(e);
    }
  };
};

export const canSignIn = (email, uniquePhone, password, form) => {
  const data = {
    url: "/sessions/can_sign_in",
    data: {
      method: "POST",
      body: {
        user: {
          email: email,
          unique_phone: uniquePhone,
          password: password,
        },
      },
    },
  };

  return async (dispatch) => {
    dispatch({ type: TOGGLE_LOADING, loading: true });
    try {
      const res = await Api.makeRequest(data);
      if (res.success === true) {
        form.submit();

        setTimeout(() => {
          window.location.href = "/appointments";
        }, 3500);
        return;
      }

      dispatch({ type: TOGGLE_LOADING, loading: false });
      dispatch({
        type: NEW_FORM_ERRORS,
        errors: [{ text: "Please correct the issues listed below." }],
      });

      let error = res.errors ? res.errors[0].text : res.error;
      if (res.unauthorized) {
        error = "Invalid email or password.";
      }

      dispatch({
        type: NEW_FIELD_SPECIFIC_ERROR,
        error: error,
        field: "password",
      });
    } catch (e) {
      console.log(e);
    }
  };
};

export const sendResetPasswordInstructions = (email, history) => {
  const data = {
    url: `/password`,
    data: {
      method: "POST",
      body: {
        user: {
          email: email,
        },
      },
    },
  };

  return async (dispatch) => {
    dispatch({ type: TOGGLE_LOADING, loading: true });
    try {
      const res = await Api.makeRequest(data);
      if (res.success === true) {
        history.push("/signin");
        dispatch({
          type: NEW_ERRORS,
          errors: [
            {
              text: "If there is an account associated with this email, you will receive instructions to reset your password.",
              status: "info",
            },
          ],
        });
        return;
      }

      dispatch({ type: TOGGLE_LOADING, loading: false });
      dispatch({
        type: NEW_FIELD_SPECIFIC_ERROR,
        error: res.errors && res.errors[0].text,
        field: "session_email",
      });
    } catch (e) {
      console.log(e);
    }
  };
};

export const createPassword = (setPassword, token) => {
  const data = {
    url: `/password`,
    data: {
      method: "PATCH",
      body: {
        user: {
          password: setPassword.password,
          password_confirmation: setPassword.password_conf,
          reset_password_token: token,
        },
      },
    },
  };

  return async (dispatch) => {
    dispatch({ type: TOGGLE_LOADING, loading: true });
    try {
      const res = await Api.makeRequest(data);
      if (res.success === true) {
        window.location.href = "/appointments";
        return;
      }

      dispatch({
        type: UPDATE_SET_PASSWORD,
        setPassword: { password: "", password_conf: "" },
      });
      dispatch({ type: TOGGLE_LOADING, loading: false });
      dispatch({ type: NEW_ERRORS, errors: res.errors });
    } catch (e) {
      console.log(e);
    }
  };
};

export const checkIfQuestionnaireDue = () => {
  const data = {
    url: `/users/check_if_questionnaire_due`,
    data: {
      method: "GET",
    },
  };

  return async (dispatch) => {
    try {
      const res = await Api.makeRequest(data);
      dispatch({
        type: SESSION_QUESTIONNAIRE_DUE,
        gutCheckDue: res.gut_check_due,
        alcoholCheckDue: res.alcohol_check_due,
        npsDue: res.nps_due,
      });
      res.nps &&
        dispatch({ type: SESSION_SET_LOCAL_STATE, obj: "nps", value: res.nps });
    } catch (e) {
      console.log(e);
    }
  };
};

export const checkIfPendingItem = () => {
  const data = {
    url: `/pending_items/check_for_items`,
    data: {
      method: "GET",
    },
  };

  return async (dispatch) => {
    try {
      const res = await Api.makeRequest(data);
      if (res.success) {
        dispatch({
          type: SESSION_HAS_PENDING_ITEM,
          hasPendingItem: res.has_pending_item,
          path: res.path,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const respondMemberRequestSend2faEmail = () => {
  const data = {
    url: `/users/generate_email_confirmation`,
    data: {
      method: "GET",
    },
  };

  return async (dispatch) => {
    try {
      const res = await Api.makeRequest(data);
      console.log("res: ", res);
      if (res.success) {
        dispatch({
          type: MEMBER_REQUEST_SEND_2FA_EMAIL,
          confirmEmail2faSent: res.confirmEmail2faSent,
        });
        dispatch({
          type: NEW_ERRORS,
          errors: [
            {
              text: "Email sent.",
              status: "info",
            },
          ],
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const disableMfa = (profileId, password, code) => {
  const data = {
    url: `/profiles/${profileId}/disable_mfa`,
    path: "/api/v2",
    data: {
      method: "POST",
      body: {
        password: password,
        code: code,
      },
    },
  };

  return async (dispatch) => {
    try {
      const res = await Api.makeRequest(data);
      if (res.success) {
        dispatch({ type: USER_LOAD, user: res.user });
        dispatch({
          type: NEW_ERRORS,
          errors: [
            {
              text: "Two factor authentication successfully disabled.",
              status: "info",
            },
          ],
        });

        return;
      }

      dispatch({ type: TOGGLE_LOADING, loading: false });
      dispatch({ type: NEW_ERRORS, errors: res.errors });
    } catch (e) {
      console.log(e);
    }
  };
};

export const configureMfa = (profileId, password) => {
  const data = {
    url: `/profiles/${profileId}/configure_mfa`,
    path: "/api/v2",
    data: {
      method: "POST",
      body: { password: password },
    },
  };

  return async (dispatch) => {
    try {
      const res = await Api.makeRequest(data);
      if (res.success) {
        dispatch({
          type: SESSION_LOAD_OTP_CONFIG,
          provisioningUri: res.provisioning_uri,
          otpSecretKey: res.otp_secret_key,
        });

        return;
      }

      dispatch({ type: TOGGLE_LOADING, loading: false });
      dispatch({ type: NEW_ERRORS, errors: res.errors });
    } catch (e) {
      console.log(e);
    }
  };
};

export const enableMfa = (profileId, otpSecretKey, mfaCode) => {
  const data = {
    url: `/profiles/${profileId}/enable_mfa`,
    path: "/api/v2",
    data: {
      method: "POST",
      body: {
        mfa_code: mfaCode,
        otp_secret_key: otpSecretKey,
      },
    },
  };

  return async (dispatch) => {
    try {
      const res = await Api.makeRequest(data);
      if (res.success) {
        dispatch({ type: USER_LOAD, user: res.user });
        dispatch({
          type: SESSION_LOAD_OTP_CONFIG,
          provisioningUri: null,
          otpSecretKey: null,
        });
        dispatch({
          type: NEW_ERRORS,
          errors: [
            {
              text: "Two factor authentication successfully enabled.",
              status: "info",
            },
          ],
        });
      } else {
        dispatch({ type: NEW_ERRORS, errors: res.errors });
      }

      dispatch({ type: TOGGLE_LOADING, loading: false });
      window.scrollTo(0, 0);
    } catch (e) {
      console.log(e);
    }
  };
};

export const submitMfaCode = (code, history) => {
  const data = {
    url: "/two_factor_authentication",
    data: {
      method: "PUT",
      body: {
        code: code,
      },
    },
  };

  return async (dispatch) => {
    dispatch({ type: TOGGLE_LOADING, loading: true });
    try {
      const res = await Api.makeRequest(data);

      if (res.unauthorized && res.unauthorized === true) {
        dispatch({
          type: NEW_FORM_ERRORS,
          errors: [{ text: "Please correct the issues listed below." }],
        });
        dispatch({
          type: NEW_FIELD_SPECIFIC_ERROR,
          error:
            "You are locked out due to too many incorrect two factor codes. Please contact hello@workithealth.com for more information.",
          field: "mfa_code",
        });
        dispatch({ type: TOGGLE_LOADING, loading: false });
        return;
      }

      if (res && res.success === true) {
        history.push("/courses");
        dispatch({
          type: NEW_ERRORS,
          errors: [{ text: "Signed In", status: "info" }],
        });
        return;
      }

      dispatch({ type: TOGGLE_LOADING, loading: false });
      dispatch({
        type: NEW_FORM_ERRORS,
        errors: [{ text: "Please correct the issues listed below." }],
      });
      dispatch({
        type: NEW_FIELD_SPECIFIC_ERROR,
        error: res.errors && res.errors[0].text,
        field: "mfa_code",
      });
    } catch (e) {
      dispatch({ type: TOGGLE_LOADING, loading: false });
    }
  };
};

export const getChecklistItems = () => {
  const data = {
    url: "/registrations/consent_checklist",
    path: "/api/v2",
    data: { method: "GET" },
  };

  return async (dispatch) => {
    try {
      const res = await Api.makeRequest(data);
      if (res.success) {
        if (
          res.signed_all &&
          window.location.pathname.includes("onboarding/consent")
        ) {
          window.location.href = "/appointments";
        }
        dispatch({
          type: SESSION_LOAD_CHECKLIST,
          checklistItemsAll: res.clinic_files,
          checklistItemsSigned: res.signed_items,
          consentCutoff: res.consent_cutoff,
          // dischargeRequestExists: res.discharge_request_exists,
        });
        return;
      }

      dispatch({
        type: NEW_FORM_ERRORS,
        errors: [
          { text: "Could not fetch your checklist, please refresh the page." },
        ],
      });
    } catch (e) {}
  };
};

export const memberInitiatesDischarge = (userId) => {
  const data = {
    url: `/registrations/initiate_discharge`,
    path: "api/v2",
    data: {
      method: "POST",
      body: {
        user_id: userId,
      },
    },
  };

  return async (dispatch) => {
    try {
      const res = await Api.makeRequest(data);
      if (res.success) {
        // dispatch({
        //   type: SESSION_LOAD_CHECKLIST,
        // dischargeRequestExists: res.success,
        // });
        dispatch({
          type: NEW_ERRORS,
          errors: [
            {
              text: res.error,
              status: "info",
            },
          ],
        });
        return;
      }

      dispatch({
        type: NEW_ERRORS,
        errors: res.error
          ? [{ text: res.error }]
          : [{ text: "We're sorry, something went wrong." }],
      });
    } catch (e) {}
  };
};

export const resendUserCode = (commType, sessionKey) => {
  const data = {
    url: "/authentication/resend_user_code",
    data: {
      method: "POST",
      body: {
        new_comm: commType,
        session_key: sessionKey,
      },
    },
  };

  return async (dispatch) => {
    try {
      const res = await Api.makeRequest(data);
      console.log("resendUserCode res", res);
      if (res.status === "200") {
        dispatch({
          type: NEW_ERRORS,
          errors: [{ status: "info", text: res.info }],
        });
        return;
      }

      dispatch({ type: NEW_ERRORS, errors: res.errors });
    } catch (e) {}
  };
};

export const textAppLink = () => {
  const data = {
    url: "/users/text_app_link",
    data: {
      method: "GET",
    },
  };

  return async (dispatch) => {
    try {
      const res = await Api.makeRequest(data);
      if (res.success) {
        dispatch({
          type: NEW_ERRORS,
          errors: [{ status: "info", text: "Text message sent." }],
        });
        return;
      }

      dispatch({
        type: NEW_ERRORS,
        errors: [{ text: "Could not send text message." }],
      });
    } catch (e) {}
  };
};
