const baseSpacing = 8;
export const variables = {
  boxShadow: "0 7px 14px 0 rgba(50,50,93,.1), 0 3px 6px 0 rgba(0,0,0,.07)",
  objLight: `'obj3-light', 'arial'`,
  objReg: `'obj3-reg', 'arial'`,
  objMed: `'obj3-med', 'arial'`,
  objBold: `'obj3-bold', 'arial'`,
  cursive: "cursive",

  // Colors Figma: https://www.figma.com/file/n2ZJk22kXw0BafoH5zcFYl/Brand?node-id=0%3A1

  // reds
  redWhite: "hsl(13, 71%, 80%)", // redWhite: "#F0B7A8",
  red: "hsl(353, 57%, 53%)", //red: "#CC4455",
  redDark: "hsl(353, 49%, 36%)", //redDark: "#882F3A",

  // yellows
  yellowWhite: "hsl(40, 100%, 92%)",
  yellowLight: "hsl(36, 100%, 84%)",
  yellow: "hsl(36, 87%, 74%)", //yellow: "#F6C882",
  orange: "hsl(19, 80%, 61%)",

  // greens
  greenWhite: "hsl(84, 47%, 89%)", //whiteGreen: "##E6F0D6",
  greenLight: "hsl(155, 46%, 55%)",
  green: "hsl(169, 80%, 28%)", //green: "#0E816C",
  greenDark: "hsl(177, 69%, 20%)", //#105753
  greenSplashForeground: "hsl(165, 52%, 45%)", // "#38b092",
  greenSplashBackground: "hsl(177, 69%, 29%)", //"#177D78",

  // blues
  blueWhite: "hsl(219, 79%, 94%)", //blueWhite: "#E5EDFC",
  blueLight: "hsl(216, 63%, 54%)",
  blue: "hsl(215, 65%, 29%)", // blue: "#1A4279",
  blueDark: "hsl(205, 32%, 23%)", // blueDark: "#283E4E",

  // purples
  purpleWhite: "hsl(325, 63%, 91%)", //lightPink: "#F6DAEA",
  purple: "hsl(325, 27%, 51%)",
  purpleDark: "hsl(327, 40%, 35%)", //darkPurple: "#7B355C",

  //grays
  grayWhite: "hsl(0, 0%, 95%)", // #F3F3F3
  grayLight: "hsl(225, 9%, 91%)", //"#E6E7EA",
  gray: "hsl(0, 0%, 46%)", //"#757575"
  grayDark: "hsl(159, 54%, 5%)", //"#06140F"

  // old colors
  darkBlue: "#283E4E", // deprecate in favor of blueDark
  boxBorder: "hsl(225, 9%, 91%)", // deprecate in favor of grayLight
  grayTrim: "#DADADA", // deprecate in favor of grayLight
  medGray: "#616680", // deprecate in favor of gray
  lightGray: "#F8FAFC", // - deprecate in favor of whiteGray
  offGray: "#F8FAFC", // deprecate in favor of gray

  //layout and spacing
  tiny: `${baseSpacing}px`,
  small: `${baseSpacing * 2}px`,
  base: `${baseSpacing * 4}px`,
  large: `${baseSpacing * 6}px`,
  xLarge: `${baseSpacing * 8}px`,
};
