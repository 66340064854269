import React, { Component } from "react";
import cn from "classnames";
import { connect } from "react-redux";
import icons from "patient_app/assets/videoCallIcons";
import _ from "lodash";

import { SET_REMOTE_VIDEO_REF } from "patient_app/constants/actionTypes";

const mapDispatchToProps = (dispatch) => {
  return {
    setRemoteVideoRef: (node) =>
      dispatch({ type: SET_REMOTE_VIDEO_REF, remoteVideoRef: node }),
  };
};

const mapStateToProps = (state) => {
  return {};
};

class VideoTrack extends Component {
  showVideo = false;

  constructor(props) {
    super(props);

    this.setWrapperRef = this.setWrapperRef.bind(this);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;

    if (this.props.setRemoteVideo) {
      console.log("set remote");
      this.props.setRemoteVideoRef(node);
    }
  }

  componentDidMount = () => {
    if (this.props.track) {
      this.initTrack();
    }
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.track && !_.isEqual(prevProps.track, this.props.track)) {
      this.removeElements();
    }

    if (!prevProps.track && this.props.track) {
      this.initTrack();
    } else {
      this.updateShowVideo();
    }
  };

  initTrack = () => {
    let { track } = this.props;
    track.on("started", this.updateShowVideo);
    track.on("disabled", this.updateShowVideo);
    track.on("enabled", this.updateShowVideo);
    track.on("dimensionsChanged", this.handleUpdateDimensions);

    this.updateShowVideo();
  };

  updateShowVideo = () => {
    let { track } = this.props;
    this.showVideo = track && track.isStarted && track.isEnabled;

    if (this.showVideo) {
      this.attachDom();
      this.handleUpdateDimensions();
    } else {
      this.removeElements();
    }
  };

  attachDom = () => {
    let { track } = this.props;
    if (this.wrapperRef) {
      this.wrapperRef.appendChild(track.attach());
    }
  };

  handleUpdateDimensions = () => {
    let { track } = this.props;
    if (!track.dimensions.height || !track.dimensions.width) {
      return null;
    }

    this.orientation =
      track.dimensions.height > track.dimensions.width
        ? "portrait"
        : "landscape";
  };

  removeElements = () => {
    const elements = this.wrapperRef
      ? this.wrapperRef.getElementsByTagName("video")
      : [];

    for (let element of elements) {
      element?.remove();
    }
  };

  render() {
    let { track, className } = this.props;

    if (!track) {
      return null;
    }

    return (
      <div className={cn(className, "video-track-container")}>
        <div ref={this.setWrapperRef} className="video-track"></div>

        {!this.showVideo && <img src={icons.face} alt="profile" />}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoTrack);
