import { drugOptions } from "components/questionnaire/constants/options";

let textHelpers = {
  lowercaseFirstLetter(string) {
    return string.charAt(0).toLowerCase() + string.slice(1);
  },
  uppercaseFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },
  insertVariables(string, variable) {
    if (variable === null) {
      return string;
    }

    let variableText = "";
    const option = drugOptions.find((x) => x.value === variable);
    if (option) {
      // let text = textHelpers.lowercaseFirstLetter(option.text)
      // variableText = `use of ${text}`
      variableText = option.variableText;
    } else {
      try {
        variableText = variable.toLowerCase();
      } catch (err) {
        console.log(err);
        variableText = "vice";
      }
    }
    return string.replace(/__var__/g, variableText);
  },
};

export default textHelpers;
