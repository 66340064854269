import Api from "patient_app/api";
import { connect, createLocalTracks } from "twilio-video";

import {
  VR_REQ_START,
  VR_REQ_SUCCESS,
  VR_REQ_FAILURE,
  NEW_ERRORS,
  VR_LOAD,

  // videoCall actions
  VC_TOKEN_LOAD,
  // VC_ZOOM_EVENT_ID_LOAD,
  VC_INITIAL_REQUEST_FAILED,
  VC_UPDATE_STATE,
} from "patient_app/constants/actionTypes";

import {
  ERRORS,
  MEDIA_DEVICE_ERRORS,
  TWILIO_ERROR_CODES,
} from "patient_app/constants/videoCallMessages";
import { combineTracks } from "patient_app/api/videoCallActions";

export const waitForInvite = (userId, queueId, props, callback) => {
  const data = {
    // url: `/video_rooms/wait_for_invite?user_id=${userId}&queue_id=${queueId}`,
    url: `/video_calls/get_token?video_queue_id=${queueId}&client=web`,
    path: `/api/v2`,
    data: {
      method: "GET",
    },
  };

  return async (dispatch) => {
    try {
      const res = await Api.makeRequest(data);
      console.log("waitForInvite res", res);
      if (res?.success) {
        const localTracks = combineTracks(
          props.localAudioTrack,
          props.localVideoTrack
        );
        const room = await connect(res.token, {
          logLevel: "info",
          dominantSpeaker: true,
          tracks: localTracks,
          audio: false,
          video: false,
        });
        dispatch({ type: VC_UPDATE_STATE, key: "room", value: room });
        dispatch({
          type: VC_UPDATE_STATE,
          key: "roomStatus",
          value: "CONNECTING",
        });
        dispatch({ type: VC_UPDATE_STATE, key: "chatId", value: res.chat_id });
        // dispatch({ type: VR_LOAD, chatId: res.chat_id, videoRoom: res.video_room })
        dispatch({ type: VC_TOKEN_LOAD, token: res.token });
        dispatch({
          type: VC_UPDATE_STATE,
          key: "adminType",
          value: res.admin_type,
        });
        dispatch({ type: VC_UPDATE_STATE, key: "videoCall", value: res.call });
        callback(room);
        return;
      }

      if (![null, undefined].includes(res?.position)) {
        dispatch({
          type: VC_UPDATE_STATE,
          key: "queuePosition",
          value: res.position,
        });
      }

      if (!!res && res.locked_out && res.locked_out === true) {
        dispatch({
          type: NEW_ERRORS,
          errors: [
            {
              text: "Cannot start call while failed payments are present. You can adjust your payment settings in your profile.",
            },
          ],
        });
        dispatch({
          type: VC_INITIAL_REQUEST_FAILED,
          error: ERRORS.INITIAL_ERROR,
        });
        return;
      }
    } catch (e) {
      console.log(e);
    }
  };
};

// export const waitForZoomInvite = (userId, queueId, props, callback) => {
//   const data = {
//     url: `/video_calls/get_zoom_event_id?video_queue_id=${queueId}&user_id=${userId}&client=web`,
//     path: `/api/v2`,
//     data: {
//       method: "POST",
//     },
//   };

//   return async (dispatch) => {
//     try {
//       const res = await Api.makeRequest(data);
//       console.log("waitForZoomInvite res", res);
//       if (res?.success) {
//         const localTracks = combineTracks(
//           props.localAudioTrack,
//           props.localVideoTrack
//         );
//         // const room = await connect(res.zoom_event_id, {
//         //   logLevel: "info",
//         //   dominantSpeaker: true,
//         //   tracks: localTracks,
//         //   audio: false,
//         //   video: false,
//         // });
//         // dispatch({ type: VC_UPDATE_STATE, key: "room", value: room });
//         // dispatch({
//         //   type: VC_UPDATE_STATE,
//         //   key: "roomStatus",
//         //   value: "CONNECTING",
//         // });
//         // dispatch({ type: VC_UPDATE_STATE, key: "chatId", value: res.chat_id });
//         // dispatch({ type: VR_LOAD, chatId: res.chat_id, videoRoom: res.video_room })
//         dispatch({ type: VC_ZOOM_EVENT_ID_LOAD, zoomEventId: res.zoom_event_id });
//         // dispatch({
//         //   type: VC_UPDATE_STATE,
//         //   key: "adminType",
//         //   value: res.admin_type,
//         // });
//         // dispatch({ type: VC_UPDATE_STATE, key: "videoCall", value: res.call });
//         // callback(room);
//         return;
//       }

//       if (![null, undefined].includes(res?.position)) {
//         dispatch({
//           type: VC_UPDATE_STATE,
//           key: "queuePosition",
//           value: res.position,
//         });
//       }

//       if (!!res && res.locked_out && res.locked_out === true) {
//         dispatch({
//           type: NEW_ERRORS,
//           errors: [
//             {
//               text:
//                 "Cannot start call while failed payments are present. You can adjust your payment settings in your profile.",
//             },
//           ],
//         });
//         dispatch({
//           type: VC_INITIAL_REQUEST_FAILED,
//           error: ERRORS.INITIAL_ERROR,
//         });
//         return;
//       }
//     } catch (e) {
//       console.log(e);
//     }
//   };
// };

export const disconnectFromRoom = (queueId, userId, zoomEventId) => {
  const data = {
    url: `/video_calls/leave?zoom_event_id=${zoomEventId}`,
    path: `/api/v2`,
    data: {
      method: "POST",
      body: { video_queue_id: queueId },
    },
  };

  return async (dispatch) => {
    try {
      const res = await Api.makeRequest(data);
      console.log("disconnectFromRoom res", res);
    } catch (e) {
      console.log(e);
    }
  };
};
