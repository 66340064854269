export let multipleChoiceOptions = [
  {
    text: "several times a day",
    value: 5,
  },
  {
    text: "daily",
    value: 4,
  },
  {
    text: "every other day",
    value: 3,
  },
  {
    text: "weekly",
    value: 2,
  },
  {
    text: "monthly",
    value: 1,
  },
  {
    text: "not at all",
    value: 0,
  },
];

export let yesNoOptions = [
  {
    text: "Yes",
    value: 1,
  },
  {
    text: "No",
    value: 0,
  },
];

export let drugOptions = [
  { value: 0, text: "Marijuana", variableText: "marijuana use" },
  { value: 1, text: "Alcohol", variableText: "alcohol use" },
  {
    value: 2,
    text: "Opioids (heroin or prescription pain medication)",
    variableText: "opioid use",
  },
  {
    value: 3,
    text: "Stimulants (cocaine, methamphetamine, uppers)",
    variableText: "use of stimulants",
  },
  {
    value: 4,
    text: "Hallucinogens (LSD, MDMA, psilocybin)",
    variableText: "use of hallucinogens",
  },
  {
    value: 5,
    text: "Sedatives or sleeping pills",
    variableText: "use of sedatives",
  },
  {
    value: 6,
    text: "Other drugs or multiple drugs",
    variableText: "use of other drugs",
  },
];

export const fetchAUDOptions = (key) => {
  let options;
  switch (key) {
    case "AUD-1":
      options = [
        {
          text: "Never",
          value: 0,
        },
        {
          text: "Monthly or less",
          value: 1,
        },
        {
          text: "2 to 4 times a month",
          value: 2,
        },
        {
          text: "2 to 3 times a week",
          value: 3,
        },
        {
          text: "4 or more times a week",
          value: 4,
        },
      ];
      break;
    case "AUD-2":
      options = [
        {
          text: "1 or 2",
          value: 0,
        },
        {
          text: "3 or 4",
          value: 1,
        },
        {
          text: "5 or 6",
          value: 2,
        },
        {
          text: "7, 8, or 9",
          value: 3,
        },
        {
          text: "10 or more",
          value: 4,
        },
      ];
      break;
    case "AUD-9":
    case "AUD-10":
      options = [
        {
          text: "No",
          value: 0,
        },
        {
          text: "Yes, but not in the last year",
          value: 1,
        },
        {
          text: "Yes, during the last year",
          value: 2,
        },
      ];
      break;
    default:
      options = [
        {
          text: "Never",
          value: 0,
        },
        {
          text: "Less than monthly",
          value: 1,
        },
        {
          text: "Monthly",
          value: 2,
        },
        {
          text: "Weekly",
          value: 3,
        },
        {
          text: "Daily or almost daily",
          value: 4,
        },
      ];
      break;
  }

  return options;
};
