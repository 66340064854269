import PropTypes from "prop-types";
import React from "react";
import cn from "classnames";
import { mobileCheck } from "patient_app/helpers/supported";
import { getIconUrl } from "patient_app/helpers/linkHelpers";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imgLoading: true,
    };
  }

  render() {
    let {
      title,
      showEmergencyBanner,
      showEmergencyButton,
      canGoBack,
      onToggleSearch,
      showSearchButton,
      showSearchBar,
      q,
      onChange,
      onFocus,
      onBlur,
      onKeyDown,
      searchIsFocused,
      bioLink,
      tabIndex,
    } = this.props;

    const mobile = mobileCheck();
    return (
      <div
        className={cn(
          "header",
          showSearchButton && showSearchBar ? "search" : ""
        )}
        id="header"
      >
        <div className="inner-header">
          <div className="left">
            {(!mobile || (mobile && canGoBack)) && (
              <button
                onClick={(e) => this.props.onGoBack()}
                className={cn(
                  "go-back",
                  "custom-button",
                  canGoBack ? "" : "inactive"
                )}
                aria-label="Click to go back to chats"
                tabIndex={tabIndex}
              >
                <img
                  src={getIconUrl("chevron-left", "dark-blue")}
                  alt="back"
                  className={cn(canGoBack ? "shown" : "")}
                />
              </button>
            )}

            {mobile && !canGoBack && (
              <button
                onClick={(e) => this.props.onToggleChats()}
                aria-label="Click to go back to chats"
                className="custom-button"
                tabIndex={tabIndex}
              >
                <img
                  src={getIconUrl("exit", "dark-blue")}
                  alt="Go Back"
                  className="toggle-chats-mobile shown"
                />
              </button>
            )}

            {/*
  	    			this i is a filler to keep the title
  	    			centered until I find a more elegant solution.
  	    			It has an opacity of 0 due to the lack of a 'shown' class.
  	    		*/}
            <img src={getIconUrl("chevron-left", "dark-blue")} alt="Go Back" />
          </div>

          {bioLink.length > 0 ? (
            <div className="title">
              <a
                href={bioLink}
                aria-label="click to show provider information"
                target="_blank"
                tabIndex={tabIndex}
              >
                {title}
                <img
                  src={getIconUrl("chevron-right", "dark-blue")}
                  alt="right_arrow"
                  className="shown provider-bio"
                />
              </a>
            </div>
          ) : (
            <div className="title">{title}</div>
          )}

          <div className="right">
            <button
              onClick={(e) => this.props.onToggleSearch()}
              aria-label="Click to search chat messages"
              className={cn(
                "custom-button",
                "search",
                showSearchButton ? "" : "hide"
              )}
              tabIndex={tabIndex}
            >
              <img src={getIconUrl("search", "dark-blue")} alt="Search Chats" />
            </button>

            <button
              onClick={(e) => this.props.onToggleEmergencyBanner()}
              className="emergency-button"
              tabIndex={tabIndex}
              aria-label="click to show emergency information"
            >
              <img
                src={getIconUrl("emergency", "dark-blue")}
                alt="Open Emergency Information"
              />
            </button>
          </div>
        </div>

        <div
          className={cn(
            showSearchBar && showSearchButton ? "search-focus" : ""
          )}
        >
          {showSearchBar && showSearchButton && (
            <div className="search-components">
              <input
                value={q}
                onFocus={(e) => onFocus(e)}
                onBlur={(e) => onBlur(e)}
                onChange={(e) => onChange(e.target.value)}
                onKeyDown={(e) => onKeyDown(e)}
                className={cn(
                  "search-bar",
                  searchIsFocused || q.length >= 0 ? "search-bar-focus" : ""
                )}
                placeholder="Search for chats or messages"
                tabIndex={tabIndex}
                aria-label="Search for chats or messages"
              />
            </div>
          )}
          {showSearchBar &&
            showSearchButton &&
            (searchIsFocused || q.length >= 0) && (
              <button
                className="custom-button cancel-search"
                onClick={(e) => this.props.onCancel(e)}
                tabIndex={tabIndex}
                aria-label="cancel search for chat messages"
              >
                <p>Cancel</p>
              </button>
            )}
        </div>
      </div>
    );
  }
}

export default Header;
