import PropTypes from "prop-types";
import React from "react";
import { Provider } from "react-redux";
import { store, history } from "patient_app/store";
import ErrorBoundary from "patient_app/components/utils/ErrorBoundary";
import MasterRouter from "patient_app/routers/MasterRouter";
import trackerActions from "patient_app/helpers/trackerActions";

class App extends React.Component {
  constructor(props) {
    super(props);
    trackerActions.configureMixpanel();
  }

  render() {
    return (
      <Provider store={store}>
        <ErrorBoundary>
          <MasterRouter />
        </ErrorBoundary>
      </Provider>
    );
  }
}

export default App;
