import alcoholCheck from "patient_app/reducers/alcoholCheck";
import appointments from "patient_app/reducers/appointments";
import chat from "patient_app/reducers/chat";
import clinic from "patient_app/reducers/clinic";
import common from "patient_app/reducers/common";
import curriculum from "patient_app/reducers/curriculum";
import drugTestOrder from "patient_app/reducers/drugTestOrder";
import emergencyContact from "patient_app/reducers/emergencyContact";
import gtc from "patient_app/reducers/gtc";
import insurance from "patient_app/reducers/insurance";
import onboarding from "patient_app/reducers/onboarding";
import pendingItem from "patient_app/reducers/pendingItem";
import profile from "patient_app/reducers/profile";
import randomDrugTest from "patient_app/reducers/randomDrugTest";
import session from "patient_app/reducers/session";
import treatmentPlan from "patient_app/reducers/treatmentPlan";
import video from "patient_app/reducers/video";
import videoCall from "patient_app/reducers/videoCall";

//import auth from './reducers/auth';
import { combineReducers } from "redux";

export default combineReducers({
  alcoholCheck,
  appointments,
  common,
  chat,
  clinic,
  curriculum,
  drugTestOrder,
  emergencyContact,
  gtc,
  insurance,
  onboarding,
  pendingItem,
  profile,
  randomDrugTest,
  session,
  treatmentPlan,
  video,
  videoCall,
});
