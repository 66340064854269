import PropTypes from "prop-types";
import React from "react";
import Item from "patient_app/views/curriculums/partials/Item";
import SubBanner from "patient_app/views/curriculums/partials/SubBanner";
import curriculumHelper from "patient_app/helpers/curriculumHelper";

class Cluster extends React.Component {
  static propTypes = {
    section: PropTypes.object,
  };

  render() {
    let { cluster } = this.props;

    return (
      <div className="cluster">
        {cluster.title && <SubBanner title={cluster.title} />}

        <div className="items">
          <ul>{this.renderItems(cluster.exerciseItems)}</ul>
        </div>
      </div>
    );
  }

  renderItems = (items) => {
    let { ...props } = this.props;
    if (!items) return;

    let count = 1;
    const itemsLength = items.length;

    return items.map((item, i) => {
      let lineBreak = true;
      if (items.length === 2) {
        lineBreak = false;
      } else {
        if (count == 2) {
          lineBreak = false;
        }
        count += 1;
        if (count == 4) {
          count = 1;
        }
      }

      //don't need a line break for the end of a cluster
      if (itemsLength === i + 1) {
        lineBreak = false;
      }

      return [
        <Item item={item} key={i} position={i + 1} />,
        lineBreak && (
          <li key={`break-${i}`}>
            <br />
          </li>
        ),
      ];
    });
  };
}

export default Cluster;
