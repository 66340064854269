import React, { Component } from "react";
import { connect } from "react-redux";

import { askForMedia } from "patient_app/api/videoCallActions";

const mapDispatchToProps = (dispatch) => {
  return {
    askForMedia: () => {
      dispatch(askForMedia());
    },
  };
};

const mapStateToProps = (state) => {
  return {};
};

class MediaPrompt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      didRequest: false,
    };
  }

  componentDidMount = () => {};

  componentWillUnmount = () => {};

  render() {
    let { didRequest } = this.state;

    return (
      <div className="media-prompt prompt">
        <p className="spaced">
          Allow access to the camera and microphone to join your call.
        </p>
        <button onClick={() => this.onClick()}>
          Allow Microphone & Video Access
        </button>

        {didRequest && <p>click "Allow"</p>}
      </div>
    );
  }

  onClick = () => {
    this.setState({ didRequest: true });
    this.props.askForMedia();
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MediaPrompt);
