import PropTypes from "prop-types";
import React from "react";
import cn from "classnames";

import { connect } from "react-redux";
import Cleave from "cleave.js/react";
import "cleave.js/dist/addons/cleave-phone.us";
import CleavePhone from "cleave.js/dist/addons/cleave-phone.i18n";
import layout from "patient_app/stylesheets/onboarding/layout";

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {
    fieldSpecificErrors: state.common.fieldSpecificErrors,
  };
};

class OnboardingField extends React.Component {
  render() {
    let {
      field,
      fieldSpecificErrors,
      fieldType,
      formatting,
      options,
      required,
      showBlankOption,
      title,
      value,
    } = this.props;

    const error = fieldSpecificErrors[field];
    let showLabel = true;
    if (fieldType === "checkbox") {
      showLabel = false;
    }

    return (
      <div className={`input-field ${fieldType} ${field}`}>
        <div className="field-row">
          {showLabel && (
            <label htmlFor={field} onClick={this.maybeFocusSelect}>
              {title} {required && <span className="light">(Required)</span>}
            </label>
          )}

          {fieldType === "select" && (
            <select
              id={field}
              value={value ? value : ""}
              onChange={(e) => this.props.onUpdateField(e.target.value, field)}
              aria-invalid={error ? true : false}
              aria-describedby={error && `aria-describedby-${field}`}
              ref={(ref) => (this.selectRef = ref)}
            >
              {(showBlankOption || showBlankOption === undefined) && (
                <option value=""></option>
              )}
              {options &&
                options.length > 0 &&
                options.map((x, i) => {
                  return (
                    <option key={i} value={typeof x === "string" ? x : x[1]}>
                      {typeof x === "string" ? x : x[0]}
                    </option>
                  );
                })}
            </select>
          )}

          {fieldType === "text" && (
            <input
              id={field}
              value={value ? value : ""}
              onChange={(e) => this.props.onUpdateField(e.target.value, field)}
              onKeyDown={this.props.onKeyPress}
              type="text"
              aria-invalid={error ? true : false}
              aria-describedby={error && `aria-describedby-${field}`}
            />
          )}

          {fieldType === "email" && (
            <input
              id={field}
              value={value ? value : ""}
              onChange={(e) => this.props.onUpdateField(e.target.value, field)}
              onKeyDown={this.props.onKeyPress}
              type="email"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
              aria-invalid={error ? true : false}
              aria-describedby={error && `aria-describedby-${field}`}
            />
          )}

          {fieldType === "textarea" && (
            <textarea
              id={field}
              value={value ? value : ""}
              onChange={(e) => this.props.onUpdateField(e.target.value, field)}
              type="text"
              aria-invalid={error ? true : false}
              aria-describedby={error && `aria-describedby-${field}`}
            />
          )}

          {fieldType === "password" && (
            <input
              id={field}
              value={value ? value : ""}
              onChange={(e) => this.props.onUpdateField(e.target.value, field)}
              onKeyDown={this.props.onKeyPress}
              type="password"
              aria-invalid={error ? true : false}
              aria-describedby={error && `aria-describedby-${field}`}
            />
          )}

          {fieldType === "date" && (
            <Cleave
              id={field}
              type="text"
              options={{
                date: true,
                datePattern: ["m", "d", "Y"],
                dateMin: "1700-01-01",
                dateMax: "2050-01-01",
              }}
              value={value ? value : ""}
              onChange={(e) => this.props.onUpdateField(e.target.value, field)}
              onKeyDown={this.props.onKeyPress}
              aria-invalid={error ? true : false}
              aria-describedby={error && `aria-describedby-${field}`}
            />
          )}

          {fieldType === "checkbox" && (
            <div className="checkbox">
              <input
                id={field}
                type="checkbox"
                checked={value ? value : false}
                onChange={(e) =>
                  this.props.onUpdateField(e.target.checked, field)
                }
                aria-invalid={error ? true : false}
                aria-describedby={error && `aria-describedby-${field}`}
              />
              <label htmlFor={field}>{title}</label>
            </div>
          )}

          {fieldType === "zipCode" && (
            <Cleave
              id={field}
              type="text"
              maxLength="5"
              options={{ numericOnly: true }}
              value={value ? value : ""}
              onChange={(e) => this.props.onUpdateField(e.target.value, field)}
              onKeyDown={this.props.onKeyPress}
              aria-invalid={error ? true : false}
              aria-describedby={error && `aria-describedby-${field}`}
            />
          )}

          {fieldType === "phone" && (
            <Cleave
              id={field}
              type="text"
              options={{ phone: true, phoneRegionCode: "us" }}
              value={value ? value : ""}
              onChange={(e) => this.props.onUpdateField(e.target.value, field)}
              onKeyDown={this.props.onKeyPress}
              aria-invalid={error ? true : false}
              aria-describedby={error && `aria-describedby-${field}`}
            />
          )}

          {fieldType === "custom" && (
            <Cleave
              id={field}
              type="text"
              options={formatting}
              value={value ? value : ""}
              onChange={(e) => this.props.onUpdateField(e.target.value, field)}
              onKeyDown={this.props.onKeyPress}
              aria-invalid={error ? true : false}
              aria-describedby={error && `aria-describedby-${field}`}
            />
          )}
        </div>

        {error && <p className="field-error">{error}</p>}

        <style jsx>{layout}</style>
      </div>
    );
  }

  maybeFocusSelect = () => {
    if (this.props.fieldType === "select") {
      this.selectRef.focus();
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingField);
