import PropTypes from "prop-types";
import React from "react";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { fetchPayment } from "patient_app/api/profileActions";
import {
  NEW_FIELD_SPECIFIC_ERROR,
  NEW_ERRORS,
  CLEAR_FIELD_SPECIFIC_ERRORS,
} from "patient_app/constants/actionTypes";

import LoadingSpinner from "patient_app/components/utils/LoadingSpinner";
import SubTabBack from "patient_app/views/profiles/partials/SubTabBack";

import { mobileCheck } from "patient_app/helpers/supported";
import permissions from "patient_app/helpers/permissions";

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPayment: (profileId, params) =>
      dispatch(fetchPayment(profileId, params)),
  };
};

const mapStateToProps = (state) => {
  return {
    paymentInfo: state.profile.paymentInfo,
    user: state.common.user,
  };
};

class MembershipPlan extends React.Component {
  profileId = this.props.match.params.id;

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (mobileCheck()) {
      this.props.fetchPayment(this.profileId, ["membership_plan"]);
    }
  }

  componentDidUpdate(prevProps) {}

  render() {
    let { paymentInfo, user } = this.props;

    return (
      <div>
        <SubTabBack />
        <div className="profile-section">
          <h1>Your Membership Plan</h1>

          {!(user && paymentInfo) && <LoadingSpinner />}

          {user && paymentInfo && (
            <div className="paragraph">
              <p>
                <span>
                  Workit {permissions.isClinic(user) ? "Clinic" : "Therapy"}
                </span>
              </p>

              {paymentInfo.plan_description && (
                <p>
                  <span>{paymentInfo.plan_description}</span>
                </p>
              )}

              {paymentInfo.activated_at && (
                <p>
                  <span>Activation: {paymentInfo.activated_at}</span>
                </p>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MembershipPlan)
);
