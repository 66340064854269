import PropTypes from "prop-types";
import React from "react";
import { DateTime } from "luxon";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  fetchDocuments,
  createROI,
  updateROI,
} from "patient_app/api/profileActions";
import {
  NEW_FIELD_SPECIFIC_ERROR,
  NEW_ERRORS,
  CLEAR_FIELD_SPECIFIC_ERRORS,
} from "patient_app/constants/actionTypes";

import ProfileField from "patient_app/views/profiles/partials/ProfileField";
import MultiSelectField from "patient_app/views/gtc/MultiSelectField";
import LoadingSpinner from "patient_app/components/utils/LoadingSpinner";
import SubTabBack from "patient_app/views/profiles/partials/SubTabBack";

import { getUrlVars, getIconUrl } from "patient_app/helpers/linkHelpers";
import { mobileCheck } from "patient_app/helpers/supported";
import { variables } from "patient_app/stylesheets/variables";

const mapDispatchToProps = (dispatch) => {
  return {
    fetchDocuments: (profileId, params) =>
      dispatch(fetchDocuments(profileId, params)),
    createROI: (roi, oldId) => dispatch(createROI(roi, oldId)),
    updateROI: (roi) => dispatch(updateROI(roi)),
    newFieldSpecificError: (field, error) =>
      dispatch({ type: NEW_FIELD_SPECIFIC_ERROR, field: field, error: error }),
    newGeneralErrors: (errors) =>
      dispatch({ type: NEW_ERRORS, errors: errors }),
    clearFieldSpecificErrors: () =>
      dispatch({ type: CLEAR_FIELD_SPECIFIC_ERRORS }),
  };
};

const mapStateToProps = (state) => {
  return {
    loading: state.profile.loading,
    rois: state.profile.rois,
    success: state.profile.success,
    user: state.common.user,
  };
};

class ReleaseOfInfo extends React.Component {
  profileId = this.props.match.params.id;
  roiTemplate = {
    consent_release: true,
    consent_receive: true,
    name: "",
    address: "",
    phone: "",
    email: "",
    purpose: "",
    allowed_information: [],
    expires_at: DateTime.local().plus({ years: 1 }).toFormat("LL/dd/yyyy"),
    signature: "",
    agree: false,
  };
  allowedOptions = [
    "All program information",
    "Assessment",
    "Diagnosis",
    "Psychosocial Evaluation",
    "SUD Treatment Plan or Summary",
    "Current Treatment Update",
    "Medication Management Information",
    "Presence/Participation in Treatment",
    "Nursing/Medical Information",
    "Toxicological Reports/Drug Screens",
    "Discharge/Transfer Summary",
    "Progress in Treatment",
    "Demographic Information",
    "Mental Health",
    "HIV Status",
    "Hep-C Status",
  ];

  constructor(props) {
    super(props);
    this.state = {
      creating: false,
      expanded: [],
      newROI: this.roiTemplate,
    };
  }

  componentDidMount() {
    if (mobileCheck()) {
      this.props.fetchDocuments(this.profileId, ["roi"]);
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.success && this.props.success) {
      this.handleCancel();
    }
  }

  render() {
    let { loading, rois, user } = this.props;
    let { creating, newROI } = this.state;

    return (
      <div>
        <SubTabBack />
        <div className="profile-section">
          <div className="header">
            <div>
              <h1>Release of Information (ROI)</h1>
              {/* {!creating && (
                <p>
                  Individuals or organizations that you have granted access to
                  your information.
                </p>
              )} */}
            </div>

            {creating && (
              <button
                className="custom-button section-action"
                onClick={this.handleCancel}
              >
                Cancel
              </button>
            )}
          </div>

          {!rois && <LoadingSpinner />}

          {rois && !creating && (
            <div>
              {/* <div className="profile-list roi">{this.renderROIs()}</div> */}

              <a
                className="link"
                href="https://www.workithealth.com/roi/"
                target="blank"
                style={{ fontFamily: "obj3-med, arial" }}
              >
                Complete Release of Information
              </a>
              {/* <button
                className="custom-button link"
                onClick={() => this.setState({ creating: true })}
              >
                Request Release of Information
              </button> */}
            </div>
          )}

          {/* {creating && this.renderROIForm()} */}
        </div>

        <style jsx>{`
          :global(.profile-field.consent-release) {
            margin-bottom: 0;
          }

          :global(.profile-field.checkbox label) {
            font-family: ${variables.objReg};
          }

          :global(.profile-field.has-subtitle label),
          :global(.gtc-multi-select.has-subtitle label) {
            margin-bottom: 0;
          }

          .roi-form p span.red {
            font-family: ${variables.objReg};
            color: ${variables.red};
          }
        `}</style>
      </div>
    );
  }

  // renderROIForm = (roi = null) => {
  //   const { loading, user } = this.props;
  //   const { newROI } = this.state;
  //   // if roi is a param, then this renders an existing roi;
  //   // otherwise, it is a form for creating a new roi

  //   const name = roi?.signature_obj
  //     ? roi.signature_obj.text_sig
  //     : `${user?.fir_name.trim()} ${user?.las_name.trim()}`;

  //   return (
  //     <div className="roi-form">
  //       <p>I, {name}, voluntarily consent to allow Workit Health to:</p>
  //       {roi ? (
  //         <p>
  //           Release my health information: {roi.consent_release ? "Yes" : "No"}
  //         </p>
  //       ) : (
  //         <ProfileField
  //           className="consent-release"
  //           field="consent_release"
  //           fieldType="checkbox"
  //           onUpdateField={this.handleUpdateField}
  //           title="Release my health information"
  //           value={newROI.consent_release}
  //         />
  //       )}

  //       {roi ? (
  //         <p className="mb-16">
  //           Receive my health information: {roi.consent_receive ? "Yes" : "No"}
  //         </p>
  //       ) : (
  //         <ProfileField
  //           field="consent_receive"
  //           fieldType="checkbox"
  //           onUpdateField={this.handleUpdateField}
  //           title="Receive my health information"
  //           value={newROI.consent_receive}
  //         />
  //       )}

  //       <p className="mb-16">
  //         My information is released or received, based on selections above,
  //         during the specified term to/from to recipient below:
  //       </p>

  //       {roi ? (
  //         <p>Name: {roi.name}</p>
  //       ) : (
  //         <ProfileField
  //           field="name"
  //           fieldType="text"
  //           onUpdateField={this.handleUpdateField}
  //           title="Their Name"
  //           value={newROI.name}
  //         />
  //       )}

  //       {roi ? (
  //         <p>Address: {roi.address}</p>
  //       ) : (
  //         <ProfileField
  //           field="address"
  //           fieldType="text"
  //           onUpdateField={this.handleUpdateField}
  //           title="Their Address"
  //           value={newROI.address}
  //         />
  //       )}

  //       {roi ? (
  //         <p>Phone/Fax: {roi.phone}</p>
  //       ) : (
  //         <ProfileField
  //           field="phone"
  //           fieldType="phone"
  //           onUpdateField={this.handleUpdateField}
  //           title="Their Phone/Fax"
  //           value={newROI.phone}
  //         />
  //       )}

  //       {roi ? (
  //         <p className="mb-16">Email: {roi.email}</p>
  //       ) : (
  //         <ProfileField
  //           field="email"
  //           fieldType="text"
  //           onUpdateField={this.handleUpdateField}
  //           title="Their Email"
  //           value={newROI.email}
  //         />
  //       )}

  //       {roi ? (
  //         <div>
  //           <p>
  //             <span>Purpose</span>
  //           </p>
  //           <p className="mb-16">
  //             I authorize this release of information for the following purpose:{" "}
  //             {roi.purpose}
  //           </p>
  //         </div>
  //       ) : (
  //         <ProfileField
  //           className="has-subtitle"
  //           field="purpose"
  //           fieldType="text"
  //           onUpdateField={this.handleUpdateField}
  //           showSubtitle={true}
  //           title="Purpose"
  //           value={newROI.purpose}
  //         >
  //           <p>
  //             I authorize this release of information for the following purpose:
  //           </p>
  //         </ProfileField>
  //       )}

  //       {roi ? (
  //         <div>
  //           <p>
  //             <span>Information to be Dislosed</span>
  //           </p>
  //           <p className="mb-16">
  //             I authorize the release of the following information (select
  //             options that apply): {roi.allowed_information.join(", ")}
  //           </p>
  //         </div>
  //       ) : (
  //         <MultiSelectField
  //           className="has-subtitle"
  //           field="allowed_information"
  //           isObject={false}
  //           options={this.allowedOptions}
  //           onUpdateField={this.handleUpdateField}
  //           showSubtitle={true}
  //           title="Information to be Disclosed"
  //           value={newROI.allowed_information}
  //         >
  //           <p>
  //             I authorize the release of the following information (select{" "}
  //             <span className="red">options</span> that apply):
  //           </p>
  //         </MultiSelectField>
  //       )}

  //       {roi ? (
  //         <div>
  //           <p>
  //             <span>Term</span>
  //           </p>
  //           <p className="mb-16">
  //             This authorization will remain in effect until the following date
  //             (date cannot exceed one year):{" "}
  //             {roi.expires_at
  //               ? DateTime.fromISO(roi.expires_at).toFormat("LLLL d, y")
  //               : ""}
  //           </p>
  //         </div>
  //       ) : (
  //         <ProfileField
  //           className="has-subtitle"
  //           field="expires_at"
  //           fieldType="date"
  //           onUpdateField={this.handleUpdateField}
  //           showSubtitle={true}
  //           title="Term"
  //           value={newROI.expires_at}
  //         >
  //           <p>
  //             This authorization will remain in effect until the following date
  //             (date cannot exceed one year):
  //           </p>
  //         </ProfileField>
  //       )}

  //       <p className="mb-16">
  //         I understand that my substance use disorder records are protected
  //         under the Federal regulations governing Confidentiality and Substance
  //         Use Disorder Patient Records, 42 C.F.R. Part 2, and the Health
  //         Insurance Portability and Accountability Act of 1996 (“HIPAA”), 45
  //         C.F.R. pts 160 & 164, and cannot be disclosed without my written
  //         consent unless otherwise provided for by the regulations.
  //       </p>

  //       <p className="mb-16">
  //         <span>Revocation</span>
  //         <br />I understand that I have a right to revoke this authorization,
  //         in writing, at any time by sending written notification to Workit
  //         Health at support@workithealth.com. I further understand that a
  //         revocation of the authorization is not effective to the extent that
  //         action has been taken in reliance on the authorization.
  //       </p>

  //       <p className="mb-16">
  //         <span>Redisclosure</span>
  //         <br />
  //         Federal law prohibits the person or organization to whom disclosure is
  //         made from making any further disclosure of substance abuse treatment
  //         information unless further disclosure is expressly permitted by the
  //         written authorization of the person to whom it pertains or as
  //         otherwise permitted by 42 C.F.R. Part 2.
  //       </p>

  //       <p className="mb-16">
  //         <i>
  //           This authorization does not extend to HIV test results, but due to
  //           the nature of the Workit Health program does include information
  //           regarding substance abuse.
  //         </i>
  //       </p>

  //       {roi ? (
  //         <div>
  //           <p>
  //             Electronically signed by:{" "}
  //             {roi.signature_obj ? roi.signature_obj.text_sig : ""}
  //           </p>
  //           <p>
  //             Signed on:{" "}
  //             {roi.signature_obj
  //               ? DateTime.fromISO(roi.signature_obj.signed_at).toFormat(
  //                   "LL/dd/yy"
  //                 )
  //               : ""}
  //           </p>
  //         </div>
  //       ) : (
  //         <ProfileField
  //           field="signature"
  //           fieldType="text"
  //           onUpdateField={this.handleUpdateField}
  //           title="Signature of Client (Your Signature)"
  //           value={newROI.signature}
  //         />
  //       )}

  //       {!roi && (
  //         <ProfileField
  //           field="agree"
  //           fieldType="checkbox"
  //           onUpdateField={this.handleUpdateField}
  //           title="I agree to electronically sign this document."
  //           value={newROI.agree}
  //         />
  //       )}

  //       {!roi && (
  //         <button
  //           className={`custom-button submit half ${loading ? "loading" : ""}`}
  //           onClick={this.handleSubmit}
  //         >
  //           Save Changes
  //         </button>
  //       )}

  //       <style jsx>{`
  //         p.mb-16 {
  //           margin-bottom: 16px;
  //         }
  //       `}</style>
  //     </div>
  //   );
  // };

  renderROIs() {
    if (this.props.rois.length === 0) {
      return (
        <div className="list-item none">
          <div className="row">
            <p>No releases of information on file.</p>
          </div>
        </div>
      );
    }

    let renewId = getUrlVars(window.location.href)["release_of_info_id"];
    renewId = !!renewId ? parseInt(renewId) : null;
    console.log("renewId", renewId);

    return this.props.rois.map((roi, i) => {
      const showDetails = this.state.expanded.includes(roi.id);
      const infoString =
        roi.allowed_information.length > 0
          ? roi.allowed_information.join(", ")
          : "None";

      const expired =
        roi.expires_at &&
        DateTime.fromISO(roi.expires_at).endOf("day") <
          DateTime.local().endOf("day");

      return (
        <div className="list-item" key={i} data-id={roi.id}>
          <div className="row">
            <p>
              <span>{roi.name}</span>
              {expired && " — Expired"}
              {!expired && roi.revoked && " — Revoked"}
            </p>
            <div className="col align-right">
              {renewId === roi.id && (
                <button
                  className="custom-button item-action"
                  onClick={() => this.handleRenew(roi)}
                >
                  Renew
                </button>
              )}

              {!roi.revoked && (
                <button
                  className="custom-button item-action red"
                  onClick={() => this.handleRevoke(roi)}
                >
                  Revoke
                </button>
              )}

              {roi.revoked && (
                <button
                  className="custom-button item-action red"
                  onClick={() => this.handleRenew(roi)}
                >
                  Reauthorize
                </button>
              )}

              <a
                className="item-action"
                href={roi.file_url}
                target="_blank"
                style={
                  roi.file_url ? {} : { opacity: "0", pointerEvents: "none" }
                }
              >
                <img src={getIconUrl("download", "dark-blue")} alt="Download" />
              </a>

              <button
                className={`custom-button item-action more ${
                  showDetails ? "expanded" : ""
                }`}
                onClick={() => this.toggleDetails(roi.id)}
              >
                <img
                  src={getIconUrl("chevron-right", "dark-blue")}
                  alt="View"
                />
              </button>
            </div>
          </div>

          {showDetails && (
            <div className="expanded-details">{this.renderROIForm(roi)}</div>
          )}
        </div>
      );
    });
  }

  toggleDetails = (id) => {
    let arr = this.state.expanded;
    const idx = arr.findIndex((i) => i === id);
    if (idx > -1) {
      arr.splice(idx, 1);
    } else {
      arr.push(id);
    }
    this.setState({ expanded: arr });
  };

  handleRevoke = (roi) => {
    this.props.updateROI({ ...roi, revoked: true });
  };

  handleRenew = (roi) => {
    const newROI = {
      ...roi,
      id: null,
      expires_at: DateTime.local().plus({ years: 1 }).toFormat("LL/dd/yyyy"),
      signature: "",
      agree: false,
      revoked: false,
    };
    this.setState({ newROI: newROI, creating: true });
  };

  handleUpdateField = (value, field) => {
    this.setState({ newROI: { ...this.state.newROI, [field]: value } });
  };

  handleCancel = () => {
    this.setState({
      creating: false,
      newROI: this.roiTemplate,
    });
  };

  handleSubmit = () => {
    let { newROI } = this.state;
    this.props.clearFieldSpecificErrors();
    this.props.newGeneralErrors([]);
    let hasErrors = false;

    if (!newROI.name || !newROI.name.length) {
      this.props.newFieldSpecificError(
        "name",
        "Name must be present and at least 3 characters."
      );
      hasErrors = true;
    }

    if (
      !newROI.email ||
      !newROI.email.includes("@") ||
      !newROI.email.includes(".")
    ) {
      this.props.newFieldSpecificError("email", "Please enter valid email.");
      hasErrors = true;
    }

    if (!newROI.phone?.length || newROI.phone.length < 11) {
      this.props.newFieldSpecificError(
        "phone",
        "Please enter valid phone number."
      );
      hasErrors = true;
    }

    if (!newROI.allowed_information.length) {
      this.props.newFieldSpecificError(
        "allowed_information",
        "Please select at least one option."
      );
      hasErrors = true;
    }

    const today = DateTime.local().endOf("day");
    const expiresAt = DateTime.fromFormat(
      newROI.expires_at,
      "LL/dd/yyyy"
    ).setZone(today.zone);
    if (!expiresAt.isValid) {
      this.props.newFieldSpecificError(
        "expires_at",
        "Please enter a valid date (mm/dd/yyyy)."
      );
      hasErrors = true;
    } else if (expiresAt.endOf("day") > today.plus({ years: 1 })) {
      this.props.newFieldSpecificError(
        "expires_at",
        "Expiration date cannot exceed one year."
      );
      hasErrors = true;
    } else if (expiresAt.endOf("day") <= today) {
      this.props.newFieldSpecificError(
        "expires_at",
        "Expiration date must be in the future."
      );
    }

    if (!newROI.signature) {
      this.props.newFieldSpecificError(
        "signature",
        "Please sign your legal name."
      );
      hasErrors = true;
    }
    if (!newROI.agree) {
      this.props.newFieldSpecificError(
        "agree",
        "Please check the box to sign this document."
      );
      hasErrors = true;
    }

    if (hasErrors) {
      this.props.newGeneralErrors([{ text: "Please fix the issues below." }]);
      return;
    }

    let roi = { ...newROI, expires_at: expiresAt.toISO() };
    let renewId = getUrlVars(window.location.href)["release_of_info_id"];
    this.props.createROI(roi, renewId);
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ReleaseOfInfo)
);
