import css from "styled-jsx/css";

export default css.global`
  .Mfa-Config-Container .canvas-container {
    margin: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-align: center;
  }

  .Mfa-Config-Container .mfa-submit {
    margin: auto;
    display: block;
    margin-top: 1rem;
  }

  .Mfa-Setup-Container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .Mfa-Setup-Container p.required {
    width: 100%;
  }
`;
