import PropTypes from "prop-types";
import React from "react";
import cn from "classnames";

import { Router, Route, Switch, Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  SESSION_USERID_UPDATE,
  NEW_FORM_ERRORS,
  NEW_FIELD_SPECIFIC_ERROR,
  CLEAR_FIELD_SPECIFIC_ERRORS,
} from "patient_app/constants/actionTypes";

import FormErrors from "patient_app/components/utils/FormErrors";

import { checkUserId } from "patient_app/api/sessionActions";

import onboardingAssets from "patient_app/assets/onboarding";

import MainLayout from "patient_app/components/layouts/MainLayout";
import ContentMain from "patient_app/components/forms/ContentMain";
import ContentBox from "patient_app/components/forms/ContentBox";
import Errors from "patient_app/components/utils/Errors";
import OnboardingField from "patient_app/views/onboarding/partials/OnboardingField";

import SubmitButton from "patient_app/components/buttons/SubmitButton";
import SelectButton from "patient_app/components/buttons/SelectButton";
import TextDivider from "patient_app/components/forms/TextDivider";
//import GoogleButton from 'patient_app/components/buttons/GoogleButton';
import CookieBanner from "patient_app/components/utils/CookieBanner";

import validation from "patient_app/helpers/validation";

import layout from "patient_app/stylesheets/onboarding/layout";

const mapDispatchToProps = (dispatch) => {
  return {
    checkUserId: (userId, history) => dispatch(checkUserId(userId, history)),
    updateUserId: (userId) =>
      dispatch({ type: SESSION_USERID_UPDATE, userId: userId }),
    onGeneralErrors: (errors) =>
      dispatch({ type: NEW_FORM_ERRORS, errors: errors }),
    onError: (field, error) =>
      dispatch({ type: NEW_FIELD_SPECIFIC_ERROR, field: field, error: error }),
  };
};

const mapStateToProps = (state) => {
  return {
    loading: state.common.loading,
    errors: state.common.errors,
    userId: state.session.userId,
    showSignUpAction: state.session.showSignUpAction,
  };
};

class New extends React.Component {
  componentDidMount() {
    document.title = "Sign In | Workit Health";
  }

  render() {
    let { loading, errors, userId, showSignUpAction } = this.props;

    return (
      <MainLayout>
        <ContentMain>
          <ContentBox className="onboarding new_session">
            <div className="inputs">
              <h1>Sign In</h1>

              <FormErrors />

              <OnboardingField
                field="userId"
                fieldType="email"
                required={true}
                title="Phone number or email"
                value={userId}
                onKeyPress={this.onKeyPress}
                onUpdateField={this.handleUpdateField}
              />

              <SubmitButton
                text={"Sign In"}
                loading={loading}
                onSubmit={this.handleSubmit}
              />

              <Link to={`/signup`} className="signup-button">
                Sign Up
              </Link>
              <p className="sign-in-text mobile-only">
                <Link to="/password/new" className="forgot-password">
                  Forgot password?
                </Link>
              </p>
              <p className="sign-in-text mobile-only">
                Already scheduled an appointment?{" "}
                <Link to="/activate">Activate</Link>
              </p>
            </div>

            {/*<TextDivider text="or" />

            <div className="social-container">
            	<GoogleButton />
            </div>

            <div className="links-container">
            	<Link to="/password/new">Forgot Password</Link>
            </div>*/}

            <div className="under-content-box">
              <div className="text">
                <p className="sign-in-text web-only">
                  <Link to="/password/new" className="forgot-password">
                    Forgot password?
                  </Link>
                </p>
                <p className="sign-in-text web-only">
                  Already scheduled an appointment?{" "}
                  <Link to="/activate">Activate</Link>
                </p>
              </div>
            </div>
          </ContentBox>

          <CookieBanner />
        </ContentMain>

        <style jsx>{layout}</style>
      </MainLayout>
    );
  }

  onKeyPress = (e) => {
    if (e.keyCode == 13) {
      this.handleSubmit();
    }
  };

  handleUpdateField = (value, field) => {
    this.props.updateUserId(value);
  };

  handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    let { userId } = this.props;
    this.props.onGeneralErrors([]);

    if (!userId || userId.length < 5) {
      this.props.onGeneralErrors([
        { text: "Please correct the issues listed below" },
      ]);
      this.props.onError(
        "userId",
        "Please enter a valid phone number or email."
      );
      return;
    }

    this.props.checkUserId(userId, this.props.history);
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(New));
