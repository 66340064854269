import Api from "patient_app/api";
import { DateTime } from "luxon";
import dates from "patient_app/helpers/dates";
import {
  APPTS_LOAD,
  NEW_ERRORS,
  APPTS_LOAD_RECOVERY_GROUP,
  APPTS_LOAD_MORE,
  APPTS_LOAD_NEXT_COURSE,
  APPTS_TOGGLE_BOOKING_LOADING,
  APPTS_BOOKING_LOAD,
  APPTS_SHOW_EXPANDED,
  APPTS_ACTION_ITEMS,
  CLINIC_LOAD_HOLIDAY,
} from "patient_app/constants/actionTypes";

export const confirmAfterHours = () => {
  const data = {
    url: `/chats/confirm_after_hours`,
    path: "/api/v1",
    data: { method: "POST" },
  };

  return async (dispatch) => {
    try {
      const res = await Api.makeRequest(data);
      // this endpoint just creates a timeline event, no need to check success
    } catch (e) {
      console.log(e);
    }
  };
};
