import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import cn from "classnames";
import assets from "patient_app/components/chat/assets";

export default class ChatToggle extends React.Component {
  static propTypes = {
    badgeCount: PropTypes.number.isRequired,
    open: PropTypes.bool,
    onToggleChats: PropTypes.func.isRequired,
  };

  render() {
    let { badgeCount, color, view } = this.props;

    return (
      <div
        id="chat-toggle"
        onClick={this.props.onToggleChats}
        onKeyDown={(e) => this.handleKeyPress(e)}
        className={cn(view)}
        aria-haspopup="dialog"
        tabIndex="0"
      >
        {badgeCount > 0 && (
          <div
            className={`chat-unreads`}
            aria-label={`${badgeCount} unread messages`}
            role="alert"
          >
            <span className="unread-messages-count">{badgeCount}</span>
          </div>
        )}

        <img
          src={/*SpeechBubble*/ assets.speechBubble}
          id="chat-bub"
          className="chat-img-bg"
          alt="Open chat."
          role="button"
          aria-label="Open chat"
        />
        <img
          className="close-chat"
          src={assets.closeWhite}
          alt="Close chat."
          role="button"
          aria-label="Close chat"
        />
      </div>
    );
  }

  handleKeyPress = (e) => {
    if (e.keyCode === 13) this.props.onToggleChats();
  };
}
