let commonAssets = {
  chatBub:
    "https://storage.googleapis.com/workit-client-assets/chat/chatbub.svg",
  chevronLeft:
    "https://storage.googleapis.com/workit-client-assets/onboarding/chevronLeft.svg",
  chevronLeftNavy:
    "https://storage.googleapis.com/workit-client-assets/icons/chevron_left_navy.svg",
  chevronLeftWhite:
    "https://storage.googleapis.com/workit-client-assets/onboarding/chevronLeftWhite.svg",
  chevronRight:
    "https://storage.googleapis.com/workit-client-assets/onboarding/chevronRight.svg",
  chevronRightNavy:
    "https://storage.googleapis.com/workit-client-assets/icons/chevron_right_navy.svg",
  chevronRightGray:
    "https://storage.googleapis.com/workit-client-assets/icons/documents_chevron_right.svg",
  chevronRightWhite:
    "https://storage.googleapis.com/workit-client-assets/onboarding/chevronRightWhite.svg",
  chevronRight757575:
    "https://storage.googleapis.com/workit-client-assets/icons/chevron_right_%23757575.svg",
  chevronDown:
    "https://storage.googleapis.com/workit-client-assets/icons/arrow-down.svg",
  closeWhite:
    "https://storage.googleapis.com/workitassets/images/dashboard/icons/close-white.svg",
  completedGreenCircle:
    "https://storage.googleapis.com/workit-client-assets/icons/completed_green_circle.svg",
  edit: "https://storage.googleapis.com/workit-client-assets/icons/edit.svg",
  grayCross:
    "https://storage.googleapis.com/workit-client-assets/emr/common/close.svg",
  greenCheck:
    "https://storage.googleapis.com/workit-client-assets/icons/onboarding/greenCheck.svg",
  greenCross:
    "https://storage.googleapis.com/workit-client-assets/icons/greenCross.svg",
  greenLogoNoText:
    "https://storage.googleapis.com/workit-client-assets/logos/greenLogoNoText.png",
  greenPlus:
    "https://storage.googleapis.com/workit-client-assets/icons/onboarding/greenPlus.svg",
  passwordShown:
    "https://storage.googleapis.com/workit-client-assets/icons/password_shown.svg",
  passwordHidden:
    "https://storage.googleapis.com/workit-client-assets/icons/password_hidden.svg",
  plusOutline:
    "https://storage.googleapis.com/workit-client-assets/icons/plus_outline.svg",
  ratingFrown:
    "https://storage.googleapis.com/workit-client-assets/icons/nps/Frown.svg",
  ratingNeutral:
    "https://storage.googleapis.com/workit-client-assets/icons/nps/Neutral.svg",
  ratingSmile:
    "https://storage.googleapis.com/workit-client-assets/icons/nps/Smile.svg",
  redX: "https://storage.googleapis.com/workit-client-assets/icons/onboarding/redX.svg",
  redCross:
    "https://storage.googleapis.com/workit-client-assets/icons/redCross.svg",
  search:
    "https://storage.googleapis.com/workitassets/images/dashboard/icons/search.svg",
  starGray:
    "https://storage.googleapis.com/workit-client-assets/icons/video/starGray.svg",
  starYellow:
    "https://storage.googleapis.com/workit-client-assets/icons/video/starYellow.svg",
  whiteLogoNoText:
    "https://storage.googleapis.com/workit-client-assets/logos/logo-white-small.png",
  checkCircle757575:
    "https://storage.googleapis.com/workit-client-assets/emr/chat/checkCircle757575.svg",
  darkGreenGradient:
    "https://storage.googleapis.com/workit-client-assets/appointments/dark_green_gradient.jpg",
  userIcon:
    "https://storage.googleapis.com/workit-client-assets/icons/user.svg",
};

export default commonAssets;
