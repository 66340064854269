export const getUrlVars = (location) => {
  var vars = {};
  var parts = location.replace(
    /[?&]+([^=&]+)=([^&]*)/gi,
    function (m, key, value) {
      vars[key] = value;
    }
  );
  return vars;
};

export const addUrlParam = (history, param, newval) => {
  let search = window.location.search;
  let regex = new RegExp("([?;&])" + param + "[^&;]*[;&]?");
  let query = search.replace(regex, "$1").replace(/&$/, "");
  let newParams =
    (query.length > 2 ? query + "&" : "?") +
    (newval ? param + "=" + newval : "");
  history.push(newParams);
};

export const removeUrlParam = (history, param) => {
  let search = window.location.search;
  let regex = new RegExp("([?;&])" + param + "[^&;]*[;&]?");
  let newParams = search.replace(regex, "$1").replace(/&$/, "");
  if (!!newParams) {
    history.push(newParams);
  }
};

/*
  gets icon url from a given name and color

  @params
  name (string): icon name, e.g. "arrow-down"
  color: (string): icon color, e.g. "brand-green", "red", "white-grey"
  - view icon names and colors at https://www.figma.com/file/7jciRk6i9yr38LgKmYcZxq/UI

  @returns
  string: url to icon in google cloud
*/
export const getIconUrl = (name, color) => {
  return `https://storage.googleapis.com/workit-client-assets/icons_lib/${name}-${color}.svg`;
};
