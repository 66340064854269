import {
  VC_INITIAL_REQUEST_FAILED,
  VC_TOKEN_LOAD,
  VC_SET_ERROR,
  VC_SET_MEDIA_PERMISSIONS_ALLOWED,
  VC_SET_MEDIA_PERMISSIONS_BLOCKED,
  VC_UPDATE_STATE,
  VC_SET_PARTICIPANT,
  VC_REMOVE_ROOM,
  VC_SET_REMOTE_TRACK,
  VC_TOGGLE_CHAT,
  VRQ_USES_ZOOM,
  VC_ZOOM_EVENT_ID_LOAD,
  ZOOM_EVENTS_LOAD_PARTICIPANT,
} from "patient_app/constants/actionTypes";

const initialState = {
  error: null,
  errorSubtitle: null,
  loading: false,
  initialLoading: true,
  token: null,
  mediaPermissionsBlocked: false,
  localVideoTrack: null,
  localAudioTrack: null,
  creatingLocalTracks: false,
  localAudioEnabled: true,
  localVideoEnabled: true,
  room: null,
  isConnecting: false,
  hasJoined: false,
  callEnded: false,
  chatShown: true,
  participants: {},
  participantSids: [],
  remoteTracks: {},
  remoteTrackSids: [],
  roomStatus: "WAITING",
  vrqUsesZoom: false,
  zoomEventId: null,
  zoomEventParticipant: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case VC_SET_ERROR:
      return {
        ...state,
        error: action.error,
        errorSubtitle: action.errorSubtitle,
        errorCta: action.errorCta,
      };
    case VC_INITIAL_REQUEST_FAILED:
      return {
        ...state,
        initialLoading: false,
        initialLoadingError: action.error,
      };
    case VC_UPDATE_STATE:
      return {
        ...state,
        [action.key]: action.value,
      };
    case VC_TOKEN_LOAD:
      return {
        ...state,
        initialLoading: false,
        token: action.token,
      };
    case VC_SET_MEDIA_PERMISSIONS_ALLOWED:
      return {
        ...state,
        mediaPermissionsAllowed: action.allowed,
      };
    case VC_SET_MEDIA_PERMISSIONS_BLOCKED:
      return {
        ...state,
        mediaPermissionsBlocked: action.blocked,
      };
    case VC_SET_PARTICIPANT:
      return {
        ...state,
        participants: {
          ...state.participants,
          [action.sid]: action.participant,
        },
      };
    case VC_SET_REMOTE_TRACK:
      return {
        ...state,
        remoteTracks: {
          ...state.remoteTracks,
          [action.sid]: action.track,
        },
      };
    case VC_REMOVE_ROOM:
      return {
        ...state,
        room: null,
        participants: {},
        participantSids: [],
        remoteTracks: {},
        remoteTrackSids: [],
      };
    case VC_TOGGLE_CHAT:
      return {
        ...state,
        chatShown: action.shown,
      };
    case VRQ_USES_ZOOM:
      // console.log("vrq uses zoom action: ", action)
      // console.log("spread the state: ", {...state, vrqUsesZoom: action.vrqUsesZoom})
      return {
        ...state,
        vrqUsesZoom: action.vrqUsesZoom,
      };
    case VC_ZOOM_EVENT_ID_LOAD:
      return {
        ...state,
        zoomEventId: action.zoomEventId,
      };
    case ZOOM_EVENTS_LOAD_PARTICIPANT:
      // console.log("action: ", action)
      // console.log("spread the state: ", {...state, zoomEventParticipant: action.zoomEventParticipant})
      return {
        ...state,
        zoomEventParticipant: action.zoomEventParticipant,
      };
    default:
      return state;
  }
};
