import css from "styled-jsx/css";
import { variables } from "patient_app/stylesheets/variables.js";

export default css.global`
  .nps {
    position: fixed;
    top: 4.5rem;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(40, 62, 78, 0.5);
    z-index: 2;
    border-top: 1px solid ${variables.grayWhite};
  }

  .nps .nps-modal {
    display: flex;
    margin: 0;
    padding: 2rem;
    background-color: white;
    width: 100%;
    height: fit-content;
    box-sizing: border-box;
  }

  .nps p {
    font-family: ${variables.objMed};
    font-size: 0.8rem;
    color: ${variables.darkBlue};
    margin: 0;
  }

  .nps .close-nps {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  .nps.completed .section {
    max-width: 35rem;
    margin: auto;
  }

  .nps.completed p {
    text-align: center;
  }
  .nps.completed p.thank-you {
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  .nps .section {
    width: calc(100% / 3);
    box-sizing: border-box;
  }

  .nps .section.left {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 1rem;
    height: 3.2rem;
  }

  .nps .section.left p {
    max-width: 14rem;
    line-height: 1.5rem;
  }

  .nps .section.right {
    padding-left: 2rem;
  }

  .nps .rating-boxes,
  .nps .rating-scale {
    display: flex;
    justify-content: space-between;
  }

  .nps .rating-boxes {
    margin-bottom: 0.2rem;
  }

  .nps button.rating-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    font-size: 0.7rem;
    font-family: ${variables.objMed};
    margin: 0 0.2rem;
  }

  .nps button.rating-box:first-of-type {
    margin-left: 0;
  }
  .nps button.rating-box:last-of-type {
    margin-right: 0;
  }

  .nps button.rating-box.red {
    background-color: #f6daea;
  }
  .nps button.rating-box.yellow {
    background-color: rgba(246, 200, 130, 0.3);
  }
  .nps button.rating-box.green {
    background-color: #ebf8f5;
  }
  .nps button.rating-box.navy {
    background-color: ${variables.darkBlue};
  }

  .nps button.rating-box img {
    width: 1.2rem;
    height: 1.2rem;
  }

  .nps input[type="text"] {
    font-family: ${variables.objReg};
    font-size: 0.8rem;
    color: ${variables.darkBlue};
    padding: 0 1rem;
    height: 3.2rem;
    width: 100%;
    margin: 0;
    border: 1px solid ${variables.darkBlue};
    border-radius: 4px;
    box-sizing: border-box;
  }

  .nps .chat-more {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
  }

  .nps .chat-more input[type="checkbox"] {
    margin: 0;
  }

  .nps .chat-more label {
    font-family: ${variables.objReg};
    font-size: 0.7rem;
    color: ${variables.darkBlue};
    margin-left: 0.5rem;
  }

  .nps .custom-button.submit {
    color: white;
    background-color: ${variables.green};
    font-family: ${variables.objMed};
    padding: 0 3rem;
    height: 3.2rem;
    border-radius: 4px;
  }

  .nps .custom-button.submit.disabled {
    color: ${variables.lightText};
    background-color: ${variables.grayLight};
  }

  @media only screen and (max-width: 1200px) {
    .nps .section.left,
    .nps .section.right {
      width: 30%;
    }
    .nps .section.center {
      width: 40%;
    }
    .nps button.rating-box {
      width: 10%;
    }
  }

  @media only screen and (max-width: 900px) {
    .nps .nps-modal {
      flex-direction: column;
      justify-content: center;
    }

    .nps .section {
      width: 100%;
      max-width: 25rem;
      margin: auto;
    }

    .nps .section.left,
    .nps .section.center {
      margin-bottom: 2rem;
    }

    .nps .section.left,
    .nps .section.right,
    .nps .section.center {
      width: 50%;
    }

    .nps .section.left {
      height: fit-content;
      justify-content: center;
    }

    .nps .section.left p {
      max-width: 100%;
      text-align: center;
      font-size: 1rem;
      padding: 0;
    }

    .nps .section.right {
      padding: 0;
    }

    .nps .custom-button.submit {
      width: 100%;
    }

    .nps .rating-boxes {
      margin-bottom: 0.5rem;
    }
    .nps button.rating-box {
      width: 10%;
      height: 4rem;
      margin: 0 1px;
      font-size: 1rem;
    }
  }

  @media only screen and (max-width: 600px) {
    .nps .nps-modal {
      padding: 2rem 1rem;
    }

    .nps .section.left,
    .nps .section.right,
    .nps .section.center {
      width: 100%;
    }

    .nps p {
      font-size: 1rem;
    }

    .nps .chat-more {
      margin-top: 0.5rem;
      align-items: flex-start;
    }

    .nps .chat-more label {
      font-size: 1rem;
    }

    .nps .chat-more input[type="checkbox"] {
      width: 1.5rem;
      height: 1.5rem;
    }

    .nps .custom-button.submit {
      font-size: 1rem;
    }
  }
`;
