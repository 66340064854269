import PropTypes from "prop-types";
import React from "react";

import ProfileField from "patient_app/views/profiles/partials/ProfileField";
import PasswordField from "patient_app/components/input_fields/PasswordField";
import SubTabBack from "patient_app/views/profiles/partials/SubTabBack";
import LoadingSpinner from "patient_app/components/utils/LoadingSpinner";
import { variables } from "emr/stylesheets/variables";
import assets from "patient_app/assets";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  fetchAccount,
  sendPhoneVerification,
  verifyAndUpdatePhone,
} from "patient_app/api/profileActions";
import { getChecklistItems } from "patient_app/api/sessionActions";
import {
  NEW_FIELD_SPECIFIC_ERROR,
  NEW_ERRORS,
  CLEAR_FIELD_SPECIFIC_ERRORS,
  PROFILE_UPDATE_PHONE_ERRORS,
  PROFILE_REQ_CODE_RESET,
} from "patient_app/constants/actionTypes";

import validation from "patient_app/helpers/validation";
import { mobileCheck } from "patient_app/helpers/supported";

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAccount: (profileId, params) =>
      dispatch(fetchAccount(profileId, params)),
    sendPhoneVerification: (newPhone, currentPassword) =>
      dispatch(sendPhoneVerification(newPhone, currentPassword)),
    newFieldSpecificError: (field, error) =>
      dispatch({ type: NEW_FIELD_SPECIFIC_ERROR, field: field, error: error }),
    newGeneralErrors: (errors) =>
      dispatch({ type: NEW_ERRORS, errors: errors }),
    clearFieldSpecificErrors: () =>
      dispatch({ type: CLEAR_FIELD_SPECIFIC_ERRORS }),
    resetPhoneErrors: () => {
      dispatch({ type: PROFILE_UPDATE_PHONE_ERRORS, errors: [] });
      dispatch({ type: PROFILE_REQ_CODE_RESET });
    },
    verifyAndUpdatePhone: (newPhone, code) =>
      dispatch(verifyAndUpdatePhone(newPhone, code)),
    getChecklistItems: () => dispatch(getChecklistItems()),
  };
};

const mapStateToProps = (state) => {
  return {
    user: state.common.user,
    profile: state.common.profile,
    loading: state.profile.loading,
    success: state.profile.success,
    requestCodeSuccess: state.profile.requestCodeSuccess,
    updatePhoneErrors: state.profile.updatePhoneErrors,
    updatePhoneSuccess: state.profile.updatePhoneSuccess,
    checklistItemsAll: state.session.checklistItemsAll,
    signedDocuments: state.profile.signedDocuments,
  };
};

class Account extends React.Component {
  profileId = this.props.match.params.id;

  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      newPhone: "",
      currentPassword: "",
      code: "",
      disableButton: true,
      phone: "",
      notification: null,
      checked: false,
    };
  }

  componentDidMount() {
    if (mobileCheck()) {
      this.props.fetchAccount(this.profileId);
    }
    this.props.getChecklistItems();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.user?.phone !== this.props.user?.phone) {
      this.setState({ phone: this.props.user?.phone });
    }
    if (!prevProps.updatePhoneSuccess && this.props.updatePhoneSuccess) {
      this.resetEditPhone();
    }
    if (prevProps.updatePhoneErrors !== this.props.updatePhoneErrors) {
      this.showErrors();
    }
    if (this.state.editing && !this.props.requestCodeSuccess) {
      if (
        prevState.newPhone !== this.state.newPhone ||
        prevState.currentPassword !== this.state.currentPassword ||
        prevState.checked !== this.state.checked
      ) {
        this.props.clearFieldSpecificErrors();
        let errors = this.validate();
        if (errors.length > 0) {
          this.setState({ disableButton: true });
          errors.forEach((error) => {
            this.props.newFieldSpecificError(error.field, error.text);
          });
        } else {
          this.setState({ disableButton: false });
        }
      }
    }
  }

  render() {
    const { loading, profile, user, requestCodeSuccess, updatePhoneErrors } =
      this.props;
    const {
      editing,
      currentPassword,
      newPhone,
      code,
      disableButton,
      phone,
      checked,
    } = this.state;

    const consentDoc = this.props.checklistItemsAll.find(
      (item) => item.uid === "2024_texts_emails"
    );

    return (
      <div>
        <SubTabBack />
        <div className="profile-section">
          <div className="header">
            <h1>Phone</h1>

            <button
              className="custom-button section-action"
              onClick={this.toggleEditPhone}
            >
              {editing ? "Cancel" : "Edit"}
            </button>
          </div>

          {!editing && !user && <LoadingSpinner />}

          {!editing && user && (
            <ProfileField
              field="phone"
              fieldType="plain-text"
              showLabel={false}
              title="Phone"
              value={phone || user.phone}
            />
          )}

          {editing && (
            <div>
              <div className="paragraph">
                <p>
                  <span>Use a phone number you can receive messages at.</span>
                </p>
              </div>

              {!requestCodeSuccess && (
                <>
                  <ProfileField
                    field="phone"
                    fieldType="text"
                    showLabel={true}
                    title="New Phone"
                    value={newPhone}
                    onUpdateField={(value, field) =>
                      this.setState({ newPhone: value })
                    }
                  />
                  <PasswordField
                    field="current_password"
                    title="Current Password"
                    value={currentPassword}
                    id="current_password"
                    onUpdateField={(value, field) =>
                      this.setState({ currentPassword: value })
                    }
                    showPasswordStrength={false}
                    uid="current_password"
                  />

                  {consentDoc && (
                    <div style={{ display: "flex" }}>
                      <ProfileField
                        field="agreement"
                        fieldType="checkbox"
                        required={true}
                        onUpdateField={(value, field) =>
                          this.setState({ checked: value })
                        }
                        showLabel={true}
                        title={`By changing my phone number I agree to Workit's ${consentDoc.title}.`}
                        value={checked}
                      />
                      <a href={consentDoc.public_url} target="_blank">
                        <img src={assets.chevronRightNavy} />
                      </a>
                    </div>
                  )}

                  <button
                    className={`custom-button submit half ${
                      loading || disableButton ? "loading" : ""
                    }`}
                    onClick={this.handleSendCode}
                  >
                    Send Verification Code
                  </button>
                  <div
                    className="profile-section warning"
                    style={{ paddingLeft: 0 }}
                  >
                    {updatePhoneErrors.length > 0 && this.showErrors()}
                  </div>
                </>
              )}
              {requestCodeSuccess && (
                <>
                  <ProfileField
                    field="code"
                    fieldType="text"
                    showLabel={true}
                    title={`Enter the code we've just sent to ${newPhone}:`}
                    value={code}
                    onUpdateField={(value, field) =>
                      this.setState({ code: value })
                    }
                  />
                  <button
                    className={`custom-button submit half ${
                      loading ? "loading" : ""
                    }`}
                    onClick={this.handleSubmit}
                  >
                    Save Changes
                  </button>
                  <div
                    className="profile-section warning"
                    style={{ paddingLeft: 0 }}
                  >
                    {updatePhoneErrors.length > 0 && this.showErrors()}
                  </div>
                </>
              )}
            </div>
          )}
          {this.state.notification && (
            <p style={{ color: variables.green }}>{this.state.notification}</p>
          )}
        </div>
      </div>
    );
  }

  showErrors = () => {
    const { updatePhoneErrors } = this.props;
    let errorList = [];
    let errorIdx = 0;
    updatePhoneErrors.forEach((error) => {
      let key = `error_${errorIdx}`;
      errorList.push(<p key={key}>{error.text}</p>);
      errorIdx++;
    });
    return errorList;
  };

  toggleEditPhone = () => {
    if (this.state.editing) {
      this.resetEditPhone();
      // this.props.clearFieldSpecificErrors();
      // this.props.newGeneralErrors([]);
    } else {
      this.setState({ editing: !this.state.editing });
    }
  };

  resetEditPhone = () => {
    this.setState({
      editing: false,
      newPhone: "",
      currentPassword: "",
      code: "",
      disableButton: true,
      checked: false,
    });
    this.props.clearFieldSpecificErrors();
    this.props.resetPhoneErrors();
    this.props.newGeneralErrors([]);
  };

  handleSendCode = () => {
    const { newPhone, currentPassword } = this.state;
    this.props.clearFieldSpecificErrors();
    this.props.newGeneralErrors([]);

    let errors = this.validate();
    if (errors.length > 0) {
      errors.forEach((error) => {
        this.props.newFieldSpecificError(error.field, error.text);
      });
      // this.props.newGeneralErrors([{ text: "Please fix the issues below." }]);
      // window.scrollTo(0, 0);
      return;
    }
    this.props.sendPhoneVerification(newPhone, currentPassword);
  };

  handleSubmit = () => {
    const { newPhone, currentPassword, code } = this.state;
    this.props.clearFieldSpecificErrors();
    this.props.newGeneralErrors([]);

    let errors = this.validate();
    if (errors.length > 0) {
      errors.forEach((error) => {
        this.props.newFieldSpecificError(error.field, error.text);
      });
      // this.props.newGeneralErrors([{ text: "Please fix the issues below." }]);
      // window.scrollTo(0, 0);
      return;
    }

    // TODO: something like:
    // this.props.updatePhone(newPhone, currentPassword, code);
    // if that succeeds, it needs to dispatch PROFILE_UPDATE_PHONE_SUCCESS
    this.props.verifyAndUpdatePhone(newPhone, code);
  };

  validate = () => {
    const { newPhone, currentPassword, checked } = this.state;
    let errors = [];

    if (!currentPassword) {
      errors.push({
        field: "current_password",
        text: ["Current password required."],
      });
    }

    if (!checked) {
      errors.push({
        field: "agreement",
        text: ["Consent to Workit's phone and email policy."],
      });
    }

    if (!validation.validatePhone(newPhone)) {
      errors.push({
        field: "phone",
        text: ["Invalid Phone."],
      });
    }

    return errors;
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Account)
);
