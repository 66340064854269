import PropTypes from "prop-types";
import cn from "classnames";
import React from "react";
import Header from "patient_app/components/chat/partials/Header";

class Container extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    onToggleEmergencyBanner: PropTypes.func,
    showEmergencyBanner: PropTypes.bool,
    showEmergencyButton: PropTypes.bool,
    canGoBack: PropTypes.bool,
    onToggleSearch: PropTypes.func,
    showSearchButton: PropTypes.bool,
    showSearchBar: PropTypes.bool,
    q: PropTypes.string,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onCancel: PropTypes.func,
    onKeyDown: PropTypes.func,
    searchIsFocused: PropTypes.bool,
    open: PropTypes.bool,
  };

  render() {
    let {
      title,
      onToggleEmergencyBanner,
      showEmergencyButton,
      showEmergencyBanner,
      canGoBack,
      onToggleSearch,
      showSearchButton,
      showSearchBar,
      q,
      onChange,
      onFocus,
      onBlur,
      onCancel,
      onKeyDown,
      searchIsFocused,
      bioLink,
      open,
      tabIndex,
    } = this.props;

    return (
      <div className="inner-container" id="inner-container">
        <Header
          title={title}
          onToggleEmergencyBanner={onToggleEmergencyBanner}
          showEmergencyBanner={showEmergencyBanner}
          showEmergencyButton={showEmergencyButton}
          canGoBack={canGoBack}
          onGoBack={this.props.onGoBack}
          onToggleChats={this.props.onToggleChats}
          onToggleSearch={onToggleSearch}
          showSearchButton={showSearchButton}
          showSearchBar={showSearchBar}
          q={q}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          onCancel={onCancel}
          onKeyDown={onKeyDown}
          searchIsFocused={searchIsFocused}
          bioLink={bioLink}
          open={open}
          tabIndex={tabIndex}
        />
        {this.props.children}
      </div>
    );
  }
}

export default Container;
