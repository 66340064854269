import PropTypes from "prop-types";
import React from "react";

import { connect } from "react-redux";
import { Multiselect } from "multiselect-react-dropdown";
import { variables } from "patient_app/stylesheets/variables";
import layout from "patient_app/stylesheets/gtc/layout";

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {
    fieldSpecificErrors: state.common.fieldSpecificErrors,
  };
};

class MultiSelectField extends React.Component {
  render() {
    let {
      className,
      field,
      fieldSpecificErrors,
      isObject,
      options,
      required,
      showSubtitle,
      title,
      value,
    } = this.props;

    const error = fieldSpecificErrors[field];

    const multiselectStyles = {
      searchBox: {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "space-between",
        border: `1px solid ${variables.darkBlue}`,
        borderRadius: "4px",
        boxSizing: "border-box",
        padding: "0 1rem",
        minHeight: "2.75rem",
        height: "fit-content",
        display: "flex",
        margin: "0 0 0.25rem",
      },
      optionContainer: {
        border: `1px solid ${variables.boxBorder}`,
        borderTop: "none",
        borderRadius: "0",
        boxSizing: "border-box",
        margin: "0 0 1rem",
      },
      option: {
        fontFamily: "obj3-reg",
        fontSize: "0.8rem",
        borderBottom: `1px solid ${variables.boxBorder}`,
      },
      chips: {
        backgroundColor: variables.lightGray,
        color: variables.darkBlue,
        fontSize: "0.8rem",
        padding: "0.25rem 0.5rem",
        borderRadius: "4px",
        margin: "0.25rem",
        height: "2.25rem",
        boxSizing: "border-box",
      },
    };

    return (
      <div className={`gtc-multi-select ${className}`}>
        <label htmlFor={field}>
          {title} {required && <span className="required">(Required)</span>}
        </label>

        {showSubtitle && this.props.children}

        <Multiselect
          id={field}
          options={options}
          isObject={isObject === false ? false : true}
          selectedValues={value}
          onSelect={(list, item) => this.props.onUpdateField(list, field)}
          onRemove={(list, item) => this.props.onUpdateField(list, field)}
          closeIcon="cancel"
          avoidHighlightFirstOption={true}
          style={multiselectStyles}
          placeholder=""
          hidePlaceholder={true}
        />

        {error && <p className="field-error">{error}</p>}

        <style jsx>{layout}</style>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MultiSelectField);
