import css from "styled-jsx/css";
import { variables } from "patient_app/stylesheets/variables.js";

export default css.global`
  .dashboard-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(40, 62, 78, 0.15);
  }

  .dashboard-modal p {
    font-family: ${variables.objReg};
    color: ${variables.blueDark};
  }

  .dashboard-modal .modal {
    width: 40%;
    height: fit-content;
    margin: auto;
    margin-top: 5rem;
    background-color: white;
  }

  .dashboard-modal .modal .header {
    display: flex;
    justify-content: space-between;
    background-color: ${variables.green};
    padding: 2rem 3rem;
    min-height: 3rem;
    position: relative;
    overflow: hidden;
  }

  .dashboard-modal .modal .header .cover-photo {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
  }

  .dashboard-modal .modal .content {
    padding: 2rem 3rem;
  }

  .hide {
    color: ${variables.red};
  }

  .click-here {
    color: ${variables.green};
  }

  .details-div {
    margin-top: 50px;
  }

  .dashboard-modal .modal .header p {
    color: white;
    margin-bottom: 0.25rem;
    font-size: 1rem;
  }

  .dashboard-modal.email-confirmation .modal .header p {
    margin-bottom: 0;
  }

  .dashboard-modal .modal .content p.title {
    font-family: ${variables.objMed};
    padding-inline-end: 32px;
  }
  .dashboard-modal .modal .header p.title.no-margin {
    margin: 0;
  }

  .dashboard-modal .modal .content p.title {
    font-family: ${variables.objBold};
    padding-inline-end: 32px;
    margin: 0;
  }
  .dashboard-modal .modal .content p.subtitle {
    margin-bottom: 1rem;
  }
  .dashboard-modal .modal .content .section {
    margin-bottom: 1.7rem;
  }

  .dashboard-modal .modal .content p.description {
    font-size: 0.85rem;
    margin-bottom: 0;
  }
  .dashboard-modal .modal .content p.description a {
    color: ${variables.green};
    text-decoration: underline;
    font-family: ${variables.objMed};
  }

  .dashboard-modal .modal .content .action-row p.description {
    margin-bottom: 0;
  }

  .dashboard-modal .modal .content .action-row {
    display: flex;
    align-items: center;
    margin-bottom: 0.8rem;
  }

  .dashboard-modal .modal .content .action-row .badge,
  .dashboard-modal .modal .content .action-row img.avatar {
    width: 2rem;
    height: 2rem;
    border-radius: 99px;
    overflow: hidden;
    object-position: center;
    object-fit: cover;
    margin-right: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
  }

  .dashboard-modal .modal .content .tag-wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  .dashboard-modal .modal .content .tag {
    font-size: 0.8rem;
    padding: 6px 10px;
    background-color: ${variables.grayWhite};
    border-radius: ${variables.base};
    overflow: hidden;
    margin-right: ${variables.tiny};
    margin-top: ${variables.tiny};
  }

  .dashboard-modal .modal .content button.submit-button {
    width: 100%;
    border-radius: 4px;
  }

  .dashboard-modal .modal .content button.submit-button.inverted {
    background-color: transparent;
    border: solid 1px ${variables.green};
    color: ${variables.green};
  }

  .dashboard-modal .modal .header button {
    height: fit-content;
  }

  .dashboard-modal .modal .header button.close {
    height: 42px;
    min-width: 42px;
    border-radius: 999px;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: ${variables.small};
    z-index: 2;
  }

  .dashboard-modal .modal .header a {
    display: block;
    color: white;
  }

  .dashboard-modal .modal .header a.link {
    text-decoration: underline;
    font-size: 0.85rem;
    font-family: ${variables.objMed};
  }

  .dashboard-modal .modal .header .select-comm-type a.link {
    display: inline;
    margin-left: 6px;
  }

  .dashboard-modal .modal .header a.video-link,
  .dashboard-modal .modal .header button.video-link {
    margin-top: 1rem;
    border: 1px solid white;
    border-radius: 25px;
    width: fit-content;
    font-size: 0.85rem;
    font-family: ${variables.objMed};
    color: white;
    padding: 0.3rem 1rem;
    box-sizing: border-box;
  }

  .dashboard-modal .modal .header .regular-link {
    font-family: ${variables.objReg};
    font-size: 0.85rem;
    text-decoration: underline;
    margin-bottom: 0.5rem;
    width: fit-content;
    text-align: left;
    padding: 0;
  }

  .dashboard-modal .modal .header .select-comm-type {
    margin-top: 1rem;
  }

  .dashboard-modal .modal .header .select-comm-type p {
    font-size: 0.85rem;
    margin: 0;
  }

  .dashboard-modal .modal .header .comm-type {
    border: 1px solid white;
    border-radius: 25px;
    width: 25%;
    height: fit-content;
    padding: 0.25rem;
    margin-top: 0.5rem;
    margin-right: 0.5rem;
    font-size: 0.8rem;
    font-family: ${variables.objMed};
    color: white;
    text-decoration: none;
    transition: all 0.3s ease;
  }

  .dashboard-modal .modal .index,
  .dashboard-modal .modal .details {
    height: calc(100vh - 10rem);
    box-sizing: border-box;
  }

  .dashboard-modal .modal .index.can-scroll {
    overflow-y: scroll;
  }

  .dashboard-modal .modal .index .alert-box {
    padding: 1.5rem 3rem;
    background-color: ${variables.grayDark};
  }

  .dashboard-modal .modal .index .alert-box.red {
    padding: 1.5rem 3rem;
    background-color: ${variables.red};
  }

  .dashboard-modal .modal .index .alert-box p {
    font-size: 0.85rem;
    margin: 0;
    line-height: 1.5rem;
    color: white;
  }

  .dashboard-modal .modal .index .alert-box.red p {
    color: white;
  }

  .dashboard-modal .modal .index .alert-box p a {
    color: ${variables.green};
    text-decoration: underline;
  }

  .dashboard-modal .modal .index .alert-box.red p a {
    color: white;
    font-family: ${variables.objMed};
  }

  .dashboard-modal .modal .index .info-item {
    display: block;
    width: 100%;
    padding: 1.5rem 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${variables.grayLight};
    box-sizing: border-box;
  }

  .dashboard-modal .modal .index .info-item.drug-test-hours {
    box-sizing: border-box;
    flex-direction: column;
    align-items: flex-start;
    font-size: 0.85rem;
  }

  .dashboard-modal .modal .index .info-item.drug-test-hours p.title {
    font-family: ${variables.objMed};
  }

  .dashboard-modal .modal .index .info-item p {
    margin: 0;
    font-size: 0.85rem;
  }

  .dashboard-modal .modal .details {
    overflow: scroll;
    height: 100%;
    height: -moz-available; /* WebKit-based browsers will ignore this. */
    height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
    height: fill-available;
  }

  .dashboard-modal .modal .details .subheader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${variables.grayLight};
    padding: 1.5rem 3rem;
  }

  .dashboard-modal .modal .details .subheader p.title {
    font-family: ${variables.objMed};
    font-size: 1rem;
    margin: 0;
  }

  .dashboard-modal .modal .details .subheader .back p {
    margin: 0;
    color: ${variables.green};
    font-size: 0.85rem;
    text-decoration: underline;
  }

  .dashboard-modal .modal .details ul {
    margin: 1.5rem 3rem;
    padding: 0;
    padding-left: 1.2rem;
    list-style-type: disc;
    overflow: scroll;
  }

  .dashboard-modal .modal .details ul li {
    margin-bottom: 1rem;
    font-family: ${variables.objReg};
    font-size: 0.85rem;
    color: ${variables.blueDark};
  }

  .dashboard-modal .modal .details ul li span.underline {
    text-decoration: underline;
  }

  .dashboard-modal .modal .details ul li a {
    color: ${variables.green};
    text-decoration: underline;
  }

  @media only screen and (max-width: 950px) {
    .dashboard-modal .modal {
      width: 80%;
      height: 80vh;
      display: flex;
      flex-direction: column;
    }
    .dashboard-modal .modal .index,
    .dashboard-modal .modal .details {
      height: auto;
    }
  }

  @media only screen and (max-width: 600px) {
    .dashboard-modal {
      position: fixed;
      width: 100vw;
      height: 100vh;
      overflow: hidden;
    }

    .dashboard-modal .modal {
      width: 100vw;
      height: 100vh;
      margin-top: 4.5rem;
    }

    .dashboard-modal .index {
      height: 100%;
    }

    .dashboard-modal .modal .header {
      padding: 2rem;
    }

    .dashboard-modal .modal .header p {
      font-size: 1.2rem;
    }

    .dashboard-modal .modal .index {
      height: 74vh;
    }

    .dashboard-modal .modal .index .alert-box,
    .dashboard-modal .modal .index .alert-box.red {
      padding: 1.5rem 2rem;
    }
    .dashboard-modal .modal .index .info-item {
      padding: 1.5rem 2rem;
    }

    .dashboard-modal .modal .details .subheader {
      padding: 1.5rem 2rem;
    }

    .dashboard-modal .modal .details ul {
      margin: 1.5rem 2rem;
    }
  }
`;
