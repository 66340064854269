import React, { Component } from "react";
import { connect } from "react-redux";

import MediaPrompt from "patient_app/components/video/MediaPrompt";
import MediaBlocked from "patient_app/components/video/MediaBlocked";

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {
    mediaPermissionsAllowed: state.videoCall.mediaPermissionsAllowed,
    mediaPermissionsBlocked: state.videoCall.mediaPermissionsBlocked,
  };
};

class MediaPermissions extends Component {
  componentDidMount = () => {};

  componentWillUnmount = () => {};

  render() {
    let { mediaPermissionsBlocked, mediaPermissionsAllowed } = this.props;

    if (mediaPermissionsAllowed) {
      return null;
    }

    return (
      <div>
        {!mediaPermissionsAllowed && !mediaPermissionsBlocked && (
          <MediaPrompt />
        )}

        {mediaPermissionsBlocked && <MediaBlocked />}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MediaPermissions);
