import css from "styled-jsx/css";
import { variables } from "patient_app/stylesheets/variables.js";

export default css.global`
  .Courses-Sidebar {
    flex: 0 0 22rem;
    margin-left: 2rem;
    display: flex;
    flex-direction: column;
  }

  .Sidebar-Tile {
    display: flex;
    justify-content: center;
    background: white;
    border-radius: 2px;
    border: 1px solid ${variables.boxBorder};
    margin-top: 1.5rem;
    min-height: 17rem;
  }

  .Sidebar-Tile .inner {
    flex: auto;
  }

  .Sidebar-Tile .main {
    padding-top: 0.5rem;
    position: relative;
    /* bottom: 1.5rem; */
  }

  .Sidebar-Tile p,
  .Sidebar-Tile a {
    font-size: 0.85rem;
    text-align: center;
    margin-bottom: 0.5rem;
    font-family: ${variables.objReg};
  }

  .Sidebar-Tile p.title {
    font-family: ${variables.objBold};
    font-size: 1.1rem;
  }

  .Sidebar-Tile p.squish {
    margin-bottom: 0.25rem;
  }

  .Sidebar-Tile a {
    text-decoration: underline;
    margin: auto;
    display: block;
    color: ${variables.green};
    font-family: ${variables.objMed};
    font-size: 0.8rem;
  }

  .Sidebar-Tile div.break {
    width: 100%;
    margin-bottom: 1rem;
  }

  .Sidebar-Tile .profile-pic-container {
    border-radius: 50%;
    /* padding: .5rem; */
    height: 6rem;
    width: 6rem;
    background: white;
    overflow: hidden;
    position: relative;
    bottom: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
  }

  .Sidebar-Tile .profile-pic-container img {
    height: 86%;
    width: 86%;
    border-radius: 50%;
    margin: 0.5rem;
    object-fit: cover;
  }

  .Sidebar-Tile .schedule-link {
    margin-bottom: 1.5rem;
    width: fit-content;
  }

  .Sidebar-Tile button.chat-init,
  .Sidebar-Tile a.chat-init {
    border-radius: 4px;
    margin: 0 auto 1.5rem;
    font-size: 0.85rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 86%;
    height: 2.5rem;
    color: white;
    background-color: ${variables.green};
    text-decoration: none;
  }

  .Sidebar-Tile .select-comm-type {
    background-color: ${variables.green};
    padding: 1rem 1rem 1.5rem;
  }

  .Sidebar-Tile .select-comm-type p {
    color: white;
    text-align: left;
    font-size: 0.8rem;
    font-family: ${variables.objMed};
    margin-bottom: 0.75rem;
  }

  .Sidebar-Tile .select-comm-type p.title {
    font-size: 1rem;
  }

  .Sidebar-Tile .select-comm-type .comm-options {
    display: flex;
    justify-content: space-between;
  }

  .Sidebar-Tile .select-comm-type button.link {
    display: block;
    font-familiy: ${variables.objMed};
    font-size: 0.8rem;
    text-decoration: underline;
    color: white;
    background-color: transparent;
    padding: 0;
    width: fit-content;
    height: fit-content;
    margin-bottom: 1rem;
    text-align: left;
  }

  .Sidebar-Tile .select-comm-type a {
    width: fit-content;
    text-align: left;
    color: white;
    margin: 0 0 1rem;
  }

  .Sidebar-Tile .select-comm-type .comm-options button {
    border: 1px solid white;
    border-radius: 25px;
    width: 30%;
    height: fit-content;
    padding: 0.5rem;
    font-size: 0.8rem;
    font-family: ${variables.objMed};
    color: white;
    text-decoration: none;
    transition: all 0.3s ease;
  }

  .Sidebar-Tile .select-comm-type .comm-options button:hover,
  .Sidebar-Tile .select-comm-type .comm-options button.selected {
    color: ${variables.green};
    background-color: white;
  }

  .Sidebar-Tile .select-comm-type .comm-options button:focus {
    outline: 5px auto white;
  }

  .Sidebar-Tile.community {
    flex-direction: column;
    min-height: fit-content;
  }

  .Sidebar-Tile.community p {
    margin: 0;
  }
  .Sidebar-Tile.community p.title {
    text-align: left;
  }

  .Sidebar-Tile .header {
    padding: 2rem 2.5rem;
    border-bottom: 1px solid ${variables.boxBorder};
  }

  .Sidebar-Tile .block-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.75rem 2.5rem;
    color: ${variables.darkBlue};
    text-decoration: none;
    margin: 0;
  }

  .Sidebar-Tile .block-item p {
    font-family: ${variables.objMed};
  }

  @media only screen and (max-width: 600px) {
    .Courses-Sidebar {
      flex: 0 0 auto;
      margin-left: 0;
      padding: 1rem 0;
      order: 1;
    }
  }
`;
