import css from "styled-jsx/css";
import { variables } from "patient_app/stylesheets/variables";

export default css.global`
  .GTC .profile-section .add-additional {
    display: flex;
    align-items: center;
    text-align: left;
    font-family: ${variables.objMed};
    color: ${variables.darkBlue};
    width: fit-content;
    margin-bottom: ${variables.small};
  }

  .GTC .profile-section .add-additional img {
    margin-right: 0.5rem;
  }

  .GTC button.save {
    background-color: ${variables.green};
    color: white;
    font-family: ${variables.objMed};
    width: calc(16px * 12);
    padding: ${variables.small};
    margin: ${variables.base} 0;
    border-radius: 4px;
  }

  .GTC button.save.loading {
    opacity: 0.3;
    pointer-events: none;
  }

  .GTC .multi-object {
    position: relative;
  }

  .GTC .multi-object:not(:last-of-type) {
    margin-bottom: calc(16px * 1.5);
  }

  .GTC .multi-object button.remove-multi {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }

  .GTC .multi-object button.remove-multi img {
    height: ${variables.small};
  }

  .gtc-multi-select {
    display: flex;
    flex-direction: column;
    margin-bottom: calc(16px * 1.2);
  }

  .gtc-multi-select label {
    font-family: ${variables.objMed};
    font-size: 0.8rem;
    color: ${variables.darkBlue};
    margin-bottom: ${variables.tiny};
  }

  .gtc-multi-select label span.required {
    color: ${variables.lightText};
  }

  .gtc-multi-select .field-error {
    font-family: ${variables.objReg};
    font-size: 0.7rem;
    color: ${variables.red};
    margin: 0;
  }
`;
