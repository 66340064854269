import {
  VR_REQ_START,
  VR_REQ_SUCCESS,
  VR_REQ_FAILURE,
  VR_LOAD,
  SET_LOCAL_VIDEO_REF,
  SET_REMOTE_VIDEO_REF,
  SET_REMOTE_AUDIO_REF,
  CLEAR_VIDEO_ROOM,
  SET_TWILIO_ROOM_OBJ,
  VIDEO_TOGGLE_AUDIO,
  VIDEO_TOGGLE_VIDEO,
  VIDEO_IS_PREVIOUS,
  VIDEO_TOGGLE_REMOTE_VIDEO,
  VIDEO_TOGGLE_REMOTE_AUDIO,
} from "patient_app/constants/actionTypes";

const initialState = {
  isFetching: false,
  reqFinished: false,
  loading: false,
  room: null,
  audioMuted: false,
  videoMuted: false,
  remoteAudioMuted: false,
  remoteVideoMuted: false,
  videoIsPrev: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case VR_REQ_START:
      return {
        ...state,
        isFetching: true,
        reqFinished: false,
      };
    case VR_REQ_SUCCESS:
      return {
        ...state,
        isFetching: false,
        reqFinished: true,
        success: true,
      };
    case VR_REQ_FAILURE:
      return {
        ...state,
        isFetching: false,
        reqFinished: true,
        success: false,
      };
    case VR_LOAD:
      return {
        ...state,
        chatId: action.chatId,
        room: action.videoRoom,
      };
    case SET_LOCAL_VIDEO_REF:
      return {
        ...state,
        localVideoRef: action.localVideoRef,
      };
    case SET_REMOTE_VIDEO_REF:
      return {
        ...state,
        remoteVideoRef: action.remoteVideoRef,
      };
    case SET_REMOTE_AUDIO_REF:
      return {
        ...state,
        remoteAudioRef: action.remoteAudioRef,
      };
    case SET_TWILIO_ROOM_OBJ:
      return {
        ...state,
        twilioRoomObj: action.roomObj,
      };
    case CLEAR_VIDEO_ROOM:
      return {
        ...state,
        room: null,
        twilioRoomObj: null,
      };
    case VIDEO_TOGGLE_VIDEO:
      return {
        ...state,
        videoMuted: action.muted,
      };
    case VIDEO_TOGGLE_REMOTE_VIDEO:
      return {
        ...state,
        remoteVideoMuted: action.muted,
      };
    case VIDEO_TOGGLE_AUDIO:
      return {
        ...state,
        audioMuted: action.muted,
      };
    case VIDEO_TOGGLE_REMOTE_AUDIO:
      return {
        ...state,
        remoteAudioMuted: action.muted,
      };
    case VIDEO_IS_PREVIOUS:
      return {
        ...state,
        videoIsPrev: true,
      };
    default:
      return state;
  }
};
