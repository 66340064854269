import React, { useState } from "react";
import { useSelector } from "react-redux";
import { DateTime } from "luxon";
import cn from "classnames";
import Api from "patient_app/api";

import SessionStore from "patient_app/helpers/SessionStore";
import dates from "patient_app/helpers/dates";
import permissions from "patient_app/helpers/permissions";
import { variables } from "patient_app/stylesheets/variables";

const DashboardBanner = (props) => {
  const { clinic, user } = useSelector((state) => {
    return {
      clinic: state.common.clinic,
      user: state.common.user,
    };
  });

  const [show, setShow] = useState(true);
  const isClinic = permissions.isClinic(user);

  const handleClick = async (e) => {
    e?.preventDefault();
    let exerciseId = permissions.isOud(user) ? "540" : "1740";
    let path = `/exercise_responses/create?exercise=${exerciseId}`;
    const data = {
      url: path,
      data: { method: "POST" },
    };

    try {
      const res = await Api.makeRequest(data);
      if (res.path) {
        window.location.href = res.path;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getHolidayText = () => {
    const holidaysInRange = dates.holidaysInRange(clinic?.holidays, 3);
    const today = DateTime.local().toFormat("yyyy-LL-dd");

    let holidayText = "";
    if (holidaysInRange.length === 0) {
      return holidayText;
    } else if (holidaysInRange.length === 1) {
      const holiday = holidaysInRange[0];
      if (holiday.toFormat("yyyy-LL-dd") === today) {
        holidayText = "Workit is closed today to observe the holiday.";
      } else {
        holidayText = `Workit will be closed this ${holiday.toFormat(
          "cccc, LLLL"
        )} ${dates.ordinalize(holiday.day)}.`;
      }
    } else {
      // multiple holidays coming up
      let notToday = [];
      holidaysInRange.forEach((holiday) => {
        if (holiday.toFormat("yyyy-LL-dd") !== today) {
          notToday.push(
            `${holiday.toFormat("cccc, LLLL")} ${dates.ordinalize(holiday.day)}`
          );
        }
      });

      if (notToday.length === holidaysInRange.length) {
        // none of the holidays are today
        holidayText = `Workit will be closed this ${notToday[0]} and ${notToday[1]}.`;
      } else {
        // one of the holidays is today
        holidayText = `Workit is closed today and ${notToday[0]}.`;
      }
      // 1. today and upcoming
      // 2. 2+ upcoming
    }

    return holidayText;
  };

  if (props.info === "gtc" && user?.first_course_completed) {
    return <div />;
  }

  if (
    ["holiday_today", "holiday_upcoming"].includes(props.info) &&
    !props.date
  ) {
    return <div />;
  }

  return (
    <div className={`dashboard-banner ${props.info}`}>
      {props.info === "gtc" && (
        <p>
          We still need some information from you.{" "}
          <button
            className="custom-button"
            onClick={(e) => this.handleClick(e)}
          >
            Click here.
          </button>
        </p>
      )}

      {props.info === "holiday" && getHolidayText() && (
        <p>{getHolidayText()}</p>
      )}

      <style jsx>{`
        .dashboard-banner {
          background-color: ${variables.green};
          color: white;
          padding: 1rem;
          display: flex;
          justify-content: center;
        }
        .dashboard-banner.holiday {
          background-color: ${variables.redDark};
        }
        .dashboard-banner p {
          margin: 0;
          font-family: ${variables.objReg};
          font-size: 0.85rem;
        }
        .dashboard-banner button.custom-button {
          color: white;
          text-decoration: underline;
          font-size: 0.85rem;
          font-family: ${variables.objReg};
        }
      `}</style>
    </div>
  );
};

export default DashboardBanner;
