import PropTypes from "prop-types";
import React from "react";
import { DateTime } from "luxon";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { fetchAccount } from "patient_app/api/profileActions";
import {
  NEW_FIELD_SPECIFIC_ERROR,
  NEW_ERRORS,
  CLEAR_FIELD_SPECIFIC_ERRORS,
} from "patient_app/constants/actionTypes";

import MfaSetup from "patient_app/views/profiles/partials/account/MfaSetup";
import MfaConfig from "patient_app/views/profiles/partials/account/MfaConfig";
import SubTabBack from "patient_app/views/profiles/partials/SubTabBack";
import LoadingSpinner from "patient_app/components/utils/LoadingSpinner";

import { mobileCheck } from "patient_app/helpers/supported";

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAccount: (profileId, params) =>
      dispatch(fetchAccount(profileId, params)),
    newFieldSpecificError: (field, error) =>
      dispatch({ type: NEW_FIELD_SPECIFIC_ERROR, field: field, error: error }),
    newGeneralErrors: (errors) =>
      dispatch({ type: NEW_ERRORS, errors: errors }),
    clearFieldSpecificErrors: () =>
      dispatch({ type: CLEAR_FIELD_SPECIFIC_ERRORS }),
  };
};

const mapStateToProps = (state) => {
  return {
    loading: state.profile.loading,
    otpSecretKey: state.session.otpSecretKey,
    success: state.profile.success,
    user: state.common.user,
  };
};

class TwoFactor extends React.Component {
  profileId = this.props.match.params.id;

  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      password: "",
    };
  }

  componentDidMount = async () => {
    if (mobileCheck()) {
      this.props.fetchAccount(this.profileId);
    }
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.user &&
      prevProps.user.mfa_enabled !== this.props.user.mfa_enabled
    ) {
      this.handleCancel();
    }
  }

  render() {
    let { loading, otpSecretKey, user } = this.props;

    let { editing, password } = this.state;

    const mfaEnabled = user && user.mfa_enabled;

    return (
      <div>
        <SubTabBack />
        <div className="profile-section">
          <div className="header">
            <h1>Two-Factor Authentication</h1>

            <button
              className="custom-button section-action"
              onClick={this.toggleEdit}
            >
              {editing ? "Cancel" : "Edit"}
            </button>
          </div>

          <p>
            Two-factor authentication is{" "}
            <span>{mfaEnabled ? "enabled" : "disabled"} for your account</span>.
          </p>

          {editing && (
            <div>
              {!otpSecretKey ? (
                <MfaSetup profileId={this.profileId} />
              ) : (
                <MfaConfig profileId={this.profileId} />
              )}
            </div>
          )}
        </div>
      </div>
    );
  }

  toggleEdit = () => {
    if (this.state.editing) {
      this.handleCancel();
    } else {
      this.setState({ editing: true });
    }
  };

  handleCancel = () => {
    this.props.clearFieldSpecificErrors();
    this.props.newGeneralErrors([]);
    this.setState({ editing: false, password: "" });
  };

  handleSubmit = () => {
    let { contact, creating, editingId } = this.state;
    this.props.clearFieldSpecificErrors();
    this.props.newGeneralErrors([]);
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TwoFactor)
);
