export let curriculum = {
  skipped:
    "https://storage.googleapis.com/workitassets/images/dashboard/icons/skipped.png",
  play:
    "https://storage.googleapis.com/workit-client-assets/courses/Play1.5.svg",
  playGray:
    "https://storage.googleapis.com/workit-client-assets/courses/Play1.5gray.svg",
  playGreen:
    "https://storage.googleapis.com/workit-client-assets/icons/courses/greenPlay.svg",
  locked:
    "https://storage.googleapis.com/workitassets/images/dashboard/icons/Lock.png",
  down:
    "https://storage.googleapis.com/workitassets/images/dashboard/icons/down.png",
  flash:
    "https://storage.googleapis.com/workitassets/images/dashboard/icons/Flash.png",
  next:
    "https://storage.googleapis.com/workitassets/images/dashboard/icons/Next.png",
  arrowForward:
    "https://storage.googleapis.com/workitassets/images/dashboard/icons/arrow-forward-white.svg",
  list:
    "https://storage.googleapis.com/workitassets/images/dashboard/icons/list-bullet-white.svg",
  whiteChevronDown:
    "https://storage.googleapis.com/workit-client-assets/courses/whiteChevronDown.svg",
  grayChevronDown:
    "https://storage.googleapis.com/workit-client-assets/courses/grayChevronDown.svg",
  greenCheck:
    "https://storage.googleapis.com/workit-client-assets/courses/green_check_circle-24px.svg",
  navyCheck:
    "https://storage.googleapis.com/workit-client-assets/courses/navyCheck.svg",
};
