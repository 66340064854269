import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { DateTime } from "luxon";

import { fetchProgram } from "patient_app/api/profileActions";
import LoadingSpinner from "patient_app/components/utils/LoadingSpinner";
import SubTabBack from "patient_app/views/profiles/partials/SubTabBack";

import { mobileCheck } from "patient_app/helpers/supported";

const TreatmentPlans = (props) => {
  const dispatch = useDispatch();
  const params = useParams();

  const [treatmentPlans, user, userTreatmentPlans] = useSelector((state) => {
    return [
      state.treatmentPlan.treatmentPlans,
      state.common.user,
      state.treatmentPlan.userTreatmentPlans,
    ];
  });
  const [expanded, setExpanded] = useState([]);

  useEffect(() => {
    if (mobileCheck()) {
      dispatch(fetchProgram(params.id, ["program_goals"]));
    }
  }, []);

  const toggleDetails = (planId) => {
    let updated = [...expanded];
    const idx = updated.indexOf(planId);
    if (idx > -1) {
      updated.splice(idx);
    } else {
      updated.push(planId);
    }
    setExpanded(updated);
  };

  const renderPlans = () => {
    console.log("uh?", userTreatmentPlans);
    if (!userTreatmentPlans.length) {
      return (
        <div className="list-item none">
          <div className="row">
            <p>Message your Counselor to update your Treatment Plan.</p>
          </div>
        </div>
      );
    }

    return userTreatmentPlans.map((planId, i) => {
      const plan = treatmentPlans[planId];
      if (!plan) {
        return <div />;
      }

      return (
        <div className="list-item" key={i}>
          <div className="row">
            <p>{DateTime.fromISO(plan.approved_at).toFormat("LL/dd/yy")}</p>
            <div className="col align-right">
              <button
                className="custom-button item-action"
                onClick={() => toggleDetails(planId)}
              >
                View
              </button>
            </div>
          </div>

          {expanded.includes(planId) && (
            <div className="expanded-details">
              <div className="line">
                <p>Treatment Goal</p>
                <p>{plan.clinical_goal}</p>
              </div>

              <div className="line">
                <p>Treatment Objective</p>
                <p>{plan.objective}</p>
              </div>

              <div className="line">
                <p>Available Support Services</p>
                <p>{plan.intervention}</p>
              </div>
            </div>
          )}
        </div>
      );
    });
  };

  return (
    <div>
      <SubTabBack />
      <div className="profile-section">
        <h1>Treatment Plans</h1>

        {!userTreatmentPlans && <LoadingSpinner />}
        {userTreatmentPlans && (
          <div className="profile-list treatment-plans">{renderPlans()}</div>
        )}
      </div>
    </div>
  );
};

export default TreatmentPlans;
