import React from "react";
import ReactDOM from "react-dom/client";
import App from "../patient_app/App";
import "core-js/stable";
import "regenerator-runtime/runtime";
import sentryHelper from "../patient_app/helpers/sentryHelper";

document.addEventListener("DOMContentLoaded", () => {
  const container = ReactDOM.createRoot(
    document.getElementById("Client-App-Entry")
  );
  sentryHelper.init();
  container.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
});
