import { applyMiddleware, createStore } from "redux";

import { createLogger } from "redux-logger";
import thunk from "redux-thunk";
import {
  promiseMiddleware,
  localStorageMiddleware,
} from "patient_app/middleware";
import reducer from "patient_app/reducer";

// import createHistory from 'history/createBrowserHistory'; // losing support in next major release
import { createBrowserHistory } from "history";

export const history = createBrowserHistory();

export const store = createStore(
  reducer,
  applyMiddleware(thunk),
);
