import PropTypes from "prop-types";
import React from "react";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  fetchPayment,
  chargePrimaryCard,
} from "patient_app/api/profileActions";
import {
  NEW_FIELD_SPECIFIC_ERROR,
  NEW_ERRORS,
  CLEAR_FIELD_SPECIFIC_ERRORS,
} from "patient_app/constants/actionTypes";

import LoadingSpinner from "patient_app/components/utils/LoadingSpinner";
import SubTabBack from "patient_app/views/profiles/partials/SubTabBack";

import { mobileCheck } from "patient_app/helpers/supported";
import permissions from "patient_app/helpers/permissions";

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPayment: (profileId, params) =>
      dispatch(fetchPayment(profileId, params)),
    chargePrimaryCard: (profileId, user) =>
      dispatch(chargePrimaryCard(profileId, user)),
  };
};

const mapStateToProps = (state) => {
  return {
    loading: state.profile.loading,
    paymentInfo: state.profile.paymentInfo,
    user: state.common.user,
  };
};

class OutstandingPayments extends React.Component {
  profileId = this.props.match.params.id;

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (mobileCheck()) {
      this.props.fetchPayment(this.profileId, ["payment_details"]);
    }
  }

  render() {
    let { loading, paymentInfo, user } = this.props;

    let amountOwed = 0;
    let amountDue = 0;
    if (paymentInfo && paymentInfo.outstanding_balance) {
      amountOwed = paymentInfo.outstanding_balance.total
        ? paymentInfo.outstanding_balance.total
        : 0;
      amountDue = paymentInfo.outstanding_balance.due
        ? paymentInfo.outstanding_balance.due
        : 0;
    }

    return (
      <div>
        <SubTabBack />
        <div className="profile-section">
          <h1>Outstanding Payments</h1>

          {!paymentInfo && <LoadingSpinner />}

          {paymentInfo && !amountOwed && (
            <div className="profile-list payment">
              <div className="list-item none">
                <div className="row">
                  <p>No outstanding balance.</p>
                </div>
              </div>
            </div>
          )}

          {paymentInfo && amountOwed > 0 && (
            <div className="profile-list payment">
              <div className="list-item">
                <div className="row">
                  <p>
                    <span>Total Outstanding Balance</span>
                  </p>
                  <div className="col align-right">
                    <p>${amountOwed.toFixed(2)}</p>
                  </div>
                </div>
              </div>

              <div className="list-item">
                <div className="row">
                  <p>
                    <span>Amount Due</span>
                  </p>
                  <div className="col align-right">
                    {amountDue > 0 && paymentInfo.card_brand && (
                      <button
                        className="custom-button item-action"
                        onClick={() =>
                          this.props.chargePrimaryCard(this.profileId, user)
                        }
                      >
                        {loading ? (
                          <LoadingSpinner />
                        ) : (
                          `Charge Amount to ${paymentInfo.card_brand} (${paymentInfo.card_digits})`
                        )}
                      </button>
                    )}

                    <p>${amountDue.toFixed(2)}</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OutstandingPayments)
);
