import PropTypes from "prop-types";
import React from "react";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { fetchPayment } from "patient_app/api/profileActions";
import {
  PROFILE_UPDATE_STATE_LOCAL,
  NEW_ERRORS,
  NEW_FIELD_SPECIFIC_ERROR,
  CLEAR_FIELD_SPECIFIC_ERRORS,
} from "patient_app/constants/actionTypes";

import MainTabBack from "patient_app/views/profiles/partials/MainTabBack";
import MembershipPlan from "patient_app/views/profiles/partials/payment/MembershipPlan";
import PaymentDetails from "patient_app/views/profiles/partials/payment/PaymentDetails";
import OutstandingPayments from "patient_app/views/profiles/partials/payment/OutstandingPayments";
import PatientInvoices from "patient_app/views/profiles/partials/payment/PatientInvoices";
import PaymentHistory from "patient_app/views/profiles/partials/payment/PaymentHistory";

import { getUrlVars } from "patient_app/helpers/linkHelpers";
import { mobileCheck } from "patient_app/helpers/supported";
import layout from "patient_app/stylesheets/profiles/layout";

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPayment: (profileId, params) =>
      dispatch(fetchPayment(profileId, params)),
    setProfileState: (obj, value) =>
      dispatch({ type: PROFILE_UPDATE_STATE_LOCAL, obj: obj, value: value }),
    clearFieldSpecificErrors: () =>
      dispatch({ type: CLEAR_FIELD_SPECIFIC_ERRORS }),
    newGeneralErrors: (errors) =>
      dispatch({ type: NEW_ERRORS, errors: errors }),
  };
};

const mapStateToProps = (state) => {
  return {
    mainTab: state.profile.mainTab,
    subTab: state.profile.subTab,
  };
};

class Payment extends React.Component {
  profileId = this.props.match.params.id;

  componentDidMount = async () => {
    document.title = "Payment | Workit Health";
    const paymentTabs = [
      "membership_plan",
      "payment_details",
      "outstanding_payments",
      "patient_invoices",
      "payment_history",
    ];
    if (!mobileCheck()) {
      this.props.fetchPayment(this.profileId, paymentTabs);
      this.props.setProfileState("subTab", paymentTabs);
    } else {
      const tab = getUrlVars(window.location.href)["tab"];
      if (tab && paymentTabs.includes(tab)) {
        this.props.setProfileState("subTab", [tab]);
      }
    }

    this.props.clearFieldSpecificErrors();
    this.props.newGeneralErrors([]);
  };

  render() {
    let { mainTab, subTab } = this.props;

    return (
      <div>
        {mainTab.length > 0 && subTab.length === 0 && (
          <nav
            className="profile-sidebar"
            role="navigation"
            aria-label="Profile navigation"
          >
            <MainTabBack />

            <button
              className="custom-button tab"
              onClick={() =>
                this.props.setProfileState("subTab", ["membership_plan"])
              }
            >
              Your Membership Plan
            </button>

            <button
              className="custom-button tab"
              onClick={() =>
                this.props.setProfileState("subTab", ["payment_details"])
              }
            >
              Payment Details
            </button>

            <button
              className="custom-button tab"
              onClick={() =>
                this.props.setProfileState("subTab", ["outstanding_payments"])
              }
            >
              Outstanding Payments
            </button>

            <button
              className="custom-button tab"
              onClick={() =>
                this.props.setProfileState("subTab", ["patient_invoices"])
              }
            >
              Member Invoices
            </button>

            <button
              className="custom-button tab"
              onClick={() =>
                this.props.setProfileState("subTab", ["payment_history"])
              }
            >
              Payment History
            </button>
          </nav>
        )}

        {subTab.includes("membership_plan") && <MembershipPlan />}
        {subTab.includes("payment_details") && <PaymentDetails />}
        {subTab.includes("outstanding_payments") && <OutstandingPayments />}
        {subTab.includes("patient_invoices") && <PatientInvoices />}
        {subTab.includes("payment_history") && <PaymentHistory />}

        <style jsx>{layout}</style>
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Payment)
);
