import PropTypes from "prop-types";
import React from "react";
import { DateTime } from "luxon";
import { connect } from "react-redux";

import { Link } from "react-router-dom";
import { fetchRandomDrugTest } from "patient_app/api/randomDrugTestActions";
import { startDrugTest } from "patient_app/api/zoomEventActions";
import {
  OPEN_CHAT,
  APPTS_DRUG_TEST_DETAILS,
} from "patient_app/constants/actionTypes";

import { getIconUrl } from "patient_app/helpers/linkHelpers";
import textHelpers from "patient_app/helpers/textHelpers";
import drugTestStyles from "patient_app/stylesheets/appointments/drugTestStyles";
import dashboardBlockStyles from "patient_app/stylesheets/appointments/dashboardBlockStyles";

const mapDispatchToProps = (dispatch) => {
  return {
    fetchRandomDrugTest: () => dispatch(fetchRandomDrugTest()),
    openChat: (data) => dispatch({ type: OPEN_CHAT, data: data }),
    showDetails: (view) =>
      dispatch({ type: APPTS_DRUG_TEST_DETAILS, view: view }),
    startDrugTest: () => dispatch(startDrugTest()),
  };
};

const mapStateToProps = (state) => {
  return {
    clinic: state.common.clinic,
    healthInfo: state.onboarding.healthInfo,
    profile: state.common.profile,
    test: state.randomDrugTest.test,
    user: state.common.user,
  };
};

class DrugTesting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      roomStatus: "closed",
    };
  }

  componentDidMount() {
    let { clinic, healthInfo } = this.props;
    if (healthInfo && healthInfo.drug_test_regimen === "RANDOMIZED") {
      this.props.fetchRandomDrugTest();
    }

    clinic && this.getRoomStatus();

    // refresh canJoinRoom every minute
    this.interval = setInterval(this.getRoomStatus, 60000);
  }

  componentDidUpdate(prevProps) {
    if (
      !prevProps.healthInfo &&
      this.props.healthInfo &&
      this.props.healthInfo.drug_test_regimen === "RANDOMIZED"
    ) {
      this.props.fetchRandomDrugTest();
    }

    if (!prevProps.clinic && this.props.clinic) {
      this.getRoomStatus();
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    let { clinic, healthInfo, profile, test, user } = this.props;

    let { roomStatus } = this.state;

    let drugTestUrl = clinic?.drug_test_url;
    let randomTestStatus = clinic && test && this.calculateTestDue(test);

    return (
      <div className="dashboard-block">
        {healthInfo &&
          healthInfo.drug_test_regimen === "RANDOMIZED" &&
          this.renderRandomDrugTest()}

        {roomStatus == "closed" && (
          <div className="block-item">
            <div className="details">
              <p className="description">Testing Video Room</p>
            </div>
            <p className={`badge red`}>
              {roomStatus === "closed" ? "Closed" : ""}
            </p>
          </div>
        )}
        {roomStatus == "open" && (
          <button
            className="custom-button block-item"
            onClick={() => this.enterDrugTest(drugTestUrl)}
          >
            <div className="details">
              <p>Enter Testing Video Room</p>
            </div>
            <p className={`badge ${roomStatus}`}>
              {roomStatus === "open" ? "Open" : ""}
            </p>
            <img src={getIconUrl("chevron-right", "dark-blue")} />
          </button>
        )}

        <button
          className="custom-button block-item"
          onClick={() => this.props.showDetails("times")}
        >
          <div className="details">
            <p>Drug Testing Hours</p>
          </div>
          <img src={getIconUrl("chevron-right", "dark-blue")} />
        </button>

        {/* <a
          href="https://www.workithealth.com/vdt-how-to-prepare/"
          target="_blank"
          className="custom-button block-item"
        >
          <div className="details">
            <p>How to Prepare</p>
          </div>
          <img src={getIconUrl("chevron-right", "dark-blue")} />
        </a> */}

        <a
          href="https://www.workithealth.com/vdt-what-to-expect/"
          target="_blank"
          className="block-item"
        >
          <div className="details">
            <p>Frequently Asked Questions</p>
          </div>
          <img src={getIconUrl("chevron-right", "dark-blue")} />
        </a>

        <a
          className="custom-button block-item"
          onClick={() => this.props.history.push("/drug_test_orders/new")}
        >
          <div className="details">
            <p>Order More Tests</p>
          </div>
          <img src={getIconUrl("chevron-right", "dark-blue")} />
        </a>

        <button
          className="custom-button block-item no-border"
          onClick={() =>
            this.props.openChat({
              view: "index",
              topic: "MEDICAL",
            })
          }
        >
          <div className="details">
            <p>Questions? Message Support</p>
          </div>
          <img src={getIconUrl("chevron-right", "dark-blue")} />
        </button>

        <style jsx>{drugTestStyles}</style>
        <style jsx>{dashboardBlockStyles}</style>
      </div>
    );
  }

  enterDrugTest = (drugTestUrl) => {
    this.props.startDrugTest();
    window.location.href = drugTestUrl;
  };

  calculateTestDue(test) {
    let { clinic } = this.props;
    let status = "not_due";
    const dueStart = DateTime.fromISO(test.due_date)
      .setZone(clinic.time_zone)
      .startOf("day")
      .minus({ days: 1 })
      .set({ hour: 8, minute: 30 });
    // drug test is due 48 hours after alert, so 8:30am the day of due_date
    const dueEnd = dueStart.plus({ days: 1 }).endOf("day");

    if (DateTime.local() >= dueStart && DateTime.local() <= dueEnd) {
      status = "due_today";
    } else if (DateTime.local() > dueEnd) {
      status = "overdue";
    }

    return status;
  }

  renderRandomDrugTest() {
    let { clinic, test } = this.props;

    let testText = "You have no tests due at this time.";
    let testType =
      test && test.test_type === "ten_panel" ? "urinalysis" : "saliva based";
    const status = clinic && test ? this.calculateTestDue(test) : "not_due";
    const dueDate =
      clinic &&
      test &&
      DateTime.fromISO(test.due_date)
        .setZone(clinic.time_zone)
        .startOf("day")
        .set({ hour: 12 });

    let message = "I have a question about drug testing.";
    if (status && status === "overdue") {
      message = "I missed my random drug test. What do I do?";
    }

    if (status === "due_today") {
      testText = `It's time for you to take a ${testType} drug test.`;
      if (test.test_type === "oral_fluid_swab") {
        testText +=
          " Do not eat or drink anything for 30 minutes before testing.";
      }

      return (
        <div className={`test-due ${status}`}>
          <p>
            {testText} You need to complete your test by the end of the day on{" "}
            {dueDate.toFormat("cccc, LLLL d")}.
          </p>
          <style jsx>{drugTestStyles}</style>
        </div>
      );
    } else if (status === "overdue") {
      return (
        <div className={`test-due ${status}`}>
          <p>You are overdue for a {testType} drug test.</p>
          <style jsx>{drugTestStyles}</style>
        </div>
      );
    }
  }

  getRoomStatus = () => {
    let { clinic, profile } = this.props;
    const timeZone =
      clinic?.time_zone || profile?.time_zone || DateTime.local().zoneName;
    let status = "closed";

    if (clinic?.testing_times && clinic.testing_times.length > 0) {
      // determine if current time is in drug testing hours
      let today = DateTime.local().setZone(timeZone);
      let dtt = clinic.testing_times.find(
        (time) => time.day === today.weekdayLong
      );
      if (dtt) {
        let startAt = DateTime.local()
          .setZone(timeZone)
          .startOf("day")
          .set({ hour: dtt.sa_h, minute: dtt.sa_m });
        let endAt = DateTime.local()
          .setZone(timeZone)
          .startOf("day")
          .set({ hour: dtt.ea_h, minute: dtt.ea_m });
        if (today > startAt && today < endAt) {
          status = "open";
        }
      }
    }

    this.setState({ roomStatus: status });
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DrugTesting);
