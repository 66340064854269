import css from "styled-jsx/css";
import { variables } from "patient_app/stylesheets/variables.js";

export default css.global`
  body #Main-Container .content-box .inputs {
    margin-bottom: 0;
  }
  .secure-payments-container {
    width: 100%;
    height: 4rem;
    background: ${variables.offGray};
    border-top: 1px solid #dadada;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 0.5rem;
    border-radius: 0 0 4px 4px;
  }

  .secure-payments-container p {
    margin: 0;
    margin-right: 0.15rem;
    font-size: 0.75rem;
    font-family: ${variables.objReg};
  }

  .secure-payments-container img {
    height: 1.75rem;
    margin-top: 3px;
  }

  .links-container {
    text-align: center;
  }

  .links-container a {
    font-size: 0.8rem;
    font-family: ${variables.objMed};
    margin-bottom: 0.5rem;
  }

  .purchase-subtitle {
    margin-bottom: 1.5rem;
    text-align: center;
  }

  .custom-button.skip {
    width: 100%;
    height: 3rem;
    color: ${variables.blueDark};
    border: 1px solid ${variables.grayTrim};
    border-radius: 4px;
    margin: 1rem 0;
    font-size: 1rem;
    font-family: ${variables.objReg};
    text-decoration: none;
  }

  .payment button.other-option {
    width: 100%;
    color: ${variables.green};
    margin-top: 1rem;
    font-size: 0.85rem;
    font-family: ${variables.objMed};
    text-decoration: underline;
  }

  @media only screen and (max-width: 600px) {
    .links-container {
      text-align: left;
    }

    p.description {
      flex: 0;
    }

    .purchase-subtitle {
      font-size: 1rem;
    }

    .secure-payments-container {
      flex: 1;
    }
  }
`;
