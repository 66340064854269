export const ERRORS = {
  INITIAL_ERROR: "Link no longer valid. Resend link to member.",
  NOT_SUPPORTED:
    "Your device does not support video calling. Please use a different device.",
};

export const MEDIA_DEVICE_ERRORS = {
  ABORT_ERROR: "AbortError",
  NOT_ALLOWED_ERROR: "NotAllowedError",
  NOT_FOUND_ERROR: "NotFoundError",
  NOT_READABLE_ERROR: "NotReadableError",
  OVERCONSTRAINED_ERROR: "OvercontrainedError",
  SECURITY_ERROR: "SecurityError",
  TYPE_ERROR: "TypeError",
};

export const TWILIO_ERROR_CODES = {
  ACCESS_TOKEN_INVALID: 20101,
  ACCES_TOKEN_EXPIRED: 20104,
  DUPLICATE_IDENTITY: 53205,
  SIGNALING_CONNECTION: 53405,
  MEDIA_CONNECTION: 53001,
  ROOM_NOT_FOUND: 53106,
};
