import PropTypes from "prop-types";
import React from "react";

class ForwardArrow extends React.Component {
  static propTypes = {
    color: PropTypes.string,
  };

  static defaultProps = {
    color: "#1ac493",
  };

  render() {
    let { color } = this.props;

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill={color}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        role="img"
        aria-labelledby="fwd-svg-id"
      >
        <title id="fwd-svg-id">Go Forward</title>
        <path d="M0 0h24v24H0z" fill="none" />
        <path
          fill="#1A7A65"
          d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"
        />
      </svg>
    );
  }
}

export default ForwardArrow;
