import css from "styled-jsx/css";
import { variables } from "patient_app/stylesheets/variables";

export default css.global`
  nav.patient {
    height: calc(16px * 4.5);
    background-color: white;
    position: relative;
    border-bottom: 1px solid hsl(225, 9%, 91%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }

  nav.patient #skip-to-content {
    position: absolute;
    top: 0;
    left: 0;
    padding: 5px 11px;
    color: white;
    background-color: ${variables.blueDark};
    font-size: 0.9rem;
    font-family: ${variables.objBold};
    border-bottom-right-radius: ${variables.tiny};
    opacity: 0;
    z-index: -1;
  }

  nav.patient #skip-to-content:focus {
    z-index: 1;
    opacity: 1;
  }

  nav.patient .logo-svg {
    height: ${variables.base};
    width: ${variables.base};
    margin: auto ${variables.small};
  }

  nav.patient .logo-svg img {
    width: 100%;
    height: 100%;
  }

  nav.patient .main-links-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: ${variables.base};
  }

  nav.patient .main-links-container .link {
    margin: auto ${variables.small};
    border-bottom: 2px solid white;
  }

  nav.patient .main-links-container .link a {
    color: ${variables.green};
    font-family: ${variables.objBold};
    text-align: center;
    display: block;
    font-size: 0.9rem;
    position: relative;
    text-decoration: none;
  }

  nav.patient .main-links-container .link.selected,
  nav.patient .main-links-container .link:hover {
    border-bottom: 2px solid ${variables.green};
  }

  nav.patient .dropdown-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    z-index: 10;
    padding-right: ${variables.base};
    white-space: nowrap;
  }

  nav.patient .dropdown-container.disabled .links > * {
    pointer-events: none;
  }

  nav.patient .dropdown-container .trigger {
    font-family: ${variables.objBold};
    font-size: 0.9rem;
    color: ${variables.blueDark};
  }

  nav.patient .dropdown-container button.nav-trigger {
    height: 100%;
  }

  nav.patient .dropdown-container button.nav-trigger .trigger {
    display: flex;
    height: 100%;
  }

  nav.patient .dropdown-container button.nav-trigger .trigger.desktop {
    display: flex;
  }

  nav.patient .dropdown-container button.nav-trigger .trigger.mobile {
    display: none;
  }

  nav.patient .dropdown-container button.nav-trigger .trigger img.arrow {
    position: relative;
    margin-left: ${variables.small};
  }

  nav.patient .dropdown-container .links {
    position: absolute;
    right: ${variables.base};
    top: calc(16px * 4.5);
    width: calc(16px * 10);
    border-radius: 3px;
    background-color: white;
    z-index: 100;
    display: none;
  }

  nav.patient .dropdown-container .links.expanded {
    display: block;
  }

  nav.patient .dropdown-container .links ul {
    padding: 0;
    margin: 0;
    border: 1px solid ${variables.grayLight};
  }

  nav.patient .dropdown-container .links ul li {
    position: relative;
    width: 100%;
    padding: 12px;
    border-bottom: 1px solid ${variables.grayLight};
    box-sizing: border-box;
  }

  nav.patient .dropdown-container .links ul li.header,
  nav.patient .dropdown-container .links ul li.footer {
    display: none;
  }

  nav.patient .dropdown-container .links ul li:last-of-type {
    border-bottom: 0;
  }

  nav.patient .dropdown-container .links ul li a,
  nav.patient .dropdown-container .links ul li button {
    display: block;
    font-family: ${variables.objReg};
    font-size: 0.85rem;
    text-align: left;
    width: 90%;
    color: ${variables.blueDark};
    text-decoration: underline;
    margin: 0;
  }

  nav.patient .dropdown-container .links ul li a:focus,
  nav.patient .dropdown-container .links ul li button:focus {
    background-color: ${variables.grayWhite};
    text-decoration: underline;
  }

  nav.patient .dropdown-container .links ul li:hover {
    background-color: ${variables.grayWhite};
    text-decoration: underline;
  }

  nav.patient .toggle-links {
    display: flex;
  }

  nav.patient .chat {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    padding: calc(16px * 1.5);
  }

  nav.patient .chat button.unread-messages {
    position: relative;
  }

  nav.patient .chat button.unread-messages img {
    height: ${variables.base};
    width: ${variables.base};
  }

  nav.patient .chat button.unread-messages .badge {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 0.8rem;
    font-weight: bolder;
    padding-bottom: 6px;
  }
}

  @media only screen and (max-width: 768px) {
    nav.patient .main-links-container {
      flex: 1;
      padding: 0;
      justify-content: center;
    }

    nav.patient .main-links-container .link {
      display: none;
    }

    nav.patient .dropdown-container {
      position: absolute;
      padding: calc(16px * 1.5);
      left: 0;
      top: 0;
      max-width: calc(16px * 5);
    }

    nav.patient .dropdown-container button.nav-trigger .trigger.mobile {
      display: flex;
    }

    nav.patient .dropdown-container button.nav-trigger .trigger.desktop {
      display: none;
    }

    nav.patient .dropdown-container button.nav-trigger .trigger i {
      margin-left: 0;
      margin-right: 1rem;
      top: 0;
      font-size: 29px;
    }

    nav.patient .dropdown-container .links {
      left: 0;
      top: 0;
      position: fixed;
      width: 70vw;
      display: block;
      left: -70vw;
      transition: left 300ms linear;
      bottom: 0;
      margin: -1px 0 -1px -1px;
      background: linear-gradient(${variables.blueDark}, #4067e6);
    }

    nav.patient .dropdown-container .links.expanded {
      left: 0;
    }

    nav.patient .dropdown-container .links ul {
      min-height: 100%;
      height: 100%;
    }

    nav.patient .dropdown-container .links ul li {
      padding: 20px 20px;
      box-sizing: border-box;
      border: 0;
    }

    nav.patient .dropdown-container .links ul li a,
    nav.patient .dropdown-container .links ul li button {
      padding: 0;
      margin: 0;
      color: white;
      font-size: 1.25rem;
      font-family: ${variables.objReg};
    }

    nav.patient .dropdown-container .links ul li a:focus,
    nav.patient .dropdown-container .links ul li button:focus {
      background-color: transparent;
    }

    nav.patient .dropdown-container .links ul li:hover {
      background-color: transparent;
    }

    nav.patient .dropdown-container .links ul li.header.mobile {
      display: flex;
      flex-direction: row;
      padding: 20px 20px;
      font-size: 1.25rem;
      color: white;
      box-sizing: border-box;
    }

    nav.patient .dropdown-container .links ul li.header.mobile:hover {
      background: transparent;
      text-decoration: none;
    }

    nav.patient .dropdown-container .links ul li.header.mobile img {
      height: ${variables.base};
      width: ${variables.base};
      object-fit: cover;
      border-radius: 50%;
      display: block;
      position: relative;
      margin-right: ${variables.small};
    }

    nav.patient .dropdown-container .links ul li.header.mobile > div {
      flex: 1;
      display: flex;
      flex-direction: column;
      font-size: 0.85rem;
    }

    nav.patient .dropdown-container .links ul li.header.mobile > div > div:nth-child(1) {
      font-family: ${variables.objReg};
    }

    nav.patient .dropdown-container .links ul button.sign-out {
      padding: 20px 20px;
      font-size: 1.25rem;
      color: white;
      box-sizing: border-box;
      text-align: left;
      font-family: ${variables.objReg};
      width: 100%;
    }

    nav.patient .dropdown-container .links ul li.footer.mobile {
      display: flex;
      position: absolute;
      bottom: 0;
      left: initial;
      right: 0;
      height: calc(16px * 4.5);
      width: calc(16px * 10);
    }

    nav.patient .dropdown-container .links ul li.footer.mobile button img {
      position: absolute;
      right: ${variables.small};
      height: ${variables.base};
      bottom: ${variables.small};
    }

    nav.patient .chat {
      display: block;
    }
  }
`;
