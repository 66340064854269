import {
  GTC_REQ_START,
  GTC_REQ_SUCCESS,
  GTC_REQ_FAILURE,
	GTC_VIEW,
  GTC_LOAD_STATE,
} from 'patient_app/constants/actionTypes';

const initialState = {
  view: "",
  loading: false,
  success: false,

  // profile
  emergencyContacts: null,

  // recovery
  substanceHistories: null,
  substanceUses: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GTC_REQ_START:
      return {
        ...state,
        loading: true,
        success: false,
      }
    case GTC_REQ_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      }
    case GTC_REQ_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
      }
  	case GTC_VIEW:
  		return {
        ...state,
        view: action.view,
      };
    case GTC_LOAD_STATE:
      return {
        ...state,
        [action.key]: action.value
      }
    default:
      return state;
  }
};
