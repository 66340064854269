import PropTypes from "prop-types";
import React from "react";
import cn from "classnames";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  createInsuranceDetails,
  updateInsuranceDetails,
  destroyInsuranceDetails,
  // skipInsuranceUpload,
} from "patient_app/api/insuranceActions";
import { fetchInsurance, createDocument } from "patient_app/api/profileActions";
import { skipPendingItem } from "patient_app/api/pendingItemActions";
import {
  NEW_FIELD_SPECIFIC_ERROR,
  NEW_ERRORS,
  CLEAR_FIELD_SPECIFIC_ERRORS,
} from "patient_app/constants/actionTypes";
import { relationshipToPolicyholderOptions } from "patient_app/helpers/insuranceHelpers";

import ProfileField from "patient_app/views/profiles/partials/ProfileField";
import TextSearchField from "patient_app/components/input_fields/TextSearchField";
import LoadingSpinner from "patient_app/components/utils/LoadingSpinner";
import DragAndDrop from "patient_app/components/utils/DragAndDrop";
import MainTabBack from "patient_app/views/profiles/partials/MainTabBack";

import assets from "patient_app/components/chat/assets";
import { mobileCheck } from "patient_app/helpers/supported";
import { getUrlVars } from "patient_app/helpers/linkHelpers";
import permissions from "patient_app/helpers/permissions";
import documentsStyles from "patient_app/stylesheets/profiles/documentsStyles";

const mapDispatchToProps = (dispatch) => {
  return {
    fetchInsurance: (profileId) => dispatch(fetchInsurance(profileId)),
    createInsuranceDetails: (insuranceDetail, resultsCallback) =>
      dispatch(createInsuranceDetails(insuranceDetail, resultsCallback)),
    updateInsuranceDetails: (insuranceDetail, resultsCallback) =>
      dispatch(updateInsuranceDetails(insuranceDetail, resultsCallback)),
    destroyInsuranceDetails: (id) => dispatch(destroyInsuranceDetails(id)),
    createDocument: (profileId, formData) =>
      dispatch(createDocument(profileId, formData)),
    // skipInsuranceUpload: (history) => dispatch(skipInsuranceUpload(history)),
    skipPendingItem: (title, uid) => dispatch(skipPendingItem(title, uid)),
    newFieldSpecificError: (field, error) =>
      dispatch({ type: NEW_FIELD_SPECIFIC_ERROR, field: field, error: error }),
    newGeneralErrors: (errors) =>
      dispatch({ type: NEW_ERRORS, errors: errors }),
    clearFieldSpecificErrors: () =>
      dispatch({ type: CLEAR_FIELD_SPECIFIC_ERRORS }),
  };
};

const mapStateToProps = (state) => {
  return {
    fieldSpecificErrors: state.common.fieldSpecificErrors,
    insuranceDetails: state.insurance.insuranceDetails,
    insuranceProviders: state.insurance.insuranceProviders,
    healthInfo: state.onboarding.healthInfo,
    loading: state.insurance.loading,
    pendingItemSuccess: state.pendingItem.success,
    profile: state.common.profile,
    success: state.insurance.success,
    user: state.common.user,
  };
};

class Insurance extends React.Component {
  profileId = this.props.match.params.id;
  fromChecklist = getUrlVars(this.props.location.search)["from_checklist"];
  fromTodo = getUrlVars(this.props.location.search)["redirect"] === "todo";

  constructor(props) {
    super(props);
    this.state = {
      commercial: true,
      medicaid: false,
      medicare: false,
      coverageInfo: {
        plan_name: "",
        other: "",
        insurance_provider_id: "",
        group_id: "",
        member_id: "",
        fir_name: "",
        las_name: "",
        dob: "",
        state: "",
        zip_code: "",
        files: null,
        relationship_to_policyholder: "",
      },
      editingId: null,
      showForm: false,
      showSSNField: false,
      expanded: [],
    };
  }

  componentDidMount() {
    document.title = "Insurance | Workit Health";
    this.props.fetchInsurance(this.profileId);

    this.props.user && this.props.profile && this.initializeCoverageInfo();
    if (this.fromChecklist || this.fromTodo) {
      this.setState({ showForm: true, editingId: null });
    }

    this.props.clearFieldSpecificErrors();
    this.props.newGeneralErrors([]);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.success && this.props.success) {
      if (this.state.showForm) {
        this.handleCancel();
      }
    }

    if (!prevProps.pendingItemSuccess && this.props.pendingItemSuccess) {
      // user skipped insurance pending item, go back to checklist
      if (this.fromChecklist) {
        this.props.history.push("/users/checklist");
      } else if (this.fromTodo) {
        this.props.history.push("/appointments");
      }
    }
  }

  render() {
    let {
      fieldSpecificErrors,
      insuranceDetails,
      insuranceProviders,
      loading,
      profile,
      user,
    } = this.props;

    let { coverageInfo, showForm, showSSNField } = this.state;

    return (
      <div>
        <MainTabBack />

        <div className="profile-section last">
          <div className="header">
            <h1>Insurance</h1>
          </div>

          {!(insuranceDetails && insuranceProviders) && <LoadingSpinner />}

          {insuranceDetails && insuranceProviders && !showForm && (
            <div>
              <div className="profile-list insurance">
                {this.renderInsuranceDetails()}
              </div>

              <button
                className="custom-button link"
                onClick={() => this.setState({ showForm: true })}
              >
                Add a New Insurance Plan
              </button>
            </div>
          )}

          {insuranceDetails && insuranceProviders && showForm && (
            <div>
              {(this.fromChecklist || this.fromTodo) && (
                <p>
                  Share your insurance information so we can check your coverage
                  and better plan your care. Your information is totally
                  confidential, and we won't bill your insurance without your
                  permission.
                </p>
              )}

              <TextSearchField
                field="plan_name"
                title="Your Insurance"
                value={coverageInfo.plan_name || ""}
                id="plan-name"
                onUpdateField={this.handleUpdateCoverageInfo}
                onKeyPress={this.handleKeyPress}
                options={this.filterInsuranceProviders()}
                uid={"plan_name"}
              />

              <ProfileField
                field="fir_name"
                fieldType="text"
                onUpdateField={this.handleUpdateCoverageInfo}
                title="Card Holder's First Name"
                value={coverageInfo.fir_name}
              />

              <ProfileField
                field="las_name"
                fieldType="text"
                onUpdateField={this.handleUpdateCoverageInfo}
                title="Card Holder's Last Name"
                value={coverageInfo.las_name}
              />

              <ProfileField
                field="dob"
                fieldType="date"
                onUpdateField={this.handleUpdateCoverageInfo}
                title="Card Holder's Date of Birth"
                value={coverageInfo.dob}
              />

              <ProfileField
                field="group_id"
                fieldType="text"
                onUpdateField={this.handleUpdateCoverageInfo}
                title="Group ID"
                value={coverageInfo.group_id}
              />

              <ProfileField
                field="member_id"
                fieldType="text"
                onUpdateField={this.handleUpdateCoverageInfo}
                title="Member ID"
                value={coverageInfo.member_id}
              />

              <ProfileField
                field="relationship_to_policyholder"
                fieldType="select"
                onUpdateField={this.handleUpdateCoverageInfo}
                title="Relationship to policyholder"
                value={coverageInfo.relationship_to_policyholder}
                options={relationshipToPolicyholderOptions()}
              />

              {/* no insurance check is run, so this isn't needed */}
              {/*(coverageInfo.plan_name === "Medi-Cal") && (
                <div>
                  {!showSSNField && (
                    <div className="medi-cal-section">
                      <ProfileField
                        field="issue_date"
                        fieldType="date"
                        onUpdateField={this.handleUpdateCoverageInfo}
                        title="Insurance Card Issue Date"
                        value={coverageInfo.issue_date}
                      />

                      <p>Don't know your issue date? <a className="link" href="#" onClick={this.handleShowSSN}>Use your social security number instead.</a></p>
                    </div>
                  )}

                  {showSSNField && (
                    <div className="medi-cal-section">
                      <ProfileField
                        field="ssn"
                        fieldType="custom"
                        formatting={{blocks: [3, 2, 4], delimiter: "-"}}
                        onUpdateField={this.handleUpdateCoverageInfo}
                        title="Social Security Number"
                        value={coverageInfo.ssn}
                      />

                      <p><a className="link" href="#" onClick={this.handleHideSSN}>Use your insurance card issue date instead.</a></p>
                    </div>
                  )}
                </div>
              )*/}

              {(coverageInfo.images || coverageInfo.image) && (
                <div className="profile-field">
                  <label htmlFor="insurance-image">
                    Uploaded Insurance Card
                  </label>

                  {coverageInfo.images &&
                    coverageInfo.images.map((image, j) => {
                      return (
                        <a
                          key={j}
                          href={image}
                          target="_blank"
                          style={{ width: "fit-content" }}
                        >
                          <img id="insurance-image" src={image} />
                        </a>
                      );
                    })}

                  {!coverageInfo.images && coverageInfo.image && (
                    <a
                      href={coverageInfo.image}
                      target="_blank"
                      style={{ width: "fit-content" }}
                    >
                      <img id="insurance-image" src={coverageInfo.image} />
                    </a>
                  )}
                </div>
              )}

              <DragAndDrop
                error={fieldSpecificErrors["ins_file"]}
                files={coverageInfo.files}
                onFileInput={this.handleFileInput}
                onRemoveFile={this.handleRemoveFile}
                allowMultiple={true}
              />

              <div className="submit-container">
                <button
                  className={`custom-button submit half ${
                    loading ? "loading" : ""
                  }`}
                  onClick={this.handleSubmit}
                >
                  Save Changes
                </button>

                {(this.fromChecklist || this.fromTodo) && (
                  <button
                    className={`custom-button submit other half ${
                      loading ? "loading" : ""
                    }`}
                    onClick={this.skipInsuranceUpload}
                  >
                    Skip insurance upload
                  </button>
                )}

                {!(this.fromChecklist || this.fromTodo) && (
                  <button
                    className={`custom-button submit other half ${
                      loading ? "loading" : ""
                    }`}
                    onClick={this.handleCancel}
                  >
                    Cancel
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  handleShowSSN = (e) => {
    if (e) {
      e.preventDefault();
    }
    // clear issueDate and show SSN field
    this.handleUpdateCoverageInfo(null, "issue_date");
    this.setState({ showSSNField: true });
  };

  handleHideSSN = (e) => {
    if (e) {
      e.preventDefault();
    }
    // clear SSN and hide SSN field
    this.handleUpdateCoverageInfo(null, "ssn");
    this.setState({ showSSNField: false });
  };

  initializeCoverageInfo = () => {
    let { profile, user } = this.props;
    let coverageInfo = this.state.coverageInfo;
    coverageInfo.fir_name = user.fir_name;
    coverageInfo.las_name = user.las_name;
    coverageInfo.dob = user.dob;
    coverageInfo.clinicId = profile.clinic_id;
    coverageInfo.state = profile.state;
    coverageInfo.zip_code = profile.zip_code;
    coverageInfo.tier = user.tier;
    coverageInfo.treatmentType = user.treatment_type;

    this.setState({ coverageInfo: coverageInfo });
  };

  filterInsuranceProviders() {
    let { insuranceProviders, profile, user } = this.props;
    if (!insuranceProviders) {
      return [];
    }

    let insurances = [];
    let userState = user && user.state;
    let showAllOptions = false;
    if (
      profile &&
      ["grant", "enterprise", "self_pay"].includes(profile.billing_type)
    ) {
      // if user's billing type is not insurance-based, show all options
      showAllOptions = true;
    }

    insuranceProviders.forEach((ip, i) => {
      let option = [ip.name, ip.id];
      let isInUserState =
        !userState || ip.states.length === 0 || ip.states.includes(userState);
      if (showAllOptions) {
        // if user's billing type is not insurance-based, show all options
        insurances.push(option);
      } else {
        // if user's billing type is insurance-based, only show options that cover workit
        if (isInUserState) {
          insurances.push(option);
        }
      }
    });

    if (showAllOptions) {
      insurances.push(["Other", "Other"]);
    }

    return insurances;
  }

  renderInsuranceDetails = () => {
    let { insuranceDetails, insuranceProviders, loading } = this.props;
    if (insuranceDetails.length === 0) {
      return (
        <div className="list-item none">
          <div className="row">
            <p>No insurance plans.</p>
          </div>
        </div>
      );
    }

    return insuranceDetails.map((ins, i) => {
      const showDetails = this.state.expanded.includes(ins.id);

      const insProvider =
        ins.insurance_provider_id &&
        insuranceProviders.find((ip) => ip.id === ins.insurance_provider_id);
      let planName = insProvider ? insProvider.name : ins.plan_name;
      if (planName === "Other") {
        planName = ins.other;
      }

      return (
        <div className="list-item" key={i}>
          <div className="row">
            <p>
              <span>
                {planName}{" "}
                {ins.member_id && ins.member_id.length > 0
                  ? `(${ins.member_id})`
                  : ""}
              </span>
            </p>
            <div className="col align-right">
              {ins.coverage_status !== "ACTIVE_COVERAGE" && (
                <div className="col align-right">
                  <button
                    className="custom-button item-action"
                    onClick={(e) => this.handleShowEdit(e, ins.id)}
                  >
                    Edit
                  </button>

                  <button
                    className={`custom-button item-action ${
                      loading ? "loading" : ""
                    }`}
                    onClick={() => this.props.destroyInsuranceDetails(ins.id)}
                  >
                    Remove
                  </button>
                </div>
              )}

              <button
                className="custom-button item-action"
                onClick={() => this.toggleDetails(ins.id)}
              >
                {showDetails ? "Hide" : "View"}
              </button>
            </div>
          </div>

          {showDetails && (
            <div className="expanded-details two-column">
              <div className="column">
                {!!this.getInsurancePriorityText(ins.priority) && (
                  <div className="line">
                    <p>
                      <span>Plan:</span>{" "}
                      {this.getInsurancePriorityText(ins.priority)}
                    </p>
                  </div>
                )}

                <div className="line">
                  <p>
                    <span>Member ID:</span> {ins.member_id}
                  </p>
                </div>

                <div className="line">
                  <p>
                    <span>Group ID:</span> {ins.group_id}
                  </p>
                </div>

                <div className="line">
                  <p>
                    <span>Coverage:</span>{" "}
                    {ins.coverage_status === "ACTIVE_COVERAGE"
                      ? "Benefits Verified"
                      : "Benefits Not Verified"}
                  </p>
                </div>

                <div className="line">
                  <p>
                    <span>Copay:</span>{" "}
                    {[null, undefined].includes(ins.copay) ||
                    ins.coverage_status !== "ACTIVE_COVERAGE"
                      ? "N/A"
                      : `$${(ins.copay / 100).toFixed(2)}`}
                  </p>
                </div>
              </div>

              <div className="column">
                {ins.images &&
                  ins.images.map((image, j) => {
                    return (
                      <a href={ins.image} target="_blank" key={j}>
                        <img className="insurance-photo" src={image} />
                      </a>
                    );
                  })}

                {!ins.images && ins.image && (
                  <a href={ins.image} target="_blank">
                    <img className="insurance-photo" src={ins.image} />
                  </a>
                )}
              </div>
            </div>
          )}
        </div>
      );
    });
  };

  getInsurancePriorityText = (priority) => {
    let priorityText = "";
    if (priority === 1) {
      priorityText = "Primary";
    } else if (priority === 2) {
      priorityText = "Secondary";
    } else if (priority === 3) {
      priorityText = "Tertiary";
    }

    return priorityText;
  };

  handleUpdateCoverageInfo = (value, field) => {
    let coverageInfo = this.state.coverageInfo;
    coverageInfo[field] = value;
    this.setState({ coverageInfo: coverageInfo });

    if (field === "plan_name") {
      if (value.includes("Medicaid") || value.includes("Medicare")) {
        this.setState({ commercial: false });
      } else {
        this.setState({ commercial: true });
      }
    }
  };

  toggleDetails = (id) => {
    let arr = this.state.expanded;
    const idx = arr.findIndex((i) => i === id);
    if (idx > -1) {
      arr.splice(idx, 1);
    } else {
      arr.push(id);
    }
    this.setState({ expanded: arr });
  };

  handleShowEdit = (e, id = null) => {
    let { user, profile, insuranceDetails, insuranceProviders } = this.props;

    if (e) {
      e.preventDefault();
    }

    let insurance = id
      ? insuranceDetails.find((ins) => ins.id === id)
      : insuranceDetails[0];
    if (insurance) {
      const plan = insuranceProviders.find(
        (ip) => ip.id === insurance.insurance_provider_id
      );
      this.setState({
        coverageInfo: {
          ...insurance,
          plan_name: plan && plan.name,
          fir_name: user.fir_name,
          las_name: user.las_name,
          dob: user.dob,
          state: profile.state,
          zip_code: profile.zip_code,
        },
        showForm: true,
        editingId: insurance.id,
        commercial:
          plan &&
          (plan.name.includes("Medicaid") || plan.name.includes("Medicare"))
            ? false
            : true,
      });
    }
  };

  clearCoverageInfo = () => {
    this.setState({
      coverageInfo: {
        plan_name: "",
        other: "",
        insurance_provider_id: "",
        group_id: "",
        member_id: "",
        fir_name: "",
        las_name: "",
        dob: "",
        state: "",
        zip_code: "",
        files: null,
      },
    });
  };

  handleCancel = () => {
    this.clearCoverageInfo();
    this.setState({ showForm: false });
  };

  skipInsuranceUpload = () => {
    let title = "Insurance Information";
    let uid = "";
    if (this.fromTodo) {
      title = "Insurance";
      uid = "insurance_todo";
    }
    this.props.skipPendingItem(title, uid);
  };

  handleFileInput = (file) => {
    let info = { ...this.state.coverageInfo };
    if (!info.files) {
      info.files = [file];
    } else if (info.files.length === 5) {
      this.props.newGeneralErrors([
        { text: "Cannot upload more than 5 files." },
      ]);
      window.scrollTo(0, 0);
      return;
    } else {
      // compare files
      let isDifferent = true;
      info.files.forEach((existingFile) => {
        if (
          existingFile.name === file.name &&
          existingFile.size === file.size
        ) {
          isDifferent = false;
        }
      });

      if (isDifferent) {
        info.files = [...info.files, file];
      }
    }

    this.setState({ coverageInfo: info });
  };

  handleRemoveFile = (fileIndex) => {
    let info = { ...this.state.coverageInfo };
    info.files.splice(fileIndex, 1);
    this.setState({ coverageInfo: info });
  };

  handleSubmit = () => {
    let { insuranceProviders, insuranceDetails, profile, user } = this.props;
    let { coverageInfo, editingId, showSSNField } = this.state;

    this.props.clearFieldSpecificErrors();
    this.props.newGeneralErrors([]);
    let hasErrors = false;
    if (!coverageInfo.plan_name || coverageInfo.plan_name.length === 0) {
      this.props.newFieldSpecificError(
        "plan_name",
        "Please select your insurance plan."
      );
      hasErrors = true;
    }
    if (!coverageInfo.fir_name || coverageInfo.fir_name.length === 0) {
      this.props.newFieldSpecificError(
        "fir_name",
        "Please enter your first name."
      );
      hasErrors = true;
    }
    if (!coverageInfo.las_name || coverageInfo.las_name.length === 0) {
      this.props.newFieldSpecificError(
        "las_name",
        "Please enter your last name."
      );
      hasErrors = true;
    }
    if (!coverageInfo.dob || coverageInfo.dob.length < 10) {
      this.props.newFieldSpecificError(
        "dob",
        "Please enter your date of birth."
      );
      hasErrors = true;
    }
    if (!coverageInfo.relationship_to_policyholder?.length) {
      this.props.newFieldSpecificError(
        "relationship_to_policyholder",
        "Please select your relationship to the policyholder."
      );
      hasErrors = true;
    }

    if (coverageInfo.files) {
      const types = ["image/png", "image/jpeg", "image/gif"];

      coverageInfo.files.forEach((file) => {
        if (types.every((type) => file.type !== type)) {
          this.props.newFieldSpecificError("ins_file", "Invalid file type.");
          hasErrors = true;
        } else if (file.size > 6000000) {
          //2,000,000 bytes
          this.props.newFieldSpecificError(
            "ins_file",
            "Please choose a smaller file."
          );
          hasErrors = true;
        }
      });
    }

    // if ((coverageInfo.planName === "Medi-Cal")) {
    //   if (!showSSNField && (!coverageInfo.issue_date || (coverageInfo.issue_date.length < 10))) {
    //     this.props.onError("issue_date", "Please enter a valid issue date.")
    //     hasErrors = true
    //   }
    //   else if (showSSNField && (!coverageInfo.ssn || (coverageInfo.ssn.length < 11))) {
    //     this.props.onError("ssn", "Please enter a valid SSN.")
    //     hasErrors = true
    //   }
    // }

    if (hasErrors) {
      this.props.newGeneralErrors([
        { text: "Please correct the issues listed below." },
      ]);
      window.scrollTo(0, 0);
      return;
    }

    let info = Object.assign({}, coverageInfo);
    const insurance = insuranceProviders.find(
      (ip) => ip.name === info.plan_name
    );
    info.insurance_provider_id = insurance && insurance.id;

    // if (info.issue_date) {
    //   info.issue_date = DateTime.fromFormat(info.issue_date, "LL/dd/yyyy").toFormat("yyyy-LL-dd")
    // }
    // else if (info.ssn) {
    //   info.ssn = info.ssn.replace(/-/g, "")
    // }

    let planType = "Commercial";
    const payerId =
      insurance && insurance.payer_id ? insurance.payer_id.toLowerCase() : null;
    if (payerId && payerId.includes("medicare")) {
      planType = "Medicare";
    } else if (payerId && payerId.includes("medicaid")) {
      planType = "Medicaid/Medi-Cal";
    }
    info.plan_type = planType;

    let formData = new FormData();
    for (var [key, value] of Object.entries(info)) {
      if (key !== "files") {
        formData.append(`insurance_detail[${key}]`, value);
      }
    }

    if (info.files?.length > 0) {
      info.files.forEach((file) => {
        formData.append("images[]", file);
      });
    }

    if (editingId) {
      formData.append("insurance_detail[id]", editingId);
      this.props.updateInsuranceDetails(formData, this.resultsCallback);
    } else {
      this.props.createInsuranceDetails(formData, this.resultsCallback);
    }
  };

  resultsCallback = () => {
    let error = {
      status: "info",
      text: "Your insurance information has been saved.",
    };

    if (this.fromChecklist) {
      this.props.history.push("/users/checklist");
      return;
    } else if (this.fromTodo) {
      this.props.history.push("/appointments");
      return;
    }

    error && this.props.newGeneralErrors([error]);
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Insurance)
);
