import { getLocalPhone } from "patient_app/helpers/onboardingHelpers";

let onboardingAssets = {
  bird:
    "https://storage.googleapis.com/workitassets/images/onboarding/punk_bird.png",
  checkMark:
    "https://storage.googleapis.com/workit-client-assets/onboarding/check_green.svg",
  checkMarkWhite:
    "https://storage.googleapis.com/workit-client-assets/onboarding/check_mark_white.svg",
  jumbotron:
    "https://storage.googleapis.com/workit-client-assets/onboarding/call_mockup.png",
  clinicDescription:
    "https://storage.googleapis.com/workit-client-assets/onboarding/background_images/clinic_description.png",
  purchase:
    "https://storage.googleapis.com/workit-client-assets/onboarding/background_images/purchase.png",
  star:
    "https://storage.googleapis.com/workit-client-assets/onboarding/star.svg",
  stripeBadge:
    "https://storage.googleapis.com/workit-client-assets/onboarding/Stripe%20logo%20-%20slate.svg",

  recommenders: [
    {
      name: "PARENT",
      title: "Parent",
      image:
        "https://storage.googleapis.com/workitassets/images/onboarding/recommenders/parent.png",
    },
    {
      name: "CHILD",
      title: "Child",
      image:
        "https://storage.googleapis.com/workitassets/images/onboarding/recommenders/child.png",
    },
    {
      name: "COWORKER",
      title: "Co Worker",
      image:
        "https://storage.googleapis.com/workitassets/images/onboarding/recommenders/coworker.png",
    },
    {
      name: "PARTNER",
      title: "Partner",
      image:
        "https://storage.googleapis.com/workitassets/images/onboarding/recommenders/partner.png",
    },
    {
      name: "FRIEND",
      title: "Friend",
      image:
        "https://storage.googleapis.com/workitassets/images/onboarding/recommenders/friend.png",
    },
    {
      name: "OTHER",
      title: "Other",
      image:
        "https://storage.googleapis.com/workitassets/images/onboarding/recommenders/other.png",
    },
  ],

  vices: [
    {
      name: "DRINKING",
      label: 2,
      title: "Drinking",
      image:
        "https://storage.googleapis.com/workitassets/images/onboarding/vices/drinking.png",
    },
    {
      name: "OPIOIDS",
      label: 11,
      title: "Opioids",
      image:
        "https://storage.googleapis.com/workitassets/images/onboarding/vices/pills.png",
    },
    {
      name: "DRUGS",
      label: 3,
      title: "Other Drugs",
      image:
        "https://storage.googleapis.com/workitassets/images/onboarding/vices/drugs.svg",
    },
    {
      name: "SMOKING",
      label: 4,
      title: "Smoking",
      image:
        "https://storage.googleapis.com/workitassets/images/onboarding/vices/smoking.png",
    },
    {
      name: "GAMBLING",
      label: 5,
      title: "Gambling",
      image:
        "https://storage.googleapis.com/workitassets/images/onboarding/vices/gambling.svg",
    },
    {
      name: "SEX&LOVE",
      label: 6,
      title: "Sex & Porn",
      image:
        "https://storage.googleapis.com/workitassets/images/onboarding/vices/sex%26love.png",
    },
    {
      name: "EATING",
      label: 8,
      title: "Eating",
      image:
        "https://storage.googleapis.com/workitassets/images/onboarding/vices/eating.png",
    },
    {
      name: "PAIN",
      label: 13,
      title: "Pain",
      image:
        "https://storage.googleapis.com/workitassets/images/onboarding/vices/pain.png",
    },
  ],
  oudDescriptions: [
    {
      image:
        "https://storage.googleapis.com/workit-client-assets/onboarding/program_description/opiate_medication_management.png",
      text:
        "Medication Management: Suboxone prescriptions sent to your local pharmacy.",
    },
    {
      image:
        "https://storage.googleapis.com/workit-client-assets/onboarding/program_description/chat_messages.png",
      text:
        "Online Coaching: 24/7 messaging with a recovery coach team to help keep you accountable.",
    },
    {
      image:
        "https://storage.googleapis.com/workit-client-assets/onboarding/program_description/curriculum.png",
      text:
        "Recovery Curriculum: Engaging curriculum of interactive courses to help you grow.",
    },
    {
      image:
        "https://storage.googleapis.com/workit-client-assets/onboarding/program_description/in_program_description_4.png",
      text: `Want to know more? Call us at ${getLocalPhone()} Monday to Friday from 9am to 7pm, we are here to help.`,
    },
  ],
  audDescriptions: [
    {
      image:
        "https://storage.googleapis.com/workit-client-assets/onboarding/program_description/alcohol_medication_management.png",
      text:
        "Medication Management: Naltrexone prescriptions sent to your local pharmacy.",
    },
    {
      image:
        "https://storage.googleapis.com/workit-client-assets/onboarding/program_description/chat_messages.png",
      text:
        "Online Coaching: 24/7 messaging with a recovery coach team to help keep you accountable.",
    },
    {
      image:
        "https://storage.googleapis.com/workit-client-assets/onboarding/program_description/curriculum.png",
      text:
        "Recovery Curriculum: Engaging curriculum of interactive courses to help you grow.",
    },
    {
      image:
        "https://storage.googleapis.com/workit-client-assets/onboarding/program_description/in_program_description_4.png",
      text: `Want to know more? Call us at ${getLocalPhone()} Monday to Friday from 9am to 7pm, we are here to help.`,
    },
  ],
  counselorDescriptions: [
    {
      image:
        "https://storage.googleapis.com/workit-client-assets/onboarding/program_description/robert_deniro.png",
      text:
        "1:1 Check-ins: A weekly phone call, video, or chat check-in with your therapist to help keep you on track.",
      subheader: "1:1 Check-ins",
      subDesc:
        "A weekly phone call, video, or chat check-in with your therapist to help keep you on track.",
    },
    {
      image:
        "https://storage.googleapis.com/workit-client-assets/onboarding/program_description/chat_messages.png",
      text:
        "Online Coaching: 24/7 messaging with a recovery coach team to help keep you accountable.",
      subheader: "Online Coaching",
      subDesc:
        "24/7 messaging with a recovery coach team to help keep you accountable.",
    },
    {
      image:
        "https://storage.googleapis.com/workit-client-assets/onboarding/program_description/curriculum.png",
      text:
        "Recovery Curriculum: Engaging curriculum of interactive courses to help you grow.",
      subheader: "Recovery Curriculum",
      subDesc:
        "Engaging curriculum of interactive courses to help keep you grow.",
    },
    {
      image:
        "https://storage.googleapis.com/workit-client-assets/onboarding/program_description/in_program_description_4.png",
      text: `Want to know more? Call us at ${getLocalPhone()} Monday to Friday from 9am to 7pm, we are here to help.`,
    },
  ],
  coachDescriptions: [
    {
      image:
        "https://storage.googleapis.com/workit-client-assets/onboarding/program_description/chat_messages.png",
      text:
        "Online Coaching: 24/7 messaging with a recovery coach team to help keep you accountable.",
    },
    {
      image:
        "https://storage.googleapis.com/workit-client-assets/onboarding/program_description/curriculum.png",
      text:
        "Recovery Curriculum: Engaging curriculum of interactive courses to help you grow.",
    },
    {
      image:
        "https://storage.googleapis.com/workit-client-assets/onboarding/program_description/in_program_description_4.png",
      text: `Want to know more? Call us at ${getLocalPhone()} Monday to Friday from 9am to 7pm, we are here to help.`,
    },
  ],
};

export default onboardingAssets;
