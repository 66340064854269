import PropTypes from "prop-types";
import React from "react";
import ChatRow from "patient_app/components/chat/partials/ChatRow";

class Index extends React.Component {
  componentDidMount = () => {
    this.props.clearChatData();
    let { chats, viewingAsAdmin } = this.props;
    if (chats.length === 1 && !viewingAsAdmin) {
      this.props.onSelectChat(chats[0]);
    }
  };

  render() {
    return <div className="Index">{this.renderChats()}</div>;
  }

  renderChats = () => {
    let { chats } = this.props;

    if (chats.length === 0) {
      return <p className="no-chats">No Chats Yet</p>;
    }

    return ["Admin Team", "Medical Team", "Counselor Team"].map(
      (chatTeam, i) => {
        const teamChat = chats.find((chat) => chat.other.full_name == chatTeam);
        return (
          <ChatRow
            key={i}
            index={i}
            chat={teamChat}
            onSelectChat={this.props.onSelectChat}
            viewingAsAdmin={this.props.viewingAsAdmin}
            tabIndex={this.props.tabIndex}
          />
        );
      }
    );
  };
}

export default Index;
