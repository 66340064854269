import css from "styled-jsx/css";
import { variables } from "patient_app/stylesheets/variables.js";

export default css.global`
  .program-cost-header {
    text-transform: uppercase;
    font-family: ${variables.objMed};
    font-size: 1rem;
    margin-top: ${variables.small};
  }

  .program-cost-details {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: ${variables.small} auto ${variables.tiny};
  }

  .program-name {
    font-family: ${variables.objBold};
    margin-bottom: ${variables.small};
  }

  .cost-row {
    height: fit-content;
    border-top: 1px solid ${variables.boxBorder};
    text-align: left;
  }

  .cost-row:last-of-type {
    border-bottom: 1px solid ${variables.boxBorder};
    margin-bottom: ${variables.small};
  }

  .cost-row.add-on p {
    color: ${variables.gray};
  }

  .cost-row .flex {
    display: flex;
    align-items: center;
  }

  .cost-row button.toggle-description {
    display: flex;
    margin-left: ${variables.tiny};
    height: fit-content;
    transition: all 250ms ease 0s;
  }

  .cost-row button.toggle-description.expanded {
    transform: rotate(90deg);
  }

  .cost-row button.toggle-description img {
    width: 12px;
  }

  .cost-breakdown {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: calc(16px * 1.25) 0;
  }

  .cost-breakdown p {
    margin: 0;
    align-self: center;
    font-family: ${variables.objMed};
    font-size: 1rem;
  }

  .faded {
    color: ${variables.medGray};
  }

  .disclaimer {
    width: 100%;
    font-size: 0.8rem;
    font-style: italic;
    text-align: center;
  }

  .info-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .info-links a {
    margin-bottom: ${variables.tiny};
    font-size: 0.8rem;
    text-align: center;
  }

  @media only screen and (max-width: 600px) {
    .align-bottom.fill.insurance-check {
      min-height: calc(16px * 9);
    }

    .cost-breakdown {
      height: calc(16px * 3);
      padding: calc(16px * 0.75) 0;
    }
  }
`;
