import Api from "patient_app/api";
import { DateTime } from "luxon";
import dates from "patient_app/helpers/dates";
import {
  NEW_ERRORS,
  ZOOM_EVENTS_LOAD,
  ZOOM_PARTICIPANTS_LOAD,
} from "patient_app/constants/actionTypes";

export const fetchZoomEvents = () => {
  const data = {
    url: `/api/v2/zoom_events`,
    data: {
      method: "GET",
    },
  };

  return async (dispatch) => {
    try {
      const res = await Api.makeRequest(data);
      if (res.success) {
        dispatch({
          type: ZOOM_EVENTS_LOAD,
          zoomEvents: res.zoom_events,
        });

        dispatch({
          type: ZOOM_PARTICIPANTS_LOAD,
          zoomParticipants: res.zoom_participants,
        });
        return;
      }

      dispatch({ type: NEW_ERRORS, errors: res.errors });
    } catch (e) {
      console.log(e);
    }
  };
};

//request is made outside of redux
export const getJoinUrl = async (zoomParticipantId) => {
  const data = {
    url: `/api/v2/zoom_events/get_join_url`,
    data: {
      method: "POST",
      body: {
        zoom_participant_id: zoomParticipantId,
      },
    },
  };

  return await Api.makeRequest(data);
};

export const startDrugTest = () => {
  const data = {
    url: `/api/v2/zoom_events/start_drug_test`,
    data: {
      method: "PATCH",
    },
  };

  return async (dispatch) => {
    try {
      const res = await Api.makeRequest(data);
    } catch (e) {
      console.log(e);
    }
  };
};

export const getParticipantSignature = async (
  zoomParticipantId,
  zoomRegistrantId,
  registrantToken
) => {
  const data = {
    url: `/api/v2/zoom_events/get_participant_signature?zoom_participant_id=${zoomParticipantId}&zoom_registrant_id=${zoomRegistrantId}&zoom_registrant_token=${registrantToken}`,
    data: {
      method: "GET",
    },
  };

  return await Api.makeRequest(data);
};
