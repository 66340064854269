import React from "react";
import commonAssets from "patient_app/assets";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
    };
  }

  static getDerivedStateFromError(error) {
    if (/Loading chunk [\d]+ failed/.test(error?.message)) {
      // Only show error for failed chunk loading
      return { error: "Could not load page, please refresh and try again." };
    } else {
      return { error: "We're sorry, something went wrong." };
    }
  }

  render() {
    if (!!this.state.error) {
      return (
        <div className="Errors Errors-Container">
          <div className="notification error">
            <p
              id="general-error"
              role="alert"
              dangerouslySetInnerHTML={{ __html: this.state.error }}
            />
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
