import PropTypes from "prop-types";
import React from "react";
import imageCompression from "browser-image-compression";

import dragAndDropStyles from "patient_app/stylesheets/components/dragAndDropStyles";

class DragAndDrop extends React.Component {
  dragCounter = 0;

  constructor(props) {
    super(props);
    this.state = {
      dragging: false,
    };
  }

  componentDidMount() {
    if (this.dropRef) {
      this.dropRef.addEventListener("dragenter", this.handleDragIn);
      this.dropRef.addEventListener("dragleave", this.handleDragOut);
      this.dropRef.addEventListener("dragover", this.handleDrag);
      this.dropRef.addEventListener("drop", this.handleDrop);
    }
  }

  componentWillUnmount() {
    this.dropRef.removeEventListener("dragenter", this.handleDragIn);
    this.dropRef.removeEventListener("dragleave", this.handleDragOut);
    this.dropRef.removeEventListener("dragover", this.handleDrag);
    this.dropRef.removeEventListener("drop", this.handleDrop);
  }

  render() {
    let { allowMultiple, error, files, id } = this.props;
    let { dragging } = this.state;
    const idPlusFile = `${id}-file`;

    return (
      <div className="drag-and-drop">
        <div className="drop-box" ref={(ref) => (this.dropRef = ref)}>
          {!dragging && (
            <div className="actions-container">
              {this.props.title && (
                <div className="drag-and-drop title">{this.props.title}</div>
              )}
              <button
                className="custom-button select-file"
                onClick={() => this.fileInput.click()}
              >
                {files?.length > 0
                  ? "Select a different file"
                  : "Select a file..."}
              </button>

              {allowMultiple ? (
                <input
                  id={id ? id : ""}
                  className="picture-input"
                  type="file"
                  ref={(fileInput) => (this.fileInput = fileInput)}
                  onChange={this.handleSelectFile}
                  multiple
                />
              ) : (
                <input
                  id="pic-input-for-dnd"
                  className="picture-input"
                  type="file"
                  ref={(fileInput) => (this.fileInput = fileInput)}
                  onChange={this.handleSelectFile}
                />
              )}

              {files &&
                files.map((file, i) => {
                  return (
                    <p key={i}>
                      {file.name}{" "}
                      <a
                        id={id ? idPlusFile : ""}
                        className="custom-button remove-file"
                        href="#"
                        onClick={this.handleRemoveFile}
                        data-index={i}
                      >
                        (remove)
                      </a>
                    </p>
                  );
                })}

              {!files?.length && (
                <p>
                  or drag and drop a file here (.jpg, .jpeg, .png, .pdf, .tiff,
                  .gif, .bmp)
                </p>
              )}
            </div>
          )}

          {dragging && <p>Drop file here</p>}
        </div>

        {error && <p className="field-error">{error}</p>}

        <style jsx>{dragAndDropStyles}</style>
      </div>
    );
  }

  handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter++;

    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({ dragging: true });
    }
  };

  handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter--;
    if (this.dragCounter > 0) return;

    this.setState({ dragging: false });
  };

  handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  handleDrop = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    let files = null;
    if (this.props.allowMultiple) {
      files = e.dataTransfer.files;
    } else {
      files = e.dataTransfer.files && e.dataTransfer.files[0];
    }

    if (files) {
      // FileList is not an array, and needs a traditional for loop
      for (let i = 0; i < files.length; i++) {
        this.handleFileInput(files[i]);
      }
    }

    this.dragCounter = 0;
    this.setState({ dragging: false });
  };

  handleSelectFile = (e) => {
    let files = null;
    if (this.props.allowMultiple) {
      files = e.target.files;
    } else {
      files = e.target.files && [e.target.files[0]];
    }

    if (files) {
      // FileList is not an array, and needs a traditional for loop
      for (let i = 0; i < files.length; i++) {
        this.handleFileInput(files[i]);
      }
    }
    // files && this.handleFileInput(files);
  };

  handleFileInput = async (file) => {
    if (file.type !== "application/pdf") {
      try {
        // compress image
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 600,
        };

        // imageCompression returns a Blob
        const compressedBlob = await imageCompression(file, options);
        const compressedFile = new File([compressedBlob], file.name, {
          type: file.type,
        });
        compressedFile && this.props.onFileInput(compressedFile);
      } catch (error) {
        console.log(error);
      }
    } else {
      // pdf file upload handling - without compression
      this.props.onFileInput(file);
    }
  };

  handleRemoveFile = (e) => {
    if (e) e.preventDefault();
    const fileIndex = e.currentTarget.dataset.index;
    this.props.onRemoveFile(fileIndex);
  };
}

export default DragAndDrop;
