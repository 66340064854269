import PropTypes from "prop-types";
import React from "react";
import moment from "moment";
import { curriculum } from "patient_app/assets/curriculum";

import { Element } from "react-scroll";

class SubBanner extends React.Component {
  static propTypes = {
    section: PropTypes.object,
    scrollRef: PropTypes.string,
  };

  render() {
    let { title, scrollRef } = this.props;

    return (
      <Element name={scrollRef}>
        <div className="sub-banner">
          <div className="circle">
            <img src={curriculum.grayChevronDown} alt="" />
          </div>

          <h2>{title}</h2>
        </div>
      </Element>
    );
  }
}

export default SubBanner;
