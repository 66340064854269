import React from "react";
import { DateTime } from "luxon";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { fetchUser, fetchProfile } from "patient_app/api/commonActions";
// import { fetchClinic } from "patient_app/api/clinicActions";

import Modal from "patient_app/components/utils/Modal";
import { getLocalPhone } from "patient_app/helpers/onboardingHelpers";
import layout from "patient_app/stylesheets/onboarding/layout";
import { variables } from "patient_app/stylesheets/variables";
import trackerActions from "patient_app/helpers/trackerActions";

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUser: () => dispatch(fetchUser()),
    fetchProfile: () => dispatch(fetchProfile()),
  };
};

const mapStateToProps = (state) => {
  return {
    profile: state.common.profile,
    user: state.common.user,
  };
};

class ContactUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: "",
      showInfo: false,
      hovering: false,
    };
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);

    if (!this.props.profile) {
      this.props.fetchProfile();
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);

    // let { user, profile } = this.props;
  }

  handleClickOutside = (event) => {
    if (this.infoRef && !this.infoRef.contains(event.target)) {
      this.setState({ hovering: false });
    }
  };

  render() {
    let { user, profile } = this.props;
    let { showInfo, hovering } = this.state;

    const timeZone = profile?.time_zone || DateTime.local().zoneName;
    const formattedTz = DateTime.local().setZone(timeZone).toFormat("ZZZZ");

    let startAt = DateTime.local()
      .setZone("America/Detroit")
      .set({ hour: 9 })
      .startOf("hour");
    let endAt = DateTime.local()
      .setZone("America/Detroit")
      .set({ hour: 20 })
      .startOf("hour");
    if (!!timeZone) {
      startAt = startAt.setZone();
      endAt = endAt.setZone();
    }

    return (
      <div>
        <span
          ref={(ref) => (this.infoRef = ref)}
          className={`question-info-outline ${hovering ? "selected" : ""}`}
          onFocus={() => this.setState({ hovering: true })}
          onBlur={() => this.setState({ hovering: false })}
        >
          <button
            className="custom-button question-info"
            onClick={() => {
              trackerActions.logMixpanelEvent("CallNow", { onboarding: "web" });
              this.setState({
                showInfo: !showInfo,
                hovering: true,
              });
            }}
            aria-label="Show more information"
            id="question-info-button"
          >
            ?
          </button>
        </span>
        {showInfo && (
          <Modal
            title="Questions? We are here to help!"
            onCancel={() =>
              this.setState({
                showInfo: !showInfo,
              })
            }
          >
            {/* line 109, combine/reference AppointmentModal(206)*format* and DrugTesting Modal(317) to utilize a time format for local zone */}
            <div>
              <p>
                Call{" "}
                <a href={`tel:${getLocalPhone(user)}`}>{getLocalPhone(user)}</a>
                . Our care navigators can answer any questions you have about
                signing up. We are available to talk from{" "}
                {startAt.toFormat("t")} -{endAt.toFormat(" t ")} {formattedTz}.
              </p>

              <p>
                <b>Here are some questions we can help with:</b>
              </p>
              <ul>
                <li>Do you take my insurance?</li>
                <li>How does video drug testing work?</li>
                <li>Will anyone know I'm in this program?</li>
              </ul>
            </div>
            <br />
            <a href={`tel:${getLocalPhone(user)}`} className="button submit">
              Call Workit's support team
            </a>
          </Modal>
        )}
        <style jsx>{layout}</style>
        <style jsx>{`
          ul {
            list-style-type: disc;
            padding: 0 1.5rem;
            color: ${variables.blueDark};
          }

          li {
            font-family: ${variables.objReg};
            font-size: 0.8rem;
            margin: 0;
            line-height: 1.5rem;
          }

          p.center-large {
            text-align: center;
            font-size: 1rem;
            font-family: ${variables.objMed};
          }
        `}</style>
      </div>
    );
  }
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ContactUs)
);
