import _ from "lodash";
import { DateTime } from "luxon";

import {
  CARE_PROVIDER_LOAD,
  CLEAR_FIELD_SPECIFIC_ERRORS,
  CLEAR_SUCCESS,
  CLINIC_LOAD,
  COACH_LOAD,
  COMMON_UPDATE_START,
  COMMON_UPDATE_SUCCESS,
  COMMON_UPDATE_FAILURE,
  COMMON_UPDATE_STATE,
  DECLARE_NO_COACH,
  NEW_ERRORS,
  NEW_FIELD_SPECIFIC_ERROR,
  NEW_FORM_ERRORS,
  PAGE_LOAD,
  PROFILE_LOAD,
  PROFILE_UPDATE_LOCAL,
  TOGGLE_LOADING,
  TOGGLE_PAYWALL,
  UPDATE_USER_SIGNED_IN,
  USER_LOAD,
  USER_UPDATE_LOCAL,
} from "patient_app/constants/actionTypes";
import trackerActions from "patient_app/helpers/trackerActions";

const initialState = {
  isFetching: false,
  success: false,
  errors: [],
  formErrors: [],
  fieldSpecificErrors: {},
  errorType: null,
  successText: "",
  errorsClass: "generic",
  paywall: false,
  userSignedIn: false,
  userDiagnoses: [],
  primaryDiagnosis: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_LOAD:
      return {
        ...state,
        user: action.user,
      };
    case PROFILE_LOAD:
      return {
        ...state,
        profile: action.profile,
      };
    case PAGE_LOAD:
      return {
        ...state,
        isFetching: false,
        success: false,
        loading: false,
        successText: "",
        errors: [],
        formErrors: [],
        fieldSpecificErrors: {},
      };
    case COACH_LOAD:
      return {
        ...state,
        counselor: action.counselor,
      };
    case CLINIC_LOAD:
      return {
        ...state,
        clinic: action.clinic,
      };
    case CARE_PROVIDER_LOAD:
      return {
        ...state,
        careProvider: action.careProvider,
      };
    case TOGGLE_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case USER_UPDATE_LOCAL:
      return {
        ...state,
        user: action.user,
      };
    case PROFILE_UPDATE_LOCAL:
      return {
        ...state,
        profile: action.profile,
        // profilePic: action.profilePic,
      };
    case COMMON_UPDATE_START:
      return {
        ...state,
        isFetching: true,
        success: false,
        errors: [],
        fieldSpecificErrors: {},
        successText: "",
      };
    case COMMON_UPDATE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        success: true,
        // errors: [],
        fieldSpecificErrors: {},
        successText: action.successText,
      };
    case COMMON_UPDATE_FAILURE:
      return {
        ...state,
        errors: action.errors || state.errors,
        isFetching: false,
        success: false,
        successText: "",
      };
    case NEW_ERRORS:
      return {
        ...state,
        errors: action.errors,
        successText: "",
        errorsClass: action.errorsClass,
        errorType: action.errorType,
        errorsTimestamp: DateTime.local(),
      };
    case NEW_FORM_ERRORS:
      return {
        ...state,
        formErrors: action.errors,
        errorsTimestamp: DateTime.local(),
      };
    case NEW_FIELD_SPECIFIC_ERROR:
      let errorsToUpdate = [...state.formErrors];
      if (!_.find(errorsToUpdate, { text: action.error })) {
        errorsToUpdate.push({ text: action.error });
      }
      trackerActions.logMixpanelEvent("ErrorMessage", {
        message: action.error,
      });
      return {
        ...state,
        //formErrors: errorsToUpdate,
        fieldSpecificErrors: {
          ...state.fieldSpecificErrors,
          [action.field]: action.error,
        },
      };
    case CLEAR_FIELD_SPECIFIC_ERRORS:
      return {
        ...state,
        fieldSpecificErrors: {},
      };
    case TOGGLE_PAYWALL:
      return {
        ...state,
        paywall: action.paywall,
      };
    case CLEAR_SUCCESS:
      return {
        ...state,
        success: false,
      };
    case UPDATE_USER_SIGNED_IN:
      return {
        ...state,
        userSignedIn: action.userSignedIn,
      };
    case DECLARE_NO_COACH:
      return {
        ...state,
        coachStatus: action.coachStatus,
      };
    case COMMON_UPDATE_STATE:
      return {
        ...state,
        [action.key]: action.value,
      };
    default:
      return state;
  }
};
