import PropTypes from "prop-types";
import React from "react";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { fetchProgram } from "patient_app/api/profileActions";

import LoadingSpinner from "patient_app/components/utils/LoadingSpinner";
import SubTabBack from "patient_app/views/profiles/partials/SubTabBack";

import { mobileCheck } from "patient_app/helpers/supported";

const mapDispatchToProps = (dispatch) => {
  return {
    fetchProgram: (profileId, params) =>
      dispatch(fetchProgram(profileId, params)),
  };
};

const mapStateToProps = (state) => {
  return {
    clinic: state.clinic.userClinic,
    loading: state.profile.loading,
    success: state.profile.success,
  };
};

class Clinic extends React.Component {
  profileId = this.props.match.params.id;

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (mobileCheck()) {
      this.props.fetchProgram(this.profileId, ["clinic"]);
    }
  }

  render() {
    let { clinic, loading } = this.props;

    return (
      <div>
        <SubTabBack />
        <div className="profile-section">
          <h1>Your Clinic</h1>

          {!clinic && <LoadingSpinner />}

          {clinic && (
            <div>
              <div className="paragraph">
                <p>
                  <span>{clinic.name}</span>
                </p>
                <p>
                  <span>{clinic.street}</span>
                </p>
                <p>
                  <span>
                    {clinic.city}, {clinic.state} {clinic.zip_code}
                  </span>
                </p>
              </div>

              <a
                className="link"
                href={clinic.google_maps_link}
                target="_blank"
              >
                <span>Get Directions</span>
              </a>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Clinic));
