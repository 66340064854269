import css from "styled-jsx/css";
import { variables } from "patient_app/stylesheets/variables";

export default css.global`
  .profile-list.preference {
    margin-bottom: 2rem;
  }
  .profile-list.preference .list-item .row {
    grid-template-columns: 70% 10% 10% 10%;
  }
  .profile-list.preference .list-item .row.time {
    grid-template-columns: 70% 30%;
  }

  .profile-list.preference .profile-field {
    padding-left: 1rem;
    margin: 0;
  }

  .profile-list.preference .profile-field .row {
    display: flex;
    padding: 0;
    margin: 0;
    height: fit-content;
  }

  .profile-list.preference .profile-field select {
    height: 2rem;
    padding: 0 0.5rem;
  }

  @media only screen and (max-device-width: 600px) {
    .profile-list.preference .list-item .row {
      grid-template-columns: 46% 18% 18% 18%;
    }
    .profile-list.preference .list-item .row.time {
      grid-template-columns: 55% 45%;
    }
  }
`;
