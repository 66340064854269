import {
  AVAILABILITIES_LOAD,
  FETCH_PROVIDER_AVS,
  FETCH_STAFF_AVAILABILITIES,
  UPDATE_APPOINTMENT_LOCAL,
  ONBOARDING_REQUEST_START,
  ONBOARDING_REQUEST_SUCCESS,
  ONBOARDING_REQUEST_FAILURE,
  ONBOARDING_HEALTH_INFO,
  UPDATE_AUD_QUESTIONNAIRE_QUESTION_LOCAL,
  UPDATE_ENT_QUESTIONNAIRE_QUESTION_LOCAL,
  LOAD_FINALIZED_APPOINTMENT,
  ONBOARDING_PAGE_LOAD,
  SET_NEXT_QUESTIONNAIRE_QUESTION,
  ONBOARDING_SET_USER,
  TOGGLE_ONBOARDING_LOADING,
  ONBOARDING_CALENDAR_LINKS,
  ONBOARDING_NEEDS_VERIFICATION,
  ONBOARDING_VALID_EMPLOYER,
  ONBOARDING_RESET_SCHEDULE_VARS,
  ONBOARDING_FETCH_INDUCTION_BLOCKS,
  ONBOARDING_USER_LOCATION,
  UPDATE_ONBOARDING_PAYERS,
  ONBOARDING_UPDATE_PROGRESS,
  ONBOARDING_VERIFY_ADDRESS,
  ONBOARDING_UPDATE_STATE,
} from "patient_app/constants/actionTypes";

const initialState = {
  availabilities: {},
  staffAvailabilities: null,
  fetchingInitial: true,
  fetchingSuccess: false,
  loading: false,
  success: false,
  progress: 0,
  AUDQuestions: {},
  enterpriseQuestions: {},
  needsEmployerVerification: false,
  needsOccupation: false,
  nextLink: "/courses",
  newUser: {
    name: "",
    email: "",
    zip_code: "",
    company: "",
    state: "",
    tier: null,
    token: null,
    platform: null,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ONBOARDING_PAGE_LOAD:
      return {
        ...state,
        loading: false,
        success: false,
        fetchingInitial: false,
        fetchingSuccess: false,
      };
    case TOGGLE_ONBOARDING_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case AVAILABILITIES_LOAD:
      return {
        ...state,
        availabilities: action.availabilities,
      };
    case FETCH_PROVIDER_AVS:
      return {
        ...state,
        recurringAvs: action.recurringAvs,
        timeOffs: action.timeOffs,
        oneTimeAvs: action.oneTimeAvs,
        scheduledAppts: action.scheduledAppts,
        holidays: action.holidays,
        adminIds: action.adminIds,
        userScheduled: action.userScheduled,
        credentialedProviders: action.credentialedProviders,
        apptLength: action.apptLength,
        apptCategory: action.apptCategory,
      };
    case FETCH_STAFF_AVAILABILITIES:
      return {
        ...state,
        staffAvailabilities: action.staffAvailabilities,
        userScheduled: action.userScheduled,
        apptLength: action.apptLength,
        apptCategory: action.apptCategory,
      };
    case UPDATE_APPOINTMENT_LOCAL:
      return {
        ...state,
        appointmentTime: action.appointmentTime,
      };
    case LOAD_FINALIZED_APPOINTMENT:
      return {
        ...state,
        finalizedAppointment: action.event,
      };
    case ONBOARDING_REQUEST_START:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case ONBOARDING_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case ONBOARDING_REQUEST_FAILURE:
      //since we handle errors in the common reducer, I think the best way to handle onboarding errors
      //is to dispatch the NEW_ERRORS action in the api call in onboarding actions, and pass along the errors
      //there. I haven't set this up yet, however.
      return {
        ...state,
        loading: false,
        success: false,
        //errors: action.errors,
      };
    case ONBOARDING_HEALTH_INFO:
      return {
        ...state,
        healthInfo: action.healthInfo,
      };
    case UPDATE_AUD_QUESTIONNAIRE_QUESTION_LOCAL:
      return {
        ...state,
        AUDQuestions: {
          ...state.AUDQuestions,
          [action.question.key]: action.question,
        },
      };
    case UPDATE_ENT_QUESTIONNAIRE_QUESTION_LOCAL:
      return {
        ...state,
        enterpriseQuestions: {
          ...state.enterpriseQuestions,
          [action.question.key]: action.question,
        },
        goToOutcome: action.goToOutcome,
      };
    case SET_NEXT_QUESTIONNAIRE_QUESTION:
      return {
        ...state,
        nextQuestionId: action.nextQuestionId,
      };
    case ONBOARDING_SET_USER:
      return {
        ...state,
        newUser: action.user,
      };
    case ONBOARDING_CALENDAR_LINKS: {
      return {
        ...state,
        links: action.links,
      };
    }
    case ONBOARDING_VALID_EMPLOYER: {
      return {
        ...state,
        isEnterprise: action.isEnterprise,
        isEnterpriseConfirmed: action.isEnterpriseConfirmed,
      };
    }
    case ONBOARDING_NEEDS_VERIFICATION: {
      return {
        ...state,
        needsEmployerVerification: true,
      };
    }
    case ONBOARDING_RESET_SCHEDULE_VARS: {
      return {
        ...state,
        recurringAvs: null,
        oneTimeAvs: null,
        timeOffs: null,
        scheduledAppts: null,
        appointmentTime: null,
        finalizedAppointment: null,
      };
    }
    case ONBOARDING_FETCH_INDUCTION_BLOCKS: {
      return {
        ...state,
        inductionBlocks: action.inductionBlocks,
      };
    }
    case ONBOARDING_USER_LOCATION: {
      return {
        ...state,
        newUser: {
          ...state.newUser,
          state: action.state,
          state_name: action.stateName,
          clinic_id: action.recommendedClinicId,
        },
      };
    }
    case UPDATE_ONBOARDING_PAYERS: {
      return {
        ...state,
        stateCoverages: action.stateCoverages,
        insuranceProviders: action.insuranceProviders,
      };
    }
    case ONBOARDING_UPDATE_PROGRESS: {
      return {
        ...state,
        progress: action.progress,
      };
    }
    case ONBOARDING_VERIFY_ADDRESS:
      return {
        ...state,
        suggestedAddress: action.suggestedAddress,
      };
    case ONBOARDING_UPDATE_STATE:
      return {
        ...state,
        [action.key]: action.value,
      };
    default:
      return state;
  }
};
