import Api from "patient_app/api";

import {
  CLINICS_LOAD,
  CLINIC_LOAD,
  NEW_ERRORS,
  COMMON_UPDATE_START,
  COMMON_UPDATE_SUCCESS,
  COMMON_UPDATE_FAILURE,
  PROFILE_UPDATE_LOCAL,
} from "patient_app/constants/actionTypes";

export const fetchClinic = (nonClinic = "") => {
  const data = {
    url: `/appointments/clinic?non_clinic=${nonClinic}`,
    path: "api/v2",
    data: {
      method: "GET",
    },
  };

  return async (dispatch) => {
    try {
      const res = await Api.makeRequest(data);
      if (res.success) {
        dispatch({
          type: CLINIC_LOAD,
          clinic: res.clinic,
        });
        return;
      }

      dispatch({ type: NEW_ERRORS, errors: res.errors });
    } catch (e) {
      console.log(e);
    }
  };
};

export const fetchClinics = () => {
  const data = {
    url: `/clinics/fetch_all_for_user`,
    data: {
      method: "GET",
    },
  };

  return async (dispatch) => {
    try {
      const res = await Api.makeRequest(data);

      if (res.success) {
        dispatch({
          type: CLINICS_LOAD,
          clinics: res.clinics,
        });
        return;
      }

      dispatch({
        type: NEW_ERRORS,
        errors: [{ text: "Something went wrong. Please try again." }],
      });
    } catch (e) {
      console.log(e);
    }
  };
};

export const updateClinic = (clinicId, history) => {
  const data = {
    url: `/clinics/update_for_user`,
    data: {
      method: "POST",
      body: {
        clinic_id: clinicId,
      },
    },
  };

  return async (dispatch) => {
    dispatch({ type: COMMON_UPDATE_START });
    try {
      const res = await Api.makeRequest(data);

      if (res.success) {
        dispatch({ type: PROFILE_UPDATE_LOCAL, profile: res.profile });
        dispatch({ type: CLINIC_LOAD, clinic: res.clinic });
        dispatch({ type: COMMON_UPDATE_SUCCESS });
        history.push("/onboarding/schedule");
        return;
      }

      dispatch({ type: COMMON_UPDATE_FAILURE });
      dispatch({ type: NEW_ERRORS, errors: res.errors });
    } catch (e) {
      console.log(e);
    }
  };
};
