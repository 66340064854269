import PropTypes from "prop-types";
import React from "react";
import cn from "classnames";
import _ from "lodash";
import Api from "patient_app/api";
import ActionCable from "actioncable";
import LoadingSpinner from "patient_app/components/chat/partials/LoadingSpinner";
import { mobileCheck } from "patient_app/helpers/supported";
import { DateTime } from "luxon";

import MessageInput from "patient_app/components/chat/video/MessageInput";
import Messages from "patient_app/components/chat/Messages";
import ContentEditable from "react-contenteditable";
import assets from "patient_app/components/chat/assets";
import Controls from "patient_app/components/chat/Controls";
import BusinessHours from "patient_app/components/chat/partials/BusinessHours";

import permissions from "patient_app/helpers/permissions";

class Chat extends React.Component {
  static propTypes = {};

  static defaultProps = {
    cableIntegrity: false,
    message: "",
    messages: [],
    showImageUpload: false,
    showGetPrevMessages: true,
    showGetNextMessages: false,
    shiftPressed: false,
    focused: false,
    fetchingInitial: true,
    otherTyping: false,
    uploadingImage: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      admins: {},
      loading: true,
      error: "",
      cableIntegrity: props.cableIntegrity,
      messages: props.messages,
      message: props.message,
      chatSub: null,
      cable: null,
      focused: props.focused,
      showImageUpload: props.showImageUpload,
      showGetPrevMessages: props.showGetPrevMessages,
      showGetNextMessages: props.showGetNextMessages,
      scrollRef: props.scrollRef,
      shiftPressed: props.shiftPressed,
      showNewMessageAlert: props.showNewMessageAlert,
      fetchingInitial: props.fetchingInitial,
      otherTyping: props.otherTyping,
      unreadMessages: props.chat.unread_count,
      showUnreadBadge: false,
      uploadingImage: false,
    };

    this.handleFileUpload.bind(this);
    this.fileInput = React.createRef();
  }

  componentWillUnmount = () => {
    let { cable, intervalId } = this.state;

    this.unsubscribe();
    // use intervalId from the state to clear the interval
    clearInterval(intervalId);
    this.setState({ cable: null, chatSub: null });
  };

  componentDidMount = () => {
    this.subscribe();

    //check to see if we need to timeout the ellipsis every 5 seconds
    const intervalId = setInterval(this.ellipsisFallback, 5000);
    // store intervalId in the state so it can be accessed later:
    this.setState({ intervalId: intervalId });
  };

  componentDidUpdate(prevProps) {
    let prevChat = prevProps.chat;
    let thisChat = this.props.chat;
    if (!!prevChat?.id && !!thisChat?.id && prevChat.id !== thisChat.id) {
      this.setState({ messages: [], fetchingInitial: true });
      this.unsubscribe();
      this.subscribe();
    }
  }

  setScrollRef = (cont) => {
    this.setState({ scrollRef: cont });
  };

  unsubscribe = () => {
    let { cable } = this.state;
    if (cable && cable.subscriptions && cable.subscriptions.subscriptions) {
      cable.subscriptions.subscriptions.map((sub, i) => {
        cable.subscriptions.remove(sub);
      });
    }
  };

  subscribe = async () => {
    let { chat, selectedMessageId, viewingAsAdmin } = this.props;
    if (viewingAsAdmin) {
      this.setState({ cableIntegrity: true });
      this.fetchMessages(selectedMessageId);
      return;
    }

    let endpoint = `wss://${window.location.host}/cable`;
    //use non ssl endpoint for local host, ssl otherwise
    if (window.location.host.startsWith("localhost")) {
      endpoint = `ws://${window.location.host}/cable`;
    }
    const params = {
      channel: "ChatChannel",
      chat_id: chat.id,
      category: chat.category,
    };

    const eventHandlers = {
      received: (data) => {
        this.handleReceiveData(data);
      },
      //initialized: () => { this.callHandler(this.options.onChannelInit); },
      connected: () => {
        this.setState({ cableIntegrity: true });
        this.fetchMessages(selectedMessageId);
      },
      disconnected: () => {
        this.setState({ cableIntegrity: false });
      },
      unsubscribed: () => {
        this.setState({ cableIntegrity: false });
      },
      //rejected: () => { this.callHandler(this.options.onChannelRejected); }
    };

    const cable = ActionCable.createConsumer(endpoint);
    let chatSub = cable.subscriptions.create(params, eventHandlers);
    this.setState({ chatSub: chatSub, cable: cable });
  };

  ellipsisFallback = () => {
    const now = DateTime.local();
    let { otherTyping, lastTypingCheckIn } = this.state;
    let diff;

    if (otherTyping && lastTypingCheckIn) {
      diff = now.diff(lastTypingCheckIn, "seconds");
      if (diff.seconds >= 5) {
        this.setState({ otherTyping: false });
      }
    } else if (otherTyping && !lastTypingCheckIn) {
      this.setState({ otherTyping: false });
    }
  };

  render() {
    let {
      admins,
      fetchingInitial,
      loading,
      showGetPrevMessages,
      showGetNextMessages,
      focused,
      message,
      messages,
      error,
      otherTyping,
      unreadMessages,
      showUnreadBadge,
      uploadingImage,
      shiftPressed,
    } = this.state;

    let {
      chat,
      selectedMessageId,
      viewingAsAdmin,
      tabIndex,
      currentUser,
    } = this.props;

    let placeholder = "<p class='placeholder'>Write a message</p>";
    let messageContent = placeholder;
    let chatDisabled = this.evalChatDisabled();
    let profilePic = assets.defaultProfilePic;

    if (focused || message.length > 0) {
      messageContent = message;
    }

    if (
      chat.other &&
      chat.other.pic &&
      !chat.other.pic.endsWith(
        "dashboard-003e096ea7b2a80e981bff26aaba8dfdd88ef8e1e3284b65deb6b28763e5147e.png"
      )
    ) {
      profilePic = chat.other.pic;
    }

    if (chat.category === "support") {
      profilePic = assets.supportChat;
    }

    if (chatDisabled) {
      messageContent = "<p class='placeholder'>Chat is read only</p>";
    }

    return (
      <div
        className={cn("Chat")}
        onScroll={this.handleScroll}
        id="chat-container"
      >
        {currentUser && <BusinessHours />}

        {error && error.length > 0 && (
          <div className="error-container">
            <p>{error}</p>
            <i className="material-icons" onClick={(e) => this.cancelError()}>
              close
            </i>
          </div>
        )}

        <Messages
          messages={messages}
          admins={admins}
          showGetPrevMessages={showGetPrevMessages}
          showGetNextMessages={showGetNextMessages}
          onGetMoreMessages={this.handleGetMoreMessages}
          setScrollRef={this.setScrollRef}
          providerImgUrl={profilePic}
          fetchingInitial={fetchingInitial}
          otherTyping={otherTyping}
          otherModel="other"
          onLoad={this.scrollToMessage}
          onOpenChat={this.props.onOpenChat}
          uploadingImage={uploadingImage}
          tabIndex={tabIndex}
        />

        {messages.length === 0 && fetchingInitial && <LoadingSpinner />}

        {!viewingAsAdmin && (
          <Controls
            focused={focused}
            loading={loading}
            chatDisabled={chatDisabled}
            uploadingImage={uploadingImage}
            messageContent={messageContent}
            message={message}
            shiftPressed={shiftPressed}
            fileInputRef={this.fileInput}
            handleFileUpload={this.handleFileUpload}
            handleInput={this.handleInput}
            handleBlur={this.handleBlur}
            handleFocus={this.handleFocus}
            handleSend={this.handleSend}
            onShiftKeyChange={this.handleShiftKeyChange}
            startTypingAlert={this.startTypingAlert}
            tabIndex={tabIndex}
          >
            {showUnreadBadge && this.showUnreadMessageBadge()}
          </Controls>
        )}
      </div>
    );
  }

  handleFocus = () => {
    this.setState({ focused: true });
  };

  handleBlur = () => {
    this.setState({ focused: false });
  };

  toggleMessages = (e) => {
    e.preventDefault();
    this.setState({ expanded: !this.state.expanded });
  };

  handleInput = (e) => {
    this.setState({ message: e.target.value });
  };

  handleReceiveTyping = (data) => {
    let otherTyping = false;
    const timestamp = DateTime.local();
    if (data.model === "other") {
      if (data.status === "start") {
        otherTyping = true;
      }

      this.setState({ otherTyping: otherTyping, lastTypingCheckIn: timestamp });
      let { scrollRef } = this.state;
      const distanceFromBottom = this.getDistanceScrolledFromBottom();

      if (distanceFromBottom < 50) {
        this.scrollBottom();
      }
    }
  };

  handleReceiveData = (data) => {
    if (data.category && data.category === "typing") {
      this.handleReceiveTyping(data);
      return;
    }

    let message = data.message;
    let messages = this.state.messages;
    this.updateLastMessage(message);

    //user is viewing older messages when they receive/send a message
    if (this.state.showGetNextMessages) {
      //message comes from other and user is viewing older messages
      if (this.messageIsFromOther(data.model)) {
        this.setState({ otherTyping: false });
        this.updateUnreadCount();
      }

      //user sends message while viewing older messages
      if (data.model === "user") {
        this.fetchMessages();
        this.clearNotifications();
        this.setState({ message: "" });
      }

      return;
    }

    messages.push(message);
    this.setState({ messages: messages });

    if (data.admin) {
      const admin = JSON.parse(data.admin);
      if (admin && admin.id) {
        let admins = this.state.admins;
        admins[admin.id] = admin;
        this.setState({ admins: admins });
      }
    }

    //stop showing ellipsis if we receive a message
    //message comes from other
    if (this.messageIsFromOther(data.model)) {
      this.setState({ otherTyping: false });
      if (this.getDistanceScrolledFromBottom() > 500) {
        this.updateUnreadCount();
      }
      this.maybeScrollBottom();
    }

    //user sends message
    if (data.model === "user") {
      this.scrollBottom();
      this.clearNotifications();
    }

    //edge case for if the user is sending an image message
    //while also typing a messge
    if (
      data.model === "user" &&
      message &&
      message.media_type &&
      message.media_type === "image"
    ) {
      this.setState({ loading: false });
      this.maybeScrollBottom();
      return;
    }

    //clear coach/admin message and loading if they sent the message
    if (!this.messageIsFromOther(data.model)) {
      this.setState({ message: "" });
    }

    this.setState({ loading: false });
  };

  messageIsFromOther(string) {
    return string === "admin" || string === "coach" || string === "other";
  }

  scrollBottom = () => {
    let { scrollRef } = this.state;
    if (scrollRef) {
      scrollRef.scrollTop = scrollRef.scrollHeight;
    }
  };

  getDistanceScrolledFromBottom = () => {
    let { scrollRef } = this.state;
    let distanceFromBottom = 0;

    try {
      distanceFromBottom = Math.abs(
        scrollRef.scrollHeight - (scrollRef.scrollTop + scrollRef.clientHeight)
      );
    } catch (e) {
      return distanceFromBottom;
    }

    return distanceFromBottom;
  };

  //scroll to bottom only if user is already scrolled to bottom
  //made so that user isn't interrupted with scroll if they are
  //reading older messages
  maybeScrollBottom = () => {
    let { scrollRef } = this.state;
    const distanceFromBottom = this.getDistanceScrolledFromBottom();

    if (distanceFromBottom < 500) {
      this.scrollBottom();
    }
  };

  scrollToMessage = (messageId, getMore) => {
    let { scrollRef, unreadMessages, messages } = this.state;
    let { selectedMessageId } = this.props;
    let message;

    if (!messageId) {
      message = this.getMessageFromIndex(messages, unreadMessages);
      messageId = message && message.id;
    }

    // find element by id
    let elementId = "message-" + messageId;
    const element = document.getElementById(elementId);

    if (!element) {
      this.scrollBottom();
      return;
    }

    //scroll element into view with some additional offset, if possible
    element.scrollIntoView(true);
    scrollRef.scrollTop -= 20;
    //if (scrollRef.scrollTop >= 20) {
    //scrollRef.scrollTop += 30;
    //}
  };

  scrollToMessageCentered = (messageId, getMore) => {
    let { scrollRef, unreadMessages, messages } = this.state;
    let { selectedMessageId } = this.props;
    let message;

    // find element by id
    let elementId = "message-" + messageId;
    const element = document.getElementById(elementId);

    //scroll element into view with some additional offset, if possible
    element && element.scrollIntoView(true);
    if (scrollRef.scrollTop >= 20) {
      scrollRef.scrollTop -= 20;
    }
  };

  updateUnreadCount = () => {
    let { unreadMessages } = this.state;
    let numUnread = unreadMessages + 1;
    this.setState({ showUnreadBadge: true, unreadMessages: numUnread });
  };

  startTypingAlert = () => {
    let { chatSub, cableIntegrity, cable } = this.state;

    if (
      chatSub &&
      cableIntegrity &&
      cable &&
      this.shouldLetTypingStatusRequestSend()
    ) {
      this.state.chatSub.perform("typing_status", {
        status: "start",
        model: "user",
      });
    }
  };

  shouldLetTypingStatusRequestSend = () => {
    let { lastTypingStatusSent } = this.state;
    let diff;
    const now = DateTime.local();

    if (!lastTypingStatusSent) {
      this.setState({ lastTypingStatusSent: DateTime.local() });
      return true;
    }

    diff = now.diff(lastTypingStatusSent, "seconds");
    if (diff.seconds < 3) {
      return false;
    } else {
      this.setState({ lastTypingStatusSent: DateTime.local() });
    }

    return true;
  };

  fetchMessages = async (selectedMessageId = null) => {
    this.setState({ loading: true, fetchingInitial: true });
    let { chat } = this.props;
    let { unreadMessages, scrollRef } = this.state;

    let path = `/chat_sessions/${this.props.chat.id}/fetch_initial_slim?category=${this.props.chat.category}&selected_message_id=${selectedMessageId}`;
    const data = {
      url: path,
      data: {
        method: "GET",
      },
    };
    const response = await Api.makeRequest(data);
    if (response?.success === true) {
      let admins = this.state.admins;
      response.admins.forEach((admin) => {
        admins[admin.id] = admin;
      });

      await this.setState({
        loading: false,
        messages: response.messages,
        error: "",
        showGetPrevMessages: response.more_prev,
        showGetNextMessages: response.more_next,
        admins: admins,
      });

      if (this.props.viewingAsAdmin) {
        this.scrollBottom();
        this.setState({ fetchingInitial: false });
        return;
      }

      if (selectedMessageId) {
        this.scrollToMessage(selectedMessageId);
      } else if (unreadMessages > 0) {
        let messagesHeight =
          document.getElementById("messages-container")?.clientHeight || 100;
        const firstUnread = this.getMessageFromIndex(
          response.messages,
          unreadMessages
        );
        if (firstUnread) {
          this.scrollToMessage(firstUnread.id, false);

          if (scrollRef && scrollRef.scrollHeight <= messagesHeight) {
            // not enough messages to scroll
            this.clearNotifications();
          } else {
            this.setState({ showUnreadBadge: true });
          }
        }
      } else {
        this.scrollBottom();
      }

      this.setState({ fetchingInitial: false });
      return;
    }
    this.setState({
      loading: false,
      error: "Could not load chat.",
      fetchingInitial: false,
    });
  };

  handleInputMessage = (message) => {
    this.setState({ message: message });
  };

  //this is passed up from the Controls componenet, which handles parsing
  //and cleaning the raw message
  handleSend = (cleanMessage) => {
    let { attr, modelId, chat } = this.props;
    let userAgent = mobileCheck() ? "mobile" : "comp";

    if (cleanMessage.length > 5000) {
      this.setState({
        error: "Please break your message into smaller pieces.",
      });
      return;
    }

    this.setState({ loading: true, error: "" });
    this.state.chatSub.perform("create_message", {
      message: cleanMessage,
      [attr]: modelId,
      agent: userAgent,
    });
  };

  handleShiftKeyChange = (changed) => {
    this.setState({ shiftPressed: changed });
  };

  // direction: "prev" or "next"
  handleGetMoreMessages = async (direction) => {
    let { messages } = this.state;
    this.setState({ showGetPrevMessages: false, loading: true });
    let messageId;
    let willShowMoreMessages = true;

    if (!messages || messages.length === 0) {
      return;
    }

    messageId =
      direction === "prev" ? messages[0].id : messages[messages.length - 1].id;

    let path = `/chat_sessions/${this.props.chat.id}/fetch_more_messages_slim?chat_id=${this.props.chat.id}&fetch_ref=${messageId}&direction=${direction}`;
    const data = {
      url: path,
      data: {
        method: "GET",
      },
    };
    const response = await Api.makeRequest(data);

    if (!response.messages) {
      this.setState({ loading: false });
      return;
    }

    let newMessages = response.messages;
    if (newMessages.length < 50) {
      willShowMoreMessages = false;
    }

    if (direction === "prev") {
      newMessages.map((message, i) => {
        messages.unshift(message);
      });
      this.setState({ showGetPrevMessages: willShowMoreMessages });
      this.scrollToMessageCentered(messageId, true);
    } else if (direction === "next") {
      newMessages.map((message, i) => {
        messages.push(message);
      });
      this.setState({ showGetNextMessages: willShowMoreMessages });
    }

    this.setState({ messages: messages, loading: false });
  };

  handleFileUpload = async (e) => {
    this.setState({ loading: true });

    const formData = new FormData();
    let { chat } = this.props;
    const types = ["image/png", "image/jpeg", "image/gif"];
    const file = this.fileInput.current.files[0];
    const path = `chat_sessions/${chat.id}/upload_image`;
    let userAgent = "comp";
    let isMobile = mobileCheck();

    if (isMobile) {
      userAgent = "mobile";
    }

    if (types.every((type) => file.type !== type)) {
      this.setState({ error: "Invalid file type.", loading: false });
      return;
    }

    //2,000,000 bytes
    if (file.size > 2000000) {
      this.setState({ error: "Please choose a smaller file.", loading: false });
      return;
    }

    formData.append("image", file);
    formData.append("from_user", true);
    formData.append("agent", userAgent);

    ///chat_sessions/${chat.id}/upload_image
    this.setState({ uploadingImage: true });
    //let res = await asyncAjax.requestFile(path, formData, "POST", this.fileProgress);

    const imageData = {
      url: path,
      data: {
        formData: formData,
        method: "POST",
      },
    };

    const res = await Api.makeRequestFile(imageData);

    if (res?.success === false) {
      this.setState({ error: res.error, loading: false });
      return;
    } else {
      this.setState({ uploadingImage: false });
    }

    //displaying of newly added image is taken care of through action cable
    //so, no clean up is needed
  };

  fileProgress = (event) => {
    /* example
		var percent = 0;
    var position = event.loaded || event.position;
    var total = event.total;
    var progress_bar_id = "#progress-wrp";
    if (event.lengthComputable) {
        percent = Math.ceil(position / total * 100);
    }
    // update progressbars classes so it fits your code
    $(progress_bar_id + " .progress-bar").css("width", +percent + "%");
    $(progress_bar_id + " .status").text(percent + "%");
   	*/
  };

  cancelError = () => {
    this.setState({ error: "" });
  };

  clearNotifications = () => {
    if (this.props.viewingAsAdmin) {
      return;
    }

    let { chatSub } = this.state;
    let { chat } = this.props;
    let oldUnreadCount = chat.unread_count;
    this.state.chatSub.perform("clear_unreads", { model: "user" });
    chat.unread_count = 0;
    this.props.onUpdateChat(chat);
    this.props.onUpdateTotalUnreads(oldUnreadCount);
    this.setState({ showUnreadBadge: false, unreadMessages: 0 });
  };

  updateLastMessage = (message) => {
    let { chatSub } = this.state;
    let { chat } = this.props;
    chat.last_message = message;
    this.props.onUpdateChat(chat);
  };

  evalChatDisabled = () => {
    let { chat } = this.props;
    let chatDisabled = false;
    if (!chat || chat.read_only || !chat.active) {
      chatDisabled = true;
    }

    return chatDisabled;
  };

  handleClickUnreadBadge = async () => {
    if (this.state.showGetNextMessages) {
      this.fetchMessages();
    }
    this.clearNotifications();
    this.scrollBottom();
  };

  showUnreadMessageBadge = () => {
    let { unreadMessages } = this.state;

    if (unreadMessages === 0) {
      return;
    }

    return (
      <div
        className="unread-badge"
        onClick={() => this.handleClickUnreadBadge()}
      >
        <img src={assets.downArrow} className="arrow" />
        {unreadMessages} unread message{unreadMessages > 1 && "s"}
      </div>
    );
  };

  getMessageFromIndex(messages, unreadCount) {
    let message = messages[0];

    if (messages.length !== unreadCount) {
      message = messages[messages.length - unreadCount - 1];
    }

    return message;
  }

  handleScroll = () => {
    let {
      scrollRef,
      unreadMessages,
      messages,
      showGetNextMessages,
    } = this.state;
    let firstUnreadId;
    let firstUnread;
    let firstElement;
    let firstElementRect;
    let lastUnread;
    let lastUnreadId;
    let lastElement;
    let lastElementRect;
    let distanceFromBottom = this.getDistanceScrolledFromBottom();

    if (unreadMessages === 0) {
      return; // unread badge does not need to be cleared
    }

    if (distanceFromBottom <= 40 && !showGetNextMessages) {
      this.clearNotifications();
    }
  };
}

export default Chat;
