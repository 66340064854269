import _ from "lodash";

let curriculumHelper = {
  replaceItem(curriculum, item, sectionIndex) {
    //let item =
  },
  prepareForRender(sections) {
    sections.map((section, i) => {
      let locked = true;
      const clusters = curriculumHelper.clusterItems(section.items);
      if (
        curriculumHelper.sectionIsUnlocked(clusters) ||
        curriculumHelper.sectionIsFirst(section)
      ) {
        locked = false;
      }

      section.clusters = clusters;
      section.locked = locked;
      section.type = "general";
    });

    return sections;
  },
  clusterItems(items) {
    let clusters = [];
    for (let item of items) {
      if (item.category == "title") {
        let cluster = { title: item.title, exerciseItems: [] };
        clusters.push(cluster);
      } else if (clusters.length === 0) {
        let cluster = { exerciseItems: [item] };
        clusters.push(cluster);
      } else {
        clusters[clusters.length - 1].exerciseItems.push(item);
      }
    }
    return clusters;
  },
  sectionIsUnlocked(clusters) {
    return (
      clusters[0] &&
      clusters[0].exerciseItems[0] &&
      clusters[0].exerciseItems[0].locked === false
    );
  },
  sectionIsFirst(section) {
    return section.position === 1;
  },
  assignCurrent(sections) {
    let indexObj = 0;
    for (var i = 0; i < sections.length; i++) {
      const section = sections[i];
      let lockedItems = _.filter(section.items, {
        category: "exercise",
        progressed: false,
      });
      if (lockedItems.length > 0) {
        indexObj = sections.indexOf(section);
        break;
      }
    }

    if (sections[indexObj]) {
      sections[indexObj].current = true;
    }
    return sections;
  },
  insertPushed(specialDelivery, sections) {
    specialDelivery.type = "pushed";
    let current = _.find(sections, { current: true });
    const indexObj = sections.indexOf(current);
    sections.splice(indexObj, 0, specialDelivery);
    return sections;
  },
  insertCompleted(sections) {
    let completedSection = { type: "completed" };
    sections.push(completedSection);
    return sections;
  },
  getScrollRef(sections, curric) {
    let pushedObj = _.find(sections, { type: "pushed" });
    let scrollRef;

    if (pushedObj) {
      if (pushedObj.completed === false) {
        scrollRef = "specialDelivery";
        return scrollRef;
      }
    }

    if (curric.completed === true) {
      scrollRef = "completed-curric";
      return scrollRef;
    }

    const current = _.find(sections, { current: true });
    scrollRef = curriculumHelper.getSectionId(current);
    return scrollRef;
  },
  getSectionId(section) {
    if (section && section.position) {
      return `${section.position}`;
    } else {
      return null;
    }
  },
};

export default curriculumHelper;
