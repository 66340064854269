import PropTypes from "prop-types";
import React from "react";
import cn from "classnames";
import assets from "patient_app/components/chat/assets";

export default class Ellipsis extends React.Component {
  static defaultProps = {
    otherModel: "other",
  };

  render() {
    let { otherModel } = this.props;
    return (
      <div>
        <div
          className={cn("message-container", "image", "ellipsis", otherModel)}
        >
          <div className="message">
            <div className="text">
              <img
                src={assets.ellipsis}
                alt="Ellipsis"
                className="message-img"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
