import PropTypes from "prop-types";
import React from "react";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { PROFILE_UPDATE_STATE_LOCAL } from "patient_app/constants/actionTypes";

import { mobileCheck } from "patient_app/helpers/supported";
import assets from "patient_app/assets";

const mapDispatchToProps = (dispatch) => {
  return {
    setProfileState: (obj, value) =>
      dispatch({ type: PROFILE_UPDATE_STATE_LOCAL, obj: obj, value: value }),
  };
};

const mapStateToProps = (state) => {
  return {};
};

class MainTabBack extends React.Component {
  render() {
    return (
      <div>
        {mobileCheck() && (
          <button
            className="custom-button profile-back"
            onClick={this.handleBack}
          >
            <img
              src={assets.chevronRightNavy}
              className="back-icon"
              aria-label="go back"
            />
            Back
          </button>
        )}
      </div>
    );
  }

  handleBack = () => {
    this.props.history.push(`/profiles/${this.props.match.params.id}`);
    this.props.setProfileState("mainTab", "");
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MainTabBack)
);
