import PropTypes from "prop-types";
import React from "react";
import { DateTime } from "luxon";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { fetchDocuments } from "patient_app/api/profileActions";

import LoadingSpinner from "patient_app/components/utils/LoadingSpinner";
import SubTabBack from "patient_app/views/profiles/partials/SubTabBack";

import { mobileCheck } from "patient_app/helpers/supported";
import { getIconUrl } from "patient_app/helpers/linkHelpers";

const mapDispatchToProps = (dispatch) => {
  return {
    fetchDocuments: (profileId, params, date) =>
      dispatch(fetchDocuments(profileId, params, date)),
  };
};

const mapStateToProps = (state) => {
  return {
    hasMoreSignedDocs: state.profile.hasMoreSignedDocs,
    loading: state.profile.loading,
    signedDocuments: state.profile.signedDocuments,
  };
};

class SignedDocuments extends React.Component {
  profileId = this.props.match.params.id;

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (mobileCheck()) {
      this.props.fetchDocuments(this.profileId, ["signed_documents"]);
    }
  }

  render() {
    let { hasMoreSignedDocs, loading, signedDocuments } = this.props;

    return (
      <div>
        <SubTabBack />
        <div className="profile-section">
          <h1>Signed Documents</h1>

          {!signedDocuments && <LoadingSpinner />}

          {signedDocuments && (
            <div className="profile-list documents">
              {this.renderDocuments()}

              {hasMoreSignedDocs && (
                <button
                  className="custom-button load-more"
                  onClick={this.handleLoadMore}
                >
                  {loading ? <LoadingSpinner /> : "Load more..."}
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }

  renderDocuments() {
    if (this.props.signedDocuments.length === 0) {
      return (
        <div className="list-item none">
          <div className="row">
            <p>No signed documents.</p>
          </div>
        </div>
      );
    }

    return (
      this.props.signedDocuments
        // .filter(sd => !sd?.title.includes("Release"))
        .map((doc, i) => {
          return (
            <div className="list-item" key={i}>
              <div className="row">
                <p>
                  <span>{doc.title}</span>
                </p>
                <div className="col align-right">
                  <p className="green">
                    <span>
                      Signed (
                      {DateTime.fromISO(doc.created_at).toFormat("LL/dd/yy")})
                    </span>
                  </p>

                  {doc.urls && doc.urls.length > 0 && (
                    <a
                      href={doc.urls[0]}
                      target="_blank"
                      className="item-action"
                    >
                      <img
                        src={getIconUrl("chevron-right", "dark-blue")}
                        alt="View"
                      />
                    </a>
                  )}
                </div>
              </div>
            </div>
          );
        })
    );
  }

  handleLoadMore = () => {
    const { signedDocuments } = this.props;
    const date = signedDocuments[signedDocuments.length - 1].created_at;
    this.props.fetchDocuments(
      this.profileId,
      ["signed_documents", "get_more"],
      date
    );
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SignedDocuments)
);
