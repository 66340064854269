import css from "styled-jsx/css";
import { variables } from "patient_app/stylesheets/variables.js";

export default css.global`
  .App-Tabs {
    position: relative;
    display: flex;
    max-width: 100%;
  }

  .App-Tabs .column {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 0 1rem;
  }

  .App-Tabs .App-Tab {
    display: flex;
    flex-direction: column;
    background-color: white;
    outline: 1px solid ${variables.boxBorder};
    height: fit-content;
    margin-bottom: 2rem;
  }

  .App-Tabs .App-Tab .header {
    display: flex;
    align-items: center;
    padding: 0 1.75rem;
    border-bottom: 1px solid ${variables.boxBorder};
    box-sizing: border-box;
    height: 5rem;
  }

  .App-Tabs .App-Tab .header h2 {
    margin: 0;
    font-weight: normal;
    font-size: 1.2rem;
    font-family: ${variables.objBold};
    color: ${variables.darkBlue};
    letter-spacing: 0.25px;
    line-height: 1.5rem;
  }

  .App-Tabs .App-Tab .header span.subtitle {
    font-family: ${variables.objReg};
    font-size: 0.85rem;
    color: ${variables.darkBlue};
    margin: 0;
    margin-left: 0.5rem;
  }

  @media only screen and (max-width: 950px) {
    .App-Tabs {
      flex-direction: column;
    }

    .App-Tabs .App-Tab .header h2 {
      display: flex;
      flex-direction: column;
    }

    .App-Tabs .App-Tab .header span.subtitle {
      margin: 0;
    }
  }
`;
