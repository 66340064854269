let icons = {
  arrows: {
    arrowBackWhite:
      "https://storage.googleapis.com/workit-client-assets/icons/arrows/arrowBackWhite.svg",
  },
  videoRoom: {
    callEnd:
      "https://storage.googleapis.com/workit-client-assets/video/call_end-24px.svg",
    chat:
      "https://storage.googleapis.com/workit-client-assets/video/chat-24px.svg",
    videoOn:
      "https://storage.googleapis.com/workit-client-assets/video/videocam-24px.svg",
    videoOff:
      "https://storage.googleapis.com/workit-client-assets/video/videocam_off-24px.svg",
    audioOn:
      "https://storage.googleapis.com/workit-client-assets/video/volume_up-24px.svg",
    audioOff:
      "https://storage.googleapis.com/workit-client-assets/video/volume_off-24px.svg",
  },
  calendar: {
    google:
      "https://storage.googleapis.com/workit-client-assets/onboarding/add_to_calendar_icons/Calendar_Google_Green.svg",
    apple:
      "https://storage.googleapis.com/workit-client-assets/onboarding/add_to_calendar_icons/Calendar_Apple_Green.svg",
    outlook:
      "https://storage.googleapis.com/workit-client-assets/onboarding/add_to_calendar_icons/Calendar_Outlook_Green.svg",
    yahoo:
      "https://storage.googleapis.com/workit-client-assets/onboarding/add_to_calendar_icons/Calendar_Yahoo_Green.svg",
  },
};

export default icons;
