import PropTypes from "prop-types";
import React from "react";
import { DateTime } from "luxon";

import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  OPEN_CHAT,
  APPTS_DRUG_TEST_DETAILS,
} from "patient_app/constants/actionTypes";

import assets from "patient_app/assets";
import permissions from "patient_app/helpers/permissions";
import textHelpers from "patient_app/helpers/textHelpers";
import { variables } from "patient_app/stylesheets/variables";
import modalStyles from "patient_app/stylesheets/appointments/modalStyles";

const mapDispatchToProps = (dispatch) => {
  return {
    openChat: (data) => dispatch({ type: OPEN_CHAT, data: data }),
    clearModal: (view) =>
      dispatch({ type: APPTS_DRUG_TEST_DETAILS, view: null }),
  };
};

const mapStateToProps = (state) => {
  return {
    clinic: state.common.clinic,
    drugTestDetails: state.appointments.drugTestDetails,
    profile: state.common.profile,
    user: state.common.user,
  };
};

class DrugTestingModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      testType: null,
    };

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    // detect if user clicks outside modal
    document.addEventListener("mousedown", this.handleClickOutside);

    // focus to close button
    this.closeRef.focus();
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.clearModal();
    }
  };

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  render() {
    let { clinic, drugTestDetails } = this.props;

    let { testType } = this.state;

    let title = this.getTitle();

    return (
      <div className="dashboard-modal">
        <div className="modal" ref={this.setWrapperRef}>
          <div
            className="header"
            style={{ backgroundColor: variables.blueLight }}
          >
            <div>
              <p className="title no-margin">{title}</p>
            </div>

            <button
              ref={(ref) => {
                this.closeRef = ref;
              }}
              className="custom-button"
              onClick={this.props.clearModal}
            >
              <img src={assets.closeWhite} />
            </button>
          </div>

          {drugTestDetails === "times" && (
            <div className="index can-scroll">
              <div className="alert-box">
                <p>
                  Workit runs drug testing on a reoccurring basis. Below are the
                  available drug testing hours for your clinic. If you need to
                  test outside of these times{" "}
                  <a
                    href="#"
                    onClick={this.messageMedicalChat}
                    style={{ color: variables.blueDark }}
                  >
                    please message your medical team.
                  </a>
                </p>
              </div>

              {this.renderTimes()}
            </div>
          )}

          {drugTestDetails === "prepare" && (
            <div className="index">
              <div className="details">
                <ul>
                  <li>
                    Make sure you have paid all outstanding balances on your
                    account.
                  </li>
                  <li>Reserve at least 30 minutes for testing.</li>
                  <li>Find a quiet, well-lit, private space to test in.</li>
                  <li>Ensure you have a stable internet connection.</li>
                  <li>
                    Have a backup device nearby, in case there are technical
                    problems.
                  </li>
                  <li>
                    Make sure you have an un-opened test and a clear empty cup
                    available to test with. (Note: please ensure you have access
                    to both Saliva and Urine tests at the time of your video
                    call.){/*If you need to order more tests click here.*/}
                  </li>
                  <li>Have a pen handy to record testing details.</li>
                  <li>
                    If you need to order more tests{" "}
                    <Link to={`/drug_test_orders/new`}>click here</Link>.
                  </li>
                </ul>
              </div>
            </div>
          )}

          {drugTestDetails === "expect" && !testType && (
            <div className="index">
              <button
                className="custom-button info-item"
                onClick={() => this.setState({ testType: "urine" })}
              >
                <p>Urine Test</p>
                <img src={assets.chevronRightNavy} />
              </button>

              <button
                className="custom-button info-item"
                onClick={() => this.setState({ testType: "saliva" })}
              >
                <p>Saliva Test</p>
                <img src={assets.chevronRightNavy} />
              </button>
            </div>
          )}

          {drugTestDetails === "expect" && testType === "urine" && (
            <div className="index">
              <div className="details">
                <div className="alert-box">
                  <p>
                    In order to continue with your treatment at Workit we are
                    required by law to administer a Urine Test prior to your
                    Provider visits.
                  </p>
                </div>

                <div className="subheader">
                  <p className="title">Urine Test</p>

                  <button
                    className="custom-button back"
                    onClick={() => this.setState({ testType: null })}
                  >
                    <p>&lt; Back to Overview</p>
                  </button>
                </div>

                <ul>
                  <li>
                    Click on the button shown on the Drug Testing page to enter
                    your testing video room.
                  </li>
                  <li>
                    Upon entering, you will join our digital waiting room.
                  </li>
                  <li>
                    When our team member is available to meet, you will be
                    admitted to a private video room where you will begin your
                    test.
                  </li>
                  <li>
                    At this point, our team member will ask you to show your
                    sealed/un-opened Urine Test and clear empty cup.{" "}
                    {/*If you need to order more tests click here.*/}
                  </li>
                  <li>
                    Next our team member will instruct you to open the test and
                    go off camera to collect your sample.
                  </li>
                  <li>
                    Once you have collected your sample, return to the camera
                    and administer your test under our team member’s
                    supervision.
                  </li>
                  <li>
                    At this point our team member will ask you to focus your
                    camera on the drug test so they can review and record your
                    test results. (Please make sure your lighting and internet
                    is clear enough to digitally view your test.)
                  </li>
                  <li>That’s it! You have completed your test.</li>
                </ul>
              </div>
            </div>
          )}

          {drugTestDetails === "expect" && testType === "saliva" && (
            <div className="index">
              <div className="details">
                <div className="alert-box">
                  <p>
                    In order to continue with your treatment at Workit we
                    require you to complete a random Saliva Test twice a year.
                  </p>
                </div>

                <div className="subheader">
                  <p className="title">Saliva Test</p>

                  <button
                    className="custom-button back"
                    onClick={() => this.setState({ testType: null })}
                  >
                    <p>&lt; Back to Overview</p>
                  </button>
                </div>

                <ul>
                  <li>
                    Click on the button shown on the Drug Testing page to enter
                    your testing video room.
                  </li>
                  <li>
                    Upon entering, you will join our digital waiting room.
                  </li>
                  <li>
                    When our team member is available to meet, you will be
                    admitted to a private video room where you will begin your
                    test.
                  </li>
                  <li>
                    At this point, our team member will ask you to show your
                    sealed/un-opened Saliva Test.{" "}
                    {/*If you need to order more tests click here.*/}
                  </li>
                  <li>
                    Next our team member will instruct you to open the test and
                    swab your mouth to produce a Saliva Test sample on camera.
                  </li>
                  <li>
                    After you have completed your test, our counselor will ask
                    you to focus your camera on the drug test so they can review
                    and record your test results. (Please make sure your
                    lighting and internet is clear enough to digitally view your
                    test.)
                  </li>
                  <li>That’s it! You have completed your test.</li>
                </ul>
              </div>
            </div>
          )}

          <style jsx>{modalStyles}</style>
        </div>
      </div>
    );
  }

  getTitle() {
    let { drugTestDetails } = this.props;
    let { testType } = this.state;

    let title = "Video Drug Testing";
    if (drugTestDetails === "times") {
      title = "Drug Testing Hours";
    } else if (drugTestDetails === "prepare") {
      title = "Drug Testing: How to Prepare";
    } else if (drugTestDetails === "expect") {
      title = "Drug Testing: What to Expect";
    }

    return title;
  }

  formatTime(hour, minute, tz, userZone) {
    let dt = DateTime.local()
      .setZone(tz)
      .startOf("day")
      .set({ hour: hour, minute: minute })
      .setZone(userZone);
    return dt.toFormat("h:mm a");
  }

  renderTimes = () => {
    let { clinic, profile } = this.props;
    const timeZone =
      profile?.time_zone || clinic?.time_zone || DateTime.local().zoneName;
    const formattedTz = DateTime.local().setZone(timeZone).toFormat("ZZZZ");

    if (clinic?.testing_times && clinic.testing_times.length > 0) {
      return clinic.testing_times.map((dtt, i) => {
        let startAt = this.formatTime(
          dtt.sa_h,
          dtt.sa_m,
          dtt.time_zone,
          timeZone
        );
        let endAt = this.formatTime(
          dtt.ea_h,
          dtt.ea_m,
          dtt.time_zone,
          timeZone
        );

        return (
          <div className="info-item drug-test-hours" key={i}>
            <p className="title">
              Every {textHelpers.uppercaseFirstLetter(dtt.day)}
            </p>
            <p>
              {startAt} - {endAt} {formattedTz}
            </p>

            <style jsx>{modalStyles}</style>
          </div>
        );
      });
    }
  };

  messageMedicalChat = (e) => {
    if (e) {
      e.preventDefault();
    }

    let message = "Hi, I need to test outside of clinic drug testing hours.";
    this.props.openChat({
      view: "index",
      prepopulatedMessage: message,
      topic: "MEDICAL",
    });
    this.props.clearModal();
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DrugTestingModal);
