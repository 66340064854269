import PropTypes from "prop-types";
import React from "react";
import cn from "classnames";
import TextField from "patient_app/components/input_fields/TextField";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { enableMfa } from "patient_app/api/sessionActions";
import SubmitButton from "patient_app/components/buttons/SubmitButton";

import {
  UPDATE_MFA_CODE,
  NEW_FIELD_SPECIFIC_ERROR,
  NEW_ERRORS,
  CLEAR_FIELD_SPECIFIC_ERRORS,
} from "patient_app/constants/actionTypes";

import QRCode from "qrcode.react";

import mfaConfigStyles from "patient_app/stylesheets/profiles/mfaConfigStyles";

const mapDispatchToProps = (dispatch) => {
  return {
    updateMfaCode: (code) => dispatch({ type: UPDATE_MFA_CODE, code: code }),
    onError: (field, error) =>
      dispatch({ type: NEW_FIELD_SPECIFIC_ERROR, field: field, error: error }),
    onGeneralErrors: (errors) => dispatch({ type: NEW_ERRORS, errors: errors }),
    clearFieldErrors: () => dispatch({ type: CLEAR_FIELD_SPECIFIC_ERRORS }),
    enableMfa: (profileId, otpSecretKey, mfaCode) =>
      dispatch(enableMfa(profileId, otpSecretKey, mfaCode)),
  };
};

const mapStateToProps = (state) => {
  return {
    provisioningUri: state.session.provisioningUri,
    otpSecretKey: state.session.otpSecretKey,
    mfaCode: state.session.mfaCode,
    isFetching: state.common.loading,
  };
};

class MfaConfig extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { provisioningUri, otpSecretKey, mfaCode, isFetching } = this.props;

    return (
      <div className="Mfa-Config-Container">
        <p>
          To proceed, scan the QR code below with your 2FA app (we recommend
          using{" "}
          <a href="https://support.google.com/accounts/answer/1066447?hl=en">
            Google Authenticator
          </a>
          ), input the code generated, and click on Enable 2FA.
        </p>
        <div className="canvas-container">
          <QRCode value={`${provisioningUri}&issuer=Workit%20Health`} />
        </div>
        <p>
          If you are unable to scan your QR code, enter your secret key
          directly:
        </p>
        <p>{otpSecretKey}</p>

        <TextField
          field={"mfa_code"}
          title={"2FA Token"}
          value={mfaCode ? mfaCode : ""}
          id={"mfa_code"}
          onUpdateField={this.updateMfa}
          uid={"mfa_code"}
          required={false}
        />

        <button
          className={`custom-button submit half ${isFetching ? "loading" : ""}`}
          onClick={this.onSubmit}
        >
          Enable 2FA
        </button>

        <style jsx>{mfaConfigStyles}</style>
      </div>
    );
  }

  updateMfa = (value, field) => {
    this.props.updateMfaCode(value);
  };

  onSubmit = () => {
    let { profileId, provisioningUri, otpSecretKey, mfaCode } = this.props;

    if (!mfaCode || mfaCode.length < 6) {
      this.props.onGeneralErrors([
        { text: "Please correct the issues listed below." },
      ]);
      this.props.onError("mfa_code", "2FA code must be 6 characters in length");
      return;
    }

    console.log(mfaCode, "sending");
    this.props.clearFieldErrors();
    this.props.onGeneralErrors([]);
    this.props.enableMfa(profileId, otpSecretKey, mfaCode);
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MfaConfig);
