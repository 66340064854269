import Api from "patient_app/api";
import trackerActions from "patient_app/helpers/trackerActions";

import {
  PROFILE_REQ_START,
  PROFILE_REQ_SUCCESS,
  PROFILE_REQ_FAILURE,
  NEW_ERRORS,
  USER_LOAD,
  PROFILE_LOAD,
  EC_LOAD,
  PROFILE_UPDATE_STATE_LOCAL,
  PROFILE_LOAD_MORE_FROM_CHAT,
  PROFILE_LOAD_MORE_SIGNED_DOCS,
  ONBOARDING_HEALTH_INFO,
  SESSION_LOAD_CHECKLIST,
  ONBOARDING_VERIFY_ADDRESS,
  CLINIC_LOAD,
  INSURANCE_UPDATE_STATE,
  COMMON_UPDATE_START,
  COMMON_UPDATE_SUCCESS,
  COMMON_UPDATE_FAILURE,
  FETCH_PROGRAM,
  FETCH_PAYMENT_INITIAL,
  FETCH_PAYMENT_ALL,
  FETCH_MISSION_MAP,
  NEW_FORM_ERRORS,
  PROFILE_UPDATE_LOCAL,
  UPDATE_PASSWORD_SUCCESS,
  PROFILE_UPDATING_PICTURE,
  PROFILE_LOAD_DOCUMENTS,
  PROFILE_LOAD_DRUG_TEST_ORDERS,
  PROFILE_LOAD_RECEIPT_INFO,
  PROFILE_LOAD_PATIENT_INVOICE,
  TOGGLE_PAYWALL,
  TX_PLAN_LOAD,
  TX_PLAN_USER_PLANS,
  PROFILE_REQ_CODE_SUCCESS,
  PROFILE_UPDATE_EMAIL_SUCCESS,
  PROFILE_UPDATE_EMAIL_ERRORS,
  PROFILE_UPDATE_PHONE_SUCCESS,
  PROFILE_UPDATE_PHONE_ERRORS,
} from "patient_app/constants/actionTypes";

export const fetchAccount = (profileId, params = []) => {
  let url = `/profiles/${profileId}/account?`;
  params.forEach((param) => {
    url += `${param}=true&`;
  });

  const data = {
    url: url,
    path: "/api/v2",
    data: { method: "GET" },
  };

  return async (dispatch) => {
    try {
      const res = await Api.makeRequest(data);
      res.user && dispatch({ type: USER_LOAD, user: res.user });
      res.profile && dispatch({ type: PROFILE_LOAD, profile: res.profile });
      res.emergency_contacts &&
        dispatch({ type: EC_LOAD, emergencyContacts: res.emergency_contacts });
    } catch (e) {
      console.log(e);
    }
  };
};

export const updateAccountProfile = (user, profile) => {
  const data = {
    url: `/profiles/${profile.id}/update_account_profile`,
    path: "/api/v2",
    data: {
      method: "POST",
      body: { user: user, profile: profile },
    },
  };

  return async (dispatch) => {
    try {
      dispatch({ type: PROFILE_REQ_START });
      const res = await Api.makeRequest(data);
      if (res.success) {
        dispatch({ type: USER_LOAD, user: res.user });
        dispatch({ type: PROFILE_LOAD, profile: res.profile });
        dispatch({ type: PROFILE_REQ_SUCCESS });
      } else {
        dispatch({
          type: NEW_ERRORS,
          errors: [{ text: "Could not save changes." }],
        });
        dispatch({ type: PROFILE_REQ_FAILURE });
        window.scrollTo(0, 0);
      }
    } catch (e) {
      dispatch({
        type: NEW_ERRORS,
        errors: [{ text: "Could not save changes." }],
      });
      dispatch({ type: PROFILE_REQ_FAILURE });
      window.scrollTo(0, 0);
      console.log(e);
    }
  };
};

export const updatePassword = (
  profileId,
  current,
  newPass,
  newPassConf,
  otpCode
) => {
  const data = {
    url: `/profiles/${profileId}/update_password`,
    path: "/api/v2",
    data: {
      method: "POST",
      body: {
        current_password: current,
        password: newPass,
        password_confirmation: newPassConf,
        otp_code: otpCode,
      },
    },
  };

  return async (dispatch) => {
    try {
      dispatch({ type: PROFILE_REQ_START });
      const res = await Api.makeRequest(data);
      if (res.success) {
        dispatch({ type: PROFILE_REQ_SUCCESS });
        dispatch({
          type: NEW_ERRORS,
          errors: [{ status: "info", text: "Successfully updated password." }],
        });
      } else {
        dispatch({ type: PROFILE_REQ_FAILURE });
        dispatch({ type: NEW_ERRORS, errors: [{ text: res.message }] });
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const sendEmailVerification = (newEmail, currentPassword) => {
  const data = {
    url: `/authentication/send_email_verification`,
    data: {
      method: "POST",
      body: {
        new_email: newEmail,
        password: currentPassword,
      },
    },
  };

  return async (dispatch) => {
    try {
      const res = await Api.makeRequest(data);
      if (res.success) {
        dispatch({ type: PROFILE_REQ_CODE_SUCCESS });
        dispatch({ type: PROFILE_UPDATE_EMAIL_ERRORS, errors: [] });
      } else {
        dispatch({ type: PROFILE_UPDATE_EMAIL_ERRORS, errors: res.errors });
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const verifyAndUpdateEmail = (newEmail, verificationCode) => {
  const data = {
    url: `/authentication/verify_and_update_email`,
    data: {
      method: "POST",
      body: {
        new_email: newEmail,
        verification_code: verificationCode,
      },
    },
  };

  return async (dispatch) => {
    try {
      const res = await Api.makeRequest(data);
      if (res.success) {
        const updatedUser = Object.assign({}, res.user);
        dispatch({ type: PROFILE_UPDATE_EMAIL_SUCCESS });
        dispatch({ type: PROFILE_UPDATE_EMAIL_ERRORS, errors: [] });
        dispatch({
          type: NEW_ERRORS,
          errors: [{ text: "Email Updated", status: "info" }],
        });
        dispatch({ type: USER_LOAD, user: updatedUser });
        return;
      }

      dispatch({ type: PROFILE_UPDATE_EMAIL_ERRORS, errors: res.errors });
    } catch (e) {
      console.log(e);
    }
  };
};

export const sendPhoneVerification = (newPhone, currentPassword) => {
  const data = {
    url: `/authentication/send_phone_verification`,
    data: {
      method: "POST",
      body: {
        new_phone: newPhone,
        password: currentPassword,
      },
    },
  };

  return async (dispatch) => {
    try {
      const res = await Api.makeRequest(data);
      if (res.success) {
        dispatch({ type: PROFILE_REQ_CODE_SUCCESS });
        dispatch({ type: PROFILE_UPDATE_PHONE_ERRORS, errors: [] });
      } else {
        dispatch({ type: PROFILE_UPDATE_PHONE_ERRORS, errors: res.errors });
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const verifyAndUpdatePhone = (newPhone, verificationCode) => {
  const data = {
    url: `/authentication/verify_and_update_phone`,
    data: {
      method: "POST",
      body: {
        new_phone: newPhone,
        verification_code: verificationCode,
      },
    },
  };

  return async (dispatch) => {
    try {
      const res = await Api.makeRequest(data);
      if (res.success) {
        const updatedUser = Object.assign({}, res.user);
        dispatch({ type: PROFILE_UPDATE_PHONE_SUCCESS });
        dispatch({ type: PROFILE_UPDATE_PHONE_ERRORS, errors: [] });
        dispatch({
          type: NEW_ERRORS,
          errors: [{ text: "Phone Updated", status: "info" }],
        });
        dispatch({ type: USER_LOAD, user: updatedUser });
        return;
      }

      dispatch({ type: PROFILE_UPDATE_PHONE_ERRORS, errors: res.errors });
    } catch (e) {
      console.log(e);
    }
  };
};

export const updateProfilePic = (profile, image) => {
  let formData = new FormData();
  formData.append("image", image);

  const imageData = {
    url: `/profiles/${profile.id}/update_profile_picture`,
    path: "/api/v2",
    data: {
      formData: formData,
      method: "POST",
    },
  };

  return async (dispatch) => {
    dispatch({ type: COMMON_UPDATE_START });
    dispatch({ type: PROFILE_UPDATING_PICTURE, updatingPicture: true });
    try {
      const res = await Api.makeRequestFile(imageData);
      if (res.success) {
        dispatch({ type: PROFILE_LOAD, profile: res.profile });
      } else {
        dispatch({ type: NEW_ERRORS, errors: [{ text: res.message }] });
      }
      dispatch({ type: PROFILE_UPDATING_PICTURE, updatingPicture: false });
    } catch (e) {
      dispatch({
        type: NEW_ERRORS,
        errors: [{ text: "Could not update profile picture." }],
      });
      dispatch({ type: PROFILE_UPDATING_PICTURE, updatingPicture: false });
      console.log(e);
    }
  };
};

export const fetchLicense = (profileId) => {
  const data = {
    url: `/profiles/${profileId}/drivers_license`,
    path: "/api/v2",
    data: { method: "GET" },
  };

  return async (dispatch) => {
    try {
      dispatch({ type: PROFILE_REQ_START });
      const res = await Api.makeRequest(data);
      if (res.success) {
        dispatch({
          type: PROFILE_UPDATE_STATE_LOCAL,
          obj: "driversLicense",
          value: res.images,
        });
        dispatch({ type: PROFILE_REQ_SUCCESS });
      } else {
        dispatch({ type: PROFILE_REQ_FAILURE });
        dispatch({
          type: NEW_ERRORS,
          errors: [{ text: "Something went wrong, please refresh the page." }],
        });
      }
    } catch (e) {
      dispatch({ type: PROFILE_REQ_FAILURE });
      dispatch({
        type: NEW_ERRORS,
        errors: [{ text: "Something went wrong, please refresh the page." }],
      });
      console.log(e);
    }
  };
};

export const createDocument = (profileId, formData, callback = null) => {
  const imageData = {
    url: `/profiles/${profileId}/upload_document`,
    path: "/api/v2",
    data: {
      formData: formData,
      method: "POST",
    },
  };

  return async (dispatch) => {
    try {
      dispatch({ type: PROFILE_REQ_START });
      const res = await Api.makeRequestFile(imageData);
      if (callback) {
        callback(res);
        return;
      }

      if (res.success) {
        if (res.document.title === "Drivers License") {
          dispatch({
            type: PROFILE_UPDATE_STATE_LOCAL,
            obj: "driversLicense",
            value: res.images,
          });

          dispatch({
            type: NEW_ERRORS,
            errors: [
              {
                status: "info",
                text: "Driver's license successfully uploaded.",
              },
            ],
          });
        } else {
          dispatch({
            type: PROFILE_LOAD_DOCUMENTS,
            documents: res.documents,
          });
          dispatch({
            type: NEW_ERRORS,
            errors: [
              { status: "info", text: "Document successfully uploaded." },
            ],
            errorsClass: "generic",
          });
        }
        dispatch({ type: PROFILE_REQ_SUCCESS });
      } else {
        dispatch({ type: PROFILE_REQ_FAILURE });
        dispatch({ type: NEW_ERRORS, errors: [{ text: res.error }] });
        window.scrollTo(0, 0);
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const fetchProgram = (profileId, params = []) => {
  let url = `/profiles/${profileId}/program?`;
  params.forEach((param) => {
    url += `${param}=true&`;
  });

  const data = {
    url: url,
    path: "/api/v2",
    data: { method: "GET" },
  };

  return async (dispatch) => {
    try {
      dispatch({ type: PROFILE_REQ_START });
      const res = await Api.makeRequest(data);
      if (res.success) {
        res.clinic && dispatch({ type: CLINIC_LOAD, clinic: res.clinic });
        res.profile && dispatch({ type: PROFILE_LOAD, profile: res.profile });
        res.mission_map &&
          dispatch({
            type: PROFILE_UPDATE_STATE_LOCAL,
            obj: "missionMap",
            value: res.mission_map,
          });
        res.curriculums &&
          dispatch({
            type: PROFILE_UPDATE_STATE_LOCAL,
            obj: "curriculums",
            value: res.curriculums,
          });

        if (res.treatment_plans) {
          let userTreatmentPlans = [];
          res.treatment_plans.forEach((plan) => {
            userTreatmentPlans.push(plan.id);
            dispatch({ type: TX_PLAN_LOAD, treatmentPlan: plan });
          });
          dispatch({
            type: TX_PLAN_USER_PLANS,
            userTreatmentPlans: userTreatmentPlans,
          });
        }

        dispatch({ type: PROFILE_REQ_SUCCESS });
      } else {
        dispatch({ type: PROFILE_REQ_FAILURE });
        dispatch({
          type: NEW_ERRORS,
          errors: [{ text: "Something went wrong, please refresh the page." }],
        });
      }
    } catch (e) {
      dispatch({ type: PROFILE_REQ_FAILURE });
      dispatch({
        type: NEW_ERRORS,
        errors: [{ text: "Something went wrong, please refresh the page." }],
      });
      console.log(e);
    }
  };
};

export const fetchDocuments = (profileId, params = [], date = null) => {
  let url = `/profiles/${profileId}/documents?`;
  params.forEach((param) => {
    url += `${param}=true&`;
  });
  if (date) {
    url += `date=${date}`;
  }

  const data = {
    url: url,
    path: "/api/v2",
    data: { method: "GET" },
  };

  return async (dispatch) => {
    try {
      dispatch({ type: PROFILE_REQ_START });
      const res = await Api.makeRequest(data);
      if (res.success) {
        if (params.includes("get_more") && params.includes("from_chat")) {
          dispatch({
            type: PROFILE_LOAD_MORE_FROM_CHAT,
            fromChat: res.from_chat,
            hasMoreFromChat: res.has_more_from_chat,
          });
        } else if (
          params.includes("get_more") &&
          params.includes("signed_documents")
        ) {
          dispatch({
            type: PROFILE_LOAD_MORE_SIGNED_DOCS,
            signedDocuments: res.signed_documents,
            hasMoreSignedDocs: res.has_more_signed_docs,
          });
        } else {
          res.general_documents &&
            dispatch({
              type: PROFILE_UPDATE_STATE_LOCAL,
              obj: "generalDocuments",
              value: res.general_documents,
            });
          res.signed_documents &&
            dispatch({
              type: PROFILE_UPDATE_STATE_LOCAL,
              obj: "signedDocuments",
              value: res.signed_documents,
            });
          res.has_more_signed_docs &&
            dispatch({
              type: PROFILE_UPDATE_STATE_LOCAL,
              obj: "hasMoreSignedDocs",
              value: res.has_more_signed_docs,
            });
          res.rois &&
            dispatch({
              type: PROFILE_UPDATE_STATE_LOCAL,
              obj: "rois",
              value: res.rois,
            });
          res.from_chat &&
            dispatch({
              type: PROFILE_UPDATE_STATE_LOCAL,
              obj: "fromChat",
              value: res.from_chat,
            });
          res.has_more_from_chat &&
            dispatch({
              type: PROFILE_UPDATE_STATE_LOCAL,
              obj: "hasMoreFromChat",
              value: res.has_more_from_chat,
            });
          res.checklist_items &&
            dispatch({
              type: PROFILE_UPDATE_STATE_LOCAL,
              obj: "checklistItems",
              value: res.checklist_items,
            });
          res.clinic_files &&
            dispatch({
              type: PROFILE_UPDATE_STATE_LOCAL,
              obj: "clinicFiles",
              value: res.clinic_files,
            });
        }
        dispatch({ type: PROFILE_REQ_SUCCESS });
      } else {
        dispatch({ type: PROFILE_REQ_FAILURE });
        dispatch({
          type: NEW_ERRORS,
          errors: [{ text: "Something went wrong, please refresh the page." }],
        });
      }
    } catch (e) {
      dispatch({ type: PROFILE_REQ_FAILURE });
      dispatch({
        type: NEW_ERRORS,
        errors: [{ text: "Something went wrong, please refresh the page." }],
      });
      console.log(e);
    }
  };
};

export const createROI = (roi, oldId = null) => {
  const data = {
    url: "/release_of_info",
    path: "/api/v1",
    data: {
      method: "POST",
      body: { release_of_info: roi, old_roi_id: oldId },
    },
  };

  return async (dispatch) => {
    try {
      dispatch({ type: PROFILE_REQ_START });
      const res = await Api.makeRequest(data);
      if (res.success) {
        dispatch({
          type: PROFILE_UPDATE_STATE_LOCAL,
          obj: "rois",
          value: res.rois,
        });
        dispatch({
          type: NEW_ERRORS,
          errors: [
            {
              status: "info",
              text: "Release of information successfully created.",
            },
          ],
          errorsClass: "generic",
        });
        dispatch({ type: PROFILE_REQ_SUCCESS });
        return;
      }

      dispatch({ type: PROFILE_REQ_FAILURE });
      dispatch({
        type: NEW_ERRORS,
        errors: [{ text: res.error }],
        errorsClass: "generic",
      });
      window.scrollTo(0, 0);
    } catch (e) {
      console.log(e);
    }
  };
};

export const updateROI = (roi) => {
  const data = {
    url: `/release_of_info/${roi.id}`,
    path: "/api/v1",
    data: {
      method: "PATCH",
      body: { release_of_info: roi },
    },
  };

  return async (dispatch) => {
    try {
      dispatch({ type: PROFILE_REQ_START });
      const res = await Api.makeRequest(data);
      if (res.success) {
        dispatch({
          type: PROFILE_UPDATE_STATE_LOCAL,
          obj: "rois",
          value: res.rois,
        });
        dispatch({
          type: NEW_ERRORS,
          errors: [
            {
              status: "info",
              text: "Release of information successfully updated.",
            },
          ],
          errorsClass: "generic",
        });
        dispatch({ type: PROFILE_REQ_SUCCESS });
        return;
      }

      dispatch({ type: PROFILE_REQ_FAILURE });
      dispatch({
        type: NEW_ERRORS,
        errors: [{ text: res.error }],
        errorsClass: "generic",
      });
      window.scrollTo(0, 0);
    } catch (e) {
      console.log(e);
    }
  };
};

export const fetchPayment = (profileId, params = []) => {
  let url = `/profiles/${profileId}/payment?`;
  params.forEach((param) => {
    url += `${param}=true&`;
  });

  const data = {
    url: url,
    path: "/api/v2",
    data: { method: "GET" },
  };

  return async (dispatch) => {
    try {
      dispatch({ type: PROFILE_REQ_START });
      const res = await Api.makeRequest(data);
      if (res.success) {
        if (params.includes("payment_history") && params.includes("get_more")) {
          dispatch({
            type: PROFILE_UPDATE_STATE_LOCAL,
            obj: "payments",
            value: res.payments,
          });
          dispatch({
            type: PROFILE_UPDATE_STATE_LOCAL,
            obj: "hasMorePayments",
            value: res.has_more_payments,
          });
        } else {
          res.patient_invoices &&
            dispatch({
              type: PROFILE_UPDATE_STATE_LOCAL,
              obj: "patientInvoices",
              value: res.patient_invoices,
            });
          res.has_more_invoices &&
            dispatch({
              type: PROFILE_UPDATE_STATE_LOCAL,
              obj: "hasMoreInvoices",
              value: res.has_more_invoices,
            });
          res.payment_info &&
            dispatch({
              type: PROFILE_UPDATE_STATE_LOCAL,
              obj: "paymentInfo",
              value: res.payment_info,
            });
          res.stripe_key &&
            dispatch({
              type: PROFILE_UPDATE_STATE_LOCAL,
              obj: "stripeKey",
              value: res.stripe_key,
            });
          res.stripe_account_key &&
            dispatch({
              type: PROFILE_UPDATE_STATE_LOCAL,
              obj: "stripeAccountKey",
              value: res.stripe_account_key,
            });
          res.payments &&
            dispatch({
              type: PROFILE_UPDATE_STATE_LOCAL,
              obj: "payments",
              value: res.payments,
            });
          res.has_more_payments &&
            dispatch({
              type: PROFILE_UPDATE_STATE_LOCAL,
              obj: "hasMorePayments",
              value: res.has_more_payments,
            });
        }

        dispatch({ type: PROFILE_REQ_SUCCESS });
      } else {
        dispatch({ type: PROFILE_REQ_FAILURE });
        dispatch({
          type: NEW_ERRORS,
          errors: [{ text: "Something went wrong, please refresh the page." }],
        });
      }
    } catch (e) {
      dispatch({ type: PROFILE_REQ_FAILURE });
      dispatch({
        type: NEW_ERRORS,
        errors: [{ text: "Something went wrong, please refresh the page." }],
      });
      console.log(e);
    }
  };
};

export const chargePrimaryCard = (profileId, user) => {
  const data = {
    url: `/profiles/${profileId}/charge_primary_card`,
    path: "/api/v2",
    data: { method: "POST" },
  };

  return async (dispatch) => {
    try {
      dispatch({ type: PROFILE_REQ_START });
      const res = await Api.makeRequest(data);
      if (res.success) {
        res.payment_info &&
          dispatch({
            type: PROFILE_UPDATE_STATE_LOCAL,
            obj: "paymentInfo",
            value: res.payment_info,
          });
        let updatedUser = Object.assign({}, user);
        updatedUser.locked_out = false;
        dispatch({ type: USER_LOAD, user: updatedUser });
        dispatch({ type: TOGGLE_PAYWALL, paywall: false });
        dispatch({ type: PROFILE_REQ_SUCCESS });
        dispatch({
          type: NEW_ERRORS,
          errors: [
            { status: "info", text: "Primary card successfully charged." },
          ],
        });
      } else {
        dispatch({ type: PROFILE_REQ_FAILURE });
        dispatch({ type: NEW_ERRORS, errors: [{ text: res.error }] });
        window.scrollTo(0, 0);
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const fetchInsurance = (profileId) => {
  const data = {
    url: `/profiles/${profileId}/insurance`,
    path: "/api/v2",
    data: { method: "GET" },
  };

  return async (dispatch) => {
    try {
      dispatch({ type: PROFILE_REQ_START });
      const res = await Api.makeRequest(data);

      if (res.success) {
        dispatch({
          type: INSURANCE_UPDATE_STATE,
          obj: "insuranceDetails",
          value: res.insurance_details,
        });
        dispatch({
          type: INSURANCE_UPDATE_STATE,
          obj: "insuranceProviders",
          value: res.insurance_providers,
        });

        dispatch({ type: PROFILE_LOAD, profile: res.profile });
        dispatch({ type: PROFILE_REQ_SUCCESS });
      } else {
        dispatch({ type: PROFILE_REQ_FAILURE });
        dispatch({
          type: NEW_ERRORS,
          errors: [{ text: "Something went wrong, please refresh the page." }],
        });
      }
    } catch (e) {
      dispatch({ type: PROFILE_REQ_FAILURE });
      dispatch({
        type: NEW_ERRORS,
        errors: [{ text: "Something went wrong, please refresh the page." }],
      });
      console.log(e);
    }
  };
};

export const fetchNotifications = (profileId) => {
  const data = {
    url: `/profiles/${profileId}/notifications`,
    path: "/api/v2",
    data: { method: "GET" },
  };

  return async (dispatch) => {
    try {
      dispatch({ type: PROFILE_REQ_START });
      const res = await Api.makeRequest(data);
      if (res.success) {
        dispatch({
          type: PROFILE_UPDATE_STATE_LOCAL,
          obj: "preference",
          value: res.preference,
        });
        dispatch({ type: PROFILE_REQ_SUCCESS });
      } else {
        dispatch({ type: PROFILE_REQ_FAILURE });
        dispatch({
          type: NEW_ERRORS,
          errors: [{ text: "Something went wrong, please refresh the page." }],
        });
      }
    } catch (e) {
      dispatch({ type: PROFILE_REQ_FAILURE });
      dispatch({
        type: NEW_ERRORS,
        errors: [{ text: "Something went wrong, please refresh the page." }],
      });
      console.log(e);
    }
  };
};

export const updatePreference = (profileId, preference) => {
  const data = {
    url: `/profiles/${profileId}/update_preference`,
    path: "/api/v2",
    data: {
      method: "POST",
      body: { preference: JSON.stringify(preference) },
    },
  };

  return async (dispatch) => {
    try {
      dispatch({ type: PROFILE_REQ_START });
      const res = await Api.makeRequest(data);
      if (res.success) {
        dispatch({
          type: PROFILE_UPDATE_STATE_LOCAL,
          obj: "preference",
          value: res.preference,
        });
        dispatch({
          type: NEW_ERRORS,
          errors: [
            { status: "info", text: "Notifications successfully updated." },
          ],
        });
        dispatch({ type: PROFILE_REQ_SUCCESS });
      } else {
        dispatch({ type: PROFILE_REQ_FAILURE });
        dispatch({
          type: NEW_ERRORS,
          errors: [{ text: "Something went wrong, please refresh the page." }],
        });
      }

      window.scrollTo(0, 0);
    } catch (e) {
      dispatch({ type: PROFILE_REQ_FAILURE });
      dispatch({
        type: NEW_ERRORS,
        errors: [{ text: "Something went wrong, please refresh the page." }],
      });
      console.log(e);
    }
  };
};

export const submitFeedback = (profileId, formData) => {
  // feedback is a FormData because users can attach an image
  const data = {
    url: `/profiles/${profileId}/submit_feedback`,
    path: "/api/v2",
    data: {
      method: "POST",
      formData: formData,
    },
  };

  return async (dispatch) => {
    try {
      dispatch({ type: PROFILE_REQ_START });
      const res = await Api.makeRequestFile(data);
      if (res.success) {
        dispatch({
          type: NEW_ERRORS,
          errors: [
            { status: "info", text: "Successfully submitted feedback." },
          ],
        });
        dispatch({ type: PROFILE_REQ_SUCCESS });
      } else {
        dispatch({ type: NEW_ERRORS, errors: [{ text: res.error }] });
        dispatch({ type: PROFILE_REQ_FAILURE });
        window.scrollTo(0, 0);
      }
    } catch (e) {
      console.log(e);
    }
  };
};

/****** OLD ACTIONS - NEED TO SORT THROUGH ******/

export const fetchPaymentInitial = (user) => {
  const data = {
    url: `/profiles/${user.id}/payment_info?has_payment=true`,
    data: {
      method: "GET",
    },
  };

  return async (dispatch) => {
    dispatch({
      type: FETCH_PAYMENT_INITIAL,
      hasPayment: false,
      initialPaymentLoading: true,
    });

    try {
      const res = await Api.makeRequest(data);
      dispatch({
        type: FETCH_PAYMENT_INITIAL,
        hasPayment: res.has_payment,
        initialPaymentLoading: false,
      });
    } catch (e) {
      console.log(e);
    }
  };
};

export const fetchROIs = () => {
  const data = {
    url: `/release_of_info/fetch`,
    path: "/api/v1",
    data: {
      method: "GET",
    },
  };

  return async (dispatch) => {
    try {
      const res = await Api.makeRequest(data);
      dispatch({
        type: PROFILE_UPDATE_STATE_LOCAL,
        obj: "rois",
        value: res.rois,
      });
      dispatch({
        type: PROFILE_UPDATE_LOCAL,
        profile: res.profile,
        profilePic: res.profile_pic,
      });
    } catch (e) {
      console.log(e);
    }
  };
};

export const fetchReceiptInfo = (userId, paymentId, cashPaymentId) => {
  let url = `/payments/receipt?json=true&user_id=${userId}`;
  if (paymentId) {
    url += `&payment_id=${paymentId}`;
  } else if (cashPaymentId) {
    url += `&cash_payment_id=${cashPaymentId}`;
  }
  const data = {
    url: url,
    data: {
      method: "GET",
    },
  };

  return async (dispatch) => {
    dispatch({ type: PROFILE_REQ_START });

    try {
      const res = await Api.makeRequest(data);
      if (res.success) {
        dispatch({
          type: PROFILE_LOAD_RECEIPT_INFO,
          receiptInfo: res.receipt_info,
        });
        dispatch({ type: PROFILE_REQ_SUCCESS });
      } else {
        dispatch({
          type: NEW_ERRORS,
          errors: [{ status: "error", text: res.error }],
        });
        dispatch({ type: PROFILE_REQ_FAILURE });
      }

      window.scrollTo(0, 0);
    } catch (e) {
      console.log(e);
      dispatch({ type: PROFILE_REQ_FAILURE });
    }
  };
};

export const fetchMissionMap = (userId) => {
  const data = {
    url: `/mission_maps/fetch`,
    data: {
      method: "GET",
    },
  };

  return async (dispatch) => {
    try {
      dispatch({ type: COMMON_UPDATE_START });
      const res = await Api.makeRequest(data);
      if (res.success) {
        dispatch({ type: COMMON_UPDATE_SUCCESS });
        dispatch({ type: FETCH_MISSION_MAP, missionMap: res.mission_map });
      } else {
        dispatch({ type: COMMON_UPDATE_FAILURE });
        window.scrollTo(0, 0);
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const payPatientInvoice = (userId, invoiceId, cardId) => {
  let url = `/patient_invoices/${invoiceId}/create_payment?user_id=${userId}`;
  if (cardId) {
    url += `&card_id=${cardId}`;
  }

  const data = {
    url: url,
    path: "/api/v2",
    data: { method: "POST" },
  };

  return async (dispatch) => {
    try {
      dispatch({ type: PROFILE_REQ_START });
      const res = await Api.makeRequest(data);
      if (res.success) {
        dispatch({
          type: PROFILE_UPDATE_STATE_LOCAL,
          obj: "payments",
          value: res.payments,
        });
        dispatch({
          type: PROFILE_UPDATE_STATE_LOCAL,
          obj: "hasMorePayments",
          value: res.has_more_payments,
        });
        dispatch({
          type: PROFILE_LOAD_PATIENT_INVOICE,
          invoice: res.patient_invoice,
        });

        dispatch({
          type: NEW_ERRORS,
          errors: [{ status: "info", text: "Invoice paid." }],
        });
        dispatch({ type: PROFILE_REQ_SUCCESS });
      } else {
        dispatch({ type: PROFILE_REQ_FAILURE });
        dispatch({ type: NEW_ERRORS, errors: [{ text: res.error }] });
      }
      window.scrollTo(0, 0);
    } catch (e) {
      console.log(e);
    }
  };
};

/*
  this endpoint should only be used for address verification; for just updating
  the address, use updateProfile() in commonActions.js

  @params
  profile (object): Profile
  skipVerification (string): "true" if verification should be skipped; defaults to ""
*/
export const updateAddress = (profile, skipVerification = "") => {
  const data = {
    url: `/profiles/${profile.id}/update_address`,
    path: "/api/v2",
    data: {
      method: "POST",
      body: {
        profile: profile,
        skip_verification: skipVerification,
      },
    },
  };

  return async (dispatch) => {
    try {
      dispatch({ type: PROFILE_REQ_START });
      const res = await Api.makeRequest(data);
      if (res.success) {
        if (res.suggested_address) {
          dispatch({
            type: ONBOARDING_VERIFY_ADDRESS,
            suggestedAddress: res.suggested_address,
          });
        }
        dispatch({ type: PROFILE_REQ_SUCCESS });
      } else {
        dispatch({ type: PROFILE_REQ_FAILURE });
        dispatch({ type: NEW_ERRORS, errors: [{ text: res.errors[0] }] });
        window.scrollTo(0, 0);
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const getTrackableProfile = async (id) => {
  const data = {
    url: `/profiles/${id}/trackable_profile`,
    path: "/api/v2",
    data: {
      method: "GET",
    },
  };

  const res = await Api.makeRequest(data);
  if (res?.success) {
    trackerActions.updateFullProfile(res);
  }
  return res;
};
