import css from "styled-jsx/css";
import { variables } from "patient_app/stylesheets/variables.js";

export default css.global`
  div#root,
  .App-Video-Call {
    height: 100%;
    width: 100%;
  }

  .outer-video-call-container {
    width: 100%;
    height: 100%;
  }

  .App-Video-Call {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    position: relative;
  }

  .App-Video-Call .prompt {
    width: 25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    flex-direction: column;
    max-width: 25rem;
    max-height: 20rem;
  }

  .App-Video-Call p {
    font-size: 15px;
    color: white;
    margin-bottom: 1rem;
    text-align: center;
    padding-left: 1rem;
    padding-right: 1rem;
    font-family: ${variables.objReg};
  }

  .App-Video-Call p.subtitle {
    font-size: 15px;
  }

  .App-Video-Call p.spaced {
    margin-bottom: 2rem;
  }

  .App-Video-Call button,
  .App-Video-Call button.custom-button {
    color: white;
    background-color: rgb(14, 129, 108);
    font-family: obj3-med, arial;
    font-size: 0.8rem;
    height: fit-content;
    text-align: center;
    padding: 0.5rem;
    border-radius: 0px;
    border: none;
    cursor: pointer;
    font-size: 15px;
    margin-bottom: 1rem;
    width: 100%;
    background-color: rgb(14, 129, 108);
    font-family: obj3-bold, arial;
    color: white;
    box-sizing: border-box;
    padding: 0.5rem;
    border-radius: 0;
  }

  .App-Video-Call button.secondary,
  .App-Video-Call button.custom-button.secondary {
    background: transparent;
    color: white;
    text-decoration: underline;
  }

  .App-Video-Call .errors-container {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    justify-content: center;
    align-items: center;
    max-width: 25rem;
    z-index: 5000;
  }

  .App-Video-Call .info-container {
    margin: 1rem;
    padding: 1rem;
    background: white;
    width: 100%;
  }

  .App-Video-Call .info-container p,
  ..App-Video-Call info-container li {
    color: black;
  }

  .App-Video-Call .info-container ul {
    padding-left: 2rem;
    margin-bottom: 1rem;
  }

  .App-Video-Call .info-container p {
    margin-bottom: 0;
    font-size: 16px;
    text-decoration: underline;
    margin-bottom: 0.5rem;
  }

  .App-Video-Call .spinner {
    height: 1.5rem;
    width: 1.5rem;
  }

  .App-Video-Call .main-video-container {
    position: absolute;
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .App-Video-Call .local-video-thumb-container {
    padding: 0;
    position: absolute;
    max-width: 22vh;
    top: 0;
    bottom: 50px;
    right: 17px;
    height: 100%;
    text-align: right;
  }

  .local-video-thumb-container .local-video-thumb {
    display: inline-block;
  }

  .App-Video-Call .video-track-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition-property: all 0.5s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    background: lightgray;
  }

  .App-Video-Call .video-track-container.thumbnail {
    background: #lightgray;
    position: relative;
    height: 24vh;
    width: 20vh;
    top: 0;
    right: 0;
    margin: 15px 0 0 0;
    overflow: hidden;
    border-radius: 12px;
    z-index: 1;
  }

  .App-Video-Call .video-track-container.thumbnail video {
    border-radius: 10px;
  }

  .App-Video-Call .video-track-container img {
    height: 3rem;
  }

  .App-Video-Call .video-track {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .App-Video-Call video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background: #000;
    display: inline-block;
  }

  div.App-Video-Call .remote-video-container video {
    object-fit: contain;
  }

  .App-Video-Call .mirrored video {
    transform: scaleX(-1);
  }

  .App-Video-Call .video-controls-container {
    position: absolute;
    z-index: 4000;
    bottom: 2rem;
    left: 0;
    right: 0;
    height: 14vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .App-Video-Call .video-controls-container button.custom-button {
    border: none;
    margin: 1rem;
    background: rgb(14, 129, 108);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3rem;
    width: 3rem;
  }
`;
