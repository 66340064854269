import PropTypes from "prop-types";
import React from "react";
import LoadingSpinner from "patient_app/components/chat/partials/LoadingSpinner";

class Loading extends React.Component {
  render() {
    return (
      <div className="Loading">
        <LoadingSpinner />
      </div>
    );
  }
}

export default Loading;
