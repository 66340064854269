import {
  EC_REQ_START,
  EC_REQ_SUCCESS,
  EC_REQ_FAILURE,
  EC_LOAD,
} from "patient_app/constants/actionTypes";

const initialState = {
  emergencyContacts: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case EC_REQ_START:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case EC_REQ_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case EC_REQ_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
      };
    case EC_LOAD:
      return {
        ...state,
        emergencyContacts: action.emergencyContacts,
      };
    default:
      return state;
  }
};
