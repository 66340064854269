//import Cookies from 'universal-cookie';

class RudderstackTracker {
  constructor() {}

  pageView(userId = null, email = null) {
    try {
      const p = window.location.pathname + window.location.search;

      if (!window.rudderanalytics) {
        return;
      }

      window.rudderanalytics.ready(() => {
        window.rudderanalytics.page(p, {
          user_id: userId,
          email: email,
        });
      });
    } catch (e) {
      console.log("Error sending Rudderstack Event: ", e);
    }
  }

  identify(user) {
    let anonId;
    if (!user || !window.rudderanalytics) {
      return;
    }

    window.rudderanalytics.ready(() => {
      window.rudderanalytics.identify(null, {
        name: `${user.fir_name} ${user.las_name}`,
        email: user.email,
      });

      anonId = rudderanalytics.getAnonymousId();
    });

    return anonId;
  }
}

export default new RudderstackTracker();
