import PropTypes from "prop-types";
import React from "react";
import cn from "classnames";
import helpers from "components/authentication/utils/helpers";
import validation from "components/patients/utils/validation";

import { Router, Route, Switch, withRouter } from "react-router-dom";

import { ONBOARDING_PAGE_LOAD } from "patient_app/constants/actionTypes";
import { connect } from "react-redux";
import Occupation from "patient_app/views/enterprise_onboarding/Occupation";
import Questionnaire from "patient_app/views/enterprise_onboarding/Questionnaire";
import Outcome from "patient_app/views/enterprise_onboarding/Outcome";

const mapDispatchToProps = (dispatch) => {
  return {
    onPageLoad: () => {
      dispatch({ type: ONBOARDING_PAGE_LOAD });
    },
  };
};

const mapStateToProps = (state) => {
  return {
    errors: state.common.errors,
  };
};

class EnterpriseOnboarding extends React.Component {
  componentDidMount = () => {
    this.unlisten = this.props.history.listen((location, action) => {
      this.props.onPageLoad();
      //on page load for mobile, scroll to top
      window.scrollTo(0, 0);
    });
  };

  componentWillUnmount = () => {
    this.unlisten();
  };

  render() {
    let { errors } = this.props;

    return (
      <div>
        <Route
          path="/enterprise_onboarding/occupation"
          component={Occupation}
        />

        <Route
          path="/enterprise_onboarding/questionnaire/:id"
          render={() => <Questionnaire key={window.location.href} />}
        />

        <Route path="/enterprise_onboarding/outcome/:id" component={Outcome} />
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EnterpriseOnboarding)
);
