import PropTypes from "prop-types";
import React from "react";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { fetchDocuments } from "patient_app/api/profileActions";
import {
  PROFILE_UPDATE_STATE_LOCAL,
  NEW_ERRORS,
  CLEAR_FIELD_SPECIFIC_ERRORS,
} from "patient_app/constants/actionTypes";

import MainTabBack from "patient_app/views/profiles/partials/MainTabBack";
import GeneralDocuments from "patient_app/views/profiles/partials/documents/GeneralDocuments";
import SignedDocuments from "patient_app/views/profiles/partials/documents/SignedDocuments";
import ReleaseOfInfo from "patient_app/views/profiles/partials/documents/ReleaseOfInfo";
import UploadedFromChat from "patient_app/views/profiles/partials/documents/UploadedFromChat";

import { getUrlVars } from "patient_app/helpers/linkHelpers";
import { mobileCheck } from "patient_app/helpers/supported";
import permissions from "patient_app/helpers/permissions";
import layout from "patient_app/stylesheets/profiles/layout";

const mapDispatchToProps = (dispatch) => {
  return {
    fetchDocuments: (profileId, params) =>
      dispatch(fetchDocuments(profileId, params)),
    setProfileState: (obj, value) =>
      dispatch({ type: PROFILE_UPDATE_STATE_LOCAL, obj: obj, value: value }),
    clearFieldSpecificErrors: () =>
      dispatch({ type: CLEAR_FIELD_SPECIFIC_ERRORS }),
    newGeneralErrors: (errors) =>
      dispatch({ type: NEW_ERRORS, errors: errors }),
  };
};

const mapStateToProps = (state) => {
  return {
    mainTab: state.profile.mainTab,
    primaryDiagnosis: state.common.primaryDiagnosis,
    subTab: state.profile.subTab,
    user: state.common.user,
  };
};

class Account extends React.Component {
  profileId = this.props.match.params.id;

  componentDidMount = async () => {
    document.title = "Documents | Workit Health";

    const documentTabs = [
      "general_documents",
      "signed_documents",
      "roi",
      "from_chat",
    ];
    if (!mobileCheck()) {
      this.props.fetchDocuments(this.profileId, documentTabs);
      this.props.setProfileState("subTab", documentTabs);
    } else {
      const tab = getUrlVars(window.location.href)["tab"];
      console.log("errrr", tab, documentTabs.includes(tab));
      if (tab && documentTabs.includes(tab)) {
        this.props.setProfileState("subTab", [tab]);
      }
    }

    this.props.clearFieldSpecificErrors();
    this.props.newGeneralErrors([]);
  };

  render() {
    let { mainTab, primaryDiagnosis, subTab, user } = this.props;

    const isOud = user && permissions.isOud(user, primaryDiagnosis);

    return (
      <div>
        {mainTab.length > 0 && subTab.length === 0 && (
          <nav
            className="profile-sidebar"
            role="navigation"
            aria-label="Profile navigation"
          >
            <MainTabBack />

            {isOud && (
              <button
                className="custom-button tab"
                onClick={() =>
                  this.props.setProfileState("subTab", ["general_documents"])
                }
              >
                General Documents
              </button>
            )}

            <button
              className="custom-button tab"
              onClick={() =>
                this.props.setProfileState("subTab", ["signed_documents"])
              }
            >
              Signed Documents
            </button>

            <button
              className="custom-button tab"
              onClick={() => this.props.setProfileState("subTab", ["roi"])}
            >
              Release of Information
            </button>

            <button
              className="custom-button tab"
              onClick={() =>
                this.props.setProfileState("subTab", ["from_chat"])
              }
            >
              Uploaded From Chat
            </button>
          </nav>
        )}

        {subTab.includes("general_documents") && isOud && <GeneralDocuments />}
        {subTab.includes("signed_documents") && <SignedDocuments />}
        {subTab.includes("roi") && <ReleaseOfInfo />}
        {subTab.includes("from_chat") && <UploadedFromChat />}

        <style jsx>{layout}</style>
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Account)
);
