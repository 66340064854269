import css from "styled-jsx/css";
import { variables } from "patient_app/stylesheets/variables";

export default css.global`
  button.business-hours {
    width: 100%;
    height: fit-content;
    background: ${variables.grayLight};
    display: flex;
    padding: ${variables.small};
    box-sizing: border-box;
    text-align: left;
  }

  .business-hours p {
    color: ${variables.blueDark};
    margin: 0;
    font-size: 0.85rem;
    font-family: ${variables.objReg};
    line-height: 1.5rem;
  }

  .business-hours p span {
    font-family: ${variables.objMed};
  }
`;
