import PropTypes from "prop-types";
import React from "react";
import { DateTime } from "luxon";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { fetchAccount } from "patient_app/api/profileActions";
import {
  createEmergencyContact,
  updateEmergencyContact,
  destroyEmergencyContact,
} from "patient_app/api/emergencyContactActions";
import {
  NEW_FIELD_SPECIFIC_ERROR,
  NEW_ERRORS,
  CLEAR_FIELD_SPECIFIC_ERRORS,
} from "patient_app/constants/actionTypes";

import ProfileField from "patient_app/views/profiles/partials/ProfileField";
import SubTabBack from "patient_app/views/profiles/partials/SubTabBack";
import LoadingSpinner from "patient_app/components/utils/LoadingSpinner";

import { getUrlVars } from "patient_app/helpers/linkHelpers";
import textHelpers from "patient_app/helpers/textHelpers";
import { mobileCheck } from "patient_app/helpers/supported";

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAccount: (profileId, params) =>
      dispatch(fetchAccount(profileId, params)),
    createEmergencyContact: (contact) =>
      dispatch(createEmergencyContact(contact)),
    updateEmergencyContact: (contact) =>
      dispatch(updateEmergencyContact(contact)),
    destroyEmergencyContact: (contact) =>
      dispatch(destroyEmergencyContact(contact)),
    newFieldSpecificError: (field, error) =>
      dispatch({ type: NEW_FIELD_SPECIFIC_ERROR, field: field, error: error }),
    newGeneralErrors: (errors) =>
      dispatch({ type: NEW_ERRORS, errors: errors }),
    clearFieldSpecificErrors: () =>
      dispatch({ type: CLEAR_FIELD_SPECIFIC_ERRORS }),
  };
};

const mapStateToProps = (state) => {
  return {
    emergencyContacts: state.emergencyContact.emergencyContacts,
    loading: state.emergencyContact.loading,
    success: state.emergencyContact.success,
  };
};

class EmergencyContact extends React.Component {
  profileId = this.props.match.params.id;

  constructor(props) {
    super(props);
    this.state = {
      contact: {
        ec_name: "",
        ec_relation: "",
        ec_phone: "",
      },
      creating: false, // when creating new emergency contact, set to true
      editingId: null, // when editing existing emergency contact, set to contact id
      expanded: [],
    };
  }

  componentDidMount = async () => {
    if (mobileCheck()) {
      this.props.fetchAccount(this.profileId, ["emergency_contact"]);
    }
    // else {
    //   const tab = getUrlVars(window.location.href)["tab"]
    //   if (tab && tab === "emergency_contact") {
    //     const element = document.getElementById(tab.replace("_", "-"))
    //     console.log("element?", element)
    //     if (element) {
    //       element.scrollIntoView()
    //     }
    //   }
    // }
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.success && this.props.success) {
      if (window.location.href.includes("redirect=todo")) {
        this.props.history.push("/appointments");
      } else {
        this.handleCancel();
      }
    }

    if (!prevProps.emergencyContacts && this.props.emergencyContacts) {
      const tab = getUrlVars(window.location.href)["tab"];
      if (tab && tab === "emergency_contact") {
        const element = document.getElementById(tab.replace("_", "-"));
        console.log("element?", element);
        if (element) {
          element.scrollIntoView();
        }
        this.setState({ creating: true });
      }
    }
  }

  render() {
    let { emergencyContacts, loading } = this.props;

    let { contact, creating, editingId } = this.state;

    return (
      <div id="emergency-contact">
        <SubTabBack />
        <div className="profile-section last">
          <div className="header">
            <h1>Emergency Contacts</h1>

            {(creating || editingId) && (
              <button
                className="custom-button section-action"
                onClick={this.handleCancel}
              >
                Cancel
              </button>
            )}
          </div>

          {!emergencyContacts && <LoadingSpinner />}

          {emergencyContacts && !(creating || editingId) && (
            <div>
              <div className="profile-list emergency-contacts">
                {this.renderEmergencyContacts()}
              </div>

              <button
                className="custom-button link"
                onClick={() =>
                  this.setState({ creating: true, editingId: null })
                }
              >
                Add Emergency Contact
              </button>
            </div>
          )}

          {(creating || editingId) && (
            <div>
              <ProfileField
                field="ec_name"
                fieldType="text"
                onUpdateField={this.handleUpdateContact}
                title="Name"
                value={contact.ec_name}
              />

              <ProfileField
                field="ec_relation"
                fieldType="text"
                onUpdateField={this.handleUpdateContact}
                title="Relationship"
                value={contact.ec_relation}
              />

              <ProfileField
                field="ec_phone"
                fieldType="phone"
                onUpdateField={this.handleUpdateContact}
                title="Phone Number"
                value={contact.ec_phone}
              />

              <button
                className={`custom-button submit half ${
                  loading ? "loading" : ""
                }`}
                onClick={this.handleSubmit}
              >
                Save Changes
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }

  renderEmergencyContacts() {
    if (this.props.emergencyContacts.length === 0) {
      return (
        <div className="list-item none">
          <div className="row">
            <p>No emergency contacts on file.</p>
          </div>
        </div>
      );
    }

    return this.props.emergencyContacts.map((contact, i) => {
      const showDetails = this.state.expanded.includes(contact.id);
      return (
        <div className="list-item" key={i}>
          <div className="row">
            <p>
              <span>{contact.name}</span>
            </p>

            <p className="web-only">{contact.relation}</p>
            <div className="col mobile-only" />

            <p className="web-only">
              {contact.phone && textHelpers.formatPhone(contact.phone)}
            </p>
            <div className="col mobile-only" />

            <div className="col align-right">
              <button
                className="custom-button item-action mobile-only"
                onClick={() => this.toggleDetails(contact.id)}
              >
                View
              </button>

              <button
                className="custom-button item-action"
                onClick={() => this.handleEdit(contact.id)}
              >
                Edit
              </button>

              <button
                className="custom-button item-action"
                onClick={() => this.props.destroyEmergencyContact(contact)}
              >
                Remove
              </button>
            </div>
          </div>

          {showDetails && (
            <div className="expanded-details">
              <div className="line">
                <p>Relationship:</p>
                <p>{contact.relation}</p>
              </div>

              <div className="line">
                <p>Phone Number:</p>
                <p>{contact.phone && textHelpers.formatPhone(contact.phone)}</p>
              </div>
            </div>
          )}
        </div>
      );
    });
  }

  toggleDetails = (id) => {
    let arr = this.state.expanded;
    const idx = arr.findIndex((i) => i === id);
    if (idx > -1) {
      arr.splice(idx, 1);
    } else {
      arr.push(id);
    }
    this.setState({ expanded: arr });
  };

  handleUpdateContact = (value, field) => {
    let contact = this.state.contact;
    contact[field] = value;
    this.setState({ contact: contact });
  };

  handleEdit = (contactId) => {
    const contact = this.props.emergencyContacts.find(
      (ec) => ec.id === contactId
    );
    if (contact) {
      this.setState({
        contact: {
          ec_name: contact.name || "",
          ec_relation: contact.relation || "",
          ec_phone: contact.phone || "",
        },
        creating: false,
        editingId: contact.id,
      });
    }
  };

  handleCancel = () => {
    this.setState({
      contact: {
        ec_name: "",
        ec_relation: "",
        ec_phone: "",
      },
      creating: false,
      editingId: null,
    });
    this.props.clearFieldSpecificErrors();
    this.props.newGeneralErrors([]);
  };

  handleSubmit = () => {
    let { contact, creating, editingId } = this.state;
    this.props.clearFieldSpecificErrors();
    this.props.newGeneralErrors([]);

    let hasErrors = false;
    if (!contact.ec_name || contact.ec_name.length < 0) {
      this.props.newFieldSpecificError(
        "ec_name",
        "Name must be present and at least 3 characters."
      );
      hasErrors = true;
    }
    if (!contact.ec_relation || contact.ec_relation.length === 0) {
      this.props.newFieldSpecificError(
        "ec_relation",
        "Please enter relationship to emergency contact."
      );
      hasErrors = true;
    }
    if (!contact.ec_phone || contact.ec_phone.length < 12) {
      this.props.newFieldSpecificError(
        "ec_phone",
        "Please enter valid phone number."
      );
      hasErrors = true;
    }

    if (hasErrors) {
      this.props.newGeneralErrors([{ text: "Please fix the errors below." }]);
      window.scrollTo(0, 0);
      return;
    }

    contact.name = contact.ec_name;
    contact.relation = contact.ec_relation;
    contact.phone = contact.ec_phone;

    if (creating) {
      this.props.createEmergencyContact(contact);
    } else {
      contact.id = editingId;
      this.props.updateEmergencyContact(contact);
    }
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EmergencyContact)
);
