//import Cookies from 'universal-cookie';

class Appsflyer {
  getEventCode(eventName) {
    const codes = {
      InsuranceSearch: "IC100",
      InsuranceSearchCommercial: "IC100C",
      ScheduleFirstAppointment: "SA200",
      ScheduleFirstAppointmentCommercial: "SA200C",
      ScheduleInduction: "ME200",
      ScheduleInductionCommercial: "ME200C",
      AppointmentAttended: "AA300",
      FirstAppointmentAttended: "FA300",
      MedicalEvaluationAttended: "MA300",
      MedicalEvaluationAttendedCommercial: "MA300C",
    };
    return codes[eventName];
  }

  event(eventName, user = null, version = null) {
    if (!window.AF) {
      console.log("returning because appsflyer library not found");
      return;
    }

    try {
      const uid = this.getEventCode(eventName);
      if (!uid) {
        console.log(
          `returning AF b/c "${eventName}" does not match a valid event code`
        );
        return;
      }

      if (!window.location.hostname.includes("app.workithealth")) {
        console.log("returning appsflyer b/c not production environment");
        console.log("AF Data", eventName);
        return;
      }

      let uidEventData = {
        eventType: "EVENT",
        eventName: uid,
      };

      window.AF("pba", "event", uidEventData);
    } catch (e) {
      console.log("track appsflyer error", e);
    }
  }

  event_LEGACY(eventName, user, version) {
    if (!window.AF) {
      console.log("returning because appsflyer library not found");
      return;
    }

    let env = "";
    if (!window.location.hostname.includes("app.workithealth")) {
      console.log("returning appsflyer b/c not production environment");
      console.log("AF Data", eventName, user, version);
      return;
      env = "staging";
    }

    try {
      let programDescriptor = "";
      let billingTypeDescriptor = "";
      let afRevenue;

      if (user) {
        if (user.tier && user.treatment_type && user.tier === 3) {
          if (user.treatment_type.includes("aud")) {
            programDescriptor = "-AUD";
          } else if (user.treatment_type.includes("oud")) {
            programDescriptor = "-OUD";
          }
        }

        if (user.billing_type) {
          billingTypeDescriptor = `@${user.billing_type}`;
        }
      }

      const uid = `${env}v${version}:${eventName}`;
      afRevenue = this.getLTV(uid);
      let uidEventData = {
        eventType: "EVENT",
        eventName: uid,
      };
      if (!!afRevenue) {
        uidEventData.eventRevenue = afRevenue;
        uidEventData.eventValue = { af_revenue: afRevenue };
      }

      if (programDescriptor) {
        // fire a separate event with the descriptor
        const uidDescriptor = `${env}v${version}:${eventName}${programDescriptor}${billingTypeDescriptor}`;
        afRevenue = this.getLTV(uidDescriptor);

        let programEventData = {
          eventType: "EVENT",
          eventName: uidDescriptor,
        };

        if (!!afRevenue) {
          programEventData.eventRevenue = afRevenue;
          programEventData.eventValue = { af_revenue: afRevenue };
        }

        window.AF("pba", "event", programEventData);
      }

      //AF('pba', 'event', {eventType: 'EVENT', eventCategory: 'conversion',eventName: 'subscription', eventLabel: 'newsletters'});
      window.AF("pba", "event", uidEventData);
    } catch (e) {
      console.log("track appsflyer error", e);
    }
  }

  identifyUser(id) {
    if (!window.AF) {
      return;
    }

    window.AF("pba", "setCustomerUserId", id);
  }

  generateUid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  getLTV(eventName = "") {
    let revenue = null;
    // 7/26/21: remove revenue values for ScheduledInduction-OUD events
    // if (eventName.includes(`ScheduledInduction-OUD@self_pay`)) revenue = 3315;
    // else if (eventName.includes(`ScheduledInduction-OUD@medicaid`))
    //   revenue = 1006;
    // else if (eventName.includes(`ScheduledInduction-OUD@commercial_insurance`))
    //   revenue = 3695;
    // else if (eventName.includes(`ScheduledInduction-OUD@medicare`))
    //   revenue = 1599;
    if (eventName.includes("inductionattended")) revenue = 370;
    else if (eventName.includes("appointmentattended")) revenue = 320;
    return revenue;
  }
}

export default new Appsflyer();
