import {
  UPDATE_UNREAD_COUNT,
  UPDATE_SHOW_CHAT,
  CHATS_LOAD,
  OPEN_CHAT,
  CHAT_UPDATE_STATE,
} from "patient_app/constants/actionTypes";

const initialState = {
  unreadCount: 0,
  showChat: false,
  chatActions: [],
  showEmergencyBanner: false,
  emergencyBannerType: "emergency", // "emergency" or "after_hours"
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_UNREAD_COUNT:
      return {
        ...state,
        unreadCount: action.unreadCount,
      };
    case UPDATE_SHOW_CHAT:
      return {
        ...state,
        showChat: action.showChat,
      };
    case CHATS_LOAD:
      return {
        ...state,
        chats: action.chats,
      };
    case OPEN_CHAT:
      let updatedActions = [...state.chatActions];
      updatedActions.push(action.data);
      return {
        ...state,
        chatActions: updatedActions,
      };
    case CHAT_UPDATE_STATE:
      return {
        ...state,
        [action.key]: action.value,
      };
    default:
      return state;
  }
};
