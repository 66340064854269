import PropTypes from "prop-types";
import React from "react";
import { retry } from "patient_app/helpers/supported";

// screens before user creation
const ZipCode = React.lazy(() =>
  retry(() => import("patient_app/views/onboarding/ZipCode"))
);
const SafetyCheck = React.lazy(() =>
  retry(() => import("patient_app/views/onboarding/SafetyCheck"))
);
const SafetyAlert = React.lazy(() =>
  retry(() => import("patient_app/views/onboarding/SafetyAlert"))
);
const ClinicsMap = React.lazy(() =>
  retry(() => import("patient_app/views/onboarding/ClinicsMap"))
);
const TreatmentType = React.lazy(() =>
  retry(() => import("patient_app/views/onboarding/TreatmentType"))
);
const OutOfArea = React.lazy(() =>
  retry(() => import("patient_app/views/onboarding/OutOfArea"))
);
const OnlineTherapy = React.lazy(() =>
  retry(() => import("patient_app/views/onboarding/OnlineTherapy"))
);
const VerifyPhoneAfterSignup = React.lazy(() =>
  retry(() => import("patient_app/views/onboarding/VerifyPhoneAfterSignup"))
);
const ProgramDescription = React.lazy(() =>
  retry(() => import("patient_app/views/onboarding/ProgramDescription"))
);
const CheckList = React.lazy(() =>
  retry(() => import("patient_app/views/onboarding/CheckList"))
);
const AUDMedHistory = React.lazy(() =>
  retry(() => import("patient_app/views/onboarding/AUDMedHistory"))
);
const Explanation = React.lazy(() =>
  retry(() => import("patient_app/views/onboarding/Explanation"))
);
const InPersonOH = React.lazy(() =>
  retry(() => import("patient_app/views/onboarding/InPersonOH"))
);
const InPerson = React.lazy(() =>
  retry(() => import("patient_app/views/onboarding/InPerson"))
);
const ProgramCost = React.lazy(() =>
  retry(() => import("patient_app/views/onboarding/ProgramCost"))
);
const EmployerCode = React.lazy(() =>
  retry(() => import("patient_app/views/onboarding/EmployerCode"))
);
const EmployerVerification = React.lazy(() =>
  retry(() => import("patient_app/views/onboarding/EmployerVerification"))
);
const InsuranceCheckPublic = React.lazy(() =>
  retry(() => import("patient_app/views/onboarding/InsuranceCheckPublic"))
);
const InsuranceCheckPublicResults = React.lazy(() =>
  retry(() =>
    import("patient_app/views/onboarding/InsuranceCheckPublicResults")
  )
);
const InsuranceCheck = React.lazy(() =>
  retry(() => import("patient_app/views/onboarding/InsuranceCheck"))
);
const InsuranceCardCheck = React.lazy(() =>
  retry(() => import("patient_app/views/onboarding/InsuranceCardCheck"))
);
const InsuranceCheckResults = React.lazy(() =>
  retry(() => import("patient_app/views/onboarding/InsuranceCheckResults"))
);
const InsuranceCallUs = React.lazy(() =>
  retry(() => import("patient_app/views/onboarding/InsuranceCallUs"))
);
const StateInsurers = React.lazy(() =>
  retry(() => import("patient_app/views/onboarding/StateInsurers"))
);
const ReviewCost = React.lazy(() =>
  retry(() => import("patient_app/views/onboarding/ReviewCost"))
);
const Compare = React.lazy(() =>
  retry(() => import("patient_app/views/onboarding/Compare"))
);
const Signup = React.lazy(() =>
  retry(() => import("patient_app/views/onboarding/Signup"))
);

// screens after user creation
const Demographics = React.lazy(() =>
  retry(() => import("patient_app/views/onboarding/Demographics"))
);
const PrimaryCurriculum = React.lazy(() =>
  retry(() => import("patient_app/views/onboarding/PrimaryCurriculum"))
);
const SecondaryCurriculum = React.lazy(() =>
  retry(() => import("patient_app/views/onboarding/SecondaryCurriculum"))
);

const Schedule = React.lazy(() =>
  retry(() => import("patient_app/views/onboarding/Schedule"))
);
const ScheduleCall = React.lazy(() =>
  retry(() => import("patient_app/views/onboarding/ScheduleCall"))
);

const ScheduleChatConfirm = React.lazy(() =>
  retry(() => import("patient_app/views/onboarding/ScheduleChatConfirm"))
);
const Address = React.lazy(() =>
  retry(() => import("patient_app/views/onboarding/Address"))
);
const VerifyAddress = React.lazy(() =>
  retry(() => import("patient_app/views/onboarding/VerifyAddress"))
);
const Conclusion = React.lazy(() =>
  retry(() => import("patient_app/views/onboarding/Conclusion"))
);
const FreemiumBlock = React.lazy(() =>
  retry(() => import("patient_app/views/onboarding/FreemiumBlock"))
);
const SubmitId = React.lazy(() =>
  retry(() => import("patient_app/views/onboarding/SubmitId"))
);
const ConsentToTreatment = React.lazy(() =>
  retry(() =>
    import("patient_app/views/consent_to_treatment/ConsentToTreatment")
  )
);

import { Router, Route, Switch } from "react-router-dom";
import { browserHistory } from "react-router";
import usStates from "patient_app/helpers/usStates";

class Onboarding extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: window.gon && window.gon.data ? window.gon.data.user : null,
    };
  }

  componentDidMount = () => {
    this.unlisten = this.props.history.listen((location, action) => {
      //on page load for mobile, scroll to top
      window.scrollTo(0, 0);
    });
  };

  render() {
    return (
      <div>
        {/*signup/entry point is in separate file*/}

        {/* freemium members cannot sign up on web */}
        <Route path="/onboarding/freemium_block" component={FreemiumBlock} />

        {/* provide a public endpoint to check insurance */}
        <Route
          path="/onboarding/insurance_check"
          component={InsuranceCheckPublic}
        />
        <Route
          path="/onboarding/insurance_check_results"
          component={InsuranceCheckPublicResults}
        />

        {/*check for self-harm*/}
        <Route path="/onboarding/safety_check" component={SafetyCheck} />
        <Route path="/onboarding/safety_alert" component={SafetyAlert} />

        {/* add initial account details */}
        <Route path="/onboarding/zip_code" component={ZipCode} />

        {/* no programs offered in user's state */}
        <Route path="/onboarding/out_of_area" component={OutOfArea} />

        {/* select a program type */}
        <Route path="/onboarding/treatment_type" component={TreatmentType} />

        {/* if out of area, show alternate screen */}
        <Route path="/onboarding/alternate" component={OnlineTherapy} />

        {/* create an account */}
        <Route path="/onboarding/signup" component={Signup} />

        {/* verify phone number 2FA */}
        <Route
          path="/onboarding/verify_phone"
          component={VerifyPhoneAfterSignup}
        />

        {/* program descriptions */}
        <Route
          path="/onboarding/program_description"
          component={ProgramDescription}
        />

        {/* OUD: check methadone use */}
        {/* AUD: check abstinence or moderation */}
        <Route path="/onboarding/check" component={CheckList} />

        {/* AUD-specific questions (current substance use) */}
        <Route path="/onboarding/check_control" component={AUDMedHistory} />

        {/* explanation is if they are on methadone, telling them to call us */}
        <Route path="/onboarding/check_explanation" component={Explanation} />

        {/* explanation if the state requires an in person visit */}
        <Route path="/onboarding/in_person" component={InPerson} />

        {/* explanation if the state requires an in person visit OH */}
        <Route path="/onboarding/in_person_oh" component={InPersonOH} />

        {/* ask how they are going to pay */}
        <Route path={`/onboarding/program_cost`} component={ProgramCost} />

        {/* select insurance payer */}
        <Route path="/onboarding/state_insurers" component={StateInsurers} />

        {/* enter insurance details */}
        <Route
          path="/onboarding/insurance_check_auth"
          component={InsuranceCheck}
        />
        <Route
          path="/onboarding/insurance_card_auth"
          component={InsuranceCardCheck}
        />

        <Route
          path="/onboarding/insurance_check_auth_results"
          component={InsuranceCheckResults}
        />
        <Route
          path="/onboarding/insurance_call_us"
          component={InsuranceCallUs}
        />

        {/* if employer is paying, ask for employer code */}
        <Route path="/onboarding/employer_code" component={EmployerCode} />
        <Route
          path="/onboarding/employer_verification"
          component={EmployerVerification}
        />

        {/* review out of pocket cost */}
        <Route path="/onboarding/review_cost" component={ReviewCost} />

        {/* if user needs a credit card on file, they will be directed to /payments/purchase (in MasterRouter) */}

        {/* more member information */}
        <Route path="/onboarding/signup_name" component={Demographics} />

        {/* for non-clinic members, choose primary/secondary programs */}
        {/*
          these links kept in separate file, but they are as follows:
          - enterprise_onboarding/occupation (for some employers)
          -/enterprise_onboarding/questionnaire/start (for redbull/salesforce)
        */}
        <Route
          path="/onboarding/primary_curriculum"
          component={PrimaryCurriculum}
        />
        <Route
          path="/onboarding/secondary_curriculum"
          component={SecondaryCurriculum}
        />

        {/* upload driver's license/photo id */}
        <Route path="/onboarding/submit_id" component={SubmitId} />

        <Route path="/onboarding/address" component={Address} />
        <Route path="/onboarding/verify_address" component={VerifyAddress} />

        {/* sign legal documents */}
        <Route path="/onboarding/consent" component={ConsentToTreatment} />

        {/*aud/oud users next go to scheduling*/}
        <Route path="/onboarding/schedule" component={Schedule} />
        {/*if they click on "none of these times work" */}
        <Route path="/onboarding/schedule_call" component={ScheduleCall} />

        {/* conclusion screen for onboarding */}
        <Route path="/onboarding/conclusion" component={Conclusion} />

        {/* conclusion screen for scheduling a counselor chat */}
        <Route
          path="/onboarding/schedule_chat_confirm"
          component={ScheduleChatConfirm}
        />

        {/* unused */}
        {/*
          <Route path="/onboarding/check_quit" component={AUDMedHistory} />
          <Route path="/onboarding/verify_insurance" component={InsuranceCheck} />
          <Route path="/onboarding/compare" component={Compare} />
          <Route path="/onboarding/clinics_map" component={ClinicsMap} />
        */}
      </div>
    );
  }
}

export default Onboarding;
