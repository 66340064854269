import PropTypes from "prop-types";
import React from "react";
import cn from "classnames";
import axios from "axios";
import Api from "patient_app/api";

import FormErrors from "patient_app/components/utils/FormErrors";

import { Router, Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { fetchUser } from "patient_app/api/commonActions";
import {
  fetchEnterpriseQuestion,
  submitAnswer,
} from "patient_app/api/onboardingActions";
import {
  UPDATE_ENT_QUESTIONNAIRE_QUESTION_LOCAL,
  NEW_FORM_ERRORS,
  ONBOARDING_UPDATE_PROGRESS,
} from "patient_app/constants/actionTypes";

import MainLayout from "patient_app/components/layouts/MainLayout";

import ContentMain from "patient_app/components/forms/ContentMain";
import ContentBox from "patient_app/components/forms/ContentBox";
import SelectButton from "patient_app/components/buttons/SelectButton";
import SubmitButton from "patient_app/components/buttons/SubmitButton";
import TextField from "patient_app/components/input_fields/TextField";
import OnboardingProgressBar from "patient_app/components/utils/OnboardingProgressBar";

import onboardingHelpers from "patient_app/helpers/onboardingHelpers";
import onboardingAssets from "patient_app/assets/onboarding";
import commonAssets from "patient_app/assets";
import {
  multipleChoiceOptions,
  yesNoOptions,
  drugOptions,
} from "components/questionnaire/constants/options";

import layout from "patient_app/stylesheets/onboarding/layout.js";

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUser: () => dispatch(fetchUser()),
    fetchEnterpriseQuestion: (id) => dispatch(fetchEnterpriseQuestion(id)),
    updateQuestionLocal: (question) =>
      dispatch({
        type: UPDATE_ENT_QUESTIONNAIRE_QUESTION_LOCAL,
        question: question,
        goToOutcome: false,
      }),
    submitAnswer: (question) => dispatch(submitAnswer(question, true)),
    onErrors: (errors) => dispatch({ type: NEW_FORM_ERRORS, errors: errors }),
    updateProgress: (progress) =>
      dispatch({ type: ONBOARDING_UPDATE_PROGRESS, progress: progress }),
  };
};

const mapStateToProps = (state) => {
  return {
    user: state.common.user,
    loading: state.onboarding.loading,
    primaryVice: state.onboarding.primaryVice,
    success: state.onboarding.success,
    questions: state.onboarding.enterpriseQuestions,
    nextQuestionId: state.onboarding.nextQuestionId,
    goToOutcome: state.onboarding.goToOutcome,
  };
};

class Questionnaire extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      needsText: false,
    };
  }

  componentDidMount = () => {
    document.title = "Questionnaire | Workit Health";
    this.props.fetchUser();

    let id = this.props.match.params.id;
    this.props.fetchEnterpriseQuestion(id);
  };

  componentDidUpdate(prevProps) {
    if (this.props.question) {
      this.evaluateNeedsText(specialChoice, question.response.answer);
    }

    if (this.props.nextQuestionId && this.props.success) {
      if (this.props.goToOutcome) {
        this.props.history.push(
          `/enterprise_onboarding/outcome/${this.props.nextQuestionId}`
        );
      } else {
        this.props.history.push(
          `/enterprise_onboarding/questionnaire/${this.props.nextQuestionId}`
        );
      }
    }
  }

  getQuestionKey = () => {
    let { primaryVice, questions, user } = this.props;
    if (!questions) return;

    let id = this.props.match.params.id;

    if (id === "start") {
      let key = "P12_a";
      if (primaryVice) {
        if (primaryVice.name === "DRINKING") {
          key = "P4_a";
        } else if (primaryVice.name === "DRUGS") {
          key = "P8";
        }
      }

      return key;
    }

    for (var q in questions) {
      if (questions[q].id.toString() === id) return q;
    }
  };

  render() {
    let { user, loading, questions } = this.props;

    let { needsText } = this.state;

    let key = this.getQuestionKey();
    if (key) {
      const progress = onboardingHelpers.calculateProgress(
        this.props.location.pathname,
        key
      );
      this.props.updateProgress(progress);
    }
    let question = questions[key];
    let specialChoice = key === "P8";
    const differentPunctuation = ["P4_a", "P9_a"];
    const canScroll = ["P4_a", "P8", "P9_a"];

    return (
      <MainLayout>
        <ContentMain halfBleed={true}>
          {question && (
            <ContentBox className="onboarding">
              <OnboardingProgressBar />

              <div className="inputs">
                <h1>Questionnaire</h1>

                <FormErrors />
                <p className="description">
                  {question.header.text}
                  {specialChoice ? "." : ""}
                </p>

                {!specialChoice && (
                  <p>
                    {question.text}
                    {differentPunctuation.includes(key) ? ":" : "."}
                  </p>
                )}

                <div className="questionnaire">
                  {this.renderOptions(question, specialChoice)}
                </div>

                {needsText && (
                  <TextField
                    field={"other_drugs"}
                    title={"Please specify..."}
                    value={
                      question.response.text_answer
                        ? question.response.text_answer
                        : ""
                    }
                    id={"other-drugs"}
                    onUpdateField={(value, field) =>
                      this.handleUpdateText(value, field, question)
                    }
                    onKeyPress={(e) =>
                      this.handleKeyPress(e, question, specialChoice)
                    }
                    uid={"other_drugs"}
                  />
                )}

                <SubmitButton
                  text={"Next"}
                  loading={loading}
                  onSubmit={(e) => this.handleSubmit(e, question)}
                />

                <style jsx>{layout}</style>
              </div>
            </ContentBox>
          )}
        </ContentMain>
      </MainLayout>
    );
  }

  renderOptions = (question, specialChoice) => {
    if (!question) return;

    let options = multipleChoiceOptions;

    if (question.question_type === "yes_no") {
      options = yesNoOptions;
    }

    if (question.custom_options === true) {
      options = drugOptions;
    }

    return this.renderButtons(question, options, specialChoice);
  };

  renderButtons = (question, options, specialChoice) => {
    return options.map((option, i) => {
      // let className = `${(question.question_type !== "yes_no") ? "full-width" : ""} allow-overflow`
      return (
        <SelectButton
          key={i}
          selectedValue={question.response.answer}
          value={option.value}
          ariaLabel={question.header.text}
          text={option.text}
          onSelect={(e) => this.handleSelect(question, option, specialChoice)}
          classNames={"full-width allow-overflow"}
        />
      );
    });
  };

  evaluateNeedsText = (specialChoice, answer) => {
    if (specialChoice && answer === 6) {
      this.setState({ needsText: true });
    } else {
      this.setState({ needsText: false });
    }
  };

  handleSelect = (question, option, specialChoice) => {
    let updatedQuestion = Object.assign({}, question);
    updatedQuestion.response.answer = option.value;
    this.props.updateQuestionLocal(updatedQuestion);

    this.evaluateNeedsText(specialChoice, updatedQuestion.response.answer);
  };

  handleUpdateText = (value, field, question) => {
    let updatedQuestion = Object.assign({}, question);
    updatedQuestion.response.text_answer = value;
    this.props.updateQuestionLocal(updatedQuestion);
  };

  handleKeyPress = (e, question) => {
    if (e.keyCode === 13) {
      this.props.submitAnswer(question);
    }
  };

  handleSubmit = (e, question, specialChoice) => {
    if (e) e.preventDefault();

    if (!question.response.answer && question.response.answer !== 0) {
      this.props.onErrors([{ text: "Please select an answer." }]);
      window.scrollTo(0, 0);
      return;
    }

    this.props.onErrors([]);
    this.props.submitAnswer(question);
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Questionnaire)
);
