import PropTypes from "prop-types";
import React from "react";

import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";

import MainLayout from "patient_app/components/layouts/MainLayout";

import ContentMain from "patient_app/components/forms/ContentMain";
import ContentBox from "patient_app/components/forms/ContentBox";

import commonAssets from "patient_app/assets";
import layout from "patient_app/stylesheets/onboarding/layout.js";

import { default as Loading } from "patient_app/components/utils/LoadingSpinner";

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {};
};

class OnboardingLoading extends React.Component {
  componentDidMount() {
    document.title = "Workit Health";
  }

  render() {
    return (
      <MainLayout className="Page-Program-Description Conclusion">
        <ContentMain halfBleed={true}>
          <ContentBox>
            <Loading className="lazy-load" />
          </ContentBox>
        </ContentMain>
      </MainLayout>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OnboardingLoading)
);
