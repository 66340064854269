import css from "styled-jsx/css";
import { variables } from "patient_app/stylesheets/variables.js";

export default css`
  :global(nav.emr) {
    display: none;
  }

  p {
    font-family: "Helvetica Neue";
    font-size: 12pt;
    margin: 0;
  }

  p.title {
    font-weight: bold;
    text-decoration: underline;
  }

  .Receipts-Container {
    position: relative;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: ${variables.boxShadow};
    padding: 2rem;
    margin: 2rem 6rem;
  }

  .loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    width: 100%;
  }

  .loading-spinner img {
    width: 1.5rem;
    height: 1.5rem;
  }

  .print-container {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
  }

  .print-container button {
    border-radius: 4px;
    color: ${variables.green};
    border: 1px solid ${variables.green};
    background: transparent;
    padding: 0.5rem;
    display: flex;
    align-items: center;
  }

  .print-container button i {
    margin-right: 0.5rem;
    font-size: 12pt;
  }

  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
  }

  .header img {
    width: 15rem;
  }

  .general-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
  }

  .general-info .section {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }

  .can-edit {
    display: flex;
    justify-content: flex-start;
  }

  .can-edit .receipt-field {
    margin-right: 0.25rem;
  }

  .patient {
    text-align: right;
  }

  table {
    text-align: left;
    width: 100%;
    font-family: "Helvetica Neue";
    font-size: 11pt;
    margin-bottom: 2rem;
  }

  tr:not(:last-of-type) {
    border-bottom: 1px solid #eee;
  }

  th {
    padding: 0.3rem 0.4rem;
    font-size: 12pt;
    background-color: #eee;
  }

  td {
    padding: 0.2rem 0.4rem;
  }

  td.red {
    color: ${variables.red};
  }

  table.payment-table {
    border-collapse: collapse;
  }
  table.payment-table {
    text-align: left;
  }
  table.payment-table th.align-right,
  table.payment-table td.align-right {
    text-align: right;
  }

  table.two-column th,
  table.two-column td {
    width: 50%;
  }
  table.two-column th:last-of-type,
  table.two-column td:last-of-type {
    text-align: right;
  }

  table.claim-item th,
  table.claim-item td {
    width: calc(100% / 5);
  }

  table.payment-history {
    border-collapse: collapse;
  }
  table.payment-history tr {
    height: 2rem;
  }
  table.payment-history th,
  table.payment-history td {
    width: 25%;
  }

  @media print {
    .Receipts-Container {
      margin: 0;
      padding: 0;
      box-shadow: none;
    }

    .print-container {
      display: none;
    }

    th {
      border-bottom: 2px solid #eee;
    }
  }
`;
