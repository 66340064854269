import Api from "patient_app/api";

import {
  NEW_ERRORS,
  CURRICULUM_LOAD,
  CURRICULUM_LOAD_SECTION,
  CURRICULUM_LOAD_SPECIAL_DELIVERY,
  CURRICULUM_TOGGLE_SKIP_LOADING,
  CURRICULUM_SKIPPED,
  CURRICULUM_UPDATE_SELECTED_ITEM,
} from "patient_app/constants/actionTypes";

export const fetchCurriculum = (curriculumId = "default", userId = null) => {
  let url = `/curriculums/${curriculumId}/sections`;
  if (userId) {
    url += `?user_id=${userId}`;
  }
  const data = {
    url: url,
    path: "/api/v1",
    data: { method: "GET" },
  };

  return async (dispatch) => {
    try {
      const res = await Api.makeRequest(data);
      // console.log("fetchCurriculum res", res)
      if (res.success) {
        dispatch({
          type: CURRICULUM_LOAD,
          curriculum: res.curriculum,
        });
        return;
      }

      dispatch({ type: NEW_ERRORS, errors: res.errors });
    } catch (e) {
      console.log(e);
      console.log("rederecting to sign in from fetchUser in common actions");
      window.location.href = "/signin";
    }
  };
};

export const fetchCurriculumSection = (
  curriculumId,
  sectionId,
  userId = null
) => {
  let url = `/curriculums/${curriculumId}/section?section_id=${sectionId}`;
  if (userId) {
    url += `&user_id=${userId}`;
  }
  const data = {
    url: url,
    path: "/api/v1",
    data: { method: "GET" },
  };

  return async (dispatch) => {
    try {
      const res = await Api.makeRequest(data);
      // console.log("fetchCurriculumSection res", res)
      if (res.success) {
        dispatch({ type: CURRICULUM_LOAD_SECTION, section: res.section });
        return;
      }

      dispatch({
        type: NEW_ERRORS,
        errors: [{ status: "error", text: res.error }],
      });
    } catch (e) {
      console.log(e);
      window.location.href = "/courses";
    }
  };
};

export const fetchSpecialDelivery = (userId = null) => {
  let url = `/curriculums/special_delivery`;
  if (userId) {
    url += `?user_id=${userId}`;
  }
  const data = {
    url: url,
    path: "/api/v1",
    data: { method: "GET" },
  };

  return async (dispatch) => {
    try {
      const res = await Api.makeRequest(data);
      console.log("fetchSpecialDelivery res", res);
      if (res.success) {
        dispatch({
          type: CURRICULUM_LOAD_SPECIAL_DELIVERY,
          specialDelivery: res.items,
        });
        return;
      }

      dispatch({
        type: NEW_ERRORS,
        errors: [{ status: "error", text: res.error }],
      });
    } catch (e) {
      console.log(e);
      window.location.href = "/courses";
    }
  };
};

export const skipCourse = (curriculumId, exerciseId) => {
  const data = {
    url: `/curriculums/${curriculumId}/skip?exercise_id=${exerciseId}`,
    path: "/api/v1",
    data: { method: "POST" },
  };

  return async (dispatch) => {
    try {
      dispatch({ type: CURRICULUM_TOGGLE_SKIP_LOADING, skipLoading: true });
      const res = await Api.makeRequest(data);
      console.log("skipCourse res", res);
      if (res.success) {
        dispatch({
          type: CURRICULUM_SKIPPED,
          skippedItem: res.skipped_item,
          nextItem: res.next_item,
        });
      } else {
        dispatch({
          type: NEW_ERRORS,
          errors: [{ status: "error", text: res.error }],
        });
      }

      dispatch({ type: CURRICULUM_TOGGLE_SKIP_LOADING, skipLoading: true });
      dispatch({ type: CURRICULUM_UPDATE_SELECTED_ITEM, selectedItem: null });
    } catch (e) {
      console.log(e);
      window.location.href = "/courses";
    }
  };
};
