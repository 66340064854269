import Api from "patient_app/api";

import {
  SESSION_SET_LOCAL_STATE,
  NEW_ERRORS,
} from "patient_app/constants/actionTypes";

export const createNetPromoterScore = (params) => {
  const data = {
    url: `/api/v2/net_promoter_scores`,
    data: {
      method: "POST",
      body: {
        net_promoter_score: params,
      },
    },
  };

  return async (dispatch) => {
    try {
      const res = await Api.makeRequest(data);
      if (res.success) {
        dispatch({ type: SESSION_SET_LOCAL_STATE, obj: "nps", value: res.nps });
      } else {
        dispatch({ type: NEW_ERRORS, errors: [{ text: res.error }] });
      }
    } catch (e) {
      console.log(e);
      dispatch({
        type: NEW_ERRORS,
        errors: [{ text: "Could not save score." }],
      });
    }
  };
};

export const updateNetPromoterScore = (params) => {
  const data = {
    url: `/api/v2/net_promoter_scores/${params.id}`,
    data: {
      method: "PATCH",
      body: {
        net_promoter_score: params,
      },
    },
  };

  return async (dispatch) => {
    try {
      const res = await Api.makeRequest(data);
      if (res.success) {
        dispatch({ type: SESSION_SET_LOCAL_STATE, obj: "nps", value: res.nps });
      } else {
        dispatch({ type: NEW_ERRORS, errors: [{ text: res.error }] });
      }
    } catch (e) {
      console.log(e);
      dispatch({
        type: NEW_ERRORS,
        errors: [{ text: "Could not save score." }],
      });
    }
  };
};
