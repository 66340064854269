import PropTypes from "prop-types";
import React from "react";

import { Router, withRouter, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { PROFILE_MAIN_TAB } from "patient_app/constants/actionTypes";

import { mobileCheck } from "patient_app/helpers/supported";
import permissions from "patient_app/helpers/permissions";

import assets from "patient_app/assets";
import layout from "patient_app/stylesheets/drug_test_orders/layout";

const mapDispatchToProps = (dispatch) => {
  return {
    setMainTab: (path) => dispatch({ type: PROFILE_MAIN_TAB, path: path }),
  };
};

const mapStateToProps = (state) => {
  return {
    primaryDiagnosis: state.common.primaryDiagnosis,
    primaryProgram: state.common.primaryProgram,
    user: state.common.user,
  };
};

class AccountSidebar extends React.Component {
  render() {
    const { primaryDiagnosis, primaryProgram, user } = this.props;
    const isClinic = permissions.isClinic(user);
    const isOud = permissions.isOud(user, primaryDiagnosis);

    const profId = this.props.match.params.id;

    return (
      <nav
        className="profile-sidebar"
        role="navigation"
        aria-label="Account navigation"
      >
        <a href="#profile-content-main" className="tab skip-link">
          Skip to Profile Content
        </a>

        <NavLink
          to={`/profiles/${profId}/account`}
          className="tab"
          activeClassName="current"
          onClick={() => this.props.setMainTab(`/profiles/${profId}/account`)}
        >
          Profile
        </NavLink>

        {isClinic && (
          <NavLink
            to={`/profiles/${profId}/drivers_license`}
            className="tab"
            activeClassName="current"
            onClick={() =>
              this.props.setMainTab(`/profiles/${profId}/drivers_license`)
            }
          >
            Driver's License
          </NavLink>
        )}

        <NavLink
          to={`/profiles/${profId}/program`}
          className="tab"
          activeClassName="current"
          onClick={() => this.props.setMainTab(`/profiles/${profId}/program`)}
        >
          Program and Clinic
        </NavLink>

        {primaryProgram?.requires_drug_test && (
          <NavLink
            to={`/profiles/${profId}/drug_test_orders`}
            className="tab"
            activeClassName="current"
            onClick={() =>
              this.props.setMainTab(`/profiles/${profId}/drug_test_orders`)
            }
          >
            Drug Test Orders
          </NavLink>
        )}

        <NavLink
          to={`/profiles/${profId}/documents`}
          className="tab"
          activeClassName="current"
          onClick={() => this.props.setMainTab(`/profiles/${profId}/documents`)}
        >
          Documents
        </NavLink>

        <NavLink
          to={`/profiles/${profId}/payment`}
          className="tab"
          activeClassName="current"
          onClick={() => this.props.setMainTab(`/profiles/${profId}/payment`)}
        >
          Payment
        </NavLink>

        <NavLink
          to={`/profiles/${profId}/insurance`}
          className="tab"
          activeClassName="current"
          onClick={() => this.props.setMainTab(`/profiles/${profId}/insurance`)}
        >
          Insurance
        </NavLink>

        <NavLink
          to={`/profiles/${profId}/notifications`}
          className="tab"
          activeClassName="current"
          onClick={() =>
            this.props.setMainTab(`/profiles/${profId}/notifications`)
          }
        >
          Notifications
        </NavLink>

        <NavLink
          to={`/profiles/${profId}/feedback`}
          className="tab"
          activeClassName="current"
          onClick={() => this.props.setMainTab(`/profiles/${profId}/feedback`)}
        >
          Feedback
        </NavLink>

        <style jsx>{layout}</style>
      </nav>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AccountSidebar)
);
