import PropTypes from "prop-types";
import React from "react";

import {
  Router,
  Route,
  NavLink,
  Switch,
  withRouter,
  Redirect,
} from "react-router-dom";
import { connect } from "react-redux";
import { CURRICULUM_UPDATE_SELECTED_ITEM } from "patient_app/constants/actionTypes";

const mapDispatchToProps = (dispatch) => {
  return {
    updateSelectedItem: (selectedItem) =>
      dispatch({
        type: CURRICULUM_UPDATE_SELECTED_ITEM,
        selectedItem: selectedItem,
      }),
  };
};

const mapStateToProps = (state) => {
  return {
    selectedItem: state.curriculum.selectedItem,
  };
};

class OuterElement extends React.Component {
  static propTypes = {
    renderButton: PropTypes.bool,
  };

  render() {
    let { renderButton, item } = this.props;

    if (renderButton) {
      return (
        <button
          className="custom-button"
          aria-haspopup="dialog"
          onClick={(e) => this.props.updateSelectedItem(item)}
        >
          {this.props.children}
        </button>
      );
    }

    return <div>{this.props.children}</div>;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OuterElement);
