import PropTypes from "prop-types";
import React from "react";
import { DateTime } from "luxon";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  fetchPayment,
  payPatientInvoice,
} from "patient_app/api/profileActions";
import {
  NEW_ERRORS,
  NEW_FIELD_SPECIFIC_ERROR,
  CLEAR_FIELD_SPECIFIC_ERRORS,
} from "patient_app/constants/actionTypes";

import LoadingSpinner from "patient_app/components/utils/LoadingSpinner";
import SubTabBack from "patient_app/views/profiles/partials/SubTabBack";
import ProfileField from "patient_app/views/profiles/partials/ProfileField";

import { mobileCheck } from "patient_app/helpers/supported";
import permissions from "patient_app/helpers/permissions";
import stripeHelpers from "patient_app/helpers/stripeHelpers";
import textHelpers from "patient_app/helpers/textHelpers";

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPayment: (profileId, params) =>
      dispatch(fetchPayment(profileId, params)),
    payPatientInvoice: (userId, invoiceId, cardId) =>
      dispatch(payPatientInvoice(userId, invoiceId, cardId)),
    newGeneralErrors: (errors) =>
      dispatch({ type: NEW_ERRORS, errors: errors }),
    newFieldSpecificError: (field, error) =>
      dispatch({ type: NEW_FIELD_SPECIFIC_ERROR, field: field, error: error }),
    clearFieldSpecificErrors: () =>
      dispatch({ type: CLEAR_FIELD_SPECIFIC_ERRORS }),
  };
};

const mapStateToProps = (state) => {
  return {
    loading: state.profile.loading,
    patientInvoices: state.profile.patientInvoices,
    paymentInfo: state.profile.paymentInfo,
    stripeKey: state.profile.stripeKey,
    stripeAccountKey: state.profile.stripeAccountKey,
    success: state.profile.success,
    user: state.common.user,
  };
};

class PatientInvoices extends React.Component {
  profileId = this.props.match.params.id;

  constructor(props) {
    super(props);
    this.state = {
      cardName: "",
      showCardForInvoice: null, // set to invoice id when used
      selectedInvoiceId: null, // set to invoice id when used
    };
  }

  componentDidMount() {
    if (mobileCheck()) {
      this.props.fetchPayment(this.profileId, ["patient_invoices"]);
    }

    if (this.props.stripeKey) {
      this.mountStripe();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.stripeKey && this.props.stripeKey) {
      this.mountStripe();
    }

    if (!prevState.showCardForInvoice && this.state.showCardForInvoice) {
      this.state.stripeCardElement.mount("#card-element");
    }

    if (
      !prevProps.success &&
      this.props.success &&
      this.state.showCardForInvoice
    ) {
      this.setState({ showCardForInvoice: null });
    }
  }

  mountStripe = () => {
    let { stripeKey, stripeAccountKey } = this.props;

    let stripe = stripeHelpers.mountStripe(stripeKey, stripeAccountKey);
    let elements = stripe.elements();
    let cardElement = elements.create("card", {
      iconStyle: "solid",
      hidePostalCode: false,
      style: {
        base: {
          fontSize: "1rem",
        },
      },
    });

    this.setState({
      stripe: stripe,
      stripeElements: elements,
      stripeCardElement: cardElement,
    });
  };

  render() {
    let { loading, patientInvoices, paymentInfo, user } = this.props;

    let { selectedInvoiceId } = this.state;

    return (
      <div>
        <SubTabBack />
        <div className="profile-section">
          <h1>Manual Invoices</h1>
          <p>One time invoices not tied to a recurring charge.</p>

          {!(patientInvoices && paymentInfo) && <LoadingSpinner />}

          {patientInvoices && paymentInfo && (
            <div className="profile-list patient-invoices">
              {patientInvoices.length > 0 && (
                <div className="list-item header">
                  <div className="row">
                    <div className="col mobile-only" />
                    <p>Amount</p>
                    <p>Due Date</p>
                    <p>Status</p>
                    <div className="col align-right web-only">
                      {loading && <LoadingSpinner />}
                    </div>
                  </div>
                </div>
              )}

              {this.renderInvoices()}

              {selectedInvoiceId && !loading && (
                <div>
                  {paymentInfo.card_brand && (
                    <button
                      className={`custom-button action-link mobile-only ${
                        loading ? "loading" : ""
                      }`}
                      onClick={() =>
                        this.props.payPatientInvoice(user.id, selectedInvoiceId)
                      }
                    >
                      Charge {paymentInfo.card_brand} ({paymentInfo.card_digits}
                      )
                    </button>
                  )}

                  <button
                    className={`custom-button action-link mobile-only ${
                      loading ? "loading" : ""
                    }`}
                    onClick={() =>
                      this.setState({ showCardForInvoice: selectedInvoiceId })
                    }
                  >
                    Charge Another Card
                  </button>
                </div>
              )}

              {loading && (
                <div
                  className="mobile-only"
                  style={{ display: "block", marginTop: "1rem" }}
                >
                  <LoadingSpinner />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }

  renderInvoices() {
    let { loading, patientInvoices, paymentInfo, user } = this.props;
    let { cardName, selectedInvoiceId, showCardForInvoice } = this.state;

    if (!patientInvoices || !patientInvoices.length) {
      return (
        <div className="list-item none">
          <div className="row">
            <p>No invoices.</p>
          </div>
        </div>
      );
    }

    return patientInvoices.map((invoice, i) => {
      const open = invoice.status === "open";
      const overdue =
        open && DateTime.fromISO(invoice.due_date).ts < DateTime.local().ts;
      const dueDate = DateTime.fromISO(invoice.due_date)
        .setZone("America/Detroit")
        .toFormat("DD");
      return (
        <div key={i}>
          <div className="list-item">
            <div className="row">
              <div className="col mobile-only align-left">
                {open && (
                  <input
                    type="checkbox"
                    checked={selectedInvoiceId === invoice.id}
                    onChange={() => this.handleToggleSelected(invoice.id)}
                  />
                )}
              </div>
              <p>${(invoice.amount / 100).toFixed(2)}</p>
              {overdue ? (
                <p className="red">
                  <span>{dueDate}</span>
                </p>
              ) : (
                <p>{dueDate}</p>
              )}
              <p>{textHelpers.titleize(invoice.status)}</p>
              <div className="col align-right web-only">
                {open && paymentInfo.card_brand && (
                  <button
                    className={`custom-button item-action ${
                      loading ? "loading" : ""
                    }`}
                    onClick={() =>
                      this.props.payPatientInvoice(user.id, invoice.id)
                    }
                  >
                    Charge {paymentInfo.card_brand} ({paymentInfo.card_digits})
                  </button>
                )}

                {open && (
                  <button
                    className={`custom-button item-action ${
                      loading ? "loading" : ""
                    }`}
                    onClick={() =>
                      this.setState({ showCardForInvoice: invoice.id })
                    }
                  >
                    Charge Another Card
                  </button>
                )}
              </div>
            </div>
          </div>

          {showCardForInvoice === invoice.id && (
            <div className="list-item form">
              <div className="row">
                <ProfileField
                  field="cardName"
                  fieldType="text"
                  onUpdateField={(value) => this.setState({ cardName: value })}
                  title="Cardholder Name"
                  value={cardName}
                />

                <div id="card-element" className="card-form" />

                <p className="red">
                  <span>
                    Your new card will be charged $
                    {(invoice.amount / 100).toFixed(2)}.
                  </span>
                </p>

                <button
                  className={`custom-button submit half ${
                    loading ? "loading" : ""
                  }`}
                  onClick={this.handleSubmitCard}
                >
                  {loading ? <LoadingSpinner /> : "Save Changes"}
                </button>
              </div>
            </div>
          )}
        </div>
      );
    });
  }

  handleToggleSelected = (value) => {
    let { selectedInvoiceId } = this.state;
    if (selectedInvoiceId === value) {
      this.setState({ selectedInvoiceId: null });
    } else {
      this.setState({ selectedInvoiceId: value });
    }
  };

  handleSubmitCard = async () => {
    let { user } = this.props;
    let { showCardForInvoice, stripe, stripeCardElement } = this.state;
    this.props.newGeneralErrors([]);
    this.props.clearFieldSpecificErrors();

    if (!this.state.cardName) {
      this.props.newGeneralErrors([
        { text: "Cardholder name cannot be blank." },
      ]);
      this.props.newFieldSpecificError(
        "cardName",
        "Cardholder name cannot be blank."
      );
      window.scrollTo(0, 0);
      return;
    }

    stripe
      .createToken(stripeCardElement, { name: this.state.cardName })
      .then((result) => {
        if (result.error) {
          // Inform the user if there was an error
          this.props.newGeneralErrors([
            { text: "Please fix any errors in the credit card form." },
          ]);
          window.scrollTo(0, 0);
        } else {
          // Send the token to your server
          this.props.payPatientInvoice(
            user.id,
            showCardForInvoice,
            result.token.id
          );
        }
      });
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PatientInvoices)
);
