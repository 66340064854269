import PropTypes from "prop-types";
import React from "react";
import cn from "classnames";
import { connect } from "react-redux";
import assets from "patient_app/assets";

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {
    fieldSpecificErrors: state.common.fieldSpecificErrors,
  };
};

class PasswordConfField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  render() {
    let {
      field,
      title,
      value,
      id,
      required,
      fieldSpecificErrors,
      uid,
    } = this.props;
    let { show } = this.state;

    let error = fieldSpecificErrors[uid];

    return (
      <div className={cn("field", field)}>
        <label htmlFor={id}>
          {title} {required && <span className="light">(Required)</span>}
        </label>
        <div className="input-wrapper">
          <input
            id={id}
            type={show ? "text" : "password"}
            value={value}
            onChange={(e) => this.props.onUpdateField(e.target.value, field)}
            onKeyDown={this.props.onKeyPress}
            autoCorrect="off"
            autoCapitalize="off"
            aria-invalid={error ? true : false}
            aria-describedby={error && `aria-describedby-${uid}`}
            spellCheck="false"
            className={cn(error && "has-error")}
          ></input>

          <button
            className="custom-button show-password"
            onClick={this.toggleShowPassword}
          >
            {show ? (
              <img src={assets.passwordHidden} />
            ) : (
              <img src={assets.passwordShown} />
            )}
          </button>
        </div>

        {error && (
          <p
            role="alert"
            id={`aria-describedby-${uid}`}
            className={cn("field-specific-error", field)}
          >
            Error: {error}
          </p>
        )}
      </div>
    );
  }

  toggleShowPassword = (e) => {
    if (e) e.preventDefault();
    this.setState({ show: !this.state.show });
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordConfField);
