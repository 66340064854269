import PropTypes from "prop-types";
import React from "react";
import cn from "classnames";
import { connect } from "react-redux";

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {
    fieldSpecificErrors: state.common.fieldSpecificErrors,
  };
};

class TextField extends React.Component {
  render() {
    let {
      field,
      title,
      value,
      id,
      className,
      required,
      fieldSpecificErrors,
      uid,
    } = this.props;

    let error = fieldSpecificErrors[uid];

    return (
      <div className={cn("field", className, field)}>
        <label htmlFor={id}>
          {title} {required && <span className="light">(Required)</span>}
        </label>
        <input
          id={id}
          type="text"
          value={value}
          onChange={(e) => this.props.onUpdateField(e.target.value, field)}
          className={cn(error && "has-error")}
          onKeyDown={this.props.onKeyPress}
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          aria-invalid={error ? true : false}
          aria-describedby={error && `aria-describedby-${uid}`}
        ></input>

        {error && (
          <p
            role="alert"
            id={`aria-describedby-${uid}`}
            className={cn("field-specific-error", field)}
          >
            Error: {error}
          </p>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TextField);
