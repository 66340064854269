import PropTypes from "prop-types";
import React from "react";
import cn from "classnames";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { disableMfa, configureMfa } from "patient_app/api/sessionActions";
import SubmitButton from "patient_app/components/buttons/SubmitButton";
import ProfileField from "patient_app/views/profiles/partials/ProfileField";
import PasswordField from "patient_app/components/input_fields/PasswordField";

import {
  UPDATE_MFA_CODE,
  NEW_FIELD_SPECIFIC_ERROR,
  NEW_ERRORS,
  CLEAR_FIELD_SPECIFIC_ERRORS,
} from "patient_app/constants/actionTypes";

import QRCode from "qrcode.react";

const mapDispatchToProps = (dispatch) => {
  return {
    onError: (field, error) =>
      dispatch({ type: NEW_FIELD_SPECIFIC_ERROR, field: field, error: error }),
    onGeneralErrors: (errors) => dispatch({ type: NEW_ERRORS, errors: errors }),
    clearFieldErrors: () => dispatch({ type: CLEAR_FIELD_SPECIFIC_ERRORS }),
    disableMfa: (profileId, password, code) =>
      dispatch(disableMfa(profileId, password, code)),
    configureMfa: (profileId, password) =>
      dispatch(configureMfa(profileId, password)),
  };
};

const mapStateToProps = (state) => {
  return {
    user: state.common.user,
    isFetching: state.common.loading,
  };
};

class MfaSetup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mfaPassword: "",
      mfaCode: "",
    };
  }

  render() {
    let { user, isFetching } = this.props;

    let { mfaPassword, mfaCode } = this.state;

    const mfaEnabled = user && user.mfa_enabled;

    return (
      <div>
        <p>To {mfaEnabled ? "disable" : "enable"} 2FA, enter your password.</p>

        <PasswordField
          field={"mfaPassword"}
          title={"Password"}
          value={mfaPassword}
          id={"mfaPassword"}
          onUpdateField={this.onUpdateField}
          onKeyPress={this.onKeyPress}
          showPasswordStrength={false}
          uid={"mfaPassword"}
        />

        {mfaEnabled && (
          <ProfileField
            field="mfaCode"
            fieldType="text"
            onUpdateField={this.onUpdateField}
            title="2FA Token"
            value={mfaCode ? mfaCode : ""}
          />
        )}

        <button
          className={`custom-button submit half ${isFetching ? "loading" : ""}`}
          onClick={() => (mfaEnabled ? this.disableMfa() : this.configureMfa())}
        >
          {mfaEnabled ? "Disable" : "Enable"} 2FA
        </button>
      </div>
    );
  }

  disableMfa = () => {
    let { mfaPassword, mfaCode } = this.state;

    let errors = [];
    if (!mfaPassword || mfaPassword.length < 6) {
      errors.push({
        field: "mfaPassword",
        text: "Password must be at least 6 characters",
      });
    }

    if (!mfaCode || mfaCode.length < 6) {
      errors.push({
        field: "mfaCode",
        text: "2FA code must be 6 characters in length",
      });
    }

    if (errors.length > 0) {
      this.props.onGeneralErrors([
        { text: "Please correct the issues listed below." },
      ]);
      for (const [key, error] of Object.entries(errors)) {
        this.props.onError(error.field, error.text);
      }
      return;
    }

    if (!confirm("Disable two factor authentication?")) {
      return;
    }

    this.props.disableMfa(this.props.profileId, mfaPassword, mfaCode);
    this.setState({ mfaPassword: "", mfaCode: "" });
  };

  configureMfa = () => {
    let { mfaPassword } = this.state;

    if (!mfaPassword || mfaPassword.length < 6) {
      this.props.onError(
        "mfaPassword",
        "Password must be at least 6 characters"
      );
      this.props.onGeneralErrors([
        { text: "Please correct the issues listed below." },
      ]);
      return;
    }

    this.props.clearFieldErrors();
    this.props.onGeneralErrors([]);
    this.props.configureMfa(this.props.profileId, mfaPassword);
  };

  onKeyPress = (e) => {
    const { user } = this.props;
    if (e.keyCode === 13) {
      const mfaEnabled = user && user.mfa_enabled;
      if (mfaEnabled) {
        this.disableMfa();
      } else {
        this.configureMfa();
      }
    }
  };

  onUpdateField = (value, field) => {
    this.setState({ [field]: value });
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MfaSetup);
