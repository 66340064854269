import {
  PROFILE_REQ_START,
  PROFILE_REQ_SUCCESS,
  PROFILE_REQ_FAILURE,
  FETCH_PROGRAM,
  FETCH_PAYMENT_INITIAL,
  FETCH_PAYMENT_ALL,
  FETCH_MISSION_MAP,
  UPDATE_EMERGENCY_CONTACT_LOCAL,
  PROFILE_UPDATE_STATE_LOCAL,
  UPDATE_PASSWORD_SUCCESS,
  PROFILE_UPDATING_PICTURE,
  PROFILE_LOAD_DOCUMENTS,
  PROFILE_LOAD_MORE_FROM_CHAT,
  PROFILE_LOAD_MORE_SIGNED_DOCS,
  PROFILE_LOAD_RECEIPT_INFO,
  PROFILE_MAIN_TAB,
  PROFILE_LOAD_PATIENT_INVOICE,
  PROFILE_REQ_CODE_SUCCESS,
  PROFILE_REQ_CODE_RESET,
  PROFILE_UPDATE_EMAIL_SUCCESS,
  PROFILE_UPDATE_EMAIL_ERRORS,
  PROFILE_UPDATE_PHONE_SUCCESS,
  PROFILE_UPDATE_PHONE_ERRORS,
} from "patient_app/constants/actionTypes";

const initialState = {
  loading: false,
  success: false,
  currentPassword: "",
  newPassword: "",
  newPasswordConf: "",
  resetPasswordMfa: "",
  feedback: "",
  updatingPicture: false,
  initialPaymentLoading: false,
  patientInvoices: null, // array of PatientInvoices

  mainTab: "", // "profile", "drivers_license", "program", ...
  subTab: [], // sections that are shown when tab is selected
  requestCodeSuccess: false,
  updateEmailSuccess: false,
  updateEmailErrors: [],
  updatePhoneSuccess: false,
  updatePhoneErrors: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PROFILE_REQ_START:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case PROFILE_REQ_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case PROFILE_REQ_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
      };
    case FETCH_PROGRAM:
      return {
        ...state,
        primary: action.primary,
        secondary: action.secondary,
        oldVices: action.oldVices,
        recommender: action.recommender,
        hasProgram: action.hasProgram,
        missionMap: action.missionMap,
        curriculums: action.curriculums,
      };
    case FETCH_PAYMENT_INITIAL:
      return {
        ...state,
        initialPaymentLoading: action.initialPaymentLoading,
        hasPayment: action.hasPayment,
      };
    case FETCH_PAYMENT_ALL:
      return {
        ...state,
        hasPayment: action.hasPayment,
        hasMorePayments: action.hasMorePayments,
        paymentInfo: action.paymentInfo,
        payments: action.payments,
        stripeKey: action.stripeKey,
      };
    case FETCH_MISSION_MAP:
      return {
        ...state,
        missionMap: action.missionMap,
      };
    case PROFILE_UPDATE_STATE_LOCAL:
      return {
        ...state,
        [action.obj]: action.value,
      };
    case UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        currentPassword: "",
        newPassword: "",
        newPasswordConf: "",
        resetPasswordMfa: "",
      };
    case PROFILE_UPDATING_PICTURE:
      return {
        ...state,
        updatingPicture: action.updatingPicture,
      };
    case PROFILE_LOAD_DOCUMENTS:
      return {
        ...state,
        documents: action.documents,
      };
    case PROFILE_LOAD_MORE_FROM_CHAT:
      return {
        ...state,
        fromChat: state.fromChat.concat(action.fromChat),
        hasMoreFromChat: action.hasMoreFromChat,
      };
    case PROFILE_LOAD_MORE_SIGNED_DOCS:
      return {
        ...state,
        signedDocuments: state.signedDocuments.concat(action.signedDocuments),
        hasMoreSignedDocs: action.hasMoreSignedDocs,
      };
    case PROFILE_LOAD_RECEIPT_INFO:
      return {
        ...state,
        receiptInfo: action.receiptInfo,
      };
    case PROFILE_MAIN_TAB:
      const path = action.path;
      let mainTab = "";
      if (path.includes("/account")) mainTab = "account";
      else if (path.includes("/drivers_license")) mainTab = "drivers_license";
      else if (path.includes("/program")) mainTab = "program";
      else if (path.includes("/drug_test_orders")) mainTab = "drug_test_orders";
      else if (path.includes("/documents")) mainTab = "documents";
      else if (path.includes("/payment")) mainTab = "payment";
      else if (path.includes("/insurance")) mainTab = "insurance";
      else if (path.includes("/notifications")) mainTab = "notifications";
      else if (path.includes("/feedback")) mainTab = "feedback";

      return {
        ...state,
        mainTab: mainTab,
      };
    case PROFILE_LOAD_PATIENT_INVOICE:
      let invoices = [...state.patientInvoices];
      const idx = invoices.findIndex((inv) => inv.id === action.invoice.id);
      if (idx > -1) {
        invoices[idx] = action.invoice;
      }
      return {
        ...state,
        patientInvoices: invoices,
      };
    case PROFILE_REQ_CODE_SUCCESS:
      return {
        ...state,
        requestCodeSuccess: true,
      };
    case PROFILE_REQ_CODE_RESET:
      return {
        ...state,
        requestCodeSuccess: false,
      };
    case PROFILE_UPDATE_EMAIL_SUCCESS:
      return {
        ...state,
        updateEmailSuccess: true,
        updateEmailErrors: [],
      };
    case PROFILE_UPDATE_EMAIL_ERRORS:
      return {
        ...state,
        updateEmailErrors: action.errors,
      };
    case PROFILE_UPDATE_PHONE_SUCCESS:
      return {
        ...state,
        updatePhoneSuccess: true,
        updatePhoneErrors: [],
      };
    case PROFILE_UPDATE_PHONE_ERRORS:
      return {
        ...state,
        updatePhoneErrors: action.errors,
      };
    default:
      return state;
  }
};
