import Api from "patient_app/api";
import { DateTime } from "luxon";
import dates from "patient_app/helpers/dates";
import {
  APPTS_LOAD,
  NEW_ERRORS,
  APPTS_LOAD_RECOVERY_GROUP,
  APPTS_LOAD_MORE,
  APPTS_LOAD_NEXT_COURSE,
  APPTS_TOGGLE_BOOKING_LOADING,
  APPTS_BOOKING_LOAD,
  APPTS_SHOW_EXPANDED,
  APPTS_ACTION_ITEMS,
  CLINIC_LOAD_HOLIDAY,
  APPTS_USER_RECOVERY_GROUP,
} from "patient_app/constants/actionTypes";

export const fetchAppointments = () => {
  const data = {
    url: `/appointments/fetch`,
    data: {
      method: "GET",
    },
  };

  return async (dispatch) => {
    try {
      const res = await Api.makeRequest(data);
      if (res.success) {
        dispatch({
          type: APPTS_LOAD,
          completed: false,
          appointments: res.appointments,
          getMore: res.get_more,
        });
        return;
      }

      dispatch({ type: NEW_ERRORS, errors: res.errors });
    } catch (e) {
      console.log(e);
    }
  };
};

export const fetchMoreAppointments = (nextPage) => {
  const data = {
    url: `/appointments/upcoming?page=${nextPage}`,
    data: {
      method: "GET",
    },
  };

  return async (dispatch) => {
    try {
      const res = await Api.makeRequest(data);
      if (res.success) {
        dispatch({
          type: APPTS_LOAD_MORE,
          appointments: res.appointments,
          getMore: res.get_more,
          nextPage: res.next_page,
        });
        return;
      }

      dispatch({ type: NEW_ERRORS, errors: res.errors });
    } catch (e) {
      console.log(e);
    }
  };
};

export const fetchRecoveryGroup = () => {
  const data = {
    url: `/appointments/groups`,
    path: "api/v2",
    data: {
      method: "GET",
    },
  };

  return async (dispatch) => {
    try {
      const res = await Api.makeRequest(data);
      if (res.success) {
        // TODO? update for multigroups?
        let recoveryGroup = res.recovery_group;
        if (recoveryGroup) {
          // mutate for appointment modal
          recoveryGroup.category = "recovery_group";
          let startAt = DateTime.local()
            .setZone(recoveryGroup.time_zone)
            .set({
              hour: recoveryGroup.sa_h,
              minute: recoveryGroup.sa_m,
              weekday: dates.getLuxonWeekday(recoveryGroup.day),
            });
          if (startAt < DateTime.local()) {
            startAt = startAt.plus({ weeks: 1 });
          }
          recoveryGroup.start_at = startAt;
        }

        if (res.clinic_holidays) {
          res.clinic_holidays.forEach((holiday) => {
            dispatch({ type: CLINIC_LOAD_HOLIDAY, clinicHoliday: holiday });
          });
        }

        dispatch({
          type: APPTS_LOAD_RECOVERY_GROUP,
          recoveryGroup: recoveryGroup,
        });
        return;
      }

      dispatch({ type: NEW_ERRORS, errors: res.errors });
    } catch (e) {
      console.log(e);
    }
  };
};

export const fetchNextCourse = () => {
  const data = {
    url: `/appointments/fetch_next_course`,
    data: {
      method: "GET",
    },
  };

  return async (dispatch) => {
    try {
      const res = await Api.makeRequest(data);
      if (res.success) {
        dispatch({
          type: APPTS_LOAD_NEXT_COURSE,
          nextCourse: res.next_course,
        });
        return;
      }

      dispatch({ type: NEW_ERRORS, errors: res.errors });
    } catch (e) {
      console.log(e);
    }
  };
};

export const fetchBooking = () => {
  const data = {
    url: `/appointments/fetch_next_booking`,
    data: {
      method: "GET",
    },
  };

  return async (dispatch) => {
    dispatch({ type: APPTS_TOGGLE_BOOKING_LOADING, bookingLoading: true });
    try {
      const res = await Api.makeRequest(data);
      if (res.success) {
        dispatch({
          type: APPTS_BOOKING_LOAD,
          booking: res.booking,
        });
        dispatch({ type: APPTS_TOGGLE_BOOKING_LOADING, bookingLoading: false });
        return;
      }

      dispatch({ type: NEW_ERRORS, errors: res.errors });
      dispatch({ type: APPTS_TOGGLE_BOOKING_LOADING, bookingLoading: false });
    } catch (e) {
      console.log(e);
    }
  };
};

export const updateBookingCommType = (
  bookingId,
  commType,
  isClinic = false
) => {
  const data = {
    url: `/appointments/update_booking_comm_type`,
    data: {
      method: "PATCH",
      body: {
        id: bookingId,
        comm_type: commType,
      },
    },
  };

  return async (dispatch) => {
    try {
      const res = await Api.makeRequest(data);
      if (res.success) {
        if (isClinic) {
          dispatch({ type: APPTS_SHOW_EXPANDED, appt: res.booking });
        } else {
          dispatch({ type: APPTS_BOOKING_LOAD, booking: res.booking });
        }
        return;
      }

      dispatch({ type: NEW_ERRORS, errors: [{ text: res.error }] });
    } catch (e) {
      console.log(e);
    }
  };
};

export const userJoinedGroup = (userId, groupId) => {
  const data = {
    url: `/appointments/user_joined`,
    path: "api/v1",
    data: {
      method: "POST",
      body: { user_id: userId, group_id: groupId },
    },
  };

  return async (dispatch) => {
    try {
      const res = await Api.makeRequest(data);
    } catch (e) {
      console.log(e);
    }
  };
};
