import PropTypes from "prop-types";
import React from "react";
import cn from "classnames";
import platform from "platform";

import {
  Router,
  Route,
  NavLink,
  Switch,
  withRouter,
  Redirect,
  Prompt,
} from "react-router-dom";
import { connect } from "react-redux";

import {
  fetchUser,
  fetchProfile,
  createEvent,
  logConnectionInfo,
} from "patient_app/api/commonActions";
import { fetchClinic } from "patient_app/api/clinicActions";
import {
  waitForInvite,
  disconnectFromRoom,
} from "patient_app/api/videoRoomActions";
import {
  setParticipants,
  handleTwilioError,
  setParticipant,
  removeParticipant,
  setRemoteTrack,
  removeRemoteTrack,
  removeRoom,
  leaveRoom,
  fetchVrqUsesZoom,
  waitForZoomInvite,
} from "patient_app/api/videoCallActions";

import {
  SET_TWILIO_ROOM_OBJ,
  VIDEO_IS_PREVIOUS,
  NEW_ERRORS,
  VC_UPDATE_STATE,
} from "patient_app/constants/actionTypes";

import Nav from "patient_app/components/navbar/Nav";
import LoadingSpinner from "patient_app/components/utils/LoadingSpinner";

import { getUrlVars } from "components/utils/helpers/linkHelpers";
import permissions from "patient_app/helpers/permissions";
import { mobileCheck, getOS, getBrowser } from "patient_app/helpers/supported";

import MobileRedirect from "patient_app/views/video_rooms/partials/MobileRedirect";
import Video from "patient_app/views/video_rooms/partials/Video";
import BusyScreen from "patient_app/views/video_rooms/partials/BusyScreen";

import layout from "patient_app/stylesheets/video_rooms/layout";

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUser: () => dispatch(fetchUser()),
    fetchProfile: (user) => dispatch(fetchProfile(user)),
    fetchClinic: () => dispatch(fetchClinic()),
    waitForInvite: (userId, queueId, props, callback) =>
      dispatch(waitForInvite(userId, queueId, props, callback)),
    waitForZoomInvite: (userId, queueId, props, callback) =>
      dispatch(waitForZoomInvite(userId, queueId)),
    updateStatus: (status) =>
      dispatch({ type: VC_UPDATE_STATE, key: "roomStatus", value: status }),
    setVideoIsPrev: () => dispatch({ type: VIDEO_IS_PREVIOUS }),
    newErrors: (errors) => dispatch({ type: NEW_ERRORS, errors: errors }),
    createEvent: (event) => dispatch(createEvent(event)),
    logConnectionInfo: (info) => dispatch(logConnectionInfo(info)),

    handleTwilioError: (error) => dispatch(handleTwilioError(null, error)),
    setParticipants: (participants) => dispatch(setParticipants(participants)),
    setParticipant: (participant, participantSids) => {
      dispatch(setParticipant(participant, participantSids));
    },
    removeParticipant: (
      participant,
      participantSids,
      participants,
      remoteTracks
    ) => {
      dispatch(
        removeParticipant(
          participant,
          participantSids,
          participants,
          remoteTracks
        )
      );
    },
    setRemoteTrack: (
      publication,
      participant,
      participants,
      remoteTrackSids
    ) => {
      dispatch(
        setRemoteTrack(publication, participant, participants, remoteTrackSids)
      );
    },
    removeRemoteTrack: (
      publication,
      participant,
      participants,
      remoteTrackSids
    ) => {
      dispatch(
        removeRemoteTrack(
          publication,
          participant,
          participants,
          remoteTrackSids
        )
      );
    },
    removeRoom: (room, remoteTracks, remoteTrackSids) => {
      dispatch(removeRoom(room, remoteTracks, remoteTrackSids));
    },
    leaveRoom: (room, audioTrack, videoTrack) => {
      dispatch(leaveRoom(room, audioTrack, videoTrack));
    },
    disconnectFromRoom: (queueId, userId, zoomEventId) =>
      dispatch(disconnectFromRoom(queueId, userId, zoomEventId)),
    fetchVrqUsesZoom: () => dispatch(fetchVrqUsesZoom()),
  };
};

const mapStateToProps = (state) => {
  return {
    user: state.common.user,
    profile: state.common.profile,
    clinic: state.common.clinic,
    chatId: state.videoCall.chatId,
    // twilioRoomObj: state.video.twilioRoomObj,
    remoteVideoRef: state.video.remoteVideoRef,
    remoteAudioRef: state.video.remoteAudioRef,

    // videoCall
    callEnded: state.videoCall.callEnded,
    isConnecting: state.videoCall.isConnecting,
    initialLoading: state.videoCall.initialLoading,
    initialLoadingError: state.videoCall.initialLoadingError,
    localAudioTrack: state.videoCall.localAudioTrack,
    localVideoTrack: state.videoCall.localVideoTrack,
    mediaPermissionsAllowed: state.videoCall.mediaPermissionsAllowed,
    participants: state.videoCall.participants,
    participantSids: state.videoCall.participantSids,
    queuePosition: state.videoCall.queuePosition,
    queueId: state.videoCall.queueId,
    remoteTracks: state.videoCall.remoteTracks,
    remoteTrackSids: state.videoCall.remoteTrackSids,
    room: state.videoCall.room,
    roomStatus: state.videoCall.roomStatus,
    token: state.videoCall.token,
    vrqUsesZoom: state.videoCall.vrqUsesZoom,
    zoomEventId: state.videoCall.zoomEventId,
    zoomEvent: state.videoCall.zoomEvent,
    zoomEventParticipant: state.videoCall.zoomEventParticipant,
    videoCall: state.videoCall,
  };
};

class Room extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shouldUseZoom: false,
    };
  }

  componentDidMount = () => {
    document.title = "Video Room | Workit Health";

    let { user, profile, clinic, chatId } = this.props;

    if (!!user) this.logConnectionInfo();
    !user &&
      this.props.fetchUser().then(() => {
        this.logConnectionInfo();
      });
    !profile && this.props.fetchProfile();
    !clinic && this.props.fetchClinic();
    this.props.fetchVrqUsesZoom();

    window.addEventListener("beforeunload", this.componentCleanup);
    console.log("this.props: ", this.props);
    console.log("zoomParticipant: ", this.props.zoomParticipant);
  };

  componentWillUnmount = () => {
    this.props.setVideoIsPrev();

    if (this.state.intervalId) {
      clearInterval(this.state.intervalId);
    }

    if (this.state.timeoutId) {
      clearTimeout(this.state.timeoutId);
    }

    this.componentCleanup();
    window.removeEventListener("beforeunload", this.componentCleanup);
  };

  componentCleanup = () => {
    if (this.props.match.params && this.props.user) {
      this.props.leaveRoom(
        this.props.room,
        this.props.localAudioTrack,
        this.props.localVideoTrack
      );
      this.props.disconnectFromRoom(
        this.props.match.params.id,
        this.props.user.id,
        this.props.zoomEventId
      );
    }
  };

  componentDidUpdate = (prevProps) => {
    if (mobileCheck()) {
      return;
    }
    let { vrqUsesZoom, user } = this.props;
    let { shouldUseZoom } = this.state;

    if (
      vrqUsesZoom &&
      getUrlVars(window.location.href)["drug_testing"] &&
      !this.state.shouldUseZoom
    ) {
      this.setState({ shouldUseZoom: true });
    }

    // if member's position in the queue is >= 1, then check queue position
    // every 15 seconds; if member's position is 0 (next in line), check every
    // 5 seconds
    if (prevProps.queuePosition !== 0 && this.props.queuePosition === 0) {
      if (this.state.intervalId) {
        clearInterval(this.state.intervalId);
      }
      this.initWaitForInvite(5000);
    }

    // on page load, start listening for queue from server
    if (!prevProps.user && this.props.user && !this.state.intervalId) {
      this.initWaitForInvite();
    }

    // as soon as media permissions are allowed, run waitForInvite() once
    if (
      !prevProps.mediaPermissionsAllowed &&
      this.props.mediaPermissionsAllowed &&
      !shouldUseZoom
    ) {
      this.waitForInvite();
    }

    //ready to connect to twilio room
    if (!prevProps.room && this.props.room && !shouldUseZoom) {
      try {
        const playPromise = this.player.play();
        if (playPromise !== undefined) {
          playPromise
            .catch((error) => {
              // could not play ding, which is fine
            })
            .then(() => {
              // ding was played, also fine
            });
        }
      } catch (e) {
        // could not play ding, which is fine
      }
      //now that we've connected, stop listening for invite
      if (this.state.intervalId) {
        clearInterval(this.state.intervalId);
      }
    }

    if (!prevProps.room && this.props.room && !!shouldUseZoom) {
      //now that we've connected, stop listening for invite
      if (this.state.intervalId) {
        clearInterval(this.state.intervalId);
      }
    }
  };

  initWaitForInvite = (milliseconds) => {
    let { vrqUsesZoom } = this.props;
    let { shouldUseZoom } = this.state;
    // subscribe to video room queue
    // default to check every 15 seconds; if milliseconds is a parameter,
    // prioritize that
    const intervalTime = milliseconds || 15000;
    console.log("intervalTime", intervalTime);
    const intervalId = !!shouldUseZoom
      ? setInterval(this.waitingForZoomUrl, intervalTime)
      : setInterval(this.waitForInvite, intervalTime);
    // store intervalId in the state so it can be accessed later:
    this.setState({ intervalId: intervalId });

    this.waitingForZoomUrl(this.props.user?.id, this.props.match.params.id);
  };

  waitForInvite = async () => {
    if (this.props.token) {
      console.log("token already found... waiting to redirect");
      return;
    }
    if (!this.props.mediaPermissionsAllowed) {
      console.log(
        "wait for member to allow media permissions before joining queue"
      );
      return;
    }

    let { user } = this.props;
    this.props.waitForInvite(
      user.id,
      this.props.match.params.id,
      this.props,
      this.onConnected
    );
  };

  // waitForZoomInvite = async () => {
  waitingForZoomUrl = async () => {
    let { vrqUsesZoom, zoomEventId, zoomEventParticipant } = this.props;
    let { shouldUseZoom } = this.state;
    // console.log("zoomEventparticipant: ", zoomEventParticipant)
    if (zoomEventId && !!shouldUseZoom) {
      // console.log("this.props.zoomEventId: ", this.props.zoomEventId)
      // console.log("zoomEventId already found... waiting to redirect");
      console.log("ZE Participant url: ", zoomEventParticipant.meeting_url);
      return;
    }

    let { user } = this.props;
    this.props.waitForZoomInvite(user.id, this.props.match.params.id);
  };

  render() {
    let { user, profile, room, chatId, roomStatus, vrqUsesZoom } = this.props;
    let { shouldUseZoom } = this.state;

    let contentLoaded = user && profile;

    if (user && mobileCheck()) {
      return <MobileRedirect />;
    }

    return (
      <div className="Patient-App-Container room">
        <Prompt
          when={roomStatus === "WAITING"}
          message="Are you sure you want to leave the queue?"
        />

        <Prompt
          when={roomStatus === "CONNECTING" || roomStatus === "CONNECTED"}
          message="Are you sure you want to leave the video room?"
        />

        {user && (
          <div className="room">
            <Nav />

            <div
              id="App-Body-Content"
              className={cn("App-VideoRoom-Room", !contentLoaded && "loading")}
              role="main"
            >
              {!contentLoaded ? (
                <LoadingSpinner />
              ) : (
                <div className="VideoRoom-Container">
                  <div className="Video-Main-Content">
                    {shouldUseZoom ? (
                      <BusyScreen shouldUseZoom={this.state.shouldUseZoom} />
                    ) : (
                      <Video />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        <audio
          ref={(ref) => (this.player = ref)}
          src="https://storage.googleapis.com/workit-client-assets/video/ding.wav"
        />
        <style jsx>{layout}</style>
      </div>
    );
  }

  onConnected = (room) => {
    this.props.setParticipants(room.participants);
    const adminParticipant = room.participants.entries().next().value;
    if (!!adminParticipant) {
      // admin participant already exists
      console.log("adminParticipant", adminParticipant[1].identity);
      const admin = adminParticipant[1].identity.replace(/^(.*?)\admin_/, "");
      const event = {
        description: `Video call started with ${admin}`,
        category: "video_call.started",
      };
      this.props.createEvent(event);
    }

    room.on("disconnected", (room, error) => {
      console.log("room disconnected", room);
      if (error) {
        this.props.handleTwilioError(error);
      }

      this.props.removeRoom(
        room,
        this.props.remoteTracks,
        this.props.remoteTrackSids
      );
    });

    room.on("participantConnected", (participant) => {
      console.log("participantConnected", participant);

      if (this.participantIsPatient(participant)) {
        this.props.setParticipant(participant, this.props.participantSids);
      }
    });

    room.on("participantDisconnected", (participant) => {
      console.log("participantDisconnected", participant);
      this.props.updateStatus("DISCONNECTED");
      this.props.removeParticipant(
        participant,
        this.props.participantSids,
        this.props.participants,
        this.props.remoteTracks
      );
      this.props.leaveRoom(
        room,
        this.props.localAudioTrack,
        this.props.localVideoTrack
      );
    });

    room.on("trackSubscribed", (track, publication, participant) => {
      console.log("trackSubscribed", track);
      this.props.setRemoteTrack(
        publication,
        participant,
        this.props.participants,
        this.props.remoteTrackSids
      );
      this.props.updateStatus("CONNECTED");
    });

    room.on("trackUnsubscribed", (track, publication, participant) => {
      console.log("trackUnsubscribed", track);
      this.props.removeRemoteTrack(
        publication,
        participant,
        this.props.participants,
        this.props.remoteTrackSids
      );
    });
  };

  onZoomConnected = (participant, adminZoomEmail) => {
    // this.props.setParticipants(participant, {adminZoomEmail: adminZoomEmail});
    const adminParticipant = adminZoomEmail;
    if (!!adminParticipant) {
      // admin participant already exists
      // console.log("adminParticipant", adminParticipant);
    }
  };

  setCallFailTimeOut = () => {
    const timeoutId = setTimeout(this.callFailTimeout, 15000);
    // store intervalId in the state so it can be accessed later:
    this.setState({ timeoutId: timeoutId });
  };

  callFailTimeout = () => {
    this.props.clearRoom();
    this.initWaitForInvite();
    this.props.updateStatus("WAITING");
    this.props.newErrors([
      {
        text: "There was a problem connecting to the call, you have been added to the waiting room queue.",
      },
    ]);
  };

  participantIsPatient = (participant) => {
    if (
      (!participant.identity.includes("Nurse_") &&
        !participant.identity.includes("Counselor_") &&
        !participant.identity.includes("-admin")) ||
      participant.identity.includes(":email_") ||
      participant.identity.includes("-user")
    ) {
      return true;
    }

    return false;
  };

  logConnectionInfo = () => {
    let { user } = this.props;
    const queueId = !!this.props.match?.params?.id
      ? parseInt(this.props.match.params.id)
      : null;
    const info = {
      user_id: user?.id,
      video_room_queue_id: queueId,
      platform: "web",
      browser: platform.name,
      browser_version: platform.version,
      browser_desc: platform.description,
      device_os: platform.os.toString(),
      device_os_version: platform.os?.version,
    };
    this.props.logConnectionInfo(info);
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Room);
