import PropTypes from "prop-types";
import React from "react";
import { DateTime } from "luxon";

import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  fetchOrders,
  destroyOrder,
} from "patient_app/api/drugTestOrderActions";
import {
  PROFILE_UPDATE_STATE_LOCAL,
  NEW_ERRORS,
  CLEAR_FIELD_SPECIFIC_ERRORS,
} from "patient_app/constants/actionTypes";

import MainTabBack from "patient_app/views/profiles/partials/MainTabBack";
import LoadingSpinner from "patient_app/components/utils/LoadingSpinner";

import { mobileCheck } from "patient_app/helpers/supported";
import textHelpers from "patient_app/helpers/textHelpers";
import layout from "patient_app/stylesheets/profiles/layout";

const mapDispatchToProps = (dispatch) => {
  return {
    fetchOrders: (orderId, getMore, date) =>
      dispatch(fetchOrders(orderId, getMore, date)),
    destroyOrder: (orderId) => dispatch(destroyOrder(orderId)),
    setProfileState: (obj, value) =>
      dispatch({ type: PROFILE_UPDATE_STATE_LOCAL, obj: obj, value: value }),
    clearFieldSpecificErrors: () =>
      dispatch({ type: CLEAR_FIELD_SPECIFIC_ERRORS }),
    newGeneralErrors: (errors) =>
      dispatch({ type: NEW_ERRORS, errors: errors }),
  };
};

const mapStateToProps = (state) => {
  return {
    allOrders: state.drugTestOrder.allOrders,
    costUrineTest: state.drugTestOrder.costUrineTest,
    costSalivaTest: state.drugTestOrder.costSalivaTest,
    hasMore: state.drugTestOrder.hasMore,
    loading: state.drugTestOrder.loading,
  };
};

class DrugTestOrders extends React.Component {
  profileId = this.props.match.params.id;

  constructor(props) {
    super(props);
    this.state = {
      expanded: [],
    };
  }

  componentDidMount = async () => {
    document.title = "Drug Test Orders | Workit Health";
    this.props.fetchOrders();
    this.props.clearFieldSpecificErrors();
    this.props.newGeneralErrors([]);
  };

  render() {
    let { allOrders, hasMore, loading } = this.props;

    const showOrderButton =
      allOrders &&
      allOrders.filter((obj) =>
        ["DRAFT", "AWAITING_APPROVAL"].includes(obj.status)
      ).length === 0;

    return (
      <div>
        <MainTabBack />

        <div className={`profile-section ${showOrderButton ? "" : "last"}`}>
          <h1>Drug Test Orders</h1>

          {!allOrders && <LoadingSpinner />}

          {allOrders && (
            <div className="profile-list dto">
              <div className="list-item header">
                <div className="row">
                  <p>Description</p>
                  <p className="web-only">Status</p>
                  <div className="col" />
                </div>
              </div>

              {this.renderOrders()}

              {hasMore && (
                <button
                  className="custom-button load-more"
                  onClick={this.handleLoadMore}
                >
                  {loading ? <LoadingSpinner /> : "Load more..."}
                </button>
              )}
            </div>
          )}
        </div>

        {showOrderButton && (
          <div className="profile-section last">
            <button
              className="custom-button submit"
              onClick={() => this.props.history.push("/drug_test_orders/new")}
            >
              Order Drug Tests
            </button>
          </div>
        )}

        <style jsx>{layout}</style>
      </div>
    );
  }

  renderOrders() {
    let { allOrders, costUrineTest, costSalivaTest } = this.props;

    if (allOrders.length === 0) {
      return (
        <div className="list-item none">
          <div className="row">
            <p>No drug test orders.</p>
          </div>
        </div>
      );
    }

    return allOrders.map((order, i) => {
      let approvedAt = "N/A";
      let estimatedDelivery = "N/A";
      if (order.approved_at) {
        approvedAt = DateTime.fromISO(order.approved_at);
        if (order.shipping_method === "regular") {
          estimatedDelivery =
            approvedAt.plus({ days: 7 }).toFormat("LLLL d-") +
            approvedAt.plus({ days: 10 }).toFormat("d, y");
        } else {
          estimatedDelivery =
            approvedAt.plus({ days: 5 }).toFormat("LLLL d-") +
            approvedAt.plus({ days: 7 }).toFormat("d, y");
        }
        approvedAt = approvedAt.toFormat("LL/dd/yy");
      }

      let costUrine = 0,
        costSaliva = 0,
        costShipping = 0;
      if (order.cost) {
        costUrine = order.ten_panel_paid * costUrineTest;
        costSaliva = order.oral_fluid_swab_paid * costSalivaTest;
        costShipping = order.cost / 100 - costUrine - costSaliva;
      }

      const createdAt = DateTime.fromISO(order.created_at).toFormat("LL/dd/yy");
      const showDetails = this.state.expanded.includes(order.id);

      return (
        <div className="list-item" key={i} data-id={order.id}>
          <div className="row">
            <p className="web-only">
              {createdAt} {order.ten_panel_count} Urine Test(s),{" "}
              {order.oral_fluid_swab_count} Saliva Test(s)
            </p>
            <p className="mobile-only">
              {order.ten_panel_count} Urine Test(s),{" "}
              {order.oral_fluid_swab_count} Saliva Test(s)
            </p>

            <p className="web-only">{textHelpers.titleize(order.status)}</p>

            <div className="col align-right">
              {["DRAFT", "AWAITING_APPROVAL"].includes(order.status) ? (
                <div className="col align-right">
                  <Link
                    className="item-action"
                    to={`/drug_test_orders/new?order_id=${order.id}`}
                  >
                    Edit
                  </Link>

                  <button
                    className="custom-button item-action"
                    onClick={() => this.handleDestroy(order.id)}
                  >
                    Remove
                  </button>
                </div>
              ) : (
                <button
                  className="custom-button item-action"
                  onClick={() => this.toggleDetails(order.id)}
                >
                  {showDetails ? "Hide" : "View"}
                </button>
              )}
            </div>
          </div>

          {showDetails && (
            <div className="expanded-details two-column">
              <div className="column">
                <div className="line">
                  <p>Order Placed:</p>
                  <p>{createdAt}</p>
                </div>

                <div className="line">
                  <p>Order Approved:</p>
                  <p>{approvedAt}</p>
                </div>

                <div className="line">
                  <p>Estimated Delivery Date:</p>
                  <p>{estimatedDelivery}</p>
                </div>

                <div className="line">
                  <p>Tracking Number:</p>
                  {order.tracking_url && order.tracking_number && (
                    <p>
                      <a href={order.tracking_url} target="_blank">
                        {order.tracking_number}
                      </a>
                    </p>
                  )}
                  {order.tracking_url && !order.tracking_number && (
                    <p>{order.tracking_number}</p>
                  )}
                  {!order.tracking_url && !order.tracking_number && <p>N/A</p>}
                </div>

                <div className="line">
                  <p>Mailing Address:</p>
                  <p>
                    {order.address} {order.address_two}
                  </p>
                  <p>
                    {order.city}, {order.state} {order.zip_code}
                  </p>
                </div>
              </div>

              <div className="column">
                <div className="line">
                  <p>Amount Charged:</p>
                  <div className="order-item">
                    <p>{order.ten_panel_paid} Urine Tests</p>
                    <p>${costUrine.toFixed(2)}</p>
                  </div>

                  <div className="order-item">
                    <p>{order.oral_fluid_swab_paid} Saliva Tests</p>
                    <p>${costSaliva.toFixed(2)}</p>
                  </div>

                  <div className="order-item">
                    <p>Shipping & Handling</p>
                    <p>${costShipping.toFixed(2)}</p>
                  </div>

                  <div className="order-item line-break" />

                  <div className="order-item total">
                    <p>
                      <span>Total</span>
                    </p>
                    <p>
                      <span>${(order.cost / 100).toFixed(2)}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      );
    });
  }

  toggleDetails = (id) => {
    let arr = this.state.expanded;
    const idx = arr.findIndex((i) => i === id);
    if (idx > -1) {
      arr.splice(idx, 1);
    } else {
      arr.push(id);
    }
    this.setState({ expanded: arr });
  };

  handleDestroy = (id) => {
    if (confirm("Are you sure you want to remove this order?")) {
      this.props.destroyOrder(id);
    }
  };

  handleLoadMore = () => {
    let { allOrders } = this.props;
    const lastCreatedAt = allOrders[allOrders.length - 1].created_at;
    this.props.fetchOrders(null, true, lastCreatedAt);
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DrugTestOrders)
);
