let stripeHelpers = {
  mountStripe(stripeKey, stripeAccountKey) {
    let stripe;
    let elements;
    let cardElement;

    if (stripeAccountKey) {
      stripe = Stripe(stripeKey, { stripeAccount: stripeAccountKey });
    } else {
      stripe = Stripe(stripeKey);
    }

    return stripe;
  },
};

export default stripeHelpers;
