export const flattenMap = (map) => {
  return Array.from(map.values());
};

export const flattenParticipant = ({ sid, identity, tracks }) => {
  return {
    sid,
    identity,
    trackSids: flattenMap(tracks)
      .filter((publication) => publication.track)
      .map((track) => track.trackSid),
  };
};
