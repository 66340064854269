import PropTypes from "prop-types";
import React from "react";

class Upgrade extends React.Component {
  render() {
    return (
      <div className="Upgrade">
        <div className="cta">
          <p>For 24/7 chat support with your personal counselor:</p>
          <a href="/pricing?change_plan=true">Upgrade Your Account</a>
        </div>
      </div>
    );
  }
}

export default Upgrade;
