import PropTypes from "prop-types";
import React from "react";

export default class LoadingSpinner extends React.Component {
  render() {
    return (
      <div className="loading__comp">
        <img
          src="https://storage.googleapis.com/workit-client-assets/chat/loading.gif"
          alt="Loading"
        />
      </div>
    );
  }
}
