import Api from "patient_app/api";
import SessionStore from "patient_app/helpers/SessionStore";

import {
  INSURANCE_REQ_START,
  INSURANCE_REQ_SUCCESS,
  INSURANCE_REQ_FAILURE,
  INSURANCE_LOADING,
  INSURANCE_UPDATE_COVERAGE,
  INSURANCE_UPDATE_ELIGIBLE_COVERAGE,
  INSURANCE_INFO_LOAD,
  INSURANCE_UPDATE_STATE,
  NEW_ERRORS,
  NEW_FORM_ERRORS,
  ONBOARDING_UPDATE_STATE,
  TOGGLE_COVERAGE_CHECK_IN_PROGRESS,
  UPDATE_INSURANCE_DETAILS_LOCAL,
  UPDATE_PATIENT_PAYMENT,
} from "patient_app/constants/actionTypes";

export const checkCoverage = (insurance) => {
  const data = {
    url: `/insurance_details/coverage_check?insurance=${insurance}`,
    data: {
      method: "GET",
    },
  };

  return async (dispatch) => {
    dispatch({ type: INSURANCE_REQ_START });
    dispatch({ type: TOGGLE_COVERAGE_CHECK_IN_PROGRESS, bool: true });
    try {
      const res = await Api.makeRequest(data);
      console.log(res, "insurance detail resp");
      if (res.success) {
        dispatch({ type: INSURANCE_REQ_SUCCESS });
        dispatch({
          type: INSURANCE_UPDATE_COVERAGE,
          coverage: res.coverage_status,
        });
        dispatch({ type: TOGGLE_COVERAGE_CHECK_IN_PROGRESS, bool: false });
        return;
      }

      dispatch({
        type: NEW_ERRORS,
        errors: res.errors,
        errorsClass: "generic",
      });
      dispatch({ type: INSURANCE_REQ_FAILURE });
      dispatch({ type: TOGGLE_COVERAGE_CHECK_IN_PROGRESS, bool: false });
    } catch (e) {
      console.log(e);
    }
  };
};

export const fetchInsuranceDetails = () => {
  const data = {
    url: `/insurance_details/fetch_for_user`,
    data: {
      method: "GET",
    },
  };

  return async (dispatch) => {
    try {
      const res = await Api.makeRequest(data);
      if (res.success) {
        dispatch({
          type: UPDATE_INSURANCE_DETAILS_LOCAL,
          insuranceDetails: res.insurance_details,
        });
        return;
      }

      dispatch({
        type: NEW_ERRORS,
        errors: res.errors,
        errorsClass: "generic",
      });
    } catch (e) {
      console.log(e);
    }
  };
};

export const fetchPatientPayment = () => {
  const data = {
    url: `/insurance_details/get_patient_payment`,
    data: {
      method: "GET",
    },
  };

  return async (dispatch) => {
    try {
      const res = await Api.makeLongRequest(data);
      if (res.success) {
        dispatch({ type: UPDATE_PATIENT_PAYMENT, amount: res.amount });
        return;
      }

      dispatch({ type: UPDATE_PATIENT_PAYMENT, amount: "n/a" });
      dispatch({
        type: NEW_ERRORS,
        errors: res.errors,
        errorsClass: "generic",
      });
    } catch (e) {
      console.log(e);
    }
  };
};

/*
  used for both public and authenticated insurance check (server checks if
  there is a user signed in)

  @params
  [coverageInfo (hash)] required information to run a coverage check
  [resultsCallback (function)] callback function to handle coverage check response
*/
export const onboardingCheckEligibility = (coverageInfo, resultsCallback) => {
  const request = {
    plan_name: coverageInfo.planName,
    group_id: coverageInfo.groupId,
    member_id: coverageInfo.memberId,
    insurance_provider_id: coverageInfo.insuranceProviderId,
    fir_name: coverageInfo.policyholderFirName || coverageInfo.firName,
    las_name: coverageInfo.policyholderLasName || coverageInfo.lasName,
    dob: coverageInfo.policyholderDob || coverageInfo.dob,
    relationship_to_policyholder: coverageInfo.relationshipToPolicyholder,
  };

  let options = {
    state: coverageInfo.state,
    clinic_id: coverageInfo.clinicId,
    zip_code: coverageInfo.zipCode,
    tier: coverageInfo.tier,
    treatment_type: coverageInfo.treatmentType,
    medicare_advantage: coverageInfo.advantage,
    plan_type: coverageInfo.planType,
    // price: "clinicMonthOne",
    // cost_estimates: true,
  };

  // only add these options if they exist
  if (coverageInfo.issueDate) {
    options.issue_date = coverageInfo.issueDate;
  }
  if (coverageInfo.ssn) {
    options.ssn = coverageInfo.ssn;
  }

  let data = {
    url: `/api/v2/registrations/check_insurance_eligibility`,
    data: {
      method: "POST",
      body: {
        ...request,
        options: options,
      },
    },
  };

  return async (dispatch) => {
    try {
      // non-image data is passed to Api.makeLongRequest
      dispatch({ type: TOGGLE_COVERAGE_CHECK_IN_PROGRESS, bool: true });
      const res = await Api.makeLongRequest(data);
      // res.insurance_detail if successful

      // BEGIN HANDLING INSURANCE IMAGE UPLOADS
      if (coverageInfo.images?.length > 0) {
        // Collect the coverageInfo.images into a FormData.
        // Using the images[] array method is necessary. Either the images attribute, or specifying an array in this format is the key part.
        let fileFormData = new FormData();

        coverageInfo.images.forEach((file) => {
          fileFormData.append("images[]", file);
        });

        // a label will eventually be added to each MedDocFile
        const label_front = "insurance_card_front"; // same label used for Mobile app
        const label_back = "insurance_card_back"; // same label used for Mobile app
        fileFormData.append("label_front", label_front);
        fileFormData.append("label_back", label_back);

        //Append an insurance_provider_id. The insurance_detail will be updated
        fileFormData.append(
          "insurance_provider_id",
          coverageInfo.insuranceProviderId
        );

        console.log("res.insurance_detail: ", res.insurance_detail);
        fileFormData.append("insurance_detail_id", res.insurance_detail_id);
        // dedicated endpoint for images upload
        let fileData = {
          url: `/api/v2/registrations/onboarding_file_upload`,
          data: {
            method: "POST",
            formData: fileFormData,
          },
        };

        // images are passed to Api.makeRequestFile
        try {
          const fileRes = await Api.makeRequestFile(fileData);
          console.log("fileRes", fileRes);
          if (fileRes.success) {
            dispatch({
              type: UPDATE_INSURANCE_DETAILS_LOCAL,
              insuranceDetails: fileRes.insurance_details,
            });
          } else {
            dispatch({ type: NEW_ERRORS, errors: fileRes.errors });
          }
        } catch (e) {
          dispatch({
            type: NEW_ERRORS,
            errors: [{ text: "Image upload was not successful." }],
          });
          dispatch({ type: INSURANCE_REQ_FAILURE });
          console.log(e);
        }
      }
      // END HANDLING INSURANCE IMAGE UPLOADS

      console.log(res, "insurance res");

      if (!res) {
        dispatch({
          type: NEW_FORM_ERRORS,
          errors: [
            {
              text: "Your request timed out due to high traffic. Please try again.",
            },
          ],
        });
        window.scrollTo(0, 0);
        dispatch({ type: TOGGLE_COVERAGE_CHECK_IN_PROGRESS, bool: false });
        return;
      }

      if (res.success) {
        if (res.coverage_response.status === "INVALID_INFO") {
          dispatch({
            type: NEW_FORM_ERRORS,
            errors: [
              {
                text:
                  res.coverage_response.custom_error ||
                  "We can't find your information for this policy. Please ensure that you have selected the correct insurance company and provided the correct ID number.",
              },
            ],
          });
          window.scrollTo(0, 0);
        }

        dispatch({
          type: INSURANCE_UPDATE_STATE,
          obj: "coverageResults",
          value: res.coverage_response,
        });

        if (res.coverage_info) {
          dispatch({
            type: INSURANCE_UPDATE_STATE,
            obj: "coverageInfo",
            value: res.coverage_info,
          });
        }

        if (res.program_costs) {
          dispatch({
            type: ONBOARDING_UPDATE_STATE,
            key: "programCosts",
            value: res.program_costs,
          });
        }

        if (res.medicaid_fee_states) {
          dispatch({
            type: ONBOARDING_UPDATE_STATE,
            key: "medicaidFeeStates",
            value: res.medicaid_fee_states,
          });
        }

        dispatch({ type: TOGGLE_COVERAGE_CHECK_IN_PROGRESS, bool: false });

        if (resultsCallback) {
          resultsCallback();
        }
      } else {
        dispatch({
          type: NEW_ERRORS,
          errors: [{ text: res.error }],
          errorsClass: "generic",
        });
        dispatch({ type: TOGGLE_COVERAGE_CHECK_IN_PROGRESS, bool: false });
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const onboardingCheckCardEligibility = (
  coverageInfo,
  resultsCallback
) => {
  const request = {
    plan_name: coverageInfo.planName,
    insurance_provider_id: coverageInfo.insuranceProviderId,
    fir_name: coverageInfo.policyholderFirName || coverageInfo.firName,
    las_name: coverageInfo.policyholderLasName || coverageInfo.lasName,
    dob: coverageInfo.policyholderDob || coverageInfo.dob,
    relationship_to_policyholder: coverageInfo.relationshipToPolicyholder,
  };

  let options = {
    state: coverageInfo.state,
    clinic_id: coverageInfo.clinicId,
    zip_code: coverageInfo.zipCode,
    tier: coverageInfo.tier,
    treatment_type: coverageInfo.treatmentType,
    medicare_advantage: coverageInfo.advantage,
    plan_type: coverageInfo.planType,
    // price: "clinicMonthOne",
    // cost_estimates: true,
  };

  let data = {
    url: `/api/v2/registrations/check_insurance_card_eligibility`,
    data: {
      method: "POST",
      body: {
        ...request,
        options: options,
      },
    },
  };

  return async (dispatch) => {
    try {
      // non-image data is passed to Api.makeLongRequest
      dispatch({ type: TOGGLE_COVERAGE_CHECK_IN_PROGRESS, bool: true });
      // res.insurance_detail if successful
      const res = await Api.makeLongRequest(data);

      // BEGIN HANDLING INSURANCE IMAGE UPLOADS
      if (coverageInfo.images?.length > 0) {
        // Collect the coverageInfo.images into a FormData.
        // Using the images[] array method is necessary. Either the images attribute, or specifying an array in this format is the key part.
        let fileFormData = new FormData();

        coverageInfo.images.forEach((file) => {
          fileFormData.append("images[]", file);
        });

        // a label will eventually be added to each MedDocFile
        const label_front = "insurance_card_front"; // same label used for Mobile app
        const label_back = "insurance_card_back"; // same label used for Mobile app
        fileFormData.append("label_front", label_front);
        fileFormData.append("label_back", label_back);

        //Append an insurance_provider_id. The insurance_detail will be updated
        fileFormData.append(
          "insurance_provider_id",
          coverageInfo.insuranceProviderId
        );

        console.log("res.insurance_detail: ", res.insurance_detail);
        fileFormData.append("insurance_detail_id", res.insurance_detail.id);
        // dedicated endpoint for images upload
        let fileData = {
          url: `/api/v2/registrations/onboarding_file_upload`,
          data: {
            method: "POST",
            formData: fileFormData,
          },
        };

        // images are passed to Api.makeRequestFile
        try {
          const fileRes = await Api.makeRequestFile(fileData);
          console.log("fileRes", fileRes);
          if (fileRes.success) {
            dispatch({
              type: UPDATE_INSURANCE_DETAILS_LOCAL,
              insuranceDetails: fileRes.insurance_details,
            });
          } else {
            dispatch({ type: NEW_ERRORS, errors: fileRes.errors });
          }
        } catch (e) {
          dispatch({
            type: NEW_ERRORS,
            errors: [{ text: "Image upload was not successful." }],
          });
          dispatch({ type: INSURANCE_REQ_FAILURE });
          console.log(e);
        }
      }
      // END HANDLING INSURANCE IMAGE UPLOADS

      if (!res) {
        dispatch({
          type: NEW_FORM_ERRORS,
          errors: [
            {
              text: "Your request timed out due to high traffic. Please try again.",
            },
          ],
        });
        window.scrollTo(0, 0);
        dispatch({ type: TOGGLE_COVERAGE_CHECK_IN_PROGRESS, bool: false });
        return;
      }

      if (res.success) {
        if (res.coverage_response === "INVALID_INFO") {
          dispatch({
            type: NEW_FORM_ERRORS,
            errors: [
              {
                text: "We can't find your information for this policy. Please ensure that you have selected the correct insurance company and provided the correct ID number.",
              },
            ],
          });
          window.scrollTo(0, 0);
        }

        dispatch({ type: TOGGLE_COVERAGE_CHECK_IN_PROGRESS, bool: false });

        if (resultsCallback) {
          resultsCallback(res);
        }
      } else {
        dispatch({
          type: NEW_ERRORS,
          errors: [{ text: res.error }],
          errorsClass: "generic",
        });
        dispatch({ type: TOGGLE_COVERAGE_CHECK_IN_PROGRESS, bool: false });
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const checkOrbitResults = (insuranceDetailId, callback) => {
  let data = {
    url: `/api/v2/registrations/check_orbit_results?insurance_detail_id=${insuranceDetailId}`,
    data: {
      method: "GET",
    },
  };

  return async (dispatch) => {
    const res = await Api.makeLongRequest(data);
    console.log("res orbit");
    callback(res);
  };
};

export const checkEligibility = (coverageInfo, resultsCallback = null) => {
  const data = {
    url: `/insurance_details/check_eligibility`,
    path: "/api/v1",
    data: {
      method: "POST",
      body: {
        request: {
          insurance_provider_id: coverageInfo.insuranceProviderId,
          member_id: coverageInfo.memberId,
          group_id: coverageInfo.groupId,
          dob: coverageInfo.dob,
          fir_name: coverageInfo.firName,
          las_name: coverageInfo.lasName,
          relationship_to_policyholder: coverageInfo.relationshipToPolicyholder,
        },
        options: {
          advantage: coverageInfo.advantage,
          state: coverageInfo.state,
          clinic_id: coverageInfo.clinicId,
          zip_code: coverageInfo.zipCode,
          tier: coverageInfo.tier,
          treatment_type: coverageInfo.treatmentType,
          price: "clinicMonthOne",
          cost_estimates: true,
          billing_type: coverageInfo.billingType,
          plan_type: coverageInfo.planType,
        },
        id: coverageInfo.id,
      },
    },
  };

  return async (dispatch) => {
    dispatch({ type: INSURANCE_LOADING, loading: true });
    dispatch({ type: TOGGLE_COVERAGE_CHECK_IN_PROGRESS, bool: true });
    try {
      const res = await Api.makeRequest(data);
      console.log("checkEligibility res", res);
      if (!res.success) {
        dispatch({
          type: NEW_ERRORS,
          errors: [
            {
              text: "Could not verify insurance details, please make sure your information is correct.",
            },
          ],
        });
      }

      dispatch({
        type: UPDATE_INSURANCE_DETAILS_LOCAL,
        insuranceDetails: res.insurance_details,
      });
      dispatch({
        type: INSURANCE_UPDATE_COVERAGE,
        coverageResponse: res.coverage_response,
      });
      if (resultsCallback) {
        resultsCallback();
      }
      dispatch({ type: INSURANCE_LOADING, loading: false });
      dispatch({ type: TOGGLE_COVERAGE_CHECK_IN_PROGRESS, bool: false });
    } catch (e) {
      console.log(e);
    }
  };
};

export const createInsuranceDetails = (
  insuranceDetail,
  resultsCallback = null
) => {
  // insuranceDetail is a FormData because of the possibility of passing in an image
  const data = {
    url: `/insurance_details`,
    path: "/api/v1",
    data: {
      method: "POST",
      formData: insuranceDetail,
    },
  };

  return async (dispatch) => {
    dispatch({ type: INSURANCE_REQ_START });
    try {
      const res = await Api.makeRequestFile(data);
      console.log("createInsuranceDetail res", res);
      if (res.success) {
        dispatch({
          type: UPDATE_INSURANCE_DETAILS_LOCAL,
          insuranceDetails: res.insurance_details,
        });
        if (resultsCallback) {
          resultsCallback();
        }

        dispatch({ type: INSURANCE_REQ_SUCCESS });
      } else {
        dispatch({ type: NEW_ERRORS, errors: res.errors });
        dispatch({ type: INSURANCE_REQ_FAILURE });
      }
    } catch (e) {
      dispatch({
        type: NEW_ERRORS,
        errors: [{ text: "Something went wrong." }],
      });
      dispatch({ type: INSURANCE_REQ_FAILURE });
      console.log(e);
    }
  };
};

export const updateInsuranceDetails = (
  insuranceDetail,
  resultsCallback = null
) => {
  // insuranceDetail is a FormData because of the possibility of passing in an image
  const insId = insuranceDetail.get("insurance_detail[id]");
  const data = {
    url: `/insurance_details/${insId}`,
    path: "/api/v1",
    data: {
      method: "PATCH",
      formData: insuranceDetail,
    },
  };

  return async (dispatch) => {
    dispatch({ type: INSURANCE_REQ_START });
    try {
      const res = await Api.makeRequestFile(data);
      console.log("updateInsuranceDetails res", res);
      if (res.success) {
        dispatch({
          type: INSURANCE_UPDATE_COVERAGE,
          coverage: res.coverage_status,
        });
        dispatch({
          type: UPDATE_INSURANCE_DETAILS_LOCAL,
          insuranceDetails: res.insurance_details,
        });
        if (resultsCallback) {
          console.log("resultsCallback exists");
          resultsCallback();
        } else {
          dispatch({
            type: NEW_ERRORS,
            errors: [
              {
                status: "info",
                text: "Your insurance information has been updated.",
              },
            ],
          });
        }
        dispatch({ type: INSURANCE_REQ_SUCCESS });
      } else {
        dispatch({ type: NEW_ERRORS, errors: res.errors });
        dispatch({ type: INSURANCE_REQ_FAILURE });
      }
    } catch (e) {
      dispatch({
        type: NEW_ERRORS,
        errors: [{ text: "Something went wrong." }],
      });
      dispatch({ type: INSURANCE_REQ_FAILURE });
      console.log(e);
    }
  };
};

export const destroyInsuranceDetails = (insuranceDetailId) => {
  const data = {
    url: `/insurance_details/${insuranceDetailId}`,
    path: "/api/v1",
    data: { method: "DELETE" },
  };

  return async (dispatch) => {
    dispatch({ type: INSURANCE_LOADING, loading: true });
    try {
      const res = await Api.makeRequest(data);
      if (res.success) {
        dispatch({
          type: UPDATE_INSURANCE_DETAILS_LOCAL,
          insuranceDetails: res.insurance_details,
        });
        dispatch({ type: INSURANCE_LOADING, loading: false });
        return;
      }

      dispatch({ type: NEW_ERRORS, errors: [{ text: res.error }] });
      dispatch({ type: INSURANCE_LOADING, loading: false });
    } catch (e) {
      console.log(e);
    }
  };
};

export const skipInsuranceUpload = (history, from) => {
  const data = {
    url: `/med_docs/skip_insurance_upload?from=${from}`,
    data: { method: "POST" },
  };

  return async (dispatch) => {
    try {
      dispatch({ type: INSURANCE_LOADING, loading: true });
      const res = await Api.makeRequest(data);
      if (res.success) {
        history.push("/appointments");
      } else {
        dispatch({
          type: NEW_ERRORS,
          errors: [{ text: "Something went wrong, please try again." }],
        });
      }
      dispatch({ type: INSURANCE_LOADING, loading: false });
    } catch (e) {
      console.log(e);
      dispatch({ type: INSURANCE_LOADING, loading: false });
    }
  };
};
