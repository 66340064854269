import PropTypes from "prop-types";
import React from "react";
import cn from "classnames";
import { connect } from "react-redux";

import commonAssets from "patient_app/assets";

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {
    fieldSpecificErrors: state.common.fieldSpecificErrors,
  };
};

class TextSearchField extends React.Component {
  static propTypes = {
    // value: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.array),
  };

  static defaultProps = {
    className: "",
  };

  constructor(props) {
    super(props);
    this.state = {
      focus: false,
      showOptions: false,
      optionValue: 0,
    };

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.keyCloseOptions = this.keyCloseOptions.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    document.addEventListener("keydown", this.keyCloseOptions);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    document.removeEventListener("keydown", this.keyCloseOptions);
  }

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ showOptions: false });
    }
  };

  keyCloseOptions = (event) => {
    if (event.keyCode === 27) {
      // escape
      this.input.blur();
      this.setState({ focus: false, optionValue: 0, showOptions: false });
    }
  };

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  onFocus = () => {
    this.setState({ focus: true });
    if (this.props.onFocus) {
      this.props.onFocus();
    }
  };

  onBlur = () => {
    if (!this.state.showOptions) {
      this.setState({ focus: false, optionValue: 0, showOptions: false });
      if (this.props.onBlur) {
        this.props.onBlur();
      }
    } else {
      this.input.focus();
    }
  };

  render() {
    let {
      title,
      value,
      field,
      id,
      options,
      required,
      fieldSpecificErrors,
      uid,
    } = this.props;

    let { focus, showOptions } = this.state;

    let inputClass = focus ? "selected" : "";
    let hasValue = "";
    if (value.length > 0) {
      hasValue = "hasValue";
    }

    let filterredOptions = this.filter(options, value);
    let error = fieldSpecificErrors[uid];

    return (
      <div
        className={cn("field", "text-search", inputClass, hasValue, field)}
        onClick={this.handleClick.bind(this)}
        ref={this.setWrapperRef}
      >
        <label id={id + "-label"} htmlFor={id}>
          {title} {required && <span className="light">(Required)</span>}
        </label>
        <input
          id={id}
          type="text"
          aria-invalid={error ? true : false}
          aria-haspopup="listbox"
          aria-describedby={error && `aria-describedby-${uid}`}
          value={value}
          onChange={(e) => this.props.onUpdateField(e.target.value, field)}
          onKeyDown={this.handleKeyPress}
          name={name}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          onClick={() => this.setState({ showOptions: true })}
          ref={(input) => (this.input = input)}
          autoComplete="off"
          className={cn(error && "has-error")}
        ></input>

        <img
          src={commonAssets.search}
          className="search-icon"
          alt="magnifying glass search icon"
        />

        {showOptions && (
          <div
            className="options"
            role="listbox"
            aria-labelledby={id + "-label"}
            aria-activedescendant={this.htmlify(value)}
          >
            {this.renderOptions(filterredOptions)}
          </div>
        )}

        {error && (
          <p
            role="alert"
            id={`aria-describedby-${uid}`}
            className={cn("field-specific-error", field)}
          >
            Error: {error}
          </p>
        )}
      </div>
    );
  }

  htmlify(str) {
    return str.replace(/\W+/g, "-").toLowerCase();
  }

  renderOptions = (options) => {
    let { id, value } = this.props;
    let { optionValue } = this.state;

    return options.map((option, i) => {
      // copying functionality of select inputs, which cannot tab through options
      if (option[0] !== value) {
        return (
          <button
            onClick={() => this.submit(option)}
            className={cn(
              "custom-button list-option",
              optionValue === i ? "hover" : ""
            )}
            key={i}
            id={this.htmlify(option[0])}
            tabIndex={-1}
            role="option"
            aria-pressed={optionValue === i ? "true" : "false"}
          >
            {option[0]}
          </button>
        );
      } else {
        return (
          <div
            onClick={() => this.submit(option)}
            className={cn(
              "custom-button list-option",
              optionValue === i ? "hover" : ""
            )}
            key={i}
            id={this.htmlify(option[0])}
            tabIndex={-1}
            aria-selected={true} // add this prop for selected option
            role="option"
          >
            {option[0]}
          </div>
        );
      }
    });
  };

  submit = (val) => {
    let { field } = this.props;
    this.props.onUpdateField(val[0], field);
    this.setState({ showOptions: false, focus: false });
  };

  handleClick = () => {
    this.input.focus();
  };

  filter(options, value) {
    let filterred = [];

    if (!value || value.length === 0) {
      if (this.props.showOther) {
        const text = this.props.otherText || "Other";
        return [...options, [text]];
      }

      return options;
    }

    for (let option of options) {
      const x = option[0];
      let y = value;
      y = y.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"); // regex breaks if special characters are typed
      const rgxp = new RegExp(y, "gi");
      const result = x.match(rgxp);
      if (result && result.length) {
        filterred.push(option);
      }
    }

    if (this.props.showOther) {
      const text = this.props.otherText || "Other";
      filterred.push([text]);
    }

    return filterred;
  }

  handleKeyPress = (e) => {
    let { options, value } = this.props;
    let { optionValue, showOptions } = this.state;
    let newValue = -1;
    let element;
    if (e.keyCode !== 9 && e.keyCode !== 16)
      this.setState({ showOptions: true });

    let filterredOptions = this.filter(options, value);

    switch (e.keyCode) {
      case 13:
        let val = filterredOptions[optionValue];
        if (val) {
          this.submit(val);
          this.setState({ optionValue: 0 });
        }
        break;
      case 9: // tab key
        if (showOptions) {
          let valueToSubmit =
            filterredOptions && filterredOptions.length > 0
              ? filterredOptions[0]
              : options[0];
          this.submit(valueToSubmit);
        }
        break;
      case 40: // down arrow
        if (showOptions) {
          newValue =
            optionValue + 1 < options.length
              ? optionValue + 1
              : options.length - 1;
          this.setState({ optionValue: newValue });
        }
        break;
      case 38: // up arrow
        if (showOptions) {
          newValue = optionValue - 1 >= 0 ? optionValue - 1 : 0;
          this.setState({ optionValue: newValue });
        }
        break;
    }

    // scroll element into view if necessary
    try {
      element =
        newValue >= 0 &&
        document.getElementById(this.htmlify(filterredOptions[newValue][0]));
    } catch (e) {
      console.log(e);
      element = undefined;
    }

    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TextSearchField);
