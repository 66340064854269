import Api from "patient_app/api";

import {
  EC_REQ_START,
  EC_REQ_SUCCESS,
  EC_REQ_FAILURE,
  NEW_ERRORS,
  EC_LOAD,
} from "patient_app/constants/actionTypes";

export const createEmergencyContact = (contact) => {
  const data = {
    url: `/emergency_contacts`,
    path: "/api/v2",
    data: {
      method: "POST",
      body: { emergency_contact: contact },
    },
  };

  return async (dispatch) => {
    try {
      dispatch({ type: EC_REQ_START });
      const res = await Api.makeRequest(data);
      if (res.success) {
        dispatch({ type: EC_LOAD, emergencyContacts: res.emergency_contacts });
        dispatch({ type: EC_REQ_SUCCESS });
      } else {
        dispatch({ type: NEW_ERRORS, errors: [{ text: res.error }] });
        dispatch({ type: EC_REQ_FAILURE });
        window.scrollTo(0, 0);
      }
    } catch (e) {
      dispatch({
        type: NEW_ERRORS,
        errors: [{ text: "Something went wrong." }],
      });
      dispatch({ type: EC_REQ_FAILURE });
      window.scrollTo(0, 0);
      console.log(e);
    }
  };
};

export const updateEmergencyContact = (contact) => {
  const data = {
    url: `/emergency_contacts/${contact.id}`,
    path: "/api/v2",
    data: {
      method: "PATCH",
      body: { emergency_contact: contact },
    },
  };

  return async (dispatch) => {
    try {
      dispatch({ type: EC_REQ_START });
      const res = await Api.makeRequest(data);
      if (res.success) {
        dispatch({ type: EC_LOAD, emergencyContacts: res.emergency_contacts });
        dispatch({ type: EC_REQ_SUCCESS });
      } else {
        dispatch({ type: NEW_ERRORS, errors: [{ text: res.message }] });
        dispatch({ type: EC_REQ_FAILURE });
        window.scrollTo(0, 0);
      }
    } catch (e) {
      dispatch({
        type: NEW_ERRORS,
        errors: [{ text: "Something went wrong." }],
      });
      dispatch({ type: EC_REQ_FAILURE });
      window.scrollTo(0, 0);
      console.log(e);
    }
  };
};

export const destroyEmergencyContact = (contact) => {
  const data = {
    url: `/emergency_contacts/${contact.id}`,
    path: "/api/v2",
    data: { method: "DELETE" },
  };

  return async (dispatch) => {
    try {
      dispatch({ type: EC_REQ_START });
      const res = await Api.makeRequest(data);
      if (res.success) {
        dispatch({ type: EC_LOAD, emergencyContacts: res.emergency_contacts });
        dispatch({ type: EC_REQ_SUCCESS });
      } else {
        dispatch({ type: NEW_ERRORS, errors: [{ text: res.message }] });
        dispatch({ type: EC_REQ_FAILURE });
        window.scrollTo(0, 0);
      }
    } catch (e) {
      dispatch({
        type: NEW_ERRORS,
        errors: [{ text: "Something went wrong." }],
      });
      dispatch({ type: EC_REQ_FAILURE });
      window.scrollTo(0, 0);
      console.log(e);
    }
  };
};
