import { DateTime } from "luxon";

let dates = {
  ordinalize(str) {
    const i = parseInt(str);

    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + "st";
    }
    if (j == 2 && k != 12) {
      return i + "nd";
    }
    if (j == 3 && k != 13) {
      return i + "rd";
    }
    return i + "th";
  },

  /*
    get Luxon weekday from day string

    @params
    day (string): day of the week (e.g. "Monday"), case-insensitive

    @returns integer
    Luxon weekday (1 is Monday and 7 is Sunday)
  */
  getLuxonWeekday(day) {
    const days = {
      monday: 1,
      tuesday: 2,
      wednesday: 3,
      thursday: 4,
      friday: 5,
      saturday: 6,
      sunday: 7,
    };

    if (!day) {
      return "";
    }

    return days[day.toLowerCase().trim()];
  },

  /*
    checks if ClinicHoliday is today; returns DateTime if holiday is today,
    and false otherwise

    @params
    holidays (array): ClinicHolidays
  */
  todayIsHoliday(holidays) {
    let isHoliday = false;
    if (!holidays) {
      return false;
    }

    const today = DateTime.local().toFormat("yyyy-LL-dd");
    holidays.forEach((holiday) => {
      const holidayDate = DateTime.fromISO(holiday.date).toFormat("yyyy-LL-dd");
      if (today === holidayDate) {
        isHoliday = DateTime.fromISO(holiday.date);
      }
    });

    return isHoliday;
  },

  /*
    looks at range [today-range, today] inclusive; returns DateTime if
    holiday is in range, and false otherwise

    @params
    holidays (array): ClinicHolidays
    range (integer): number of days out to consider
  */
  upcomingHoliday(holidays, range) {
    let isHoliday = false;
    if (!holidays) {
      return false;
    }

    const today = DateTime.local().endOf("day");
    let datesInRange = [];
    for (var i = 0; i <= range; i++) {
      let date = today.plus({ days: i }).toFormat("yyyy-LL-dd");
      datesInRange.push(date);
    }

    holidays.forEach((holiday) => {
      const holidayDate = DateTime.fromISO(holiday.date).toFormat("yyyy-LL-dd");
      if (datesInRange.includes(holidayDate)) {
        isHoliday = DateTime.fromISO(holiday.date);
      }
    });

    return isHoliday;
  },

  /*
    looks at range [today, today+range] inclusive;

    @params
    holidays (array): ClinicHolidays
    range (integer): number of days out to consider

    @response
    array of DateTimes for holidays in range
  */
  holidaysInRange(holidays, range) {
    let inRange = [];
    if (!holidays) {
      return inRange;
    }

    const today = DateTime.local().endOf("day");
    let datesInRange = [];
    for (var i = 0; i <= range; i++) {
      let date = today.plus({ days: i }).toFormat("yyyy-LL-dd");
      datesInRange.push(date);
    }

    holidays.forEach((holiday) => {
      const holidayDate = DateTime.fromISO(holiday.date).toFormat("yyyy-LL-dd");
      if (datesInRange.includes(holidayDate)) {
        inRange.push(DateTime.fromISO(holiday.date));
      }
    });

    return inRange;
  },
};

export default dates;
