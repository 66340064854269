import PropTypes from "prop-types";
import React from "react";
import { DateTime, Interval } from "luxon";
import Api from "patient_app/api";
import Appsflyer from "patient_app/helpers/Appsflyer";
import SessionStore from "patient_app/helpers/SessionStore";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  OPEN_CHAT,
  APPTS_SHOW_EXPANDED,
  NEW_ERRORS,
} from "patient_app/constants/actionTypes";
import {
  updateBookingCommType,
  userJoinedGroup,
} from "patient_app/api/appointmentActions";

import { getJoinUrl } from "patient_app/api/zoomEventActions";

import { getIconUrl } from "patient_app/helpers/linkHelpers";
import assets from "patient_app/assets";
import permissions from "patient_app/helpers/permissions";
import time from "patient_app/helpers/time";
import appointmentHelpers from "patient_app/helpers/appointmentHelpers";
import modalStyles from "patient_app/stylesheets/appointments/modalStyles";
import { variables } from "patient_app/stylesheets/variables";

import SubmitButton from "patient_app/components/buttons/SubmitButton";

const mapDispatchToProps = (dispatch) => {
  return {
    openChat: (data) => dispatch({ type: OPEN_CHAT, data: data }),
    clearModal: () => dispatch({ type: APPTS_SHOW_EXPANDED, appt: null }),
    updateBookingCommType: (bookingId, commType, isClinic) =>
      dispatch(updateBookingCommType(bookingId, commType, isClinic)),
    userJoinedGroup: (userId, groupId) =>
      dispatch(userJoinedGroup(userId, groupId)),
    onErrors: (errors) => dispatch({ type: NEW_ERRORS, errors: errors }),
  };
};

const mapStateToProps = (state) => {
  return {
    user: state.common.user,
    profile: state.common.profile,
    clinic: state.common.clinic,
    clinicHolidays: state.clinic.clinicHolidays,
    expandedAppt: state.appointments.expandedAppt,
    zoomEvents: state.appointments.zoomEvents,
    zoomParticipants: state.appointments.zoomParticipants,
  };
};

class AppointmentModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      canJoinRoom: false,
      details: null,
      rangeStart: null,
      selected: null,
      showLinkRange: null,
      startAt: null,
      endAt: null,
      updateCommType: false,
    };

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    const { expandedAppt, profile } = this.props;
    const tz = time.getTimeZone(profile);
    let startAt = appointmentHelpers.calculateStartAt(expandedAppt, tz);
    let endAt = appointmentHelpers.calculateEndAt(expandedAppt, tz);

    const rangeStart = startAt.minus({ minutes: 5 });
    const rangeEnd = startAt.plus({
      minutes: 20,
    });
    const showLinkRange = Interval.fromDateTimes(rangeStart, rangeEnd);
    this.setState(
      {
        rangeStart: rangeStart,
        startAt: startAt,
        endAt: endAt,
        showLinkRange: showLinkRange,
      },
      () => {
        this.setCanJoinRoom(expandedAppt, showLinkRange);
      }
    );

    // refresh canJoinRoom every 10 seconds
    this.interval = setInterval(this.setCanJoinRoom, 10000);

    // focus to close button
    this.closeRef.focus();

    // detect if user clicks outside modal
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  setCanJoinRoom = () => {
    if (!this.state.showLinkRange) {
      return;
    }

    const canJoinRoom = this.state.showLinkRange.contains(DateTime.local());
    this.setState({ canJoinRoom: canJoinRoom });

    if (DateTime.local() > this.state.showLinkRange.end) {
      clearInterval(this.interval);
    }
  };

  componentWillUnmount() {
    clearInterval(this.interval);
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.clearModal();
    }
  };

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  render() {
    let { user, clinic, clinicHolidays, expandedAppt } = this.props;
    let {
      canJoinRoom,
      details,
      rangeStart,
      selected,
      showLinkRange,
      startAt,
      endAt,
      updateCommType,
    } = this.state;
    let title = this.getTitle(expandedAppt);
    let hostName = expandedAppt?.host_name;
    let cohostName = expandedAppt?.cohost_name;

    const isInPerson = false;
    //expandedAppt.telehealth === false ||
    //expandedAppt.category.includes("in_person");
    const isChatCategory = ["counselor_chat", "clinic_chat"].includes(
      expandedAppt.category
    );
    const isECCPhoneOrChat =
      expandedAppt.category === "counselor_chat" &&
      ["phone", "chat", null, ""].includes(expandedAppt.comm_type);
    const isClinicPhoneOrChat =
      expandedAppt.category === "clinic_chat" &&
      ["phone", "chat"].includes(expandedAppt.comm_type);
    const isGroup = expandedAppt.category === "recovery_group";
    const isSMA = expandedAppt.category === "sma";
    const earlyText = `Your room will open up 5 minutes prior to your ${
      isGroup && !isSMA ? "support group" : "appointment"
    } time.`;
    const lateText =
      "Your appointment has passed, please message the Admin Team if you need to reschedule.";

    let cancelledDate = null;
    if (expandedAppt.cancelled_at) {
      // check if group is cancelled, and if cancelled date is in the future/today
      const cancelledLuxon = DateTime.fromISO(expandedAppt.cancelled_at, {
        zone: expandedAppt.time_zone,
      }).endOf("day");
      if (cancelledLuxon.ts >= DateTime.local().ts) {
        cancelledDate = cancelledLuxon;
      }
    } else if (startAt && Object.values(clinicHolidays).length > 0) {
      // check if there is a holiday on the day of the group
      const groupDate = startAt.setZone(expandedAppt.time_zone).startOf("day");
      Object.values(clinicHolidays).forEach((holiday) => {
        const holidayLuxon = DateTime.fromISO(holiday.date, {
          zone: expandedAppt.time_zone,
        }).startOf("day");

        if (holidayLuxon.ts === groupDate.ts) {
          cancelledDate = holidayLuxon;
        }
      });
    }

    let prepareLink = "";
    let expectLink = "";
    if (isGroup) {
      prepareLink = "https://www.workithealth.com/group-how-to-prepare/";
      expectLink = "https://www.workithealth.com/group-what-to-expect/";
    } else if (isInPerson) {
      prepareLink =
        "https://www.workithealth.com/in-person-appointment-how-to-prepare/";
      expectLink =
        "https://www.workithealth.com/in-person-appointment-what-to-expect/";
    } else {
      prepareLink = "https://www.workithealth.com/appointment-how-to-prepare/";
      expectLink = "https://www.workithealth.com/appointment-what-to-expect/";
    }

    let isZoomEvent = expandedAppt?.use_zoom_events;

    let color = appointmentHelpers.getApptColor(expandedAppt);

    const canReschedule =
      !permissions.isClinic(user) && startAt && startAt > DateTime.local();

    return (
      <div className="dashboard-modal">
        <div className="modal" ref={this.setWrapperRef}>
          <div className="index can-scroll">
            <div className="header" style={{ backgroundColor: color }}>
              {isSMA || isGroup ? (
                <div />
              ) : (
                <div>
                  <p className="title">{title}</p>
                  <p>{startAt && startAt.toFormat("cccc, LLLL d, t ZZZZ")}</p>

                  {isInPerson && (
                    <a
                      href={expandedAppt.clinic.google_maps_link}
                      target="_blank"
                      className="info-item"
                      style={{ textDecoration: "underline" }}
                    >
                      <p>
                        {expandedAppt.address}, {expandedAppt.address_details}
                      </p>
                    </a>
                  )}

                  {canJoinRoom &&
                    !isInPerson &&
                    !isECCPhoneOrChat &&
                    !isClinicPhoneOrChat && (
                      <button
                        className="custom-button video-link"
                        onClick={this.handleEnterRoom}
                      >
                        Enter your Video Room
                      </button>
                    )}

                  {canJoinRoom && (isECCPhoneOrChat || isClinicPhoneOrChat) && (
                    <a
                      href="#"
                      className="video-link"
                      onClick={this.handleMessageCoach}
                    >
                      Let your counselor know you are ready
                    </a>
                  )}

                  {isInPerson && (
                    <p
                      style={{
                        fontSize: "0.8rem",
                        fontFamily: variables.objMed,
                      }}
                    >
                      Please make sure to arrive at the clinic 10 minutes prior
                      to your scheduled appointment time.
                    </p>
                  )}

                  {isChatCategory &&
                    (!expandedAppt.comm_type || updateCommType) && (
                      <div className="select-comm-type">
                        <p>How would you like to meet with your counselor?</p>
                        <div className="comm-options">
                          <button
                            value="video"
                            className="comm-type"
                            onClick={this.handleSelectComm}
                          >
                            Video
                          </button>
                          <button
                            value="phone"
                            className="comm-type"
                            onClick={this.handleSelectComm}
                          >
                            Phone
                          </button>
                          <button
                            value="chat"
                            className="comm-type"
                            onClick={this.handleSelectComm}
                          >
                            Chat
                          </button>
                        </div>
                      </div>
                    )}

                  {isChatCategory &&
                    expandedAppt.comm_type &&
                    !updateCommType && (
                      <div className="select-comm-type">
                        <p>
                          Your appointment will be over {expandedAppt.comm_type}
                          .
                        </p>
                        <button
                          className="regular-link"
                          onClick={() =>
                            this.setState({ updateCommType: true })
                          }
                        >
                          Change communication method
                        </button>
                      </div>
                    )}

                  {isZoomEvent && (
                    <div className="select-comm-type">
                      <p>
                        Your public display name for this event is{" "}
                        <b>{user?.public_display_name}</b>. This display name
                        will be visible to other members of the group.
                        <a
                          target="_blank"
                          className="link"
                          href={`/profiles/${this.state.profile?.id}/account`}
                        >
                          Change display name
                        </a>
                      </p>
                    </div>
                  )}
                </div>
              )}

              <button
                ref={(ref) => {
                  this.closeRef = ref;
                }}
                className="custom-button close"
                onClick={this.props.clearModal}
              >
                <img src={assets.closeWhite} />
              </button>

              {(isSMA || isGroup) && (
                <img
                  className="cover-photo"
                  src={expandedAppt?.file?.url || assets.darkGreenGradient}
                />
              )}
            </div>

            {!details && (
              <div>
                {!canJoinRoom &&
                  !isInPerson &&
                  !isECCPhoneOrChat &&
                  !isGroup && (
                    <div className="alert-box">
                      <p>
                        {DateTime.local() < rangeStart ? earlyText : lateText}
                      </p>
                    </div>
                  )}

                {isGroup && cancelledDate && (
                  <div className="alert-box">
                    <p>
                      This group has been cancelled for the week of{" "}
                      {cancelledDate.toFormat("LLLL d, yyyy")}. If you would
                      like to attend another group, please message your care
                      team.
                    </p>
                  </div>
                )}

                {isGroup && !cancelledDate && (
                  <div className="alert-box">
                    <p>{earlyText}</p>
                  </div>
                )}

                {/*isZoomEvent && (
                  <div className="alert-box red">
                    <p>
                      You will not be able to join your group if you do not have
                      the Zoom app downloaded on your device.{" "}
                      <a href="https://zoom.us/download" target="_blank">
                        Click here to download Zoom.
                      </a>
                    </p>
                  </div>
                )*/}

                {isInPerson && (
                  <div className="alert-box">
                    <p>
                      This appointment is in-person
                      {expandedAppt.clinic
                        ? ` at our ${expandedAppt.clinic.city}, ${expandedAppt.clinic.state} Clinic`
                        : ""}
                      .
                    </p>
                  </div>
                )}

                {isInPerson && expandedAppt.clinic?.google_maps_link && (
                  <a
                    href={expandedAppt.clinic.google_maps_link}
                    target="_blank"
                    className="info-item"
                  >
                    <p>Clinic location</p>
                    <img src={getIconUrl("chevron-right", "dark-blue")} />
                  </a>
                )}

                {isSMA || isGroup ? (
                  <div className="content">
                    <p className="title">{title}</p>
                    {!!startAt && (
                      <p className="subtitle">
                        {startAt.toFormat("LLL d  |  h:mm")}–
                        {!!endAt
                          ? endAt.toFormat("h:mm a ZZZZ")
                          : startAt.toFormat("ZZZZ")}
                      </p>
                    )}

                    {expandedAppt.description_member_facing?.length && (
                      <div className="section">
                        <p className="description">
                          {expandedAppt.description_member_facing}
                        </p>
                      </div>
                    )}
                    {(!!hostName ||
                      !!cohostName ||
                      !!expandedAppt?.member_ids) && (
                      <div className="section">
                        {!!hostName && (
                          <div className="action-row">
                            {!!expandedAppt.host_image ? (
                              <img
                                className="avatar"
                                src={expandedAppt.host_image}
                              />
                            ) : (
                              <div
                                className="badge"
                                style={{
                                  backgroundColor:
                                    variables.greenSplashBackground,
                                }}
                              >
                                <img src={assets.userIcon} />
                              </div>
                            )}
                            <p
                              className="description"
                              style={{ fontFamily: variables.objMed }}
                            >
                              {hostName}
                            </p>
                          </div>
                        )}
                        {!!cohostName && (
                          <div className="action-row">
                            {!!expandedAppt.cohost_image ? (
                              <img
                                className="avatar"
                                src={expandedAppt.cohost_image}
                              />
                            ) : (
                              <div
                                className="badge"
                                style={{
                                  backgroundColor:
                                    variables.greenSplashBackground,
                                }}
                              >
                                <img src={assets.userIcon} />
                              </div>
                            )}
                            <p
                              className="description"
                              style={{ fontFamily: variables.objMed }}
                            >
                              {cohostName}
                            </p>
                          </div>
                        )}
                        {!!expandedAppt?.member_ids && (
                          <div className="action-row">
                            <div
                              className="badge"
                              style={{
                                backgroundColor:
                                  variables.greenSplashForeground,
                              }}
                            >
                              <img src={assets.userIcon} />
                            </div>
                            <p className="description">
                              {expandedAppt.member_ids.length} Member
                              {expandedAppt.member_ids.length == 1
                                ? ""
                                : "s"}{" "}
                              {isSMA ? "scheduled" : "enrolled"}
                            </p>
                          </div>
                        )}
                      </div>
                    )}

                    {!!expandedAppt?.treatment_types && (
                      <div className="section">
                        <p className="description">
                          <span style={{ fontFamily: variables.objMed }}>
                            This group is open to members enrolled in treatment
                            for:
                          </span>
                          <div className="tag-wrapper">
                            {appointmentHelpers
                              .uniqueTreatmentTypes(
                                expandedAppt.treatment_types
                              )
                              .map((tt, i) => (
                                <div className="tag">
                                  {appointmentHelpers.formatTreatmentType(tt)}
                                </div>
                              ))}
                          </div>
                        </p>
                      </div>
                    )}

                    {isZoomEvent && (
                      <div className="section">
                        <p className="description">
                          Your public display name for this event is{" "}
                          <b>{user?.public_display_name}</b>. This display name
                          will be visible to other members of the group. If you
                          would like to change your display name,{" "}
                          <a
                            target="_blank"
                            className="link"
                            href={`/profiles/${this.state.profile?.id}/account`}
                          >
                            click here.
                          </a>
                        </p>
                      </div>
                    )}

                    {canJoinRoom && (
                      <div className="section">
                        <SubmitButton
                          text={"Join appointment"}
                          // loading={loading}
                          onSubmit={this.handleEnterRoom}
                        />
                      </div>
                    )}
                    {canJoinRoom && (
                      <div className="section">
                        <a href="tel:855-659-7734">
                          <SubmitButton
                            text={
                              "Trouble joining? Call us at 855-659-7734, option 1"
                            }
                            classNames={["inverted"]}
                            onSubmit={() => null}
                          />
                        </a>
                      </div>
                    )}
                    <div className="section">
                      <p className="description">
                        Questions about this appointment?{" "}
                        <a href="#" onClick={this.handleMessageAdmin}>
                          Chat with us
                        </a>
                      </p>
                    </div>
                  </div>
                ) : (
                  <>
                    {!isInPerson && (
                      <a
                        href={prepareLink}
                        target="_blank"
                        className="info-item"
                      >
                        <p>How to prepare</p>
                        <img src={getIconUrl("chevron-right", "dark-blue")} />
                      </a>
                    )}

                    {!isInPerson && (
                      <a
                        href={expectLink}
                        target="_blank"
                        className="info-item"
                      >
                        <p>What to expect</p>
                        <img src={getIconUrl("chevron-right", "dark-blue")} />
                      </a>
                    )}

                    {!isInPerson && expandedAppt.clinic?.state === "OH" && (
                      <a
                        href="https://www.workithealth.com/ohio-in-person/"
                        target="_blank"
                        className="info-item"
                      >
                        <p>Learn more about this policy</p>
                        <img src={getIconUrl("chevron-right", "dark-blue")} />
                      </a>
                    )}

                    <button
                      className="custom-button info-item"
                      onClick={this.handleMessageAdmin}
                    >
                      <p>Questions? Message support</p>
                      <img src={getIconUrl("chevron-right", "dark-blue")} />
                    </button>

                    {canReschedule && (
                      <button
                        className="custom-button info-item"
                        onClick={this.handleReschedule}
                      >
                        <p>Reschedule Your Appointment</p>
                        <img src={getIconUrl("chevron-right", "dark-blue")} />
                      </button>
                    )}
                  </>
                )}
              </div>
            )}
          </div>

          <style jsx>{modalStyles}</style>
        </div>
      </div>
    );
  }

  getTitle(appt) {
    let title = appt.title;
    let suffix = "";

    if (appt.title_member_facing) {
      title = appt.title_member_facing;
    }

    if (!appt.admin) {
      return title;
    }

    suffix = appt.admin.suffix && ", " + appt.admin.suffix;

    const provider = ["welcome_intake", "workit_welcome_intake"].includes(
      appt.category
    )
      ? appt.admin?.fir_name
      : appt.admin?.name + suffix;

    title += ` Appointment with ${provider}`;

    return title;
  }

  handleMessageAdmin = (e) => {
    if (e) {
      e.preventDefault();
    }
    const { expandedAppt, profile } = this.props;
    const { startAt } = this.state;
    let timeZone = time.getTimeZone(profile);
    // let message = `Hi, I have a question about my appointment.`;
    // if (startAt) {
    //   message = `Hi, I have a question about my appointment on ${startAt.toFormat(
    //     "LLLL d"
    //   )}.`;
    // }
    this.props.openChat({
      view: "index",
      // prepopulatedMessage: message,
      topic: "ADMIN",
    });
    this.props.clearModal();
  };

  handleMessageCoach = (e) => {
    if (e) {
      e.preventDefault();
    }
    let message = `Hi, I'm ready for my appointment.`;
    if (this.props.expandedAppt.comm_type) {
      message = `Hi, I'm ready for my appointment over ${this.props.expandedAppt.comm_type}.`;
    }
    this.props.openChat({
      view: "index",
      prepopulatedMessage: message,
      topic: "COACH",
    });
    this.props.clearModal();
  };

  handleReschedule = () => {
    const scheduleLink = `/onboarding/schedule?referral=dashboard&event_id=${this.props.expandedAppt.id}`;
    this.props.clearModal();
    this.props.history.push(scheduleLink);
  };

  handleSelectComm = (e) => {
    this.props.updateBookingCommType(
      this.props.expandedAppt.id,
      e.target.value,
      true
    );
    this.setState({ updateCommType: false });
  };

  handleCall = (e) => {
    if (e) {
      e.preventDefault();
    }
    // href="tel:855-659-7734"
  };

  handleEnterRoom = async () => {
    let { expandedAppt, user, zoomEvents, zoomParticipants } = this.props;

    let linkUrl = "#";

    if (!!expandedAppt.zoom_participant_id) {
      const updatedPart = await getJoinUrl(expandedAppt.zoom_participant_id);

      if (!updatedPart.success) {
        this.props.onErrors([{ text: updatedPart.error }]);
        return;
      }

      linkUrl = updatedPart?.zoom_participant?.meeting_url;
    } else if (expandedAppt.use_zoom_events) {
      // prefer a fresh zoom_participant.meeting_url
      if (!!expandedAppt.item?.zoom_participant_id) {
        const part = zoomParticipants.find(
          (p) => p.id === expandedAppt.item.zoom_participant_id
        );
        const updatedPart = await getJoinUrl(part.id);

        if (!updatedPart.success) {
          this.props.onErrors([{ text: updatedPart.error }]);
          return;
        }

        linkUrl =
          updatedPart?.zoom_participant?.meeting_url || part?.meeting_url;
      } else if (!!expandedAppt.zoom_id) {
        // second choice is a stale zoom_participant.meeting_url
        const part = zoomParticipants.filter(
          (p) => p.zoom_event_id === expandedAppt.id
        )[0];
        linkUrl = part?.meeting_url;
      } else if (!!expandedAppt.url?.length) {
        // fallback is the propagated appt.url
        linkUrl = expandedAppt.url.startsWith("http")
          ? expandedAppt.url
          : "https://" + expandedAppt.url;
      }
    } else if (
      !expandedAppt.use_zoom_events &&
      expandedAppt.category == "recovery_group"
    ) {
      // legacy recovery_groups (not using zoom)
      linkUrl = expandedAppt.url.startsWith("http")
        ? expandedAppt.url
        : "https://" + expandedAppt.url;
      this.props.userJoinedGroup(user.id, expandedAppt.id);
    } else if (expandedAppt.video_queue_id) {
      // one-on-one appointments link directly to a twilio video_queue
      this.maybeAddAppsflyerEvent();
      linkUrl = `${window.location.origin}/users/sign_in?redirect=waiting_room&queue_id=${expandedAppt.video_queue_id}`;
    }

    window.open(linkUrl, "_blank");
  };

  maybeAddAppsflyerEvent = () => {
    let { user, profile, expandedAppt } = this.props;

    // check if appsflyer event was already created for this appointment
    let afAppointmentEvent = SessionStore.get("af_appointment_event");
    if (!afAppointmentEvent || !afAppointmentEvent.includes(expandedAppt.id)) {
      Appsflyer.event("Appointment_Attended", user, 2);
      Appsflyer.event("AppointmentAttended");

      const category = expandedAppt.category;

      const isInductionAppt =
        category?.includes("induction") ||
        category === "new_evaluation_medical";
      if (isInductionAppt) {
        Appsflyer.event("MedicalEvaluationAttended");
        if (profile?.billing_type?.includes("commercial")) {
          Appsflyer.event("MedicalEvaluationAttendedCommercial");
        }
      }

      if (category.includes("induction") || category.includes("intake")) {
        Appsflyer.event("FirstAppointmentAttended");
        Appsflyer.event("Induction_Attended", user, 2);
        Appsflyer.event("inductionattended", null, 2); // for revenue
      }

      // add appointment id to cookies
      if (!afAppointmentEvent) {
        afAppointmentEvent = [expandedAppt.id];
      } else {
        afAppointmentEvent.push(expandedAppt.id);
      }
      SessionStore.set("af_appointment_event", afAppointmentEvent);
    }
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AppointmentModal)
);
