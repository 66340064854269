import PropTypes from "prop-types";
import React from "react";
import cn from "classnames";

class SubmitButton extends React.Component {
  static propTypes = {
    //text that appears to user in button
    text: PropTypes.string,
    //loading indicates button status
    loading: PropTypes.bool,
    onSubmit: PropTypes.func,
  };

  render() {
    let { text, loading, classNames, id } = this.props;

    return (
      <button
        id={id ? id : ""}
        type="button"
        onClick={this.props.onSubmit}
        className={cn(loading ? "disabled" : "", "submit-button", classNames)}
      >
        {text}
      </button>
    );
  }
}

export default SubmitButton;
