import PropTypes from "prop-types";
import React from "react";
import cn from "classnames";

import { Router, Route, Switch, Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  SESSION_PASSWORD_UPDATE,
  NEW_FORM_ERRORS,
  NEW_FIELD_SPECIFIC_ERROR,
  CLEAR_FIELD_SPECIFIC_ERRORS,
} from "patient_app/constants/actionTypes";

import { canSignIn } from "patient_app/api/sessionActions";

import FormErrors from "patient_app/components/utils/FormErrors";

import onboardingAssets from "patient_app/assets/onboarding";

import MainLayout from "patient_app/components/layouts/MainLayout";
import ContentMain from "patient_app/components/forms/ContentMain";
import ContentBox from "patient_app/components/forms/ContentBox";
import Errors from "patient_app/components/utils/Errors";
import PasswordField from "patient_app/components/input_fields/PasswordField";
import SubmitButton from "patient_app/components/buttons/SubmitButton";

import layout from "patient_app/stylesheets/onboarding/layout";

const mapDispatchToProps = (dispatch) => {
  return {
    canSignIn: (email, uniquePhone, password, form) =>
      dispatch(canSignIn(email, uniquePhone, password, form)),
    updatePassword: (password) =>
      dispatch({ type: SESSION_PASSWORD_UPDATE, password: password }),
    onErrors: (errors) => dispatch({ type: NEW_FORM_ERRORS, errors: errors }),
    onError: (field, error) =>
      dispatch({ type: NEW_FIELD_SPECIFIC_ERROR, field: field, error: error }),
    clearFormSpecificErrors: () =>
      dispatch({ type: CLEAR_FIELD_SPECIFIC_ERRORS }),
  };
};

const mapStateToProps = (state) => {
  return {
    loading: state.common.loading,
    errors: state.common.errors,
    email: state.session.email,
    uniquePhone: state.session.uniquePhone,
    password: state.session.password,
  };
};

class Password extends React.Component {
  componentDidMount = () => {
    document.title = "Sign In | Workit Health";

    document.title = "Sign In | Workit Health";
    if (!this.props.email && !this.props.uniquePhone) {
      this.props.history.push("/signin");
    }

    //clear out password when this page is navigated to
    this.props.updatePassword("");
  };

  render() {
    let { loading, errors, password, email, uniquePhone } = this.props;

    return (
      <MainLayout className="Page-Session-Password">
        <ContentMain>
          <ContentBox className="onboarding new_session">
            <div className="inputs social">
              <h1>Sign In</h1>
              <FormErrors />

              <form
                action="/signin"
                acceptCharset="UTF-8"
                method="post"
                ref={(form) => (this.form = form)}
                onKeyDown={this.onFormKeyPress}
              >
                <input
                  type="hidden"
                  name="authenticity_token"
                  value={this.getCsrf()}
                />
                {email && email.length > 0 && (
                  <input type="hidden" name="user[email]" value={email} />
                )}

                <PasswordField
                  field={"password"}
                  title={"Your Password"}
                  value={password}
                  id={"password"}
                  uid="password"
                  name="user[password]"
                  required={true}
                  onUpdateField={this.handleUpdateField}
                  onKeyPress={this.onKeyPress}
                />

                <SubmitButton
                  text={"Next"}
                  loading={loading}
                  onSubmit={this.handleSubmit}
                />

                <p className="sign-in-text mobile-only">
                  <Link to="/password/new" className="forgot-password web-only">
                    Forgot password?
                  </Link>
                </p>
                <p className="sign-in-text mobile-only">
                  Already scheduled an appointment?{" "}
                  <Link to="/activate">Activate</Link>
                </p>

                <div className="under-content-box">
                  <div className="text">
                    <p className="sign-in-text web-only">
                      <Link
                        to="/password/new"
                        className="forgot-password web-only"
                      >
                        Forgot password?
                      </Link>
                    </p>
                    <p className="sign-in-text web-only">
                      Already scheduled an appointment?{" "}
                      <Link to="/activate">Activate</Link>
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </ContentBox>
        </ContentMain>

        <style jsx>{layout}</style>
      </MainLayout>
    );
  }

  getCsrf() {
    const metas = document.getElementsByTagName("meta");

    for (let i = 0; i < metas.length; i++) {
      if (metas[i].getAttribute("name") === "csrf-token") {
        return metas[i].getAttribute("content");
      }
    }

    return "";
  }

  onKeyPress = (e) => {
    if (e.keyCode == 13) {
      this.handleSubmit();
    }
  };

  onFormKeyPress = (e) => {
    //prevent form submission so it can be handled
    //via json
    if (e.keyCode == 13) {
      e.preventDefault();
    }
  };

  handleUpdateField = (value, field) => {
    this.props.updatePassword(value);
  };

  handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    let { password, email, uniquePhone } = this.props;
    this.props.onErrors([]);
    this.props.clearFormSpecificErrors();

    let errors = [];
    if (!password || password.length < 8) {
      this.props.onErrors([{ text: "Please correct the issues listed below" }]);
      this.props.onError("password", "Password must be at least 8 characters.");
      window.scrollTo(0, 0);
      return;
    }

    this.props.canSignIn(email, uniquePhone, password, this.form);
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Password)
);
