import {
  APPTS_UPDATE_CONTENT_LOADED,
  APPTS_LOAD,
  APPTS_LOAD_MORE,
  APPTS_LOAD_RECOVERY_GROUP,
  APPTS_LOAD_NEXT_COURSE,
  APPTS_TOGGLE_BOOKING_LOADING,
  APPTS_BOOKING_LOAD,
  APPTS_SHOW_EXPANDED,
  APPTS_DRUG_TEST_DETAILS,
  APPTS_ACTION_ITEMS,
  APPTS_USER_RECOVERY_GROUP,
  ZOOM_EVENTS_LOAD,
  ZOOM_PARTICIPANTS_LOAD,
} from "patient_app/constants/actionTypes";

const initialState = {
  actionItems: {}, // { current: [PendingItems], completed: [PendingItems] }
  zoomEvents: null,
  zoomParticipants: null,
  appointments: null,
  bookingLoading: false,
  completedAppointments: null,
  contentLoaded: false,
  drugTestDetails: null,
  expandedAppt: null,
  getMore: false,
  gtcId: null,
  mymId: null,
  nextPage: 1,
  recoveryGroup: null,
  userRecoveryGroups: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case APPTS_UPDATE_CONTENT_LOADED:
      return {
        ...state,
        contentLoaded: action.contentLoaded,
      };
    case APPTS_LOAD:
      if (action.completed) {
        return {
          ...state,
          completedAppointments: action.completedAppointments,
        };
      } else {
        return {
          ...state,
          appointments: action.appointments,
          getMore: action.getMore,
        };
      }
    case APPTS_LOAD_MORE:
      return {
        ...state,
        appointments: [...state.appointments].concat(action.appointments),
        getMore: action.getMore,
        nextPage: action.nextPage,
      };
    case APPTS_LOAD_RECOVERY_GROUP:
      return {
        ...state,
        recoveryGroup: action.recoveryGroup,
      };
    case APPTS_LOAD_NEXT_COURSE:
      return {
        ...state,
        nextCourse: action.nextCourse,
      };
    case APPTS_TOGGLE_BOOKING_LOADING:
      return {
        ...state,
        bookingLoading: action.bookingLoading,
      };
    case APPTS_BOOKING_LOAD:
      return {
        ...state,
        booking: action.booking,
      };
    case APPTS_SHOW_EXPANDED:
      return {
        ...state,
        expandedAppt: action.appt,
      };
    case APPTS_DRUG_TEST_DETAILS:
      return {
        ...state,
        drugTestDetails: action.view,
      };
    case APPTS_ACTION_ITEMS:
      return {
        ...state,
        actionItems: {
          ...state.actionItems,
          [action.key]: action.items,
        },
        gtcId: action.gtcId || state.gtcId,
        mymId: action.mymId || state.mymId,
      };
    case APPTS_USER_RECOVERY_GROUP:
      return {
        ...state,
        userRecoveryGroups: action.userRecoveryGroups,
      };
    case ZOOM_EVENTS_LOAD:
      return {
        ...state,
        zoomEvents: action.zoomEvents,
      };
    case ZOOM_PARTICIPANTS_LOAD:
      return {
        ...state,
        zoomParticipants: action.zoomParticipants,
      };
    default:
      return state;
  }
};
