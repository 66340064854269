import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { getIconUrl } from "patient_app/helpers/linkHelpers";
import dashboardBlockStyles from "patient_app/stylesheets/appointments/dashboardBlockStyles";

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {
    nextCourse: state.appointments.nextCourse,
  };
};

class NextCourse extends React.Component {
  render() {
    let { nextCourse } = this.props;

    return (
      <div className="dashboard-block">
        <Link to="/courses" className="block-item no-border">
          <div className="details">
            <p>Go to Courses</p>
          </div>

          <img src={getIconUrl("chevron-right", "dark-blue")} />
        </Link>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NextCourse);
