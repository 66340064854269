import {
  DTO_PAGE_LOAD,
  DTO_START,
  DTO_SUCCESS,
  DTO_FAILURE,
  DTO_LOAD_ALL,
  DTO_LOAD_MORE,
  DTO_LOAD_ONE,
  DTO_SUGGESTED_ADDRESS,
  DTO_UPDATE_STATE,
  DTO_CLEAR_STATE,
} from "patient_app/constants/actionTypes";

const initialState = {
  loading: false,
  success: false,
  continue: false,
  costUrineTest: 1.72,
  costSalivaTest: 5.95,
  hasMore: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DTO_PAGE_LOAD:
      return {
        ...state,
        loading: false,
        success: false,
        continue: false,
      };
    case DTO_START:
      return {
        ...state,
        loading: true,
        success: false,
        continue: false,
      };
    case DTO_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        continue: action.continue,
      };
    case DTO_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
      };
    case DTO_LOAD_ALL:
      return {
        ...state,
        allOrders: action.orders,
        hasMore: action.hasMore,
      };
    case DTO_LOAD_MORE:
      return {
        ...state,
        allOrders: action.orders.concat(state.allOrders),
        hasMore: action.hasMore,
      };
    case DTO_LOAD_ONE:
      return {
        ...state,
        newOrder: action.order,
      };
    case DTO_UPDATE_STATE:
      return {
        ...state,
        [action.key]: action.value,
      };
    case DTO_CLEAR_STATE:
      return initialState;
    default:
      return state;
  }
};
