import PropTypes from "prop-types";
import React from "react";
import cn from "classnames";
import LoadingSpinner from "patient_app/components/utils/LoadingSpinner";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { updateProfilePic } from "patient_app/api/profileActions";
import {
  NEW_FIELD_SPECIFIC_ERROR,
  CLEAR_FIELD_SPECIFIC_ERRORS,
} from "patient_app/constants/actionTypes";

import assets from "patient_app/assets";

const mapDispatchToProps = (dispatch) => {
  return {
    updateProfilePic: (profile, image) => {
      dispatch(updateProfilePic(profile, image));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    loading: state.profile.updatingPicture,
    profile: state.common.profile,
  };
};

class ProfilePic extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { loading, profile } = this.props;

    let imageUrl = profile && profile.image;
    if (imageUrl?.url) {
      imageUrl = imageUrl.url;
    }

    return (
      <div className="profile-picture-container">
        {imageUrl && !loading && (
          <button
            className="custom-button change-picture"
            onClick={() => this.fileInput.click()}
            aria-label="Click to edit profile picture"
          >
            <img src={assets.edit} aria-label="edit icon" />
          </button>
        )}

        <input
          className="picture-input"
          type="file"
          ref={(fileInput) => (this.fileInput = fileInput)}
          onChange={(e) => this.editProfilePic(e)}
        />

        {loading && (
          <div className="profile-picture">
            <LoadingSpinner />
          </div>
        )}

        {!loading && imageUrl && (
          <img
            src={imageUrl}
            className="profile-picture"
            alt="profile picture"
          />
        )}

        {!loading && !imageUrl && (
          <div className="profile-picture" aria-label="No profile picture" />
        )}
      </div>
    );
  }

  editProfilePic(e) {
    let image = e.target.files && e.target.files[0];
    if (image) this.props.updateProfilePic(this.props.profile, image);
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProfilePic)
);
