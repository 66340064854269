import {
  PENDING_ITEM_START,
  PENDING_ITEM_SUCCESS,
  PENDING_ITEM_FAILURE,
  PENDING_ITEM_LOAD,
  PENDING_ITEM_TOGGLE_SIGNED,
  PENDING_ITEM_UPDATE_SIGNATURE,
  PENDING_ITEM_START_TALKING,
} from "patient_app/constants/actionTypes";

const initialState = {
  userSigned: false,
  success: false,
  pendingItems: {}, // { PendingItem id: PendingItem }
  item: null, // current item being viewed (in ItemApproval or PendingItemUpload)
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PENDING_ITEM_START:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case PENDING_ITEM_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
      };
    case PENDING_ITEM_FAILURE:
      return {
        ...state,
        success: false,
        loading: false,
      };
    case PENDING_ITEM_LOAD:
      return {
        ...state,
        item: action.item,
        pendingItems: {
          ...state.pendingItems,
          [action.item.id]: action.item,
        },
        clinicFile: action.clinicFile,
        success: false,
      };
    case PENDING_ITEM_TOGGLE_SIGNED:
      return {
        ...state,
        userSigned: !state.userSigned,
      };
    case PENDING_ITEM_UPDATE_SIGNATURE:
      return {
        ...state,
        signature: action.signature,
      };
    case PENDING_ITEM_START_TALKING:
      return {
        ...state,
        startTalking: action.startTalking,
      };
    default:
      return state;
  }
};
