import css from "styled-jsx/css";
import { variables } from "patient_app/stylesheets/variables.js";

export default css.global`
  .dashboard-block .test-due {
    padding: 1.75rem;
  }
  .dashboard-block .test-due.due_today {
    background-color: ${variables.red};
  }
  .dashboard-block .test-due.overdue {
    background-color: ${variables.redDark};
  }

  .dashboard-block .test-due p {
    color: white;
    font-size: 0.85rem;
    font-family: ${variables.objReg};
    line-height: 1.5rem;
    margin: 0;
  }

  .dashboard-block .test-due .regular-link {
    font-family: ${variables.objReg};
    font-size: 0.85rem;
    color: white;
    text-decoration: underline;
    margin-bottom: 0.5rem;
    width: fit-content;
    text-align: left;
    padding: 0;
  }

  .dashboard-block .block-item p.badge.open {
    background-color: ${variables.green};
  }
  .dashboard-block .block-item p.badge.red {
    background-color: ${variables.red};
  }
  .dashboard-block .block-item p.badge.closed {
    display: none;
  }

  @media only screen and (max-width: 600px) {
  }
`;
