import { DateTime, Interval } from "luxon";
import timezones from "patient_app/components/input_fields/utils/constants";
import { variables } from "patient_app/stylesheets/variables";

let appointmentHelpers = {
  /*
    converts ProviderAvailability objects into luxon Intervals

    @params
    avs: an array of ProviderAvailability objects
    tz: time zone of clinic or user
    range: number of days to show to the user

    @returns array
    each index in the array is an object with an Interval, admin ID, and time zone
  */
  formatAvsFromData(avs = [], tz, range = 14) {
    let formattedAvs = [];
    // loop through each provider availability
    avs.forEach((av) => {
      let dates = [];
      const today = DateTime.local();

      // go through the range of dates that will be shown
      // if the weekday matches the provider availability day, push to dates[]
      for (var i = 0; i < range; i++) {
        // in rails: sunday = 0 --> saturday = 6
        // in luxon: monday = 1 --> sunday = 7
        let compareDate = today.plus({ days: i });
        if (av.day === compareDate.weekday % 7) {
          // check for effective start and end dates
          // activeAv = true when av has no effective dates, or compareDate falls
          // within the effective date ranges
          let activeAv = false;
          let afterStart =
            av.effective_start &&
            DateTime.fromISO(av.effective_start, { zone: tz }) <=
              compareDate.startOf("day");
          let beforeEnd =
            av.effective_end &&
            DateTime.fromISO(av.effective_end, { zone: tz }) >=
              compareDate.endOf("day");

          // if there is no effective start or end
          if (!av.effective_start && !av.effective_end) {
            activeAv = true;
          }
          // if there is an effective start but no end
          else if (av.effective_start && !av.effective_end) {
            activeAv = afterStart;
          }
          // if there is an effective end but no start
          else if (!av.effective_start && av.effective_end) {
            activeAv = beforeEnd;
          }
          // if there is both an effective start and end
          else {
            activeAv = afterStart && beforeEnd;
          }

          if (activeAv) {
            dates.push(DateTime.local().plus({ days: i }));
          }
        }
      }

      // set provider availability
      for (var date of dates) {
        let startTime = DateTime.fromObject({
          year: date.year,
          month: date.month,
          day: date.day,
          hour: av.sa_h,
          minute: av.sa_m,
          zone: av.time_zone,
        }).setZone(tz);
        let endTime = DateTime.fromObject({
          year: date.year,
          month: date.month,
          day: date.day,
          hour: av.ea_h,
          minute: av.ea_m,
          zone: av.time_zone,
        }).setZone(tz);

        let avObject = {
          interval: Interval.fromDateTimes(startTime, endTime),
          adminId: av.admin_id,
          timeZone: tz,
        };
        formattedAvs.push(avObject);
      }
    });
    return formattedAvs;
  },

  /*
    converts CalEvent objects into luxon Intervals

    @params
    avs: an array of ProviderAvailability objects
    tz: time zone of clinic or user

    @returns array
    each index in the array is an object with an Interval, admin ID, and time zone
  */
  formatAvsFromDateTimes(avs = [], tz) {
    let formattedAvs = [];
    avs.forEach((av) => {
      let startTime = DateTime.fromISO(av.start_at)
        .startOf("minute")
        .setZone(tz);
      let endTime = DateTime.fromISO(av.end_at).startOf("minute").setZone(tz);
      let avObject = {
        interval: Interval.fromDateTimes(startTime, endTime),
        adminId: av.care_provider_id,
        timeZone: tz,
      };
      formattedAvs.push(avObject);
    });

    return formattedAvs;
  },

  /*
    removes overlapping times from existing availabilities, and only keeps
    resulting availabilities that are long enough to schedule an appointment

    @params
    avs: an array of objects that include Intervals
    unavs: an array of CalEvent objects (or at least objects that contain
           an ISO start_at, ISO end_at, time_zone, and care_provider_id)
    tz: time zone of clinic or user
    apptLength: desired appointment length (in minutes)

    @returns array
    each index in the array is an object with an Interval, admin ID, and time zone
  */
  removeUnavailable(avs = [], unavs = [], tz, apptLength = 60) {
    let formattedAvs = avs;
    let unavInterval, diff, startTime, endTime;
    unavs.forEach((unav) => {
      // convert ISO DateTimes to luxon DateTimes, set the time zone, and round to the nearest minute
      startTime = DateTime.fromISO(unav.start_at).setZone(tz).startOf("minute");
      endTime = DateTime.fromISO(unav.end_at).setZone(tz).startOf("minute");
      unavInterval = Interval.fromDateTimes(startTime, endTime);

      let newAvs = [];
      formattedAvs.forEach((av) => {
        if (
          unav.care_provider_id === av.adminId &&
          unavInterval.overlaps(av.interval)
        ) {
          diff = av.interval.difference(unavInterval);
          diff.forEach((d) => {
            // if length in minutes is greater than minimum appointment time
            // and start time is before end time
            if (d.length("minutes") >= apptLength && d.s < d.e) {
              // change the new interval's time zone to match the time zone of the availability
              // to catch any discrepancies with unavailability scheduled in another time zone
              d = Interval.fromDateTimes(
                d.s.setZone(av.timeZone),
                d.e.setZone(av.timeZone)
              );

              // push the new interval
              newAvs.push({
                interval: d,
                adminId: av.adminId,
                timeZone: av.timeZone,
              });
            }
          });
        } else {
          newAvs.push(av);
        }
      });
      formattedAvs = newAvs;
    });

    return formattedAvs;
  },

  /*
    removes time before bufferTime so that it cannot be scheduled

    @params
    avs: an array of objects that include Intervals
    adminIds: an array of admin IDs
    tz: time zone to use (either clinic time zone or user time zone)
    apptLength: desired appointment length (in minutes)
    bufferTime: how far in advance a user can schedule an appointment (in hours)

    @returns array
    each index in the array is an object with an Interval of apptLength time,
    admin ID, and time zone
  */
  filterBeforeNow(
    avs = [],
    adminIds = [],
    tz,
    apptLength = 60,
    bufferTime = 1
  ) {
    let intervals = [];
    adminIds.forEach((id) => {
      let endTime = DateTime.local().setZone(tz).plus({ hours: bufferTime });
      if (endTime.minute > 0 && endTime.minute < 30) {
        // round up to nearest half hour
        endTime = endTime.set({ minute: 30 });
      } else if (endTime.minute > 30) {
        endTime = endTime.plus({ hours: 1 }).startOf("hour");
      }

      intervals.push({
        start_at: DateTime.local()
          .setZone(tz)
          .startOf("day")
          .minus({ weeks: 1 }),
        end_at: endTime,
        time_zone: tz,
        care_provider_id: id,
      });
    });
    let formattedAvs = appointmentHelpers.removeUnavailable(
      avs,
      intervals,
      tz,
      apptLength
    );
    return formattedAvs;
  },

  /*
    divides availabilities into apptLength chunks; also removes duplicate
    appointment times

    @params
    avs: an array of objects that include Intervals
    apptLength: desired appointment length (in minutes)
    shownLength: appointment length that member sees (in minutes)

    @returns array
    each index in the array is an object with an Interval of apptLength time,
    admin ID, and time zone
  */
  divideAndFilterTimes(avs = [], apptLength = 30, shownLength = null) {
    let newAvs = [];
    avs.forEach((av) => {
      let roundedAv = av;
      if (roundedAv.interval.s) {
        // round up to nearest half hour
        if (
          roundedAv.interval.s.minute > 0 &&
          roundedAv.interval.s.minute < 30
        ) {
          roundedAv.interval.s = roundedAv.interval.s.set({ minute: 30 });
        } else if (roundedAv.interval.s.minute > 30) {
          roundedAv.interval.s = roundedAv.interval.s
            .plus({ hours: 1 })
            .startOf("hour");
        }

        // split times by appt length
        let timeSlots = [];
        timeSlots = roundedAv.interval.splitBy({ minutes: apptLength });
        timeSlots.forEach((time, j) => {
          if (time.length("minutes") === apptLength) {
            if (shownLength) {
              // modify interval to match shownLength rather than apptLength
              time.e = time.s.plus({ minutes: shownLength });
            }
            newAvs.push({
              interval: time,
              adminId: av.adminId,
              timeZone: av.timeZone,
            });
          }
        });
      }
    });

    // delete duplicate times
    let filterNewAvs = [];
    newAvs.forEach((newAv) => {
      let duplicate = false;
      for (let i = 0; i < filterNewAvs.length; i++) {
        // using a for loop to break on first duplicate
        if (
          appointmentHelpers.compareIntervalsAreEqual(
            filterNewAvs[i].interval,
            newAv.interval
          )
        ) {
          duplicate = true;
          break;
        }
      }
      if (!duplicate) {
        filterNewAvs.push(newAv);
      }
    });

    return filterNewAvs;
  },

  /*
    compares two luxon Intervals to see if the start and end times are equal

    from the luxon spec: 'Equality check Two DateTimes are equal iff they represent
    the same millisecond, have the same zone and location, and are both valid'

    @params
    time1, time2: Intervals

    @returns boolean
    true if the Intervals are equal; false otherwise
  */
  compareIntervalsAreEqual(time1, time2) {
    if (time1.equals(time2)) {
      return true;
    }
    return false;
  },

  /*
    counts the number of slots for available appointment times for each induction
    block, based on provider availability

    if there are no appointments already scheduled in a block,
    # slots = (# of concurrent members) * (# of available providers)

    @params
    avs: availabilities for appointments with unavailable times removed
    range: range of days that will be shown to the user
    blocks: induction blocks for a clinic

    @returns array
    each index in the array is an array of objects that represent blocks; each "block"
    contains the following fields:
    - slots: maximum number of members that can be scheduled in that block (integer)
    - concurrentPatients: maximum number of members that one provider can see per block (integer)
    - interval: block time (luxon Interval)
    - adminId: available provider (integer)
  */
  availableProviderBlocks(avs, range, blocks) {
    const today = DateTime.local().startOf("day");

    // days represents the days that will be shown
    let allBlocks = [];
    if (!blocks || blocks.length === 0) {
      return allBlocks;
    }

    for (var i = 0; i < range; i++) {
      let dayBlocks = [];
      blocks.forEach((block) => {
        let blockStart = DateTime.local()
          .setZone(block.time_zone)
          .set({ hour: block.sa_h, minute: block.sa_m })
          .plus({ days: i })
          .startOf("minute");
        let blockEnd = blockStart.plus({ hour: 1, minutes: 30 });
        dayBlocks.push({
          slots: 0,
          concurrentPatients: block.concurrent_patients,
          interval: Interval.fromDateTimes(blockStart, blockEnd),
          adminId: null,
        });
      });

      allBlocks.push(dayBlocks);
    }

    // for each provider availability, calculate the number of days from today
    avs.forEach((av, i) => {
      let interval = Interval.fromDateTimes(today, av.interval.s);
      let idx = Math.floor(interval.length("days"));

      // for each block on that day, check if there is not already another provider
      // av, and that provider av covers block
      let dayBlocks = allBlocks[idx];
      if (dayBlocks) {
        dayBlocks.forEach((block) => {
          // "engulfs": provider av is greater than or equal to block slot time
          if (!block.adminId && av.interval.engulfs(block.interval)) {
            block.slots = block.slots + block.concurrentPatients;
            block.adminId = av.adminId;
          }
        });
      }
    });

    return allBlocks;
  },

  /*
    removes block slots where other appointments have already been scheduled;
    one slot = one member that can be seen in an induction block

    @params
    days: array of blocks for each day created from availableProviderBlocks()
    scheduledAppts: appointments scheduled with providers

    @returns array
    each index in the array is an array of objects that represent blocks; each "block"
    contains the following fields:
    - slots: maximum number of members that can be scheduled in that block (integer)
    - concurrentPatients: maximum number of members that one provider can see per block (integer)
    - interval: block time (luxon Interval)
    - adminIds: available provider (integer)
  */
  filterScheduledInBlock(allBlocks, scheduledAppts) {
    let availableBlocks = Array.from(allBlocks);
    const today = DateTime.local().startOf("day");
    // check if existing appts overlap with any existing blocks for a given admin
    // remove one slot from that admin if there is overlap
    scheduledAppts.forEach((appt) => {
      let interval = Interval.fromDateTimes(
        today,
        DateTime.fromISO(appt.start_at)
      );
      let idx = Math.floor(interval.length("days"));
      let dayBlocks = availableBlocks[idx];

      let overlap =
        dayBlocks &&
        dayBlocks.findIndex(appointmentHelpers.overlapsWithBlock, appt);
      if (overlap > -1) {
        dayBlocks[overlap].slots = dayBlocks[overlap].slots - 1;
      }
    });

    return availableBlocks;
  },

  /*
    helper function for createBlockSlots()

    @params
    block: one induction block (DateTime)
    this: appointment time object (CalEvent) - from Array.find

    @returns boolean
    true if there is any overlap between the appointment time and the induction
    block; false otherwise
  */
  overlapsWithBlock(block) {
    // no overlap if admin IDs don't match
    if (block.adminId !== this.care_provider_id) {
      return false;
    }

    // convert appt to Interval
    let apptInterval = Interval.fromDateTimes(
      DateTime.fromISO(this.start_at).startOf("minute"),
      DateTime.fromISO(this.end_at).startOf("minute")
    );

    // compare if Intervals overlap
    return apptInterval.overlaps(block.interval);
  },
  formatAvsTimeString(av) {
    let start = appointmentHelpers.formatTimeString(
      av.interval.s.hour,
      av.interval.s.minute
    );
    let end = appointmentHelpers.formatTimeString(
      av.interval.e.hour,
      av.interval.e.minute
    );
    return `${start} - ${end}`;
  },
  formatTimeString(hour, min) {
    let dt = DateTime.local().set({ hour: hour, minute: min });
    return dt.toFormat("h:mma");
  },
  getTimesFromDay(avs, day) {
    let dayAvs = [];
    avs.forEach((av) => {
      if (
        av.interval.s.toLocaleString(DateTime.DATE_SHORT) ===
        day.toLocaleString(DateTime.DATE_SHORT)
      ) {
        dayAvs.push(av);
      }
    });
    return dayAvs;
  },
  formatDate(dt) {
    const suffixes = ["th", "st", "nd", "rd"];
    const tail = dt.c.day % 100;
    const suffix =
      suffixes[(tail < 11 || tail > 13) && tail % 10] || suffixes[0];

    return dt.toFormat("MMMM d") + suffix;
  },
  timeZoneToMoment(zone) {
    for (let t of timezones) {
      if (t[1] === zone) return t[0];
    }

    return zone; // if not found, assume it is already in moment
  },
  changeTimeZone(days, tz) {
    if (!days) {
      return;
    }
    // days is an array of arrays, where each array contains DateTimes
    let updatedDays = days;
    for (var day of updatedDays) {
      for (var time of day) {
        let start = time.interval.s;
        let end = time.interval.e;
        time.interval.s = start.toUTC().setZone(tz);
        time.interval.e = end.toUTC().setZone(tz);
        time.timeZone = tz;
      }
    }

    return updatedDays;
  },

  /*
    returns title for appointment showed on user dashboard

    @params
    appt: one event from CalEvent.mutated_events

    @returns string
  */
  getTitle(appt) {
    let title = "Appointment";
    if (
      [
        "counselor_intake",
        "counselor_chat",
        "clinic_chat",
        "new_evaluation_counseling",
        "coach_chat_ecc",
        "recovery_counseling",
        "talk_therapy",
      ].includes(appt.category)
    ) {
      title = "Virtual Counselor Appointment";
    } else if (
      ["welcome_intake", "workit_welcome_intake"].includes(appt.category)
    ) {
      title = "Virtual Orientation Appointment";
    } else if (appt.category === "recovery_group") {
      title = `${appt.title_member_facing || appt.title} Group Meeting`;
    } else if (appt.category === "sud_assessment") {
      title = "In-Person Assessment";
    } else {
      // indicate if "in person"
      if (appt.category.includes("in_person")) {
        title = "In-Person Appointment";
      } else {
        title = "Virtual Medical Appointment";
      }
    }

    return title;
  },
  getApptColor(appt) {
    let color = variables.green;
    if (appt.category === "recovery_group") {
      color = variables.greenSplashBackground;
    } else if (appt.category === "sma") {
      color = variables.greenDark;
    } else if (
      appt.category.includes("induction") ||
      appt.category.includes("follow_up")
    ) {
      color = variables.blue;
    }

    return color;
  },
  filterCredentialedProviders(avs, credentialedProviders) {
    let toRemove = [];
    const now = DateTime.local().endOf("day");
    credentialedProviders.map((cp, x) => {
      //only do date math if credentialed provider has effectivce start defined, and it's in the future
      if (cp.effective_start) {
        const effectiveStart = DateTime.fromISO(cp.effective_start);
        if (effectiveStart > now) {
          const providerAvs = avs.filter((av, x) => av.adminId === cp.admin_id);
          providerAvs.map((av, x) => {
            if (av.interval.s < effectiveStart) {
              toRemove.push(av);
            }
          });
        }
      }
    });

    if (toRemove.length > 0) {
      avs = avs.filter((av, i) => !toRemove.includes(av));
    }

    return avs;
  },
  /*
    returns availabilities with holidays filtered out

    @params
    avs: an array of objects that include Intervals
    holidays: an array of ClinicHoliday objects

    @returns array
    each index in the array is an object with an Interval of apptLength time,
    admin ID, and time zone
  */
  removeHolidays(avs, holidays) {
    let newAvs = [];
    avs.forEach((av) => {
      const date = av.interval.s?.toFormat("yyyy-LL-dd");
      const holiday = holidays.find((hol) => hol.date === date);
      if (!holiday) {
        newAvs.push(av);
      }
    });
    return newAvs;
  },
  /*
    returns DateTime of calEvent.start_at + calEvent.review_before_minutes

    @params
    calEvent (object): CalEvent
    timeZone (string): (optional) time zone, e.g. "America/Detroit"
  */
  calculateStartAt(calEvent, timeZone = null) {
    if (!calEvent || !calEvent.start_at) {
      return null;
    }

    let startAt;
    if (calEvent.member_start_at) {
      startAt = DateTime.fromISO(calEvent.member_start_at);
    } else {
      startAt = DateTime.fromISO(calEvent.start_at);
      if (startAt.isValid && calEvent.review_before_minutes) {
        startAt = startAt.plus({ minutes: calEvent.review_before_minutes });
      }
    }

    if (timeZone) {
      startAt = startAt.setZone(timeZone);
    }

    return startAt;
  },
  calculateEndAt(calEvent, timeZone = null) {
    if (!calEvent || !calEvent.end_at) {
      return null;
    }

    let endAt;
    if (calEvent.member_end_at) {
      endAt = DateTime.fromISO(calEvent.member_end_at);
    } else {
      endAt = DateTime.fromISO(calEvent.end_at);
    }

    if (timeZone) {
      endAt = endAt.setZone(timeZone);
    }

    return endAt;
  },
  // Make treatment_types abbrvs readable and remove duplicates
  formatTreatmentType(treatment_type) {
    return (
      {
        oud: "Opioids",
        aud_moderation: "Alcohol",
        aud_abstinence: "Alcohol",
        anx: "Anxiety",
        dep: "Depression",
      }[treatment_type] || treatment_type
    );
  },
  uniqueTreatmentTypes(treatment_types) {
    let treatmentTypes = treatment_types.map((tt) =>
      appointmentHelpers.formatTreatmentType(tt)
    );

    function onlyUnique(value, index, array) {
      return array.indexOf(value) === index;
    }

    var unique = treatmentTypes.filter(onlyUnique);
    return unique;
  },
};

export default appointmentHelpers;
